import { useMutation, useQueryClient } from 'react-query';

import { addExternalReferral } from '../../../../api/playerReferrals';

export const useAddExternalReferral = () => {
  const queryClient = useQueryClient();

  return useMutation(addExternalReferral, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('externalReferrals');
    },
  });
};
