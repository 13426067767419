import axiosWrapper from './axiosInterceptor';

export const getTimeline = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/notifications/timeline?offset=0&limit=0',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addViewedPage = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/notifications/viewed-page',
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
