import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { useAddInfoLetter } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import { InformationLetterForm, EnumInformationLetterFormMode } from '../../../../organisms/Forms/InformationLetterForm'

Modal.setAppElement('#root');

export const LettersModule = ({ params }) => {
  const { push } = useHistory();

  const { mutateAsync, isLoading: isAddLoading } = useAddInfoLetter();

  const onSubmit = async (dataToTrim, onError) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (params.problemId) {
      formData.append('problemId', params.problemId);
    }

    formData.append('name', data.name);
    formData.append('date', data.date);

    if (data.indication) {
      formData.append('indication', data.indication);
    }

    if (data.outcome) {
      formData.append('outcome', data.outcome);
    }

    if (data.currentFiles) {
      for (const key of data.currentFiles) {
        formData.append('file', key);
      }
    }

    try {
      await mutateAsync({
        teamId: params.teamId,
        playerId: params.playerId,
        formData,
      });
      push(`/teams/${params.teamId}/players/${params.playerId}/information`);
    } catch (error) {
      console.log('error', error);
      onError(error?.data?.message);
    }
  };

  return (
    <InformationLetterForm
      onSubmit={onSubmit}
      mode={EnumInformationLetterFormMode.Add}
      isLoading={isAddLoading}
      params={params}
    />
  );
};
