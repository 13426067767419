import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import * as yup from 'yup'
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { Typography } from '../../../../atoms/Typography';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useHistory } from 'react-router-dom';
import { FormItem, InputError, Fieldset } from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import { formatFormDate } from '../../../../../utils/formatFormDate';
import Modal from 'react-modal';
import { getMedicationCategories } from 'api/playerInformation';
import { loadOptions } from '../../../../../utils/loadSelectOptions';
import { AllergyModule } from './Modules/allergy';
import { MedsModule } from './Modules/meds';
import { VaccinesModule } from './Modules/vaccines';
import { useAddInfoMedications } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import { REQUIRED_FIELD } from "../../../../../constants/massages";
import { EnumMedicationCategoryTypes } from "../../../../../constants/medicationCategoryTypes";

Modal.setAppElement('#root');

const AddMedication = yup.object({
  medicationsCategory: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
  name: yup.string().required(REQUIRED_FIELD),
  date: yup.date().required().typeError(REQUIRED_FIELD),
})

const AddMedicationAllergy = yup.object({
  medicationsCategory: yup.object().required(REQUIRED_FIELD).typeError(REQUIRED_FIELD),
  name: yup.string(),
  date: yup.date().required().typeError(REQUIRED_FIELD),
})

export const MedicationsModule = ({ params }) => {
  const useYupValidationResolver = ({ validationSchema, key, allergyValidationSchema }) =>
    useCallback(
      async data => {
        try {
          let schema = validationSchema
          switch (data[key]?.label) {
            case 'allergy':
              schema = allergyValidationSchema
              break

            default:
              break
          }
          const values = await schema.validate(data, {
            abortEarly: false
          });

          return {
            values,
            errors: {}
          };
        } catch (errors) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message
                }
              }),
              {}
            )
          };
        }
      },
      [validationSchema]
    );

  const maxDate = new Date().toISOString().split('T')[0];

  const methods = useForm({
    mode: 'all',
    resolver: useYupValidationResolver({
      key: 'medicationsCategory',
      validationSchema: AddMedication,
      allergyValidationSchema: AddMedicationAllergy,
    }),
    defaultValues: {
      date: maxDate,
    }
  });

  const { push } = useHistory();

  const {
    register,
    unregister,
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { errors, isValid },
  } = methods;

  const medicationsCategoryName = watch('medicationsCategory')?.value?.name;

  // Receiving entity "medicationCategories"
  const { data: medicationCategories = [] } = useQuery(
    'medicationCategories',
    getMedicationCategories
  );

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as an 3rd argument
  const categoryOptions = [];
  for (let i = 0; i < medicationCategories.length; ++i) {
    categoryOptions.push({
      value: medicationCategories[i],
      label: medicationCategories[i].name,
    });
  }

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isAddLoading } = useAddInfoMedications();

  const newData = {};

  const onSubmitConfirm = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        teamId: params.teamId,
        playerId: params.playerId,
        ...(params.problemId && { problemId: params.problemId }),
        categoryTypeId: newData.medicationsCategory.id,
        name: newData.name,
        date: newData.date,
        ...(newData.medication && { medication: newData.medication }),
        ...(newData.reaction && { reaction: newData.reaction }),
        ...(newData.form && { form: newData.form }),
        ...(newData.times && { times: newData.times }),
        ...(newData.dose && { dose: newData.dose }),
        ...(newData.reason && { reason: newData.reason }),
        ...(newData.reason && { reason: newData.reason }),
        ...(newData.vaccinesIdsList && { vaccinesIdsList: newData.vaccinesIdsList.map(i => +i) }),
      });
      push(`/teams/${params.teamId}/players/${params.playerId}/information`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (isValid) {
      toggleModal();
    }
  };

  const _getModule = (name) => {
    switch (name) {
      case EnumMedicationCategoryTypes.Allergy.name:
        return <AllergyModule/>

      case EnumMedicationCategoryTypes.Vaccines.name:
        return <VaccinesModule/>

      case EnumMedicationCategoryTypes.Meds.name:
        return <MedsModule/>

      default:
        return null
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  // In case we need to unregister some fields
  useEffect(() => {
    (medicationsCategoryName !== EnumMedicationCategoryTypes.Allergy.name &&
      unregister(['medication', 'reaction'])) ||
    (medicationsCategoryName !== EnumMedicationCategoryTypes.Meds.name &&
      unregister(['form', 'dose', 'times', 'reason'])) ||
    (medicationsCategoryName !== EnumMedicationCategoryTypes.Vaccines.name && unregister(['type', 'booster']));
  }, [medicationsCategoryName]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitConfirm)}>
        <Fieldset>
          <FormItem>
            <Controller
              name='medicationsCategory'
              control={control}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='medicationsCategory'>Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId='medicationsCategory'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['medicationsCategory'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, categoryOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='medicationsCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='date'
              max={maxDate}
              {...register('date')}
              placeholder='Enter Here'
              label='Date'
              error={errors.name}
              className={`${errors['date'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='date' as={InputError}/>
          </FormItem>
        </Fieldset>

        {_getModule(medicationsCategoryName)}

        <Button type='button' onClick={onPreSubmit}>
          <Icon name='check'/>
          Submit Information
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          {submitMessage?.length > 0 ? (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Something went wrong
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  {submitMessage}
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModalClear} variant={'secondary'}>
                  Try again
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Are you sure?
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  Do you really want to add this information?
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModal} variant={'secondary'}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmitConfirm)}
                  disabled={isAddLoading}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </Modal>
      </form>
    </FormProvider>
  );
};
