import { useMutation, useQueryClient } from 'react-query';
import {
  addConsult,
  editConsult,
  addProblem,
  editProblem,
  addTreatment,
  editTreatment,
  addMedication,
  editMedication,
  addResult,
  editResult,
  addLetter,
  editLetter,
  approveInfo,
} from 'api/playerInformation';

export const useAddInfoConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(addConsult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(editConsult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('consultInformation');
    },
  });
};

export const useApproveInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(approveInfo, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};



export const useAddInfoProblem = () => {
  const queryClient = useQueryClient();

  return useMutation(addProblem, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoProblem = () => {
  const queryClient = useQueryClient();

  return useMutation(editProblem, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('problemInformation');
    },
  });
};

export const useAddInfoMedications = () => {
  const queryClient = useQueryClient();

  return useMutation(addMedication, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoMedications = () => {
  const queryClient = useQueryClient();

  return useMutation(editMedication, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('medicationsInformation');
    },
  });
};

export const useAddInfoResult = () => {
  const queryClient = useQueryClient();

  return useMutation(addResult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoResult = () => {
  const queryClient = useQueryClient();

  return useMutation(editResult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('resultInformation');
    },
  });
};

export const useAddInfoTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation(addTreatment, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation(editTreatment, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('treatmentsInformation');
    },
  });
};

export const useAddInfoLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(addLetter, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useEditInfoLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(editLetter, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
      queryClient.invalidateQueries('letterInformation');
    },
  });
};
