import styled from 'styled-components';

const Component = styled.button`
  ${(props) => props.theme.buttons[props.variant]};
  color: ${(props) =>
    props.color
      ? `${props.color} !important`
      : props.theme.buttons[`${props.variant}`].color};
  background-color: ${(props) =>
    props.backgroundColor
      ? `${props.backgroundColor} !important`
      : props.theme.buttons[`${props.variant}`].backgroundColor};

  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
    background-color: #cacaca;
  }

  svg {
    transform: translateY(-1px);
    margin-right: 10px;
    fill: ${(props) =>
      props.color
        ? `${props.color} !important`
        : props.theme.buttons[`${props.variant}`].color};
  }

  path {
    fill: ${(props) =>
      props.color
        ? `${props.color} !important`
        : props.theme.buttons[`${props.variant}`].color};
  }
`;

export default Component;
