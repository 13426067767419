import React, {useRef} from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import {
  ContentWrapper,
} from '../../organisms/Layout';
import { Button } from '../../atoms/Button';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';

import {getExternalReferral, getExternalReferralPDF} from "../../../api/playerReferrals";

Modal.setAppElement('#root');

export const OneReferral = () => {
  let { externalReferralId } = useParams();

  console.log('externalReferralId: ', externalReferralId);

  // Receiving entity "externalReferral" for a particular Team by invoking "getExternalReferral" hook
  const { data: externalReferralData, isLoading } = useQuery(
      ['externalReferral'],
      () => getExternalReferral(externalReferralId)
  );

  if (!externalReferralData) {
    return '';
  }

  return (
      <ContentWrapper>
        <TableHeader>
          <Typography as={'h1'} tag={'h1'}>
            View Referral
          </Typography>
          <Button
              as={Link}
              variant={'secondary'}
              onClick={() => history.back()}
          >
            Cancel
          </Button>
        </TableHeader>
        <div style={{width: 800}}>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_name}<br />{externalReferralData?.external_address}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_telephone}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_email}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.date}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            Team
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.team.name}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            Player
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.player?.user.first_name} {externalReferralData?.player?.user.last_name}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>D.O.B.: {externalReferralData?.player?.birthday}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.player?.address_1}, {externalReferralData?.player?.address_2}, {externalReferralData?.player?.city}. {externalReferralData?.player?.postcode}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            <>{externalReferralData?.name}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.description}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            Your Sincerely,
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textIndent: '50px'}}>
            <>{externalReferralData?.added_by_user?.first_name} {externalReferralData?.added_by_user?.last_name}</>
          </Typography>
        </div>
      </ContentWrapper>
  );
};
