import axiosWrapper from './axiosInterceptor';

export const getForm = async (teamId, formId) => {
    try {
        const response = await axiosWrapper({
            method: 'GET',
            url: `/groupforms/team/${teamId}/form/${formId}`,
            responseType: 'json',
            withCredentials: true,
        });
        return response.data;
    } catch (err) {
        return Promise.reject(err.response);
    }
};

export const getForms = async (teamId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/groupforms/team/${teamId}?offset=0&limit=0`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addForm = async (data) => {
    try {
        const response = await axiosWrapper({
            method: 'POST',
            url: `/groupforms/team/${data.teamId}`,
            responseType: 'json',
            withCredentials: true,
            data,
        });
        return response.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err.response);
    }
};

export const editForm = async (data) => {
    try {
        const response = await axiosWrapper({
            method: 'PUT',
            url: `/groupforms/team/${data.teamId}/form/${data.formId}`,
            responseType: 'json',
            withCredentials: true,
            data,
        });
        return response.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err.response);
    }
};
