import React from 'react';
import { NotificationLink, NotificationBox } from './styles';

export const Notification = ({ info }) => {
  const refactorNotification = (data) => {
    switch (data.action_object_to) {
      case 'team':
        if (data.action !== 'deleted') {
          return data.team !== null && `/teams/${data.team?.id}`;
        }
      case 'player':
        if (data.action !== 'deleted' && data.player?.team) {
          return `/teams/${data.player?.team?.id}/players/${data.player?.id}`;
        }
      case 'doctor':
        if (data.action !== 'deleted' && data.doctor?.team) {
          return `/teams/${data.doctor?.team?.id}/team-medics/${data.doctor?.id}/edit`;
        }
      case 'problem':
        if (
          data.action !== 'deleted' &&
          data.player?.team?.id &&
          data.problem?.id
        ) {
          return `/teams/${data.player?.team?.id}/players/${data.player?.id}/problems/${data.problem.id}/edit`;
        }
      case 'information':
        if (
          data.action !== 'deleted' &&
          data.player?.team?.id &&
          data.information?.id
        ) {
          return `/teams/${data.player?.team?.id}/players/${data.player?.id}/information/${data.information.id}/edit`;
        }
      case 'configuration':
        if (data.action !== 'deleted') {
          if (data.problem_category) {
            return `/configuration/problem-categories`;
          }
          if (data.sport) {
            return `/configuration/sports`;
          }
        }
      default:
        return '#';
    }
  };

  const oldDate = info?.created_at;
  const newData = new Date(oldDate);

  const finalDate = `${
    newData.getDate() +
    '/' +
    (newData.getMonth() + 1) +
    '/' +
    newData.getFullYear()
  }`;

  return (
    <>
      {info && (
        <NotificationLink to={refactorNotification(info) || ''}>
          <NotificationBox>
            <p>{finalDate}</p>
            <h3>
              {info.action} {info.action_object_to}
            </h3>
            <p>{info.msg}</p>
          </NotificationBox>
        </NotificationLink>
      )}
    </>
  );
};
