import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Input } from '../../../../atoms/Input';
import {
  FormItem,
  InputError,
  CheckboxGroup,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { Checkbox } from 'components/atoms/Checkbox';
import {
  getConsultInfo,
  getConsultInformationAudios,
  getConsultInformationById,
} from 'api/playerInformation';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';

Modal.setAppElement('#root');

export const ConsultModule = ({ params }) => {
  const { teamId, playerId, informationId } = params;

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      investigations: [],
      plans: [],
    },
  });

  const {
    data: consultInformation = [],
    isLoading: consultInfoIsLoading,
    isError: consultInfoIsError,
  } = useQuery(['consultInformation', informationId], () =>
    getConsultInformationById(teamId, playerId, informationId)
  );

  // Receiving entity "playerConsultInfo"
  const { data: playerConsultInfo = [], isLoading } = useQuery(
    'playerConsultInfo',
    getConsultInfo
  );

  const [investArray, setInvestArray] = useState([]);
  const [planArray, setPlanArray] = useState([]);

  const [prevAudios, setPrevAudios] = useState([]);

  const fetchAudio = async ({ teamId, playerId, informationId, id }) => {
    const data = await getConsultInformationAudios({
      teamId,
      playerId,
      informationId,
      id,
    });

    const arr = prevAudios.map((photo) => {
      if (photo.id === id) {
        return {
          ...photo,
          url: data.publicUrl,
        };
      }

      return photo;
    });

    setPrevAudios(arr);
  };

  const formPhotos = [];

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const detailsDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const detailsDateTimeFormat = new Intl.DateTimeFormat("en-GB", detailsDateOptions);

  useEffect(() => {
    if (!consultInfoIsLoading) {
      const d = new Date(consultInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);

      let detailsDate, detailsDateString = '';
      if (consultInformation.consult?.date) {
        detailsDate = new Date(consultInformation.consult.date);
        detailsDateString = detailsDateTimeFormat.format(detailsDate);
      }
      [
        { name: 'history', value: consultInformation.consult.history },
        { name: 'exam', value: consultInformation.consult.exam },
        { name: 'date', value: detailsDateString },
        { name: 'diagnosis', value: consultInformation.consult.diagnosis.name },
        { name: 'clinicianName', value: consultInformation.consult.clinicianName },
        { name: 'location', value: consultInformation.consult.location },
        { name: 'registrationNumber', value: consultInformation.consult.registrationNumber },
        { name: 'details', value: consultInformation.consult.details },
        { name: 'dateAdded', value: dateAddedString },
      ].forEach(({ name, value }) => setValue(name, value));
      setInvestArray(
        consultInformation.consult.investigations.map((e) => e.name)
      );
      setPlanArray(consultInformation.consult.plan.plans.map((e) => e.name));
      const audios = consultInformation.consult.audio.map((audio) => {
        return {
          ...audio,
          url: null,
        };
      });
      setPrevAudios(audios);
    }
  }, [consultInformation, consultInfoIsLoading]);

  return (
    <form>
      {consultInfoIsError && (
        <p>An error occured during receiving consult data</p>
      )}
      {consultInfoIsLoading ? (
        'Receiving investigation data...'
      ) : (
        <>
          <FormItem>
            <Input
              id='clinicianName'
              name='clinicianName'
              type='text'
              variant='input'
              {...register('clinicianName')}
              placeholder='Enter Here'
              label='Clinician Name'
              disabled
            />
          </FormItem>
          <FormItem>
            <Input
              id='location'
              name='location'
              type='text'
              variant='input'
              {...register('location')}
              label='Location'
              disabled
            />
          </FormItem>
          <FormItem small>
            <Input
              id='dateAdded'
              name='dateAdded'
              type='text'
              {...register('dateAdded', {})}
              label='Date Added'
              disabled
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='text'
              {...register('date')}
              label='Date'
              disabled
            />
          </FormItem>
          <FormItem>
            <Input
              id='registrationNumber'
              name='registrationNumber'
              type='text'
              variant='input'
              {...register('registrationNumber')}
              label='Registration Number'
              disabled
            />
          </FormItem>
          <FormItem>
            <Input
              id='history'
              name='history'
              type='text'
              variant='textarea'
              disabled
              {...register('history', {
              })}
              placeholder='Enter Here'
              label='History'
              error={errors.name}
              className={`${errors['history'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='history' as={InputError} />
          </FormItem>
          {!isLoading && (
            <FormItem>
              <label>Investigations</label>
              <CheckboxGroup>
                {playerConsultInfo.investigations.map((item) => {
                  return (
                    <Checkbox
                      {...register('investigations')}
                      key={item.name}
                      type='checkbox'
                      value={item.id}
                      label={item.name}
                      disabled
                      checked={investArray.includes(item.name)}
                      className={`${errors['investigations'] ? 'error' : ''}`}
                    />
                  )
                })}
              </CheckboxGroup>
            </FormItem>
          )}
          <FormItem>
            <Input
              id='exam'
              name='exam'
              type='text'
              variant='textarea'
              disabled
              {...register('exam', {
              })}
              placeholder='Enter Here'
              label='Exam'
              error={errors.name}
              className={`${errors['exam'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='exam' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='diagnosis'
              name='diagnosis'
              type='text'
              variant='textarea'
              disabled
              {...register('diagnosis', {
              })}
              placeholder='Enter Here'
              label='Diagnosis'
              error={errors.name}
              className={`${errors['diagnosis'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='diagnosis' as={InputError} />
          </FormItem>
          <FormItem>
            <label>Treatment</label>
            <CheckboxGroup>
              {playerConsultInfo?.plans?.map((item, index) => {
                return (
                  <Checkbox
                    key={index * 10 - index}
                    name='plans'
                    type='checkbox'
                    value={item.id}
                    label={item.name}
                    checked={planArray.includes(item.name)}
                    disabled
                    {...register('plans', {
                    })}
                    className={`${errors['plans'] ? 'error' : ''}`}
                  />
                )
              })}
              <ErrorMessage errors={errors} name='plans' as={InputError} />
            </CheckboxGroup>
          </FormItem>
          <FormItem>
            <Input
              id='details'
              name='details'
              type='text'
              variant='textarea'
              {...register('details')}
              label='Treatment details'
              disabled
            />
          </FormItem>
          <FormItem>
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return <div key={i + index}>{i.name}</div>;
              })}
            </PhotosWrapper>
            {prevAudios.length > 0 && (
              <>
                <label>Current audios</label>
                <PhotosWrapper>
                  {prevAudios.map((audio, index) => {
                    return (
                      <div
                        key={index + audio}
                        style={{
                          alignItems: audio?.url ? 'start' : 'center',
                          flexDirection: audio?.url ? 'column' : 'row',
                        }}
                      >
                        <span>{audio.original_name}</span>
                        {audio?.url ? (
                          <audio
                            controls
                            style={{ marginTop: '8px' }}
                            controlsList='nodownload'
                          >
                            <source src={audio?.url} type={audio.encoding} />
                          </audio>
                        ) : (
                          <Button
                            type='button'
                            aria-label='Fetch audio'
                            icon
                            onClick={() =>
                              fetchAudio({
                                teamId,
                                playerId,
                                informationId,
                                id: audio.id,
                              })
                            }
                          >
                            <Icon name='view' />
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </PhotosWrapper>
              </>
            )}
          </FormItem>
        </>
      )}
    </form>
  );
};
