import { useMutation, useQueryClient } from 'react-query';
import { deletePlayerProblem } from '../../../../api/playerProblems';

export const useDeletePlayerProblem = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlayerProblem, {
    onSuccess: () => {
      queryClient.invalidateQueries('playerProblems');
    },
  });
};
