import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Input } from "../../../../atoms/Input";
import { Button } from "../../../../atoms/Button";
import { Icon } from "../../../../atoms/Icon";
import { Typography } from "../../../../atoms/Typography";
import { AsyncPaginate } from "react-select-async-paginate";
import { useHistory } from "react-router-dom";
import {
  FormItem,
  InputError,
  Fieldset,
  PhotosWrapper,
} from "../../../../organisms/Layout";
import { useQuery } from "react-query";
import Modal from "react-modal";
import { getResultInformationById } from "api/playerInformation";
import { useEditInfoResult } from "../../hooks";
import { trimFormData } from "../../../../../utils/trimFormData";

Modal.setAppElement("#root");

export const ResultsModule = ({ params }) => {
  const methods = useForm();

  const { teamId, playerId, problemId, informationId } = params;

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const maxDate = new Date().toISOString().split("T")[0];

  const { data: resultInformation, isLoading: isLoading } = useQuery(
    ["resultInformation", informationId],
    () => getResultInformationById(teamId, playerId, informationId)
  );

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isEditLoading } = useEditInfoResult();

  const watchPhotos = watch("photos");

  const [formPhotos, setFormPhotos] = useState([]);

  const [prevPhotos, setPrevPhotos] = useState([]);

  const [deletedPhotos, setDeletedPhotos] = useState([]);

  const onPrevPhotosDelete = (deletedId) => {
    setPrevPhotos(
      prevPhotos.filter((elem) => {
        return elem.id !== deletedId;
      })
    );
    setDeletedPhotos([...deletedPhotos, deletedId]);
  };

  useEffect(() => {
    setFormPhotos(getValues("photos"));
  }, [watchPhotos]);

  const onDelete = (index) => {
    setFormPhotos(
      Array.from(formPhotos).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);
    const formData = new FormData();

    if (problemId) {
      formData.append("problemId", problemId);
    }

    formData.append("categoryTypeId", data.resultsCategory.value.id);
    formData.append("date", data.date);
    formData.append("outcome", data.outcome);
    if (data.tests) {
      formData.append("tests", data.tests);
    }
    formData.append("notes", data.notes);
    formData.append("deletedFiles", JSON.stringify(deletedPhotos));

    if (data.overview) {
      formData.append("overview", data.overview);
    }

    if (data.indication) {
      formData.append("indication", data.indication);
    }

    if (data.photos) {
      for (const key of data.photos) {
        formData.append("file", key);
      }
    }

    try {
      await mutateAsync({
        teamId,
        playerId,
        informationId,
        formData,
      });
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      console.log("error", error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage("");
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage("");
    }, 500);
  }, [isOpen]);

  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  useEffect(() => {
    if (!isLoading && resultInformation) {
      const d = new Date(resultInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);
      [
        {
          name: "resultsCategory",
          value: {
            value: {
              id: resultInformation.information_results.category_type.id,
              name: resultInformation.information_results.category_type.name,
            },
            label: resultInformation.information_results.category_type.name,
          },
        },
        {
          name: "date",
          value: resultInformation.information_results.date,
        },
        {
          name: "outcome",
          value: resultInformation.information_results.outcome,
        },
        {
          name: "tests",
          value: resultInformation.information_results.tests,
        },
        {
          name: "indication",
          value: resultInformation.information_results.indication,
        },
        {
          name: "dateAdded",
          value: dateAddedString,
        },
      ].forEach(({ name, value }) => setValue(name, value));
      setPrevPhotos(resultInformation.information_results.photos);
    }
  }, [resultInformation]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Fieldset>
          <FormItem>
            <Controller
              name="resultsCategory"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor="resultsCategory">Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId="resultsCategory"
                    ref={ref}
                    isClearable
                    isDisabled
                    placeholder={"Your information"}
                    className={`react-select ${
                      errors["resultsCategory"] ? "error" : ""
                    }`}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="resultsCategory"
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id="dateAdded"
              name="dateAdded"
              type="text"
              {...register("dateAdded", {})}
              label="Date Added"
              disabled
            />
          </FormItem>
          <FormItem small>
            <Input
              id="date"
              name="date"
              type="date"
              max={maxDate}
              {...register("date", {
                required: "This is required",
              })}
              placeholder="Enter Here"
              label="Date"
              error={errors.name}
              className={`${errors["date"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="date" as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id="indication"
              name="indication"
              type="text"
              variant="textarea"
              {...register("indication", {})}
              error={errors.name}
              placeholder="Enter Here"
              label="Indication"
              className={`${errors["indication"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="indication" as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id="outcome"
              name="outcome"
              type="text"
              variant="textarea"
              {...register("outcome", { required: "This is required" })}
              placeholder="Enter Here"
              label="Outcome"
              error={errors.name}
              className={`${errors["outcome"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="outcome" as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id="tests"
              name="tests"
              type="text"
              variant="textarea"
              {...register("tests", {})}
              placeholder="Enter Here"
              label="Tests"
              error={errors.name}
              className={`${errors["tests"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="tests" as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id="photos"
              name="photos"
              type="file"
              multiple
              accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              {...register("photos")}
              placeholder="Enter Here"
              label="Files"
              error={errors.name}
              className={`${errors["photos"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="photos" as={InputError} />
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return (
                  <div key={i + index}>
                    {i.name}
                    <Button
                      aria-label="Delete"
                      icon
                      type="button"
                      onClick={() => onDelete(index)}
                    >
                      <Icon name="remove" />
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
            {prevPhotos.length > 0 && (
              <>
                <label>Current Files</label>
                <PhotosWrapper>
                  {prevPhotos.map((i, index) => {
                    return (
                      <div key={index + i + index}>
                        {i.original_name}
                        <Button
                          type="button"
                          aria-label="Delete"
                          icon
                          onClick={() => onPrevPhotosDelete(i.id)}
                        >
                          <Icon name="remove" />
                        </Button>
                      </div>
                    );
                  })}
                </PhotosWrapper>
              </>
            )}
          </FormItem>
        </Fieldset>
        <Button type="button" onClick={onPreSubmit}>
          <Icon name="check" />
          Submit Information
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="mymodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          {submitMessage?.length > 0 ? (
            <>
              <div className="modal-body">
                <Typography as={"h2"} tag={"h2"}>
                  Something went wrong
                </Typography>
                <Typography as={"p"} tag={"h4"}>
                  {submitMessage}
                </Typography>
              </div>
              <div className="modal-buttons">
                <Button onClick={toggleModalClear} variant={"secondary"}>
                  Try again
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="modal-body">
                <Typography as={"h2"} tag={"h2"}>
                  Are you sure?
                </Typography>
                <Typography as={"p"} tag={"h4"}>
                  Do you really want to edit this information?
                </Typography>
              </div>
              <div className="modal-buttons">
                <Button onClick={toggleModal} variant={"secondary"}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disbled={isEditLoading}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </Modal>
      </form>
    </FormProvider>
  );
};
