import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useHistory, useParams } from 'react-router-dom';
import { useTFLogin } from './hooks/useTFLogin';
import { ForgotForm } from '../Layout';
import { Icon } from '../../atoms/Icon';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { createCookie, getCookie, parseCookieString } from '../../../utils/cookie';
import jwtDecode from 'jwt-decode';
import { USER_COOKIE } from '../../../utils/constants';
import { UserContext } from '../../../context';
import { PlainLink } from 'components/atoms/PlainLink';
import { FormItem, InputError } from '../Layout';
import { StyledVerificationInput } from './styles'
import { REQUIRED_FIELD } from "../../../constants/massages";

const fpPromise = FingerprintJS.load();

export const TFAuth = () => {
  const { token } = useParams();
  const [, setContext] = useContext(UserContext);
  const { mutateAsync, error } = useTFLogin();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { isSubmitted },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      if (data) {
        const fp = await fpPromise;
        const { visitorId } = await fp.get();

        const response = await mutateAsync({
          fingerprint: visitorId,
          sixDigitsNumber: +data.code,
          token,
        });

        let authToken = getCookie(USER_COOKIE);
        if (!authToken && response.cookies?.length) {
          const cookies = parseCookieString(response.cookies.join('; '));
          [
            'Authentication',
            'Refresh',
            'User'
          ].forEach(key => {
            createCookie(key, cookies[key], cookies['Max-Age'])
          })
          authToken = cookies['User']
        }

        setContext(jwtDecode(authToken));
        history.push('/teams');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <ForgotForm onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Icon name='mainLogo'/>
        <Typography as={'h2'} tag={'h2'}>
          Two-Factor Authentication
        </Typography>
      </div>
      <PlainLink as='p'>
        Please enter the 6-digit code we emailed to you
      </PlainLink>
      <FormItem>
        <Controller
          control={control}
          name='code'
          rules={{
            required: REQUIRED_FIELD,
            minLength: { value: 6, message: REQUIRED_FIELD }
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error: fieldError, isTouched } }) => (
            <>
              <StyledVerificationInput
                removeDefaultStyles
                classNames={{
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
                name='code'
                length={6}
                value={value}
                type='numeric'
                inputMode='number'
                onChange={(value) => onChange(value)}
                onBlur={onBlur}
              />
              {fieldError && (isTouched || isSubmitted) &&
              <Typography variant={'error'}>{fieldError.message}</Typography>}
            </>
          )
          }
        />
        {error ? <InputError>{error.data?.message}</InputError> : null}
      </FormItem>
      <Button type='submit'>Submit</Button>
    </ForgotForm>
  );
};
