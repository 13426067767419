import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../../atoms/Input';

import { FormItem, InputError } from '../../../../../organisms/Layout';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const MedsModule = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormItem>
        <Input
          id='name'
          name='name'
          type='text'
          {...register('name', {
            required: 'This is required',
          })}
          placeholder='Enter Here'
          label='Name'
          className={`${errors['name'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='name' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='form'
          name='form'
          type='text'
          variant='textarea'
          {...register('form', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Form'
          className={`${errors['form'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='form' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='dose'
          name='dose'
          type='text'
          variant='textarea'
          {...register('dose', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Dose'
          className={`${errors['dose'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='dose' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='times'
          name='times'
          type='text'
          variant='textarea'
          {...register('times', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Times'
          className={`${errors['times'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='times' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='reason'
          name='reason'
          type='text'
          variant='textarea'
          {...register('reason', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Indication'
          className={`${errors['reason'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='reason' as={InputError} />
      </FormItem>
    </>
  );
};
