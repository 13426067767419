import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { getLetterInformationById } from 'api/playerInformation';
import { useEditInfoLetter } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import {
  InformationLetterForm,
  EnumInformationLetterFormMode
} from "../../../../organisms/Forms/InformationLetterForm";

Modal.setAppElement('#root');

export const LettersModule = ({ params }) => {
  const { push } = useHistory();

  const { teamId, playerId, problemId, informationId } = params;
  const [defaultValues, setDefaultValues] = useState()

  const {
    data: letterInformation,
    isLoading: isLoading,
  } = useQuery(['letterInformation', informationId], () =>
    getLetterInformationById(teamId, playerId, informationId)
  );

  const { mutateAsync, isLoading: isEditLoading } = useEditInfoLetter();

  const onSubmit = async (dataToTrim, onError) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (problemId) {
      formData.append('problemId', problemId);
    }

    formData.append('name', data.name);
    formData.append('date', data.date);
    formData.append('deletedFiles', JSON.stringify(data.deletedFiles || []));

    if (data.indication) {
      formData.append('indication', data.indication);
    }

    if (data.outcome) {
      formData.append('outcome', data.outcome);
    }

    if (data.currentFiles) {
      for (const key of data.currentFiles) {
        formData.append('file', key);
      }
    }

    try {
      await mutateAsync({
        teamId,
        playerId,
        informationId,
        formData,
      });
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      console.log('error', error);
      onError(error?.data?.message);
    }
  };

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  useEffect(() => {
    if (!isLoading && letterInformation) {
      let dateAdded, dateAddedString, until, untilString = '';
      if (letterInformation.created_at) {
        dateAdded = new Date(letterInformation.created_at);
        dateAddedString = dateTimeFormat.format(dateAdded);
      }
      setDefaultValues({
        name: letterInformation.information_letters.name,
        date: letterInformation.information_letters.date,
        outcome: letterInformation.information_letters.outcome,
        indication: letterInformation.information_letters.indication,
        dateAdded: dateAddedString,
        files: letterInformation.information_letters.files.map(
          (photo) => {
            return {
              ...photo,
              image: null,
            };
          }
        )
      })
    }
  }, [letterInformation]);

  if (!defaultValues) return null

  return (
    <InformationLetterForm
      params={params}
      defaultValues={defaultValues}
      mode={EnumInformationLetterFormMode.Edit}
      isLoading={isEditLoading}
      onSubmit={onSubmit}
    />
  );
};
