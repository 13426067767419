import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout';
import { Typography } from '../../atoms/Typography';
import { useQuery } from 'react-query';
import { loadOptions } from '../../../utils/loadSelectOptions';
import {
  getPlayerInformation,
  getPlayerInformationForReferral,
  getPlayerInformationForReferralInfo
} from 'api/playerInformation';
import { getPlayerProblems } from 'api/playerProblems';
import { ProblemModule } from './modules/Problem';
import { MedicationsModule } from './modules/Medications';
import { ConsultModule } from './modules/Consult';
import { getDiagnosisCodeNameFromProblem } from "../../../utils/getDiagnosisCodeNameFromProblem";

import Modal from 'react-modal';
import { TreatmentsModule } from './modules/Treatments';
import { LettersModule } from './modules/Letters';
import { ResultsModule } from './modules/Results';
import { EnumInformationCategories } from "../../../constants/informationCategories";

Modal.setAppElement('#root');

export const ViewOneInformation = () => {
  let { externalReferralId, teamId, playerId, informationId } = useParams();

  const history = useHistory();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "playerInformation" for a particular player by invoking "playerInformation" hook
  const { data: playerInformationData, isLoading, isError = []  } = useQuery(
      ['playerInformation', playerId],
      () => getPlayerInformation(teamId, playerId)
  );

  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const { data: playerProblemsData = [] } = useQuery(
      ['playerProblems', playerId],
      () => getPlayerProblems(teamId, playerId)
  );

  const problemOptions = [];
  for (let i = 0; i < playerProblemsData.length; ++i) {
    problemOptions.push({
      value: playerProblemsData[i],
      label: playerProblemsData[i].name,
    });
  }

  const [problemId, setProblemId] = useState();

  const watchProblem = watch('problem');

  const _renderModule = (id) => {
    switch (id) {
      case EnumInformationCategories.Details.id:
        return <ProblemModule params={moduleParams} />

      case EnumInformationCategories.Consultation.id:
        return <ConsultModule params={moduleParams} />

      case EnumInformationCategories.Medications.id:
        return <MedicationsModule params={moduleParams} />

      case EnumInformationCategories.Treatments.id:
        return <TreatmentsModule params={moduleParams} />

      case EnumInformationCategories.Letters.id:
        return <LettersModule params={moduleParams} />

      case EnumInformationCategories.Results.id:
        return <ResultsModule params={moduleParams} />

      default:
        return null
    }
  }

  useEffect(() => {
    setProblemId(watchProblem?.value?.id);
  }, [watchProblem]);

  const [infoCategory, setInfoCategory] = useState();

  const watchInfoCategory = watch('informationCategory');

  useEffect(() => {
    setInfoCategory(watchInfoCategory?.value?.id);
  }, [watchInfoCategory]);

  const moduleParams = {
    playerId,
    teamId,
    infoCategory,
    problemId,
    informationId,
  };

  useEffect(() => {
    if (!playerProblemsData?.length || !playerInformationData?.length) return null

    const filterPlayerInfoById = playerInformationData?.find((e) => {
      return e.id === +informationId;
    });

    if (!isLoading && filterPlayerInfoById?.category_type?.id) {
      [
        {
          name: 'informationCategory',
          value: {
            value: {
              id: filterPlayerInfoById.category_type.id,
              name: filterPlayerInfoById.category_type.name,
            },
            label: filterPlayerInfoById.category_type.name,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }

    if (!isLoading && filterPlayerInfoById?.problem?.id) {
      [
        {
          name: 'problem',
          value: {
            value: {
              id: filterPlayerInfoById.problem.id,
              name: filterPlayerInfoById.problem.name,
            },
            label: `${filterPlayerInfoById.problem.name} ${
              getDiagnosisCodeNameFromProblem(playerProblemsData.find(item => item.id === (playerInformationData || [])[0]?.problem?.id))
            }`,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [playerInformationData, playerProblemsData]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          View App Info
        </Typography>
        <Button
          variant={'secondary'}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </TableHeader>
      <FormWrapper>
        {isError && (
          <p>An error occurred during receiving player information data</p>
        )}
        {isLoading ? (
          'Receiving player name...'
        ) : (
          <div>
            <FormItem>
              <Controller
                name='informationCategory'
                control={control}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='informationCategory'>
                      Information Category
                    </label>
                    <AsyncPaginate
                      {...field}
                      inputId='informationCategory'
                      ref={ref}
                      isClearable
                      isDisabled
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['informationCategory'] ? 'error' : ''
                      }`}
                    />
                  </>
                )}
              />
            </FormItem>
            <FormItem>
              <Controller
                name='problem'
                control={control}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='problem'>Problem</label>
                    <AsyncPaginate
                      {...field}
                      inputId='problem'
                      ref={ref}
                      isClearable
                      isDisabled
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['problem'] ? 'error' : ''
                      }`}
                      //onChange={({ value }) => setProblem({ value })}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, problemOptions)
                      }
                    />
                  </>
                )}
              />
            </FormItem>
            {/* Conditional form render depending on "Information Category" select */}
            {_renderModule(infoCategory)}
          </div>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};
