import { getProblemCategorySearch } from 'api/playerProblems';
import { useEffect, useState } from 'react';

export const useDebounce = ({ delay, clearErrors, inputName }) => {
  const [downShiftValue, setDownShiftValue] = useState('');
  const [items, setItems] = useState([]);

  const onDownshiftInputType = async (typed) => {
    if (typed && typed.length > 1) {
      const searchResult = await getProblemCategorySearch(typed);

      const searchArr = searchResult.map((item) => {
        return {
          id: item.id,
          name: item.name,
          diagnosis_code: item.diagnosis_code,
        };
      });

      setItems(searchArr);
      await clearErrors(inputName);

      return;
    }
  };

  useEffect(() => {
    const handler = setTimeout(
      () => onDownshiftInputType(downShiftValue),
      delay
    );

    return () => clearTimeout(handler);
  }, [downShiftValue, delay]);

  return [downShiftValue, setDownShiftValue, items];
};
