import axiosWrapper from './axiosInterceptor';


export const getPlayerNote = async (teamId, playerId, noteId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/notes/team/${teamId}/player/${playerId}/note/${noteId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};


export const getPlayerNotes = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/notes/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};


export const addNote = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/notes/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};


export const editNote = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/notes/team/${data.teamId}/player/${data.playerId}/note/${data.noteId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};


export const getNotePhoto = async (
  teamId,
  playerId,
  noteId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/notes/team/${teamId}/player/${playerId}/note/${noteId}/photo/${photoId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getNotePdfUrl = async (
  teamId,
  playerId,
  noteId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/notes/team/${teamId}/player/${playerId}/note/${noteId}/pdf/${photoId}`,
      responseType: 'text',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deletePlayerNote = async ({ teamId, playerId, noteId }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/notes/team/${teamId}/player/${playerId}/note/${noteId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
