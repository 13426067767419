import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { CheckboxGroup, FormItem, InputError } from '../../../../../organisms/Layout';
import { Checkbox } from 'components/atoms/Checkbox';
import { Input } from "../../../../../atoms/Input";

import Modal from 'react-modal';
import { useQuery } from "react-query";
import { getAllVaccinesShots } from "../../../../../../api/playerInformation";
Modal.setAppElement('#root');

export const VaccinesModule = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { data: vaccinesShots, isLoading } = useQuery('getVaccines', getAllVaccinesShots)

  return (
    <>
      <FormItem>
        <Input
          id='name'
          name='name'
          type='text'
          {...register('name')}
          placeholder='Enter Here'
          label='Name'
          className={`${errors['name'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='name' as={InputError}/>
      </FormItem>
      {isLoading ? (
        'Receiving investigation data...'
      ) : (
        <FormItem>
          <label>Injection</label>
          <CheckboxGroup>
            {vaccinesShots?.map((item) => {
              return (
                <Checkbox
                  key={item.id}
                  name='vaccinesIdsList'
                  type='checkbox'
                  value={item.id}
                  label={item.name}
                  {...register('vaccinesIdsList')}
                  className={`${errors['vaccinesIdsList'] ? 'error' : ''}`}
                />
              );
            })}
          </CheckboxGroup>
        </FormItem>
      )}
    </>
  );
};
