import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { Link, useHistory, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
  ButtonGroup,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { useQuery } from 'react-query';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { getAllStatuses } from '../../../api/statuses';
import { getAllNationalities } from '../../../api/nationalities';
import { getAllGenders } from '../../../api/genders';
import { getTeams } from '../../../api/teams';
import { useEditPlayer } from './hooks/useEditPlayer';
import { formatFormDate } from '../../../utils/formatFormDate';
import Modal from 'react-modal';
import { getPlayer } from 'api/players';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const EditPlayer = () => {
  let { teamId, playerId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "Player" for a particular Team by invoking "getTeam" hook
  const { data: playerData, isLoading, isError } = useQuery(
    ['player', playerId],
    () => getPlayer(teamId, playerId)
  );

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const {
    data: statusesData = [],
    isFetching: isAllStatusesFetching,
  } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  // Receiving entity "nationalities" by invoking "getAllNationalities" hook
  const { data: nationalityData = [] } = useQuery(
    'nationalities',
    getAllNationalities
  );

  const nationalityOptions = [];
  for (let i = 0; i < nationalityData.length; ++i) {
    nationalityOptions.push({
      value: nationalityData[i],
      label: nationalityData[i].name,
    });
  }

  // Receiving entity "genders" by invoking "getAllGenders" hook
  const { data: genderData = [] } = useQuery('genders', getAllGenders);

  const genderOptions = [];
  for (let i = 0; i < genderData.length; ++i) {
    genderOptions.push({
      value: genderData[i],
      label: genderData[i].name,
    });
  }

  // Receiving entity "teams" by invoking "getteams" hook
  const { data: teamsData = [] } = useQuery('team', getTeams);

  const teamsOptions = [];
  for (let i = 0; i < teamsData.length; ++i) {
    teamsOptions.push({
      value: teamsData[i],
      label: teamsData[i].name,
    });
  }

  const maxDate = new Date().toISOString().split('T')[0];

  const { mutateAsync } = useEditPlayer();

  const { push } = useHistory();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object)
    // before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string,
    // object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        teamId: parseInt(newData.team?.id) || parseInt(newData.team),
        playerId: playerId,
        firstName: newData.playerFirstName,
        lastName: newData.playerSecondName,
        email: newData.playerEmail,
        statusId: parseInt(newData.status?.id) || parseInt(newData.status),
        nationalityId:
          parseInt(newData.nationality?.id) || parseInt(newData.nationality),
        genderId: parseInt(newData.gender?.id) || parseInt(newData.gender),
        phoneNumber: phoneValue,
        nhsNumber: newData.NHS,
        birthday: new Date(newData.dob).toISOString(),
        gpSurgery: newData.GPsurgery,
        nextOfKin: newData.nextOfKin,
        address1: newData.address1,
        address2: newData.address2,
        city: newData.city,
        postcode: newData.postcode,
        isInvited: isInvited,
      });
      push('/players');
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [submitMessage, setSubmitMessage] = useState();

  const [phoneValue, setPhoneValue] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const [isInvited, setIsInvited] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async (arg) => {
    setIsInvited(arg);
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setPhoneValue(playerData?.user?.phone_number);
  }, [playerData]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  // Setting default values to react-hook-form component, depending on receiving data for this entity
  useEffect(() => {
    if (playerData) {
      [
        { name: 'playerFirstName', value: playerData.user.first_name },
        { name: 'playerSecondName', value: playerData.user.last_name },
        { name: 'NHS', value: playerData.nhs_number },
        {
          name: 'gender',
          value: { value: playerData.gender.id, label: playerData.gender.name },
        },
        {
          name: 'nationality',
          value: {
            value: playerData.nationality.id,
            label: playerData.nationality.name,
          },
        },
        { name: 'dob', value: playerData.birthday },
        {
          name: 'team',
          value: {
            value: playerData.team.id,
            label: playerData.team.name,
          },
        },
        {
          name: 'status',
          value: {
            value: playerData.status.id,
            label: playerData.status.name,
          },
        },
        { name: 'playerEmail', value: playerData.user.email },
        { name: 'playerPhone', value: playerData.user.phone_number },
        { name: 'GPsurgery', value: playerData.gp_surgery },
        { name: 'nextOfKin', value: playerData.next_of_kin },
        { name: 'address1', value: playerData.address_1 },
        { name: 'address2', value: playerData.address_2 },
        { name: 'city', value: playerData.city },
        { name: 'postcode', value: playerData.postcode },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [playerData, isLoading]);

  console.log(playerData);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit Player
        </Typography>
        <Button as={Link} variant={'secondary'} to={`/teams/${teamId}/players`}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        {isError && <p>An error occured during receiving team data</p>}
        {isLoading ? (
          'Receiving player name...'
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <FormItem>
                <Input
                  id='playerFirstName'
                  name='playerFirstName'
                  type='text'
                  {...register('playerFirstName', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Player First Name'
                  error={errors.name}
                  className={`${errors['playerFirstName'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='playerFirstName'
                  as={InputError}
                />
              </FormItem>
              <FormItem>
                <Input
                  id='playerSecondName'
                  name='playerSecondName'
                  type='text'
                  {...register('playerSecondName', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Player Second Name'
                  error={errors.name}
                  className={`${errors['playerSecondName'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='playerSecondName'
                  as={InputError}
                />
              </FormItem>
              <FormItem small>
                <Input
                  id='NHS'
                  name='NHS'
                  type='text'
                  {...register('NHS', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='NHS Number'
                  error={errors.name}
                  className={`${errors['NHS'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='NHS' as={InputError} />
              </FormItem>
              <FormItem>
                <Controller
                  name='gender'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                    <>
                      <label htmlFor='gender'>Gender</label>
                      <AsyncPaginate
                        {...field}
                        inputId='gender'
                        ref={ref}
                        isClearable
                        placeholder={'Your information'}
                        className={`react-select ${
                          errors['gender'] ? 'error' : ''
                        }`}
                        loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, genderOptions)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage errors={errors} name='gender' as={InputError} />
              </FormItem>
              <FormItem>
                <Controller
                  name='nationality'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                    <>
                      <label htmlFor='nationality'>Nationality</label>
                      <AsyncPaginate
                        {...field}
                        inputId='nationality'
                        ref={ref}
                        isClearable
                        placeholder={'Your information'}
                        className={`react-select ${
                          errors['nationality'] ? 'error' : ''
                        }`}
                        loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, nationalityOptions)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name='nationality'
                  as={InputError}
                />
              </FormItem>
              <FormItem small>
                <Input
                  id='dob'
                  name='dob'
                  type='date'
                  max={maxDate}
                  {...register('dob', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Date of Birth'
                  error={errors.name}
                  className={`${errors['dob'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='dob' as={InputError} />
              </FormItem>
            </Fieldset>
            <Fieldset small>
              <FormItem>
                <Controller
                  name='team'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                    <>
                      <label htmlFor='team'>Team</label>
                      <AsyncPaginate
                        {...field}
                        ref={ref}
                        isClearable
                        placeholder={'Your information'}
                        className={`react-select ${
                          errors['team'] ? 'error' : ''
                        }`}
                        loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, teamsOptions)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage errors={errors} name='team' as={InputError} />
              </FormItem>
              <FormItem>
                <Controller
                  name='status'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                    <>
                      <label htmlFor='status'>Status</label>
                      <AsyncPaginate
                        {...field}
                        inputId='status'
                        ref={ref}
                        isClearable
                        placeholder={'Your information'}
                        className={`react-select ${
                          errors['status'] ? 'error' : ''
                        }`}
                        loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, statusesOptions)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage errors={errors} name='status' as={InputError} />
              </FormItem>
              <FormItem>
                <Input
                  id='playerEmail'
                  name='playerEmail'
                  type='email'
                  {...register('playerEmail', {
                    required: 'This is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  placeholder='yourname@example.com'
                  label='Email Address'
                  error={errors.name}
                  className={`${errors['playerEmail'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='playerEmail'
                  as={InputError}
                />
              </FormItem>
              <FormItem>
                <label htmlFor='playerPhone'>Contact Telephone Number</label>
                <PhoneInput
                  // defaultCountry='UA'
                  // international
                  // countryCallingCodeEditable={false}
                  id='playerPhone'
                  name='playerPhone'
                  placeholder='Enter here'
                  {...register('playerPhone', {
                    required: 'This is required',
                  })}
                  value={phoneValue}
                  onChange={setPhoneValue}
                  className={`${errors['playerPhone'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='playerPhone'
                  as={InputError}
                />
              </FormItem>
              <FormItem>
                <Input
                  id='GPsurgery'
                  name='GPsurgery'
                  type='text'
                  {...register('GPsurgery', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='GP Surgery'
                  error={errors.name}
                  className={`${errors['GPsurgery'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='GPsurgery'
                  as={InputError}
                />
              </FormItem>
              <FormItem>
                <Input
                  id='nextOfKin'
                  name='nextOfKin'
                  type='text'
                  {...register('nextOfKin', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Next of Kin'
                  error={errors.name}
                  className={`${errors['nextOfKin'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='nextOfKin'
                  as={InputError}
                />
              </FormItem>
              <FormItem>
                <Input
                  id='address1'
                  name='address1'
                  type='text'
                  {...register('address1', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Address Line 1'
                  error={errors.name}
                  className={`${errors['address1'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='address1' as={InputError} />
              </FormItem>
              <FormItem>
                <Input
                  id='address2'
                  name='address2'
                  type='text'
                  {...register('address2', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Address Line 2'
                  error={errors.name}
                  className={`${errors['address2'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='address2' as={InputError} />
              </FormItem>
              <FormItem small>
                <Input
                  id='city'
                  name='city'
                  type='text'
                  {...register('city', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='City'
                  error={errors.name}
                  className={`${errors['city'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='city' as={InputError} />
              </FormItem>
              <FormItem small>
                <Input
                  id='postcode'
                  name='postcode'
                  type='text'
                  {...register('postcode', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Postcode'
                  error={errors.name}
                  className={`${errors['postcode'] ? 'error' : ''}`}
                />
                <ErrorMessage errors={errors} name='postcode' as={InputError} />
              </FormItem>
            </Fieldset>
            <ButtonGroup>
              <Button type='button' onClick={() => onPreSubmit(false)}>
                <Icon name='check' />
                Submit details
              </Button>
              <Button
                type='button'
                variant={'secondary'}
                onClick={() => onPreSubmit(true)}
              >
                Edit Player and Send Link
              </Button>
            </ButtonGroup>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel='My dialog'
              className='mymodal'
              overlayClassName='myoverlay'
              closeTimeoutMS={500}
            >
              {submitMessage?.length > 0 ? (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Something went wrong
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      {submitMessage}
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModalClear} variant={'secondary'}>
                      Try again
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Are you sure?
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      Do you really want to edit this player?
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModal} variant={'secondary'}>
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)}>Confirm</Button>
                  </div>
                </>
              )}
            </Modal>
          </form>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};
