import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { useQuery } from 'react-query';
import { loadOptions } from '../../../utils/loadSelectOptions';

import Modal from 'react-modal';
import { getDiagnosisCodes, getProblemCategoryById } from 'api/problems';
import { useEditProblemCategory } from '../ProblemCategories/hooks/useEditProblemCategory';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const EditProblemCategory = () => {
  let { problemId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { data: problemCategory, isLoading } = useQuery(
    ['problemCategory', problemId],
    () => getProblemCategoryById(problemId)
  );

  const { data: diagnosisCodes = [] } = useQuery(
    'diagnosisCodes',
    getDiagnosisCodes
  );

  const diagnosisOptions = [];
  for (let i = 0; i < diagnosisCodes.length; ++i) {
    diagnosisOptions.push({
      value: diagnosisCodes[i],
      label: `${diagnosisCodes[i].name}`,
    });
  }

  const { mutateAsync, isLoading: isUpdating } = useEditProblemCategory();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    try {
      await mutateAsync({
        problemId,
        name: data.name,
        description: data.description,
        diagnosisCodeId: data.diagnosisCode.value.id,
      });
      push('/configuration/problem-categories');
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [diagnosisCode, setDiagnosisCode] = useState();

  const watchDiagnosisCode = watch('diagnosisCode');

  useEffect(() => {
    setDiagnosisCode(watchDiagnosisCode?.value);
  }, [watchDiagnosisCode]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading) {
      [
        { name: 'name', value: problemCategory.name },
        { name: 'description', value: problemCategory.description },
        {
          name: 'diagnosisCode',
          value: {
            value: {
              id: problemCategory.diagnosis_code.id,
              name: problemCategory.diagnosis_code.name,
              code: problemCategory.diagnosis_code.code,
            },
            label: `${problemCategory.diagnosis_code.name}`,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [problemCategory, isLoading]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit Problem Category
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={'/configuration/problem-categories'}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              id='name'
              name='name'
              type='text'
              {...register('name', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Name'
              error={errors.name}
              className={`${errors['name'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='name' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='description'
              name='description'
              type='text'
              variant={'textarea'}
              {...register('description', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Description/Note'
              error={errors.name}
              className={`${errors['description'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='description' as={InputError} />
          </FormItem>
          <FormItem>
            <Controller
              name='diagnosisCode'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='diagnosisCode'>Diagnosis code</label>
                  <AsyncPaginate
                    {...field}
                    inputId='diagnosisCode'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['diagnosisCode'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, diagnosisOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='diagnosisCode'
              as={InputError}
            />
            {diagnosisCode && (
              <>
                <label style={{ padding: '16px 0 8px' }}>
                  You have chosen:
                </label>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                    </tr>
                    <tr>
                      <td>{diagnosisCode.name}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this problem category?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isUpdating}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
