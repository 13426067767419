import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as logo } from '../../../assets/svg/logo.svg';
import { ReactComponent as mainLogo } from '../../../assets/svg/mainLogo.svg';
import { ReactComponent as search } from '../../../assets/svg/search.svg';
import { ReactComponent as notification } from '../../../assets/svg/notification.svg';
import { ReactComponent as view } from '../../../assets/svg/view.svg';
import { ReactComponent as edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as remove } from '../../../assets/svg/remove.svg';
import { ReactComponent as arrowLeft } from '../../../assets/svg/arrowLeft.svg';
import { ReactComponent as arrowRight } from '../../../assets/svg/arrowRight.svg';
import { ReactComponent as add } from '../../../assets/svg/add.svg';
import { ReactComponent as check } from '../../../assets/svg/check.svg';
import { ReactComponent as separator } from '../../../assets/svg/separator.svg';
import { ReactComponent as userplus } from '../../../assets/svg/userplus.svg';
import { ReactComponent as searchblue } from '../../../assets/svg/searchblue.svg';
import { ReactComponent as info } from '../../../assets/svg/info.svg';
import { ReactComponent as mailpencil } from '../../../assets/svg/mailpencil.svg';
import { ReactComponent as download } from '../../../assets/svg/download.svg';
import { ReactComponent as printer } from '../../../assets/svg/printer.svg';
import { ReactComponent as file_link } from '../../../assets/svg/file-link.svg';
import { ReactComponent as alt_download } from '../../../assets/svg/alt-download.svg';
import { ReactComponent as list } from '../../../assets/svg/list.svg';

export const iconSet = {
  logo,
  mainLogo,
  search,
  notification,
  view,
  edit,
  remove,
  arrowLeft,
  arrowRight,
  add,
  check,
  separator,
  userplus,
  searchblue,
  info,
  mailpencil,
  download,
  printer,
  file_link,
  alt_download,
  list,
};

export const Icon = ({ name, ...rest }) => {
  const IconSvg = iconSet[name];

  return IconSvg ? <IconSvg {...rest} /> : null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

Icon.defaultProps = {
  color: undefined,
  size: '100%',
  style: {},
  className: '',
};
