import React from 'react';
import { Container } from '../../../components/organisms/Layout';

const PrivatePrintLayout = ({ children, props }) => {
  const routesStore = {
    '/print': 'Print',
  };
  const array = props.location.pathname.split('/');
  const filtered = array.filter((i) => !Number.isInteger(Number.parseInt(i)));
  const joined = filtered.join('/');

  return (
    <>
      <main style={{  }}>
        <Container>{children}</Container>
      </main>
    </>
  );
};

export default PrivatePrintLayout;
