import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import {Link, useParams, useRouteMatch} from 'react-router-dom';

import { Status } from '../../atoms/Status/index';
import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
    ContentWrapper,
    TableWrapper,
    PaginationWrapper, TableHeader, TableSubheader, SubheaderWrapper,
} from '../../organisms/Layout';
import { Typography } from '../../atoms/Typography';
import { ADMIN } from '../../../utils/constants';
import { UserContext } from '../../../context';
import {getAllExternalUsers, getExternalUsers} from "../../../api/externalUsers";
import {TabList} from "../../atoms/TabList";
import {TabItem} from "../../atoms/TabItem";
import {getExternal} from "../../../api/external-one";

Modal.setAppElement('#root');

export const ExternalUsersTable = () => {
    let { url } = useRouteMatch();
    let { externalId } = useParams();

    console.log('This is Users!!');
    console.log(url);
    console.log('External ID: ', externalId);

    // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
    const [user] = useContext(UserContext);

    const {
        data: externalUsersData = { external_user: [] },
        isLoading,
        isFetching,
        error,
        isError,
    } = useQuery(['external_user', externalId], () => getExternalUsers(externalId));

    const {
        data: externalData = { external: [] }
    } = useQuery(['external', externalId], () => getExternal(externalId));

    let companyName = '';

// scroll through the object to find the name of the external company
    for (const [key, value] of Object.entries(externalData)) {
        if (key === 'name' && companyName === '') {
            companyName = value;
        }
    }

    console.log('companyName: ' + companyName);
    //
    // console.log(externalUsersData);

    const [isOpen, setIsOpen] = useState(false);


    const toggleModal = () => {
        setIsOpen(!isOpen);
    };


    const columns = React.useMemo(
        () => [
            {
                Header: 'Reference',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'col2',
            },
            {
                Header: 'Lastname',
                accessor: 'col3',
            },
            {
                Header: 'Email',
                accessor: 'col4',
            },
            {
                Header: '',
                accessor: 'col5',
                disableSortBy: true,
            },
            {
                Header: '',
                accessor: 'col6',
                disableSortBy: true,
            },
        ],
        []
    );

    const data = React.useMemo(() => {
        return externalUsersData.external_user.map((e) => {
            return {
                col1: e.id,
                col2: e.user.first_name,
                col3: e.user.last_name,
                col4: e.user.email,
                col5: (
                    <Status variant={e.status.name}>
                        {e.status.name && e.status.name.toUpperCase()}
                    </Status>
                ),
                col6: user?.role === ADMIN && (
                    <InterButton title={`Edit ${e?.user?.first_name} ${e?.user?.last_name}`} as={Link} to={`${url}/external-users/${e.id}/edit`}>
                        <Icon name='edit' />
                    </InterButton>
                ),
            };
        });
    }, [isLoading, isFetching]);

    // React-table parameters
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }, // Pass our default pageSize
        },
        useSortBy,
        usePagination
    );

    const [selectedPage, setSelectedPage] = useState(0);

    // Tracker shows which page is selected via pagination clicking
    const handlePageClick = (e) => {
        const currentPage = e.selected;
        setSelectedPage(currentPage);
        gotoPage(currentPage);
    };

    // Setting the correct active page in Pagination component after sorting the table by any column
    useEffect(() => {
        gotoPage(0);
        setSelectedPage(0);
    }, [sortBy]);

    return (
        <>
            <ContentWrapper>
                <TableHeader>
                    <Typography as={'h1'} tag={'h1'}>
                        { companyName }
                    </Typography>
                    {user?.role === ADMIN && (
                        <Button as={Link} to={`${url}/add-external-user`}>
                            Add an External User
                        </Button>
                    )}
                </TableHeader>
                <SubheaderWrapper>
                    <TableSubheader>
                        <TabList>
                            <li>
                                <TabItem to={`${url}`}>External Users</TabItem>
                            </li>
                            <li>
                                <TabItem to={`${url}/referrals`}>Referrals</TabItem>
                            </li>
                        </TabList>
                    </TableSubheader>
                </SubheaderWrapper>
                <TableWrapper>
                    {isError && <div>{error.message}</div>}
                    {isLoading ? (
                        <p>Receiving external users data...</p>
                    ) : (
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            <span>
                          {column.isSorted
                              ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                              : null}
                        </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    )}
                </TableWrapper>
                {pageCount > 1 && (
                    <PaginationWrapper>
                        <ReactPaginate
                            previousLabel={
                                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
                            }
                            nextLabel={
                                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
                            }
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            pageCount={pageCount}
                            forcePage={selectedPage}
                        />
                    </PaginationWrapper>
                )}
            </ContentWrapper>
        </>
    );
};
