import axiosWrapper from './axiosInterceptor';

export const addPublicConsult = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: data.audio
        ? `/consult/v2/public/team/${data.teamId}/player/${data.playerId}`
        : `/consult/public/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
      headers: {
        'public-consult': data.token,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getPublicPlayerProblems = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/problems/public-consult/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        'public-consult': data.token,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
