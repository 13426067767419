import axiosWrapper from './axiosInterceptor';

export const getReferralsActive = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getReferralsArchived = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/archived`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getReferral = async (externalReferralId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/${externalReferralId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

