import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import PhoneInput from 'react-phone-number-input';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import 'react-phone-number-input/style.css';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
  ButtonGroup,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { getAllStatuses } from '../../../api/statuses';
import { formatFormDate } from '../../../utils/formatFormDate';
import { useAddExternalUser } from './hooks/useAddExternalUser';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const AddExternalUser = () => {
  let { externalId } = useParams();

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  const { mutateAsync } = useAddExternalUser();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        externalId: externalId,
        firstName: newData.externalUserFirstName,
        lastName: newData.externalUserLastName,
        email: newData.externalUserEmail,
        statusId: newData.status.id,
        phoneNumber: phoneValue,
        gmcNumber: newData.externalUserGMC,
        isInvited: isInvited,
      });
      push(`/external/${externalId}`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isInvited, setIsInvited] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();

  const [phoneValue, setPhoneValue] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async (arg) => {
    setIsInvited(arg);
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add External User
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/external/${externalId}/add-external-user`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset small>
            <FormItem>
              <Input
                id='externalUserFirstName'
                name='externalUserFirstName'
                type='text'
                {...register('externalUserFirstName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='First Name'
                error={errors.name}
                className={`${errors['externalUserFirstName'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='externalUserFirstName'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id='externalUserLastName'
                name='externalUserLastName'
                type='text'
                {...register('externalUserLastName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='Last Name'
                error={errors.name}
                className={`${errors['externalUserLastName'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='externalUserLastName'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id='externalUserGMC'
                name='externalUserGMC'
                type='text'
                {...register('externalUserGMC', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='GMC or Registration Number'
                error={errors.name}
                className={`${errors['externalUserGMC'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalUserGMC' as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name='status'
                control={control}
                rules={{ required: 'This is required' }}
                render={({ field }) => (
                  <>
                    <label htmlFor='status'>Status</label>
                    <AsyncPaginate
                      {...field}
                      inputId='status'
                      isClearable
                      placeholder={'External User Status'}
                      className={`react-select ${
                        errors['status'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, statusesOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name='status' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                id='externalUserEmail'
                name='externalUserEmail'
                type='email'
                {...register('externalUserEmail', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                label='Email Address'
                error={errors.name}
                className={`${errors['externalUserEmail'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='externalUserEmail'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <label htmlFor='externalUserPhone'>Contact Telephone Number</label>
              <PhoneInput
                id='externalUserPhone'
                placeholder='Enter here'
                name='externalUserPhone'
                international
                {...register('externalUserPhone', {
                  required: 'This is required',
                })}
                value={phoneValue}
                onChange={setPhoneValue}
                className={`${errors['externalUserPhone'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='externalUserPhone'
                as={InputError}
              />
            </FormItem>
          </Fieldset>
          <ButtonGroup>
            <Button
              type='button'
              onClick={() => onPreSubmit(false)}
              style={{ marginRight: '16px' }}
            >
              <Icon name='check' />
              Submit External User
            </Button>
            <Button
              type='button'
              onClick={() => onPreSubmit(true)}
              variant={'secondary'}
            >
              Add External User and Send Link
            </Button>
          </ButtonGroup>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this External User?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)}>Confirm</Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
