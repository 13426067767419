import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { Typography } from '../../atoms/Typography';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import { ADMIN } from '../../../utils/constants';
import { UserContext } from '../../../context';
import Modal from 'react-modal';
import { getProblemCategories } from 'api/playerProblems';
import { useDeleteProblemCategory } from './hooks/useDeleteProblemCategory';

Modal.setAppElement('#root');

export const ProblemCategories = () => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "Players" for a particular Team by invoking "getPlayers" hook
  const {
    data: problemCategories = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery('problemCategories', getProblemCategories);

  const {
    mutateAsync,
    isLoading: isDeleteLoading,
  } = useDeleteProblemCategory();

  const [isOpen, setIsOpen] = useState(false);

  const [currentProblem, setCurrentProblem] = useState();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentProblem(e.id);
    toggleModal();
  };

  const onDeletePlayer = () => {
    mutateAsync(currentProblem);
    toggleModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Problem name',
        accessor: 'col2',
      },
      {
        Header: 'Problem Description',
        accessor: 'col3',
      },
      {
        Header: 'Diagnosis Name',
        accessor: 'col5',
      },
      {
        Header: '',
        accessor: 'col6',
      },
      {
        Header: '',
        accessor: 'col7',
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return problemCategories.map((e) => {
      return {
        col1: e.id,
        col2: e.name,
        col3: e.description,
        col5: e.diagnosis_code.name,
        col6: user?.role === ADMIN && (
          <InterButton title={`Edit ${e.name}`} as={Link} to={`${url}/${e.id}/edit`}>
            <Icon name='edit' />
          </InterButton>
        ),
        col7: user?.role === ADMIN && (
          <InterButton
            color={'red'}
            title={`Delete ${e.name}`}
            type='button'
            onClick={() => onPreSubmit(e)}
            disabled={isDeleteLoading}
          >
            <Icon name='remove' />
          </InterButton>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error.message}</div>}
            {isLoading ? (
              <p>Receiving team players data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
            />
          </PaginationWrapper>
        )}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Are you sure?
            </Typography>
            <Typography as={'p'} tag={'h4'}>
              Do you really want to delete this problem category?
            </Typography>
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={onDeletePlayer}>Confirm</Button>
          </div>
        </Modal>
      </ContentWrapper>
    </>
  );
};
