import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const ConsultRoute = ({ layout: Layout, children, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        const { teamId, playerId, token = '' } = props.match.params;

        let allowed = false;
        let encryptedToken = { teamId: '', playerId: '' };

        try {
          encryptedToken = jwtDecode(token);
          allowed = true;
        } catch (error) {
          console.log('error', error);
        }

        return allowed &&
          playerId == encryptedToken.playerId &&
          teamId == encryptedToken.teamId ? (
          <Layout props={props}>{children}</Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default ConsultRoute;
