import React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../atoms/Input';
import { ForgotForm, InputError } from '../Layout';
import { Icon } from '../../atoms/Icon';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { PlainLink } from 'components/atoms/PlainLink';
import { usePasswordReset } from './hooks/usePasswordReset';

export const ForgotPassword = () => {
  const {
    mutateAsync,
    isSuccess,
    isLoading,
    isError,
    error,
  } = usePasswordReset();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      await mutateAsync({
        email: data.email,
        authMethod: 'basic',
        userAgent: 'web-application',
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <ForgotForm onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Icon name='mainLogo' />
        <Typography as={'h2'} tag={'h2'}>
          Forgotten password
        </Typography>
      </div>
      {isSuccess ? (
        <>
          <Typography tag={'p'} variant={'subtitle1'} style={{ textAlign: 'center'}}>
            Now you have to create your password. Please check your email.
          </Typography>
        </>
      ) : (
        <>
          <PlainLink as='p' variant={'subtitle1'}>
            Please enter your email address below and we will send you a
            password reset link
          </PlainLink>
          <Input
            name='email'
            type='text'
            placeholder='Email address'
            required
            className='first'
            {...register('email', {
              required: 'This is required',
            })}
          />
          <Button type='submit' disabled={isLoading}>
            Submit
          </Button>
        </>
      )}
      {isError && (
        <div>
          <InputError>Something went wrong</InputError>
          <InputError>{error?.data?.message}</InputError>
        </div>
      )}
    </ForgotForm>
  );
};
