import React from 'react';
import { Typography } from "../Typography";

import { StyledOrderedList } from "./styles";

export const OrderedList = ({ title, list }) => {
  return (
    <>
      {title && <Typography tag={'h4'}>{title}:</Typography>}
      <StyledOrderedList>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </StyledOrderedList>
    </>
  )
}
