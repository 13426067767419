import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import { Link, useRouteMatch } from 'react-router-dom';
import Modal from 'react-modal';

import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { getReferrals } from '../../../api/groupreferrals';
import { UserContext } from '../../../context';
import { Typography } from '../../atoms/Typography';
import { ADMIN } from '../../../utils/constants';

Modal.setAppElement('#root');

export const TeamReferrals = ({ teamId }) => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving referrals data from the API for a particular Team by invoking "getReferrals" hook
  const {
    data: teamToolsData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['groupreferrals', teamId], () => getReferrals(teamId));

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Title',
        accessor: 'col2',
      },
      {
        Header: 'External',
        accessor: 'col3',
      },
      {
        Header: 'Player',
        accessor: 'col4',
      },
      {
        Header: 'Problem',
        accessor: 'col5',
        disableSortBy: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return teamToolsData.map((e) => {
      return {
        col1: e.id,
        col2: e.name,
        col3: e.external.name,
        col4: (
            <InterButton title={`View Problem`} as={Link} to={`./players/${e.player.id}`}>
              {e.player.user.first_name + ' ' + e.player.user.last_name}
            </InterButton>
        ),
        col5: e.problem.name,
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error.message}</div>}
            {isLoading ? (
              <p>Receiving team players data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </>
  );
};
