import { useMutation, useQueryClient } from 'react-query';
import { editTeam } from '../../../../api/team';

export const useEditTeam = () => {
  const queryClient = useQueryClient();

  return useMutation(editTeam, {
    onSuccess: () => {
      // const cachedTodos = queryClient.getQueryData('teams');
      queryClient.invalidateQueries('teams');
      // queryClient.setQueryData('teams', [
      //   ...cachedTodos,
      //   {
      //     id: response.id,
      //     name: variables.name,
      //     statusId: variables.status,
      //     sportId: variables.sport,
      //   },
      // ]);
    },
  });
};
