import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';

export const DownshiftList = styled.ul`
  display: none;

  &:not(:empty) {
    display: block;
    margin: 1px 0 0 0;
    max-height: 200px;
    padding: 4px 0;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    border-radius: 4px;
  }

  li {
    padding: 5px 0 5px 10px;
    background-color: transparent;
    color: ${themeMain.colors.gray[100]};

    &:hover,
    &:focus {
      background-color: #deebff;
      color: ${themeMain.colors.gray[100]};
    }
  }
`;
