import React from 'react';
import { Link } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { useQuery } from 'react-query';

import { BreadcrumbsWrapper, Breadcrumbs } from '../Layout';
import { Icon } from '../../atoms/Icon';
import { getTeams } from '../../../api/teams';
import { getTeamPlayers } from '../../../api/players';
import { getTeamDoctors } from 'api/doctors';
import {
  reformatBreadCrumbsPlayersId,
  reformatBreadCrumbsTeamsId,
  reformatBreadCrumbsDoctorsId, reformatBreadCrumbsExternalUsersId, reformatBreadCrumbsExternalId,
} from '../../../utils/breadcrumbsFormat';
import {getExternalUsers} from "../../../api/externalUsers";
import {getExternals} from "../../../api/external";

const DynamicTeamBreadcrumb = ({ match }) => {
  // Receiving entity "teams" by invoking "getTeams" hook
  const { data: teamsData = [], isLoading } = useQuery('teams', getTeams);

  const teamNamesById = reformatBreadCrumbsTeamsId(teamsData);

  return (
    <span>
      {isLoading
        ? `Receiving teams data...`
        : `${teamNamesById[match.params.teamId]}`}
    </span>
  );
};

const DynamicExternalBreadcrumb = ({ match }) => {
  // Receiving entity "teams" by invoking "getTeams" hook
  const { data: externalData = [], isLoading } = useQuery('external', getExternals);

  const externalNamesById = reformatBreadCrumbsExternalId(externalData);

  return (
      <span>
      {isLoading
          ? `Receiving external company data...`
          : `${externalNamesById[match.params.externalId]}`}
    </span>
  );
};

const DynamicPlayerBreadcrumb = ({ match }) => {
  // Receiving entity "teamPlayers" for a particular Team by invoking "getTeamPlayers" hook
  const { data: teamPlayersData = { players: [] }, isLoading } = useQuery(
    ['teamPlayers', match.params.teamId],
    () => getTeamPlayers(match.params.teamId)
  );

  const playerNamesIdByTeam = reformatBreadCrumbsPlayersId(
    teamPlayersData.players
  );

  return (
    <span>
      {isLoading
        ? `Receiving player data...`
        : `${playerNamesIdByTeam[match.params.playerId]}`}
    </span>
  );
};

const DynamicExternalUsersBreadcrumb = ({ match }) => {
  // Receiving entity "externalUsers" for a particular External by invoking "getExternalUsers" hook
  const { data: externalUsersData = { external_user: [] }, isLoading } = useQuery(
      ['external_user', match.params.externalId],
      () => getExternalUsers(match.params.externalId)
  );

  const externalUserNamesIdByExternal = reformatBreadCrumbsExternalUsersId(
      externalUsersData.external_user
  );

  console.log('externalUserNamesIdByExternal: ', externalUserNamesIdByExternal);

  return (
      <span>
      {isLoading
          ? `Receiving external users data...`
          : `${externalUserNamesIdByExternal[match.params.externalUserId]}`}
    </span>
  );
};

const DynamicDoctorBreadcrumb = ({ match }) => {
  // Receiving entity "teamDoctors" for a particular Team by invoking "getTeamDoctors" hook
  const { data: teamDoctorsData = {}, isLoading } = useQuery(
    ['teamDoctors', match.params.teamId],
    () => getTeamDoctors(match.params.teamId)
  );

  const doctorNamesIdByTeam = reformatBreadCrumbsDoctorsId(
    teamDoctorsData.users
  );

  return (
    <span>
      {isLoading
        ? `Receiving medic data...`
        : `${doctorNamesIdByTeam[match.params.doctorId]}`}
    </span>
  );
};

// This is a route config, where we replace ["teamId", "PlayerId", "DoctorId"] entities into their names to render in breadcrumb component
const routesConfig = [
  { path: '/teams/add-team', breadcrumb: 'Add Team' },
  { path: '/teams/:teamId', breadcrumb: DynamicTeamBreadcrumb },
  { path: '/players/add-player', breadcrumb: 'Add player' },
  {
    path: '/teams/:teamId/add-player',
    breadcrumb: 'Add Player',
  },
  {
    path: '/teams/:teamId/players/:playerId',
    breadcrumb: DynamicPlayerBreadcrumb,
  },
  {
    path: '/teams/:teamId/players/:playerId/add-information',
    breadcrumb: 'Add Information',
  },
  {
    path: '/teams/:teamId/players/:playerId/add-note',
    breadcrumb: 'Add Rehab Note',
  },
  {
    path: '/teams/:teamId/players/:playerId/add-problem',
    breadcrumb: 'Add Problem',
  },
  {
    path: '/teams/:teamId/team-medics',
    breadcrumb: 'Team Medics',
  },
  {
    path: '/teams/:teamId/add-medic',
    breadcrumb: 'Add Medic',
  },
  {
    path: '/teams/:teamId/team-medics/:doctorId',
    breadcrumb: DynamicDoctorBreadcrumb,
  },
  {
    path: '/configuration/problem-categories',
    breadcrumb: 'Problem Categories',
  },
  {
    path: '/configuration/add-problem-category',
    breadcrumb: 'Add Problem Category',
  },
  {
    path: '/configuration/add-sport',
    breadcrumb: 'Add Sport',
  },
  {
    path: '/account',
    breadcrumb: 'Manage your AB3 account',
  },
  {
    path: '/search-results',
    breadcrumb: 'Search Results',
  },
  {
    path: '/teams/:teamId/players/:playerId/problems',
    breadcrumb: 'Problems',
  },
  {
    path: '/teams/:teamId/players/:playerId/information',
    breadcrumb: 'App Info',
  },
  {
    path: '/teams/:teamId/players/:playerId/notes',
    breadcrumb: 'Rehab Notes',
  },
  {
    path: '/external/add-external',
    breadcrumb: 'Add External Company',
  },
  {
    path: '/external/:externalId/add-external-user',
    breadcrumb: 'Add External User',
  },
  {
    path: '/external/:externalId/external-users/:externalUserId/edit',
    breadcrumb: 'Edit',
  },
  {
    path: '/external/:externalId/external-users/:externalUserId',
    breadcrumb: DynamicExternalUsersBreadcrumb,
  },
  {
    path: '/external/:externalId',
    breadcrumb: DynamicExternalBreadcrumb,
  },
];

// map & render your breadcrumb components however you want.
const PureBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumbs>
        {window.innerWidth >= 1280 && <img style={{width: 120, height: 57}} src={process.env.PUBLIC_URL+"/stripes.png"} />}
        <ul>
          {breadcrumbs.slice(1).map(({ match, breadcrumb }, index) => {
            // other props are available during render, such as `location`
            // and any props found in your route objects will be passed through too
            return (
              <li key={match.url}>
                <Link to={match.url || ''}>{breadcrumb}</Link>
                {index <= breadcrumbs.length - 2 && <Icon name='separator' />}
              </li>
            );
          })}
        </ul>
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  );
};

export default withBreadcrumbs(routesConfig)(PureBreadcrumbs);
