import { useState } from 'react';

export const useSearch = () => {
  const [searchContext, setSearchContext] = useState({
    teams: [],
    players: [],
    doctors: [],
  });

  return [searchContext, setSearchContext];
};
