import React, { useState, useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import { Typography } from '../../atoms/Typography';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import Modal from 'react-modal';
import {Controller, useForm} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";
import {useMutation, useQuery} from 'react-query';
import ReactPaginate from 'react-paginate';

import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper, InputError, FormItem,
} from '../../organisms/Layout';
import { getPlayerProblems } from 'api/playerProblems';
import { useDeletePlayerProblem } from './hooks/useDeletePlayerProblem';
import { useAddProblemToExternal } from './hooks/useAddProblemToExternal'
import { loadOptions } from "../../../utils/loadSelectOptions";
import { ErrorMessage } from "@hookform/error-message";
import { getExternals } from "../../../api/external";

Modal.setAppElement('#root');

export const PlayerProblems = ({ params }) => {
  let { url } = useRouteMatch();

  console.log('teamId: ', params.teamId);
  console.log('playerId: ', params.playerId);

  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const {
    data: playerProblemsData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['playerProblems', params.playerId], () =>
      getPlayerProblems(params.teamId, params.playerId)
  );

  //const playerProblemsData = [];

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { mutateAsync, isLoading: isDeleteLoading } = useDeletePlayerProblem();

  const { mutateAsync: externalMutation, isLoading: isExternalLoading } = useAddProblemToExternal();

  const [isOpen, setIsOpen] = useState(false);

  const [isExternalOpen, setIsExternalOpen] = useState(false);

  const [currentProblem, setCurrentProblem] = useState();

  const [externalCompany, setExternalCompany] = useState();
  const [externalCompanyName, setExternalCompanyName] = useState();

  const [delProblemName, setDelProblemName] = useState({})

  const [tableName, setTableName] = useState({})
  const [tableCategory, setTableCategory] = useState({})
  const [tableAddedBy, setTableAddedBy] = useState({})
  const [tableAddedDate, setTableAddedDate] = useState({})
  const [tableOverview, setTableOverview] = useState({})
  const [tableMajor, setTableMajor] = useState({})

  const [tableBodypart, setTableBodypart] = useState({})
  const [tableSide, setTableSide] = useState({})

  const [tableFiles, setTableFiles] = useState({})

  const [tableInfoOpen, setTableInfoOpen] = useState(false)

  const [currentTableInfo, setCurrentTableInfo] = useState();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleExternalModal = () => {
    setIsExternalOpen(!isExternalOpen);
  };

  const toggleTableModal = () => {
    setTableInfoOpen(!tableInfoOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentProblem(e?.id);
    setDelProblemName(e?.name)
    toggleModal();
  };

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const onPreTableClick = (e) => {
    let d = new Date(e?.created_at);
    let dateTime = dateTimeFormat.format(d);

    if (e?.major === true) {
      setTableMajor('Major');
    } else if (e?.major === false) {
      setTableMajor('Minor');
    } else {
      setTableMajor('Unknown');
    }

    setTableCategory(e?.category.name + ' [' + e?.category.diagnosis_code.name + ']')
    setTableAddedBy(e?.added_by)
    setTableAddedDate(dateTime)
    setTableOverview(e?.overview);
    setTableName(e?.name);
    setTableBodypart(e?.bodypart);
    setTableSide(e?.injury_select_box?.name || 'N/A');

    //console.log(JSON.stringify(e))

    let tempFile = [];
    let a= 0;
    while (e?.files[a]?.original_name && e?.files[a]?.publicUrl) {
      tempFile.push(<p key={e?.files[a]?.id}><a href={e?.files[a]?.publicUrl} target="_blank">{e?.files[a]?.original_name}</a></p>);
      a++;
    }
    setTableFiles(tempFile);

    setCurrentTableInfo(e?.id);
    toggleTableModal();
  };

  const onExternalProblemAdd = (e) => {
    setCurrentProblem(e?.id);
    toggleExternalModal();
  };

  const setExternalValue = (e) => {
    setExternalCompany(e?.value);
    setExternalCompanyName(e?.label);
  }

  const onDeleteProblem = () => {
    mutateAsync({
      teamId: params.teamId,
      playerId: params.playerId,
      problemId: currentProblem,
    });
    toggleModal();
  };

/*  const addExternal = (e) => {
    const formData = new FormData();
    formData.append('externalId', 2);
    {`${url}/${e.id}/edit`}
    fetch("/problems/team/2/player/6/problem/31/add-external", {
      method: 'PUT',
      body: formData
    })
  }*/

  const addExternal = () => {
    externalMutation({
      teamId: params.teamId,
      playerId: params.playerId,
      problemId: currentProblem,
      externalId: externalCompany,
    });
    toggleExternalModal();
  };

  // Receiving entity "external" by invoking "getExternal" hook
  const { data: externalData = [] } = useQuery('external', getExternals);
  const externalOptions = [];
  if (!externalData) {
    console.log('External Data is empty');
  }
  for (let i = 0; i < externalData.length; i++) {
    externalOptions.push({
      value: externalData[i].id,
      label: externalData[i].name,
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'From',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Until',
        accessor: 'col2',
      },
      {
        Header: 'Side',
        accessor: 'col3',
      },
      {
        Header: 'Problem name',
        accessor: 'col4',
      },
      {
        Header: '',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
    ],
    []
  );

  const fromUntilOptions = { year: '2-digit', month: 'short', day: 'numeric' };
  const fromUntilTimeFormat = new Intl.DateTimeFormat("en-GB", fromUntilOptions);

  const data = React.useMemo(() => {
    return playerProblemsData.map((e) => {

      let from, fromString, until, untilString, majorString, sideString, activeString, externalString = '';
      if (e?.from) {
        from = new Date(e?.from);
        fromString = fromUntilTimeFormat.format(from);
      }
      if (e?.until) {
        until = new Date(e?.until);
        untilString = fromUntilTimeFormat.format(until);
      }
      majorString = sideString = activeString = '';
      if (e?.major) {
        majorString = '* ';
      }
      if (e?.injury_select_box?.name) {
        sideString = e?.injury_select_box?.name;
      }
      if (e.external) {
        e.external?.forEach(async (company) => {
          externalString += company.name + ' ';
        });
      }

      let referral_volume = e?.external_referrals?.length;

      return {
        col1: fromString,
        col2: untilString,
        col3: sideString,
        col4: majorString + e?.name,
        col5: (
          <>
            <InterButton onClick={()=> onPreTableClick(e)} type='button' disabled={isExternalLoading} title={`Details`}><Icon name='searchblue'/></InterButton>
            { referral_volume }
            <InterButton
                color={'green'}
                type='button'
                as={Link} to={`${url}/${e?.id}/add-external-referral`}
                title={`Create External Referral`}
            >
              <Icon name='mailpencil'/>
            </InterButton>
          </>
        ),
        col6: (
            <>
              <InterButton title={`View \"${e?.name}\" app info`} as={Link} to={`${url}/${e.id}`}>
                <Icon name='info'/>
              </InterButton>
              <InterButton title={`Edit ${e?.name}`} as={Link} to={`${url}/${e.id}/edit`}>
                <Icon name='edit'/>
              </InterButton>
              <InterButton
                color={'red'}
                title={`Delete ${e?.name}`}
                type='button'
                onClick={() => onPreSubmit(e)}
                disabled={isDeleteLoading}
              >
                <Icon name='remove'/>
              </InterButton>
            </>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    activeString,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  let getCellProps = {
    className: 'active',
  };

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving teams data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} /*{...active === 'active' && ("class='active'")}*/ /*class='active'*/>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            {/* React Paginate component*/}
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft'/>
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight'/>
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Are you sure?
            </Typography>
            <Typography as={'p'} tag={'h4'}>
              Do you really want to delete problem {currentProblem}: "{JSON.stringify(delProblemName)}"?
            </Typography>
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={onDeleteProblem}>Confirm</Button>
          </div>
        </Modal>
        <Modal
            isOpen={isExternalOpen}
            onRequestClose={toggleExternalModal}
            contentLabel='My external dialog'
            className='myexternalmodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Add problem to External Company?
            </Typography>
            { externalCompanyName && (
                <Typography as={'p'} tag={'h4'}>
                  Add problem to {externalCompanyName}.
                </Typography>
            )}
            <Controller
                name="external"
                control={control}
                rules={{ }}
                render={({ field }) => (
                  <AsyncPaginate
                      {...field}
                      closeMenuOnSelect={true}
                      placeholder={'Add External'}
                      className={`react-select ${
                          errors['external'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, externalOptions)
                      }
                      onChange={setExternalValue}
                  />
                )}
            />
            <ErrorMessage errors={errors} name='external' as={InputError} />
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleExternalModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={addExternal}>Confirm</Button>
          </div>
        </Modal>
        <Modal isOpen={tableInfoOpen} closeModal={toggleTableModal} className='mymodal'  >
          <h1>{tableName}</h1>
          <p>
            <strong>Body Part:</strong> {tableBodypart}
          </p>
          <p>
            <strong>Side:</strong> {tableSide}
          </p>
          <p>
            <strong>Severity:</strong> {tableMajor}
          </p>
          <p>
            <strong>Category:</strong> {tableCategory}
          </p>
          <p>
            <strong>Overview:</strong> {tableOverview}
          </p>
          {tableFiles.length > 0 && (
          <div>
            <p>
              <strong>Files:</strong>
            </p>
            {tableFiles}
          </div>
          )}
          <p>
            <strong>Added:</strong> {tableAddedDate} by {tableAddedBy}
          </p>
          <Button onClick={toggleTableModal} variant={'secondary'}>
            Close
          </Button>
        </Modal>
      </ContentWrapper>
    </>
  );
};
