import React, { useState, useEffect } from 'react';
import { API_ENDPOINT } from 'api/_config';
import { io } from 'socket.io-client';
import { AUTHENTICATION_COOKIE } from 'utils/constants';
import { getCookie } from 'utils/cookie';
import { NotificationsDropdown } from './dropdown';
import { Notification } from './notification';

let socket;

export const Notifications = () => {
  const [isNotifications, setIsNotifications] = useState([]);
  const [isNewNotif, setIsNewNotif] = useState(false);

  useEffect(() => {
    socket = io.connect(`${API_ENDPOINT}`, {
      auth: {
        token: getCookie(AUTHENTICATION_COOKIE),
      },
    });
  }, []);

  const handleSetMessage = () => {
    socket.emit('get_all_notifications', {
      offset: 0,
      limit: 20,
    });
  };

  useEffect(() => {
    socket.on('is_disconnect', (id) => {});
    socket.on('is_online', (user) => {});
  }, []);

  useEffect(() => {
    socket.on('all_notifications', (data) => {
      setIsNotifications(data.notifications);
    });
  }, []);

  useEffect(() => {
    socket.on('notification', (data) => {
      handleSetMessage();
      setIsNewNotif(true);
      setIsNotifications((prevState) => [...prevState, data]);
    });
  }, []);

  return (
    <NotificationsDropdown
      onGetNotifications={handleSetMessage}
      isNotif={isNewNotif}
      setNew={setIsNewNotif}
    >
      <ul>
        {isNotifications ? (
          isNotifications.map((notification) => {
            return (
              <li key={notification.id}>
                <Notification info={notification} />
              </li>
            );
          })
        ) : (
          <p>No data yet</p>
        )}
      </ul>
    </NotificationsDropdown>
  );
};
