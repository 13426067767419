import React, { useEffect, useState, useContext } from 'react';
import { Icon } from '../../atoms/Icon';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Status } from '../../atoms/Status/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Button } from '../../atoms/Button/index';
import { Typography } from '../../atoms/Typography';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import { getTeamDoctors } from '../../../api/doctors';
import { UserContext } from '../../../context';
import { ADMIN } from '../../../utils/constants';
import { useDeleteDoctor } from './hooks/useDeleteTeamDoctor';

import Modal from 'react-modal';

Modal.setAppElement('#root');

export const Doctors = ({ teamId }) => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "Doctors" for a particular Team by invoking "getDoctors" hook
  const {
    data: teamDoctorsData = { users: [] },
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['teamDoctors', teamId], () => getTeamDoctors(teamId), { retry: false});

  const { mutateAsync, isLoading: isDeleteLoading } = useDeleteDoctor();

  const [isOpen, setIsOpen] = useState(false);

  const [currentDoctor, setCurrentDoctor] = useState();
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentDoctor(e?.doctor?.id);
    toggleModal();
  };

  const onDeleteDoctor = () => {
    mutateAsync({ doctorId: currentDoctor });
    toggleModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Medic Firstname',
        accessor: 'col2',
      },
      {
        Header: 'Medic Lastname',
        accessor: 'col3',
      },
      {
        Header: 'Status',
        accessor: 'col4',
      },
      {
        Header: '',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    // teamDoctorsData object will now include players (fix so that the doctor list for teams with no doctors is not broken)
    // Filter out anyone who isn't a doctor...
    const teamDoctors = teamDoctorsData.users.filter(( obj ) => {
      return obj.doctor !== null;
    });
    return teamDoctors?.map((e) => {
      if (e?.doctor?.id == null) {
        return false;
      }
        return {
          col1: e?.doctor?.id,
          col2: e?.first_name,
          col3: e?.last_name,
          col4: (
            <Status variant={e?.doctor?.status.name}>
              {e?.doctor?.status.name && e?.doctor?.status.name.toUpperCase()}
            </Status>
          ),
          col5: user?.role === ADMIN && (
            <InterButton title={`Edit ${e?.first_name} ${e?.last_name}`} as={Link} to={`${url}/${e?.doctor?.id}/edit`}>
              <Icon name='edit'/>
            </InterButton>
          ),
          col6: user?.role === ADMIN && (
            <InterButton
              color={'red'}
              title={`Delete ${e?.first_name} ${e?.last_name}`}
              type='button'
              onClick={() => onPreSubmit(e)}
              disabled={isDeleteLoading}
            >
              <Icon name='remove'/>
            </InterButton>
          ),
        };

    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isLoading ? (
              <p>Receiving team medics data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {isError && <Typography variant='error'>{error.data.message}</Typography>}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
            />
          </PaginationWrapper>
        )}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Are you sure?
            </Typography>
            <Typography as={'p'} tag={'h4'}>
              Do you really want to delete this doctor?
            </Typography>
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={onDeleteDoctor}>Confirm</Button>
          </div>
        </Modal>
      </ContentWrapper>
    </>
  );
};
