import axiosWrapper from './axiosInterceptor';

export const getProblemCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/problem-category',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getProblemCategorySearch = async (searchString) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/problem-category/?search=${searchString}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblems = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/problems/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addExternalToProblem = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/problems/team/${data.teamId}/player/${data.playerId}/problem/${data.problemId}/add-external`,
      responseType: 'json',
      withCredentials: true,
      data: {
        externalId: data.externalId,
      }
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

// This is getting the "information-problem" as well as the problem
export const getPlayerProblem = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/problems/team/${teamId}/player/${playerId}/problem/${problemId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addProblem = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/problems/team/${data.teamId}/player/${data.playerId}/`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editProblem = async ({
  teamId,
  playerId,
  problemId,
  ...restData
}) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/problems/team/${teamId}/player/${playerId}/problem/${problemId}`,
      responseType: 'json',
      data: { ...restData },
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deletePlayerProblem = async ({ teamId, playerId, problemId }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/problems/team/${teamId}/player/${playerId}/problem/${problemId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
