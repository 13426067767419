import { useMutation } from 'react-query';
import { createPassword } from '../../../../api/auth';

export const useCreatePassword = () => {
  return useMutation(createPassword, {
    onSuccess: (response, variables) => {
      return response;
    },
  });
};
