import axiosWrapper from './axiosInterceptor';

export const getPlayers = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/players',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayer = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/players/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editPlayer = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/players/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addPlayer = async ({ teamId, ...restData }) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/players/team/${teamId}`,
      responseType: 'json',
      withCredentials: true,
      data: { ...restData },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deletePlayer = async ({ teamId, playerId }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/players/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getTeamPlayers = async (teamId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/teams/${teamId}/players`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
