import styled from 'styled-components';
import themeMain from 'theme/themeMain';

export const StyledBurger = styled.div`
  width: 32px;
  height: 32px;
  margin-left: auto;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  align-self: center;

  @media (min-width: 1024px) {
    display: none;
  }

  div {
    width: 32px;
    height: 4px;
    margin: 3px 0;
    background-color: ${({ open }) => (open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: transform 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const Ul = styled.div`
  display: flex;
  opacity: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 100%;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: ${({ open }) => (open ? 'translateX(-100%)' : 'translateX(0)')};
  transition: all 0.3s ease-in-out;
  transition-property: transform, opacity;
  z-index: 10;
  background-color: ${themeMain.colors.gray[600]};

  @media (min-width: 1024px) {
    position: static;
    flex-flow: row nowrap;
    overflow-y: unset;
    transform: none;
    background-color: ${themeMain.colors.white};
    opacity: 1;
    transition: none;
  }
`;
