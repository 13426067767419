import { addPlayer } from '../../../../api/players';
import { useMutation, useQueryClient } from 'react-query';

export const useAddPlayer = () => {
  const queryClient = useQueryClient();

  return useMutation(addPlayer, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('players');
    },
  });
};
