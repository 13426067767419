import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllVaccinesShots, getPDFInfo } from "api/playerInformation";
import { Button } from "components/atoms/Button";
import * as XLSX from "xlsx";

export const XLSXDocument1 = ({ params }) => {
  const [isClicked, setClicked] = useState(false);
  const [isFinished, setFinished] = useState(false);
  const { data: vaccinesShots } = useQuery("getVaccines", getAllVaccinesShots);

  const generateXLSX = async () => {
    const playerInfo = await getPDFInfo(params);

    // Generate XLSX content
    const workbook = XLSX.utils.book_new();

    // Player Main Information
    const playerMainData = [
      [
        "Name",
        "Lastname",
        "Date of Birth",
        "Gender",
        "Team",
        "Status",
        "NHS number",
        "Email",
        "Phone number",
        "GP surgery",
        "Address 1",
        "Address 2",
        "Next of kin",
        "Nationality",
        "City",
        "Postcode",
      ],
      [
        playerInfo.player.user.first_name,
        playerInfo.player.user.last_name,
        playerInfo.player.birthday,
        playerInfo.player.gender.name,
        playerInfo.player.team.name,
        playerInfo.player.status.name,
        playerInfo.player.nhs_number,
        playerInfo.player.user.email,
        playerInfo.player.user.phone_number,
        playerInfo.player.gp_surgery,
        playerInfo.player.address_1,
        playerInfo.player.address_2,
        playerInfo.player.next_of_kin,
        playerInfo.player.nationality.name,
        playerInfo.player.city,
        playerInfo.player.postcode,
      ],
    ];
    const playerMainSheet = XLSX.utils.aoa_to_sheet(playerMainData);
    XLSX.utils.book_append_sheet(
      workbook,
      playerMainSheet,
      "Player Main Information"
    );

    // Player Problems
    const playerProblemsData = playerInfo.problems.map((problem) => [
      problem.name,
      problem.category?.name,
      problem.category?.description,
      problem.category?.diagnosis_code?.code,
      problem.category?.diagnosis_code?.name,
      problem.added_by,
      new Date(problem.created_at).toString().split("(")[0],
    ]);
    const playerProblemsSheet = XLSX.utils.aoa_to_sheet([
      [
        "Problem Name",
        "Category",
        "Category Description",
        "Diagnosis Code",
        "Diagnosis Name",
        "Added By",
        "Created At",
      ],
      ...playerProblemsData,
    ]);
    XLSX.utils.book_append_sheet(
      workbook,
      playerProblemsSheet,
      "Player Problems"
    );

    // Add Player Information to a new sheet
    const informationData = playerInfo.information.flatMap((elem) => {
      switch (elem.category_type.name) {
        case "details":
          return [
            [
              "Information category",
              "From",
              "Until",
              "Notes",
              "Problem Status",
              "MSK",
              "Information created at",
            ],
            [
              elem.category_type.name,
              new Date(elem.information_problem?.from).toString().split("(")[0],
              new Date(elem.information_problem?.until)
                .toString()
                .split("(")[0],
              elem.information_problem?.notes,
              elem.information_problem?.problem_status?.name,
              elem.information_problem?.msk?.name,
              new Date(elem.created_at).toString().split("(")[0],
            ],
          ];
        case "consultations":
          return [
            [
              "Information category",
              "Clinician Name",
              "Location",
              "Registration Number",
              "Problem",
              "History",
              "Investigation List",
              "Exam",
              "Diagnosis",
              "Treatment",
              "Treatment Details",
              "Information created at",
            ],
            [
              elem.category_type.name,
              elem.consult?.clinicianName,
              elem.consult?.location,
              elem.consult?.registrationNumber,
              elem.problem?.name,
              elem.consult?.history,
              elem.consult?.investigations.map((el) => el.name).join(", "),
              elem.consult?.exam,
              elem.consult?.diagnosis?.name,
              elem.consult?.plan?.plans.map((el) => el.name).join(", "),
              elem.consult?.details,
              new Date(elem.created_at).toString().split("(")[0],
            ],
          ];
        case "medications":
          return [
            [
              "Information category",
              "Problem",
              "Category",
              "Date",
              "Name",
              "Form",
              "Dose",
              "Times",
              "Indication",
              "Medication",
              "Reaction",
              "Injection",
              "Information created at",
            ],
            [
              elem.category_type.name,
              elem.problem?.name,
              elem.medications?.category_type?.name,
              new Date(elem.medications?.date).toString().split("(")[0],
              elem.medications?.name,
              elem.medications?.form,
              elem.medications?.dose,
              elem.medications?.times,
              elem.medications?.reason,
              elem.medications?.medication,
              elem.medications?.reaction,
              elem.medications?.vaccinesIdsList
                ?.map(
                  (vId) => vaccinesShots.find((shot) => shot.id === +vId)?.name
                )
                .join(", "),
              new Date(elem.created_at).toString().split("(")[0],
            ],
          ];
        case "letters":
          return [
            [
              "Information category",
              "Problem",
              "Name",
              "Date",
              "Indication",
              "Outcome",
              "File Upload",
              "Information created at",
            ],
            [
              elem.category_type.name,
              elem.problem?.name,
              elem.information_letters?.name,
              elem.information_letters?.date,
              elem.information_letters?.indication,
              elem.information_letters?.outcome,
              elem.information_letters?.files,
              new Date(elem.created_at).toString().split("(")[0],
            ],
          ];
        case "treatments":
          return [
            [
              "Information category",
              "Problem",
              "Category",
              "Date",
              "Notes",
              "Body Part",
              "Drug",
              "Dose",
              "Information created at",
            ],
            [
              elem.category_type.name,
              elem.problem?.name,
              elem.treatments?.category_type?.name,
              elem.treatments?.date,
              elem.treatments?.details,
              elem.treatments?.body_part,
              elem.treatments?.drug,
              elem.treatments?.dose,
              new Date(elem.created_at).toString().split("(")[0],
            ],
          ];
        default:
          return [];
      }
    });
    const informationWs = XLSX.utils.aoa_to_sheet(informationData);
    XLSX.utils.book_append_sheet(workbook, informationWs, "Player Information");

    XLSX.writeFile(workbook, "player_info.xlsx");
    setFinished(true);
  };

  useEffect(() => {
    if (isFinished) {
      setTimeout(() => {
        const elem = document.getElementById("xlsx-download-link");
        elem.click();
        setClicked(false);
        setFinished(false);
      });
    }
  }, [isFinished]);

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setClicked(true);
          generateXLSX();
        }}
      >
        Export Player (XLSX)
      </Button>
      {isClicked ? (
        <a
          id="xlsx-download-link"
          style={{ display: "none" }}
          href="/path/to/download/xlsx"
          download="player_info.xlsx"
        >
          Download XLSX
        </a>
      ) : null}
    </>
  );
};
