import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { useHistory } from 'react-router-dom';
import { Typography } from '../../../../atoms/Typography';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormItem, InputError } from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import { formatFormDate } from '../../../../../utils/formatFormDate';
import Modal from 'react-modal';
import { getTreatmentsInformationById } from 'api/playerInformation';
import { InjectionModule } from './Modules/injection';
import { useEditInfoTreatment } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";

Modal.setAppElement('#root');

export const TreatmentsModule = ({ params }) => {
  const methods = useForm();

  const { push } = useHistory();

  const { teamId, playerId, problemId, informationId } = params;

  const {
    register,
    unregister,
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const maxDate = new Date().toISOString().split('T')[0];

  const {
    data: treatmentsInformation = [],
    isLoading: isLoading,
  } = useQuery(['treatmentsInformation', informationId], () =>
    getTreatmentsInformationById(teamId, playerId, informationId)
  );

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isEditLoading } = useEditInfoTreatment();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        teamId: teamId,
        playerId: playerId,
        informationId: treatmentsInformation.id,
        ...(problemId && { problemId: problemId }),
        categoryTypeId: newData.medicationsCategory.id,
        date: newData.date,
        bodyPart: newData.bodyPart || null,
        drug: newData.drug || null,
        dose: newData.dose || null,
        details: newData.details
      });
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  const medicationsCategory = watch('medicationsCategory');

  // In case we need to unregister some fields
  useEffect(() => {
    medicationsCategory?.value?.name !== 'injection' &&
      unregister(['bodyPart', 'drug', 'dose']);
  }, [medicationsCategory]);

  useEffect(() => {
    if (!isLoading && treatmentsInformation) {
      [
        {
          name: 'medicationsCategory',
          value: {
            value: {
              id: treatmentsInformation.treatments.category_type.id,
              name: treatmentsInformation.treatments.category_type.name,
            },
            label: treatmentsInformation.treatments.category_type.name,
          },
        },
        { name: 'date', value: treatmentsInformation.treatments.date },
        { name: 'drug', value: treatmentsInformation.treatments.drug },
        {
          name: 'dose',
          value: treatmentsInformation.treatments.dose,
        },
        {
          name: 'bodyPart',
          value: treatmentsInformation.treatments.body_part,
        },
        {
          name: 'details',
          value: treatmentsInformation.treatments.details,
        }
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [treatmentsInformation]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormItem>
            <Controller
              name='medicationsCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='medicationsCategory'>Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId='medicationsCategory'
                    ref={ref}
                    isClearable
                    isDisabled
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['medicationsCategory'] ? 'error' : ''
                    }`}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='medicationsCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='date'
              max={maxDate}
              {...register('date')}
              placeholder='Enter Here'
              label='Date'
              error={errors.name}
              className={`${errors['date'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='date' as={InputError} />
          </FormItem>
          {medicationsCategory?.value?.name === 'injection' && <InjectionModule />}
        {['surgery', 'advice'].includes(medicationsCategory?.value.name) && (
          <FormItem>
            <Input
              id='details'
              name='details'
              type='text'
              variant='textarea'
              {...register('details')}
              error={errors.details}
              placeholder='Enter Here'
              label='Notes'
              className={`${errors['details'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='details' as={InputError} />
          </FormItem>
        )}
        <Button type='button' onClick={onPreSubmit}>
          <Icon name='check' />
          Submit Information
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          {submitMessage?.length > 0 ? (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Something went wrong
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  {submitMessage}
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModalClear} variant={'secondary'}>
                  Try again
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className='modal-body'>
                <Typography as={'h2'} tag={'h2'}>
                  Are you sure?
                </Typography>
                <Typography as={'p'} tag={'h4'}>
                  Do you really want to edit this information?
                </Typography>
              </div>
              <div className='modal-buttons'>
                <Button onClick={toggleModal} variant={'secondary'}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disabled={isEditLoading}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </Modal>
      </form>
    </FormProvider>
  );
};
