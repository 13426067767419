import { useMutation } from 'react-query';
import { invite } from '../../../../api/auth';

export const useInvite = () => {
  return useMutation(invite, {
    onSuccess: (response, variables) => {
      return response;
    },
  });
};
