import React, { useEffect } from 'react';
import { AddListForm } from './addListForm';
import useTodoState from './hooks/useTodoState';
import { InputListWrapper } from './styles';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';

export const AddList = React.forwardRef(
  (
    { label, value, name, setValues, initialValues, disabled, ...props },
    ref
  ) => {
    const { todos, addTodo, deleteTodo } = useTodoState(initialValues);

    useEffect(() => {
      if (setValues) {
        setValues(todos);
      }
    }, [todos, addTodo]);

    return (
      <InputListWrapper>
        <label>{label}</label>
        {!disabled && (
          <AddListForm
            saveTodo={(todoText) => {
              const trimmedText = todoText.trim();

              if (trimmedText.length > 0) {
                addTodo(trimmedText);
              }
            }}
          />
        )}

        <ul>
          {todos.map((todo, index) => {
            return (
              <li key={index.toString()}>
                <input ref={ref} name={name} value={todo} {...props} hidden />
                <p>{todo}</p>
                {!disabled && (
                  <Button
                    aria-label='Delete'
                    icon
                    type='button'
                    onClick={() => {
                      deleteTodo(index);
                    }}
                  >
                    <Icon name='remove' />
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
      </InputListWrapper>
    );
  }
);
