import { useMutation, useQueryClient } from 'react-query';
import { deleteDoctor } from '../../../../api/doctors';

export const useDeleteDoctor = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteDoctor, {
    onSuccess: () => {
      queryClient.invalidateQueries('teamDoctors');
    },
    retry: false,
  });
};
