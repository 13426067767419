import { useMutation, useQueryClient } from 'react-query';

import { editForm } from '../../../../api/forms';

export const useEditForm = () => {
  const queryClient = useQueryClient();

  return useMutation(editForm, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('forms');
    },
  });
};
