import { useMutation, useQueryClient } from 'react-query';
import { addPublicConsult } from 'api/publicConsult';

export const useAddPublicConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(addPublicConsult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};
