import { useMutation, useQueryClient } from 'react-query';

import { addTool } from '../../../../api/grouptools';

export const useAddTool = () => {
  const queryClient = useQueryClient();

  return useMutation(addTool, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('forms');
    },
  });
};
