import { useMutation, useQueryClient } from 'react-query';
import { addExternalUser } from '../../../../api/externalUsers';

export const useAddExternalUser = () => {
  const queryClient = useQueryClient();

  return useMutation(addExternalUser, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('externalUsers');
    },
  });
};
