import React, { useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormItem, InputError } from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { getTreatmentsInformationById } from 'api/playerInformation';
import { InjectionModule } from './Modules/injection';

Modal.setAppElement('#root');

export const TreatmentsModule = ({ params }) => {
  const methods = useForm();

  const { teamId, playerId, informationId } = params;

  const {
    register,
    unregister,
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const {
    data: treatmentsInformation = [],
    isLoading: isLoading,
  } = useQuery(['treatmentsInformation', informationId], () =>
    getTreatmentsInformationById(teamId, playerId, informationId)
  );

  const medicationsCategory = watch('medicationsCategory');

  // In case we need to unregister some fields
  useEffect(() => {
    medicationsCategory?.value?.id !== 1 && unregister(['bodyPart', 'drug', 'dose']);
  }, [medicationsCategory]);

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const detailsDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const detailsDateTimeFormat = new Intl.DateTimeFormat("en-GB", detailsDateOptions);

  useEffect(() => {
    if (!isLoading && treatmentsInformation) {
      const d = new Date(treatmentsInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);

      let detailsDate, detailsDateString = '';
      if (treatmentsInformation.treatments.date) {
        detailsDate = new Date(treatmentsInformation.treatments.date);
        detailsDateString = detailsDateTimeFormat.format(detailsDate);
      }
      [
        {
          name: 'medicationsCategory',
          value: {
            value: {
              id: treatmentsInformation.treatments.category_type.id,
              name: treatmentsInformation.treatments.category_type.name,
            },
            label: treatmentsInformation.treatments.category_type.name,
          },
        },
        { name: 'date', value: detailsDateString },
        { name: 'drug', value: treatmentsInformation.treatments.drug },
        {
          name: 'dose',
          value: treatmentsInformation.treatments.dose,
        },
        {
          name: 'bodyPart',
          value: treatmentsInformation.treatments.body_part,
        },
        {
          name: 'details',
          value: treatmentsInformation.treatments.details,
        },
        {
          name: 'dateAdded',
          value: dateAddedString,
        }
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [treatmentsInformation]);

  return (
    <FormProvider {...methods}>
      <form>
          <FormItem>
            <Controller
              name='medicationsCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='medicationsCategory'>Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId='medicationsCategory'
                    ref={ref}
                    isClearable
                    isDisabled
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['medicationsCategory'] ? 'error' : ''
                    }`}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='medicationsCategory'
              as={InputError}
            />
          </FormItem>
        <FormItem small>
          <Input
            id='dateAdded'
            name='dateAdded'
            type='text'
            {...register('dateAdded', {})}
            label='Date Added'
            disabled
          />
        </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='text'
              disabled
              {...register('date')}
              label='Date'
            />
          </FormItem>
        {medicationsCategory?.value?.id === 1 && <InjectionModule />}
        {[2, 3].includes(medicationsCategory?.value?.id) && (
          <FormItem>
            <Input
              id='details'
              name='details'
              type='text'
              variant='textarea'
              {...register('details')}
              error={errors.details}
              placeholder='Enter Here'
              disabled
              label='Notes'
              className={`${errors['details'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='details' as={InputError} />
          </FormItem>
        )}
      </form>
    </FormProvider>
  );
};
