import styled from 'styled-components';
import VerificationInput from "react-verification-input";

export const StyledVerificationInput = styled(VerificationInput)`
  display: flex;
  padding-top: 23px;
  margin-bottom: 10px;
  
  .character {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.gray[400]};
    
    &--selected {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
