import axiosWrapper from './axiosInterceptor';

export const getSearchResult = async (searchString) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/search?search=${searchString}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
