import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from "react-select";
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import {
  FormItem,
  InputError,
  CheckboxGroup,
  Fieldset,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { Checkbox } from 'components/atoms/Checkbox';
import {
  getProblemCategoryTypes,
  getProblemInformationById,
  getProblemInformationPdfUrl,
  getProblemInformationPhoto,
} from 'api/playerInformation';
import { EnumInformationDetailsMsk } from "../../../../../constants/informationDetails";

Modal.setAppElement('#root');

export const ProblemModule = ({ params }) => {

  const { teamId, playerId, informationId } = params;
  const { data: informationCategories = [], isLoading: informationCategoryIsLoading } = useQuery(
    'problemCategoryTypes',
    getProblemCategoryTypes
  );

  const {
    register,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      category: '',
      subCategory: '',
    }
  });

  const selectedSubCategory = watch('category')

  const {
    data: problemInformation = [],
    isLoading: isLoading,
  } = useQuery(['problemInformation', informationId], () =>
    getProblemInformationById(teamId, playerId, informationId)
  );

  const [prevPhotos, setPrevPhotos] = useState([]);

  const fetchPhoto = async ({ teamId, playerId, informationId, id }) => {
    const data = await getProblemInformationPhoto(
      teamId,
      playerId,
      informationId,
      id
    );

    const link = document.createElement('a');
    link.href = "data:" + data.encoding + ";base64," + data.base64;
    document.body.appendChild(link);
    link.download = data.original_name;
    link.click();
    document.body.removeChild(link);
  };

  const fetchPdfUrl = async ({ teamId, playerId, informationId, id }) => {
    const downloadUrl = await getProblemInformationPdfUrl(
      teamId,
      playerId,
      informationId,
      id
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  const formPhotos = [];

  const subCategoryOptions = selectedSubCategory?.value?.subCategories?.map(item => ({ label: item.name, value: item })) || []

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const detailsDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const detailsDateTimeFormat = new Intl.DateTimeFormat("en-GB", detailsDateOptions);

  useEffect(() => {
    if (!isLoading && !informationCategoryIsLoading && problemInformation) {
      const d = new Date(problemInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);
      const { category_type: categoryType, photos } = problemInformation.information_problem;

      let fromDetailsDate, fromDetailsDateString = '';
      if (problemInformation.information_problem?.from) {
        fromDetailsDate = new Date(problemInformation.information_problem?.from);
        fromDetailsDateString = detailsDateTimeFormat.format(fromDetailsDate);
      }
      let untilDetailsDate, untilDetailsDateString = '';
      if (problemInformation.information_problem?.until) {
        untilDetailsDate = new Date(problemInformation.information_problem?.until);
        untilDetailsDateString = detailsDateTimeFormat.format(untilDetailsDate);
      }

      const parentCategory = informationCategories.find(category => category.id === categoryType.parent_id) || null

      reset({
        dateAdded: dateAddedString,
        from: fromDetailsDateString,
        until: untilDetailsDateString,
        notes: problemInformation.information_problem.notes,
        category: parentCategory ? {
          value: {
            id: parentCategory.id,
            name: parentCategory.name,
            subCategories: informationCategories.filter(i => i.parent_id === parentCategory.id)
          },
          label: parentCategory.name,
        } : {
          value: {
            id: categoryType.id,
            name: categoryType.name,
          },
          label: categoryType.name,
        },
        subCategory: categoryType.parent_id !== 0 ? {
          value: {
            id: categoryType.id,
            name: categoryType.name,
          },
          label: categoryType.name,
        } : null
      })
      setPrevPhotos(photos);
    }
  }, [problemInformation]);

  return (
    <form>
      <FormItem>
        <Controller
          name='category'
          control={control}
          rules={{ required: 'This is required' }}
          render={({ field }, ref) => (
            <>
              <label htmlFor='category'>Category</label>
              <Select
                {...field}
                inputId='category'
                ref={ref}
                isClearable
                isDisabled
                placeholder={'Your information'}
                className={`react-select ${errors['category'] ? 'error' : ''}`}
              />
            </>
          )}
        />
        <ErrorMessage
          errors={errors}
          name='informationCategory'
          as={InputError}
        />
      </FormItem>
      {subCategoryOptions.length ? (
        <FormItem>
          <Controller
            name='subCategory'
            control={control}
            rules={{ required: 'This is required' }}
            render={({ field }, ref) => (
              <>
                <label htmlFor='subCategory'>Sub Category</label>
                <Select
                  {...field}
                  inputId='subCategory'
                  ref={ref}
                  isClearable
                  isDisabled
                  placeholder={'Your information'}
                  className={`react-select ${errors['subCategory'] ? 'error' : ''}`}
                  options={subCategoryOptions}
                />
              </>
            )}
          />
          <ErrorMessage
            errors={errors}
            name='subCategory'
            as={InputError}
          />
        </FormItem>
      ) : null}
      <FormItem small>
        <Input
          id='dateAdded'
          name='dateAdded'
          type='text'
          {...register('dateAdded', {})}
          label='Date Added'
          disabled
        />
      </FormItem>
      <Fieldset>
        <FormItem small>
          <Input
            id='from'
            name='from'
            type='text'
            disabled
            {...register('from', {})}
            label='From'
          />
        </FormItem>
        <FormItem small>
          <Input
            id='until'
            name='until'
            type='text'
            disabled
            {...register('until', {})}
            label='Until'
          />
        </FormItem>
      </Fieldset>
      <FormItem>
        <Input
          id='notes'
          name='notes'
          type='text'
          variant='textarea'
          disabled
          {...register('notes', {
            required: 'This is required',
          })}
          placeholder='Enter Here'
          label='Overview'
          error={errors.name}
          className={`${errors['notes'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='notes' as={InputError} />
      </FormItem>
      <FormItem>
        <PhotosWrapper>
          {Array.from(formPhotos).map((i, index) => {
            return <div key={i + index}>{i.name}</div>;
          })}
        </PhotosWrapper>
        {prevPhotos.length > 0 && (
          <>
            <label>Current photos</label>
            <PhotosWrapper>
              {prevPhotos.map((photo, index) => {
                return (
                  <div key={index + photo}>
                    {photo.encoding.startsWith('application') && (

                      <Button
                        type='button'
                        aria-label='Fetch url'
                        icon
                        onClick={() =>
                          fetchPdfUrl({
                            teamId,
                            playerId,
                            informationId,
                            id: photo.id,
                          })
                        }
                      >
                        Download {photo.original_name}
                      </Button>

                    )}
                    {photo.encoding.startsWith('image') && (
                      photo?.image ? (
                        <img
                          alt={photo.original_name}
                          src={`data:${photo?.encoding};base64, ${photo?.image}`}
                        />
                      ) : (
                        <Button
                          type='button'
                          aria-label='Fetch photo'
                          icon
                          onClick={() =>
                            fetchPhoto({
                              teamId,
                              playerId,
                              informationId,
                              id: photo.id,
                              encoding: photo.encoding,
                              image: photo.original_name
                            })
                          }
                        >
                          Download {photo.original_name}
                        </Button>
                      )
                    )}
                  </div>
                );
              })}
            </PhotosWrapper>
          </>
        )}
      </FormItem>
      {!isLoading && (
        <>
          <FormItem>
            <label>Problem Status</label>
            <Checkbox
              name='major'
              type='radio'
              value={1}
              disabled
              label='Minor'
              defaultChecked={
                1 === problemInformation.information_problem.problem_status.id
              }
              {...register('major')}
              className={`${errors['major'] ? 'error' : ''}`}
            />
            <Checkbox
              name='major'
              type='radio'
              value={2}
              disabled
              label='Major'
              defaultChecked={
                2 === problemInformation.information_problem.problem_status.id
              }
              {...register('major')}
              className={`${errors['major'] ? 'error' : ''}`}
            />
          </FormItem>
        </>
      )}
    </form>
  );
};
