import { addNote } from '../../../../api/playerNotes';
import { useMutation, useQueryClient } from 'react-query';

export const useAddNote = () => {
  const queryClient = useQueryClient();

  return useMutation(addNote, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerNotes');
    },
  });
};
