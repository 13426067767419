import {Route, Switch} from "react-router-dom";
import React from "react";

import { PrintExternalReferral } from "../PrintExternalReferral";

export const Print = () => {
    return (
        <>
            <Switch>
                <Route path={'/print/teams/:teamId/players/:playerId/referrals/:externalReferralId'}>
                    <PrintExternalReferral/>
                </Route>
            </Switch>

        </>
    );
};
