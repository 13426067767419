import React from 'react';
import { SearchLink, SearchBox } from './styles';

export const SearchItem = ({ info, link }) => {
  const refactorNotification = (data) => {
    return (
      <SearchLink to={link}>
        <SearchBox>
          {data?.name && <p>{data?.name}</p>}
          {data?.user?.first_name && <p>{data?.user?.first_name}</p>}
          {data?.user?.last_name && <p>{data?.user?.last_name}</p>}
        </SearchBox>
      </SearchLink>
    );
  };

  return <>{info && refactorNotification(info)}</>;
};
