import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { Status } from '../../atoms/Status/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Typography } from '../../atoms/Typography';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  TableHeader,
  PaginationWrapper,
} from '../../organisms/Layout';
import ReactPaginate from 'react-paginate';
import { SearchContext, UserContext } from '../../../context';
import { ADMIN } from '../../../utils/constants';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const TeamsSearch = () => {
  const [user] = useContext(UserContext);
  const [search] = useContext(SearchContext);

  // Table Header Columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Team Name',
        accessor: 'col2',
      },
      {
        Header: 'Sport',
        accessor: 'col3',
      },
      {
        Header: 'Added by',
        accessor: 'col4',
      },
      {
        Header: 'Status',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col7',
        disableSortBy: true,
      },
    ],
    []
  );

  // Table data recompiled from query request to react-table tbody data
  const data = React.useMemo(() => {
    return search.teams.map((e) => {
      return {
        col1: e.id,
        col2: e.name,
        col3: e.sport.name,
        col4: e.added_by,
        col5: (
          <Status variant={e.status.name}>
            {e.status.name && e.status.name.toUpperCase()}
          </Status>
        ),
        col6: (
          <InterButton title={`View ${e.name}`} as={Link} to={`/teams/${e.id}`}>
            <Icon name='view' />
            View
          </InterButton>
        ),
        col7: user?.role === ADMIN && (
          <InterButton title={`Edit ${e.name}`} as={Link} to={`/teams/${e.id}/edit`}>
            <Icon name='edit' />
            Edit
          </InterButton>
        ),
      };
    });
  }, [search.teams]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <ContentWrapper>
      <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
        <TableWrapper>
          {!!search.teams.length ? (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>No information is available for this request</p>
          )}
        </TableWrapper>
      </div>
      {pageCount > 1 && (
        <PaginationWrapper>
          {/* React Paginate component*/}
          <ReactPaginate
            previousLabel={
              <span>
                <Icon name='arrowLeft' />
                Previous
              </span>
            }
            nextLabel={
              <span>
                Next
                <Icon name='arrowRight' />
              </span>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={pageCount}
            forcePage={selectedPage}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
    </ContentWrapper>
  );
};
