import styled from "styled-components";
import themeMain from "../../../../theme/themeMain";
import {Typography} from "../../../atoms/Typography";
import React from "react";
import {Button} from "../../../atoms/Button";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${themeMain.colors.gray[600]};
`;


export const ErrorNotFound = () => {
    return (
        <Background>
            <Typography as={'h2'} tag={'h2'}>
                Page not found
            </Typography>
            <Typography as={'p'} tag={'h4'}>
                The page you've tried to access doesn't exist.
            </Typography>

            <Button
                aria-label='Go back'
                icon
                type='button'
                onClick={() => history.back()}
            >
                Go back
            </Button>

        </Background>
    )
}
