import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Input } from "../../../../atoms/Input";
import { Button } from "../../../../atoms/Button";
import { Icon } from "../../../../atoms/Icon";
import { Typography } from "../../../../atoms/Typography";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import {
  FormItem,
  InputError,
  CheckboxGroup,
  Fieldset,
  PhotosWrapper,
} from "../../../../organisms/Layout";
import { useQuery } from "react-query";
import Modal from "react-modal";
import { Checkbox } from "components/atoms/Checkbox";
import { getProblemCategoryTypes } from "api/playerInformation";
import { useAddInfoProblem } from "../../hooks";
import { trimFormData } from "../../../../../utils/trimFormData";
import { EnumInformationDetailsMsk } from "../../../../../constants/informationDetails";

Modal.setAppElement("#root");

export const ProblemModule = ({ params }) => {
  const { push } = useHistory();

  const maxDate = new Date().toISOString().split("T")[0];

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    resetField,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      category: "",
      subCategory: "",
      from: maxDate,
    },
  });

  const selectedSubCategory = watch("category");

  // Receiving entity "informationCategories"
  const { data: informationCategories = [] } = useQuery(
    "problemCategoryTypes",
    getProblemCategoryTypes
  );

  const categories = informationCategories.reduce((accumulator, item) => {
    if (item.parent_id !== 0) {
      return accumulator?.map((i) => {
        if (i.id === item.parent_id) {
          return { ...i, subCategories: [...(i.subCategories || []), item] };
        }
        return i;
      });
    }
    return [...accumulator, item];
  }, []);

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as an 3rd argument
  const categoryOptions = categories.map((category) => ({
    value: category,
    label: category.name,
  }));

  const subCategoryOptions =
    selectedSubCategory?.value?.subCategories?.map((item) => ({
      label: item.name,
      value: item,
    })) || [];

  const { mutateAsync, isLoading: isAddLoading } = useAddInfoProblem();

  const [submitMessage, setSubmitMessage] = useState();

  const watchPhotos = watch("photos");

  const [formPhotos, setFormPhotos] = useState([]);

  useEffect(() => {
    setFormPhotos(getValues("photos"));
  }, [watchPhotos]);

  const onDelete = (index) => {
    setFormPhotos(
      Array.from(formPhotos).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);
    const formData = new FormData();

    if (params.problemId) {
      formData.append("problemId", params.problemId);
    }
    formData.append(
      "categoryTypeId",
      data.subCategory?.value?.id || data.category.value.id
    );
    formData.append("from", data.from);
    formData.append("until", data.until);
    formData.append("problemStatusId", data.major);
    formData.append("notes", data.notes);

    if (data.photos) {
      for (const key of data.photos) {
        formData.append("file", key);
      }
    }

    try {
      await mutateAsync({
        teamId: params.teamId,
        playerId: params.playerId,
        formData,
      });
      push(`/teams/${params.teamId}/players/${params.playerId}/information`);
    } catch (error) {
      console.log("error", error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage("");
    }, 500);
  }, [isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <Controller
          name="category"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field: { onChange, onBlur, value } }, ref) => (
            <>
              <label htmlFor="category">Category</label>
              <Select
                onChange={(v) => {
                  resetField("subCategory");
                  onChange(v);
                }}
                onBlur={onBlur}
                value={value}
                inputId="category"
                ref={ref}
                isClearable
                placeholder={"Your information"}
                className={`react-select ${errors["category"] ? "error" : ""}`}
                options={categoryOptions}
              />
            </>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="informationCategory"
          as={InputError}
        />
      </FormItem>
      {subCategoryOptions.length ? (
        <FormItem>
          <Controller
            name="subCategory"
            control={control}
            rules={{ required: "This is required" }}
            render={({ field }, ref) => (
              <>
                <label htmlFor="subCategory">Sub Category</label>
                <Select
                  {...field}
                  inputId="subCategory"
                  ref={ref}
                  isClearable
                  placeholder={"Your information"}
                  className={`react-select ${
                    errors["subCategory"] ? "error" : ""
                  }`}
                  options={subCategoryOptions}
                />
              </>
            )}
          />
          <ErrorMessage errors={errors} name="subCategory" as={InputError} />
        </FormItem>
      ) : null}
      <Fieldset>
        <FormItem small>
          <Input
            id="from"
            name="from"
            type="date"
            max={maxDate}
            {...register("from", {
              required: "This is required",
            })}
            placeholder="Enter Here"
            label="From"
            error={errors.name}
            className={`${errors["from"] ? "error" : ""}`}
          />
          <ErrorMessage errors={errors} name="from" as={InputError} />
        </FormItem>
        <FormItem small>
          <Input
            id="until"
            name="until"
            type="date"
            max={maxDate}
            {...register("until")}
            placeholder="Enter Here"
            label="Until"
            error={errors.name}
            className={`${errors["until"] ? "error" : ""}`}
          />
          <ErrorMessage errors={errors} name="until" as={InputError} />
        </FormItem>
      </Fieldset>
      <FormItem>
        <Input
          id="notes"
          name="notes"
          type="text"
          variant="textarea"
          {...register("notes", {
            required: "This is required",
          })}
          placeholder="Enter Here"
          label="Overview"
          error={errors.name}
          className={`${errors["notes"] ? "error" : ""}`}
        />
        <ErrorMessage errors={errors} name="notes" as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id="photos"
          name="photos"
          type="file"
          multiple
          accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          {...register("photos", {})}
          placeholder="Enter Here"
          label="Files"
          error={errors.name}
          className={`${errors["photos"] ? "error" : ""}`}
        />
        <ErrorMessage errors={errors} name="photos" as={InputError} />
        <PhotosWrapper>
          {Array.from(formPhotos).map((i, index) => {
            return (
              <div key={i + index}>
                {i.name}
                <Button
                  aria-label="Delete"
                  icon
                  type="button"
                  onClick={() => onDelete(index)}
                >
                  <Icon name="remove" />
                </Button>
              </div>
            );
          })}
        </PhotosWrapper>
      </FormItem>
      <FormItem>
        <label>Problem Status</label>
        <Controller
          control={control}
          rules={{ required: { value: true, message: "This is required" } }}
          render={({ field: { onChange, onBlur } }) => (
            <CheckboxGroup>
              <Checkbox
                name="major"
                type="radio"
                value={1}
                label="Minor"
                className={`${errors["major"] ? "error" : ""}`}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
              />
              <Checkbox
                name="major"
                type="radio"
                value={2}
                label="Major"
                className={`${errors["major"] ? "error" : ""}`}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
              />
            </CheckboxGroup>
          )}
          name={"major"}
        />
        {errors["major"] && (
          <ErrorMessage errors={errors} name="major" as={InputError} />
        )}
      </FormItem>
      <Button type="button" onClick={onPreSubmit}>
        <Icon name="check" />
        Submit Information
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {submitMessage?.length > 0 ? (
          <>
            <div className="modal-body">
              <Typography as={"h2"} tag={"h2"}>
                Something went wrong
              </Typography>
              <Typography as={"p"} tag={"h4"}>
                {submitMessage}
              </Typography>
            </div>
            <div className="modal-buttons">
              <Button onClick={toggleModalClear} variant={"secondary"}>
                Try again
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-body">
              <Typography as={"h2"} tag={"h2"}>
                Are you sure?
              </Typography>
              <Typography as={"p"} tag={"h4"}>
                Do you really want to add this information?
              </Typography>
            </div>
            <div className="modal-buttons">
              <Button onClick={toggleModal} variant={"secondary"}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isAddLoading}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal>
    </form>
  );
};
