import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';
import { devices } from '../../../theme/devices';

export const Nav = styled.nav`
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 16px;
  background: ${themeMain.colors.white};
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  right: -44px;

  @media ${devices.tablet} {
    min-width: 332px;
    right: 0;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    margin: 0;

    li {
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(15, 15, 15, 0.2);

      &:last-child {
        border: none;
      }
    }
  }
`;

export const NotificationIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin-left: 16px;
  cursor: pointer;
  border: 2px solid #1E51DC;
  border-radius: 50%;

  ${({ isNew }) =>
    isNew &&
    css`
      border-radius: 50%;
      background: ${themeMain.colors.blue};

      path {
        stroke: ${themeMain.colors.white};
      }
    `}
`;

export const NotificationLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${themeMain.colors.gray[100]};

  &:visited {
    color: ${themeMain.colors.gray[100]};
  }
`;

export const NotificationBox = styled.div`
  padding: 8px 0 4px;

  h3 {
    font: 700 16px/23px ${fonts.monserrat};
    margin: 0 0 4px;
  }

  p {
    font: 300 12px/14px ${fonts.monserrat};
    margin: 0 0 4px !important;

    &:first-child {
      color: ${themeMain.colors.gray[400]};
    }
  }
`;
