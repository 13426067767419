import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';

import Modal from 'react-modal';

import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { Status } from '../../atoms/Status/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Typography } from '../../atoms/Typography';

import {
  ContentWrapper,
  TableWrapper,
  TableHeader,
  PaginationWrapper, SubheaderWrapper, TableSubheader,
} from '../../organisms/Layout';
import { UserContext } from '../../../context';
import { ADMIN } from '../../../utils/constants';
import {TabList} from "../../atoms/TabList";
import {TabItem} from "../../atoms/TabItem";
import {getExternalReferrals, getPlayerReferrals} from "../../../api/playerReferrals";
import { getExternal } from "../../../api/external-one";


Modal.setAppElement('#root');

export const ExternalReferrals = () => {
  let { url} = useRouteMatch();
  let { externalId } = useParams();

    console.log('This is Referrals!!');
    console.log(url);
    console.log('External ID: ', externalId);

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "referrals"
  const {
    data: referralData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(
      ['referrals', externalId], () =>
      getExternalReferrals(externalId)
  );

    const {
        data: externalData = { external: [] }
    } = useQuery(['external', externalId], () => getExternal(externalId));

    let companyName = '';

// scroll through the object to find the name of the external company
    for (const [key, value] of Object.entries(externalData)) {
        if (key === 'name' && companyName === '') {
            companyName = value;
        }
    }


  // Table Header Columns
  const columns = React.useMemo(
      () => [
        {
          Header: 'Reference',
          accessor: 'col1', // accessor is the "key" in the data
        },
        {
          Header: 'External Company',
          accessor: 'col2',
        },
        {
          Header: 'Referral Title',
          accessor: 'col3',
        },
        {
          Header: 'Added by',
          accessor: 'col4',
        },
        {
          Header: 'Status',
          accessor: 'col5',
        },
        {
          Header: '',
          accessor: 'col6',
          disableSortBy: true,
        },
      ],
      []
  );

  // Table data recompiled from query request to react-table tbody data
  const data = React.useMemo(() => {
    return referralData.map((e) => {
      return {
        col1: e?.id,
        col2: e?.external.name,
        col3: e?.name,
        col4: e?.added_by,
        col5: (
            <Status variant={e?.status.name}>
              {e?.status.name && e?.status.name.toUpperCase()}
            </Status>
        ),
        col6: (
            <InterButton title={`View ${e?.name}`} as={Link} to={`/teams/${e?.team.id}/players/${e?.player.id}/referrals/${e?.id}`}>
              <Icon name='view'/>
            </InterButton>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 10 }, // Pass our default pageSize
      },
      useSortBy,
      usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
      <ContentWrapper>
        <TableHeader>
          <Typography as={'h1'} tag={'h1'}>
              { companyName }
          </Typography>
        </TableHeader>
        <SubheaderWrapper>
          <TableSubheader>
            <TabList>
              <li>
                <TabItem to={`/external/${externalId}`}>External Users</TabItem>
              </li>
              <li>
                <TabItem to={`${url}`}>Referrals</TabItem>
              </li>
            </TabList>
          </TableSubheader>
        </SubheaderWrapper>
        <TableWrapper>
          {isError && <div>{error?.message}</div>}
          {isLoading ? (
              <p>Receiving external company data...</p>
          ) : (
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                          <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                        {column.isSorted
                            ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                            : null}
                      </span>
                          </th>
                      ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                  );
                })}
                </tbody>
              </table>
          )}
        </TableWrapper>
        {pageCount > 1 && (
            <PaginationWrapper>
              {/* React Paginate component*/}
              <ReactPaginate
                  previousLabel={
                    <span>
                <Icon name='arrowLeft' />
                Previous
              </span>
                  }
                  nextLabel={
                    <span>
                Next
                <Icon name='arrowRight' />
              </span>
                  }
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  pageCount={pageCount}
                  forcePage={selectedPage}
                  onPageChange={handlePageClick}
              />
            </PaginationWrapper>
        )}
      </ContentWrapper>
  );
};
