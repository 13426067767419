import React, {useRef} from 'react';
import {Link, useParams} from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Typography } from '../../atoms/Typography/index';
import {getExternalReferral, getExternalReferralPDF} from "../../../api/playerReferrals";
// import { Icon } from "../../atoms/Icon";
// import { Button } from "../../atoms/Button";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

Modal.setAppElement('#root');

export const PrintExternalReferral = () => {
  let { teamId, playerId, externalReferralId } = useParams();

  // Receiving entity "externalReferral" for a particular Team by invoking "getExternalReferral" hook
  const { data: externalReferralData, isLoading } = useQuery(
    ['externalReferral'],
    () => getExternalReferral(externalReferralId)
  );

 /* console.log(externalReferralData?.id);
  const pdfRef = useRef(null);
  const handleDownload = () => {
    const content = pdfRef.current;

    const doc = new jsPDF();
    doc.html(content, {
      callback: function (doc) {
        doc.save(`referral-${externalReferralData?.team?.name}-${externalReferralData?.id}.pdf`);
      },
      html2canvas: { scale: 0.3, position: "center", width: 595 }
    });
  };*/

  return (
      <>
        <div style={{width: 800, padding: 50}}>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_name}<br/>{externalReferralData?.external_address}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_telephone}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.external_email}</>
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
            <>{externalReferralData?.date}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            Team
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.team.name}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            Player
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.player?.user.first_name} {externalReferralData?.player?.user.last_name}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>D.O.B.: {externalReferralData?.player?.birthday}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.player?.address_1}, {externalReferralData?.player?.address_2}, {externalReferralData?.player?.city}. {externalReferralData?.player?.postcode}</>
          </Typography>
          <Typography as={'h2'} tag={'h2'}>
            <>{externalReferralData?.name}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            <>{externalReferralData?.description}</>
          </Typography>
          <Typography as={'p'} tag={'p'}>
            Your Sincerely,
          </Typography>
          <Typography as={'p'} tag={'p'} style={{textIndent: '50px'}}>
            <>{externalReferralData?.added_by_user?.first_name} {externalReferralData?.added_by_user?.last_name}</>
          </Typography>
        </div>
      </>
  );
};
