import styled, { css } from 'styled-components';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';
import { devices } from '../../../theme/devices';

export const Component = styled.div`
  max-width: 392px;
  height: auto;

  ${(props) =>
    props.search &&
    css`
      input {
        padding-left: 41px !important;
        max-width: 320px;

        @media ${devices.desktop} {
          min-width: 320px;
        }
      }
      svg {
        right: auto;
        left: 15px;
      }
    `}

  ${(props) =>
    props.small &&
    css`
      max-width: 186px;
    `}

  label {
    display: block;
    font: 500 14px/16px ${fonts.monserrat};
    color: ${themeMain.colors.gray[700]};
    margin-bottom: 3px;
  }

  input,
  textarea {
    ${(props) => props.theme.input};
    color: ${themeMain.colors.gray[700]};

    &:focus,
    &::focus-visible {
      border-color: ${themeMain.colors.blue} !important;
    }

    &::placeholder {
      color: ${themeMain.colors.gray[500]};
      font-weight: 400;
    }

    &:disabled {
      background: #e9edf2;
      border: 1px solid #e2e4e8;
      cursor: not-allowed;
    }

    &.error {
      border-color: ${themeMain.colors.red};
    }
  }

  input::placeholder,
  ::-webkit-input-placeholder {
    color: white;
  }

  textarea {
    resize: none;
    height: auto !important;
    line-height: 1.5rem;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;
