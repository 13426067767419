import { editDoctor } from '../../../../api/doctors';
import { useMutation, useQueryClient } from 'react-query';

export const useEditDoctor = () => {
  const queryClient = useQueryClient();

  return useMutation(editDoctor, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('doctors');
    },
  });
};
