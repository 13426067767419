import { useMutation } from 'react-query';
import { logout } from '../../../../api/auth';
import { deleteCookie } from '../../../../utils/cookie';
import {
  AUTHENTICATION_COOKIE,
  REFRESH_COOKIE,
  USER_COOKIE,
} from '../../../../utils/constants';

export const useLogout = () => {
  return useMutation(logout, {
    onSuccess: (response) => {
      response.cookies.forEach((cookie) => {
        document.cookie = cookie;
      });
      return response;
    },
    onError: (err, history) => {
      deleteCookie(REFRESH_COOKIE);
      deleteCookie(USER_COOKIE);
      deleteCookie(AUTHENTICATION_COOKIE);
      history.push('/login');
    },
  });
};
