import { useMutation, useQueryClient } from 'react-query';
import { editExternal } from '../../../../api/external-one';

export const useEditExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(editExternal, {
    onSuccess: () => {
      // const cachedTodos = queryClient.getQueryData('teams');
      queryClient.invalidateQueries('external');
      // queryClient.setQueryData('teams', [
      //   ...cachedTodos,
      //   {
      //     id: response.id,
      //     name: variables.name,
      //     statusId: variables.status,
      //     sportId: variables.sport,
      //   },
      // ]);
    },
  });
};
