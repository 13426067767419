import React from 'react';
import { ComponentWrapper, Component } from './styles';

export const TabItem = ({ children, ...rest }) => {
  return (
    <ComponentWrapper {...rest}>
      <Component>{children}</Component>
    </ComponentWrapper>
  );
};
