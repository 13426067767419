import { useMutation, useQueryClient } from 'react-query';
import { deletePlayerInformation } from 'api/playerInformation';

export const useDeletePlayerInformation = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlayerInformation, {
    onSuccess: () => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};
