import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';

import Modal from 'react-modal';
import { useAddSport } from '../Sports/hooks/useAddSport';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const AddSport = () => {
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { mutateAsync, isLoading: isUpdating } = useAddSport();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    try {
      await mutateAsync({
        name: data.name,
        description: data.description,
      });
      push('/configuration/sports');
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add Sport
        </Typography>
        <Button as={Link} variant={'secondary'} to={'/configuration/sports'}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              id='name'
              name='name'
              type='text'
              {...register('name', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Name'
              error={errors.name}
              className={`${errors['name'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='name' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='description'
              name='description'
              type='text'
              variant={'textarea'}
              {...register('description', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Description/Note'
              error={errors.name}
              className={`${errors['description'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='description' as={InputError} />
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit Team
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this sport?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isUpdating}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
