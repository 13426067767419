import axiosWrapper from './axiosInterceptor';

export const getAllSports = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/sports',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getSport = async (sportId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/sports/${sportId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addSport = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/sports',
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editSport = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/sports/${data.id}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deleteSport = async ({ id }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/sports/${id}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
