import React, {useRef} from 'react';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import {
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';

// player, team and problem comes from the URL, we just need to select the external...
import {getExternalReferral, getExternalReferralPDF} from "../../../api/playerReferrals";
import { Icon } from "../../atoms/Icon";
import jsPDF from "jspdf";

Modal.setAppElement('#root');

export const ViewExternalReferral = () => {
  let { teamId, playerId, externalReferralId } = useParams();

  console.log('externalReferralId: ', externalReferralId);

  // Receiving entity "externalReferral" for a particular Team by invoking "getExternalReferral" hook
  const { data: externalReferralData, isLoading } = useQuery(
    ['externalReferral'],
    () => getExternalReferral(externalReferralId)
  );

  // jspdf library makes the PDF
  const pdfRef = useRef(null);
  const handleDownload = () => {
    const content = pdfRef.current;

    const doc = new jsPDF();
    doc.html(content, {
      callback: function (doc) {
        doc.save(`referral-${externalReferralData?.team?.name}-${externalReferralData?.id}.pdf`);
      },
      html2canvas: { scale: 0.4, position: "center" },
      width: 595, // <- here
      windowWidth: 595 // <- here
    });
  };

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          View External Referral
        </Typography>
        <Button
            as={Link}
            variant={'secondary'}
            to={`/print/teams/${teamId}/players/${playerId}/referrals/${externalReferralId}`}
        >
          Printable Version
        </Button>
        <Button
            variant={'secondary'}
            onClick={handleDownload}
        >
          <Icon name='alt_download' />
          Download PDF
        </Button>
        <Button
          as={Link}
          variant={'secondary'}
          onClick={() => history.back()}
        >
          Cancel
        </Button>
      </TableHeader>
      <div style={{width: 495, padding: 50}} ref={pdfRef}>
        <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
          <>{externalReferralData?.external_name}<br />{externalReferralData?.external_address}</>
        </Typography>
        <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
          <>{externalReferralData?.external_telephone}</>
        </Typography>
        <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
          <>{externalReferralData?.external_email}</>
        </Typography>
        <Typography as={'p'} tag={'p'} style={{textAlign: 'right'}}>
          <>{externalReferralData?.date}</>
        </Typography>
        <Typography as={'h2'} tag={'h2'}>
          Team
        </Typography>
        <Typography as={'p'} tag={'p'}>
          <>{externalReferralData?.team.name}</>
        </Typography>
        <Typography as={'h2'} tag={'h2'}>
          Player
        </Typography>
        <Typography as={'p'} tag={'p'}>
          <>{externalReferralData?.player?.user.first_name} {externalReferralData?.player?.user.last_name}</>
        </Typography>
        <Typography as={'p'} tag={'p'}>
          <>D.O.B.: {externalReferralData?.player?.birthday}</>
        </Typography>
        <Typography as={'p'} tag={'p'}>
          <>{externalReferralData?.player?.address_1}, {externalReferralData?.player?.address_2}, {externalReferralData?.player?.city}. {externalReferralData?.player?.postcode}</>
        </Typography>
        <Typography as={'h2'} tag={'h2'}>
          <>{externalReferralData?.name}</>
        </Typography>
        <Typography as={'p'} tag={'p'}>
          <>{externalReferralData?.description}</>
        </Typography>
        <Typography as={'p'} tag={'p'}>
          Your Sincerely,
        </Typography>
        <Typography as={'p'} tag={'p'} style={{textIndent: '50px'}}>
          <>{externalReferralData?.added_by_user?.first_name} {externalReferralData?.added_by_user?.last_name}</>
        </Typography>
      </div>
    </ContentWrapper>
  );
};
