import React, { useState, useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import {
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Icon } from '../../atoms/Icon';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { getPlayerProblemInformationFiles } from 'api/playerInformation';
import { Typography } from "../../atoms/Typography";



export const ViewPlayerProblemFiles = ({ params }) => {
  let { url } = useRouteMatch();
  let { playerId, teamId, problemId } = useParams();
  const problemUrl = url.substring(0, url.lastIndexOf("\/"));
  const problemsUrl = problemUrl.substring(0, problemUrl.lastIndexOf("\/"));
  const playersUrl = problemsUrl.substring(0, problemsUrl.lastIndexOf("\/"));

  const {
    data: playerInformationFilesData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['playerProblemInformation'], () =>
    getPlayerProblemInformationFiles(teamId, playerId, problemId)
  );



  const columns = React.useMemo(
    () => [
      {
        Header: 'File',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Problem',
        accessor: 'col2',
      },
      {
        Header: 'App Info',
        accessor: 'col3',
      },
    ],
    []
  );


  let parentUrl;

  // console.log(playerInformationFilesData);

  const data = React.useMemo(() => {
    return playerInformationFilesData.map((e) => {
      parentUrl = `${playersUrl}/information/${e?.parentId}`;


      return {
        col1: e?.filePath && (
          <a href={`${e?.filePath}`} target='_blank'>
            {e?.originalName}
          </a>
        ),
        col2: e?.problemId && (
          e?.problemName
        ),
        col3:  e?.parentType && e?.parentId && (
          <a title={'View'} href={parentUrl}>
            View {e?.parentType} {e?.parentId}
          </a>
        ),
      };


    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  // Get the problem name to put into the title, is probably a better way to do this.
  const problemTitle = playerInformationFilesData.map(elem => ({ name: elem.problemName }))
  let problemName;
  React.useMemo(() => {
    return problemTitle.map((e) => {
      if (problemName === undefined) {
        problemName = e.name;
      }
    });
  });


  return (
    <>
      <ContentWrapper>
        <Typography as={'h1'} tag={'h1'}>Problem: {problemName} FILES</Typography>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving problem data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </>
  );
};
