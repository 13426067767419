import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { useHistory } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { UserContext } from '../../../context';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getCookie } from '../../../utils/cookie';
import Modal from 'react-modal';
import { useEditUser } from './hooks/useEditAccount';
import { USER_COOKIE } from '../../../utils/constants';
import jwtDecode from 'jwt-decode';
import { Avatar } from 'components/atoms/Avatar';
import { getUserAvatar } from './hooks/getUserAvatar';
import { useQuery } from 'react-query';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

const fpPromise = FingerprintJS.load();

export const Account = () => {
  const [user, setContext] = useContext(UserContext);
  const [visitorId, setVisitorId] = useState('');
  const { push } = useHistory();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "players"
  const { data: userAvatar = {}, isLoading } = useQuery(
    'userAvatar',
    getUserAvatar,
    {
      retry: false,
    }
  );

  const { mutateAsync, isLoading: isUpdating } = useEditUser();

  const [submitMessage, setSubmitMessage] = useState();

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (data.name) {
      formData.append('firstName', data.name);
    }
    if (data.lastName) {
      formData.append('lastName', data.lastName);
    }
    if (data.password && data.passwordConfirm) {
      formData.append('password', data.password);
      formData.append('passwordConfirm', data.passwordConfirm);
    }

    if (data.email) {
      formData.append('email', data.email);
    }

    if (data.avatar) {
      for (const key of data.avatar) {
        formData.append('avatar', key);
      }
    }

    formData.append('fingerprint', visitorId);

    try {
      const response = await mutateAsync({
        formData,
      });

      console.log('response (account update):', response);

      const isAuthenticated = getCookie(USER_COOKIE);


      // TODO This is redirecting on account update, check type of user external -> referrals
      if (response.cookies.length) {
        setContext(jwtDecode(isAuthenticated));

        console.log('account page, auth token decoded: ', jwtDecode(isAuthenticated));
        console.log('account page, auth token decoded, role: ', jwtDecode(isAuthenticated).role);
        if (jwtDecode(isAuthenticated)?.role == 'external') {
          console.log('account page, value of (referrals): ', jwtDecode(isAuthenticated).role);
          return push('/referrals');
        } else if (jwtDecode(isAuthenticated)?.role != 'external') {
          console.log('account page, value of (teams): ', jwtDecode(isAuthenticated).role);
          return push('/teams');
        }
      }
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const watchPassword = watch('password');

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();

      // This is the visitor identifier:
      setVisitorId(result.visitorId);
    })();
  }, []);

  // Setting default values to react-hook-form component, depending on receiving data for this entity
  useEffect(() => {
    if (user) {
      [
        { name: 'name', value: user.firstName },
        { name: 'lastName', value: user.lastName },
        { name: 'email', value: user.email },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [user]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Manage your AB3 account
        </Typography>
        <Button variant={'secondary'} onClick={() => history.goBack()}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem style={{ flexDirection: 'row' }}>
            {!isLoading && userAvatar?.base64 && (
              <Avatar>
                <img
                  alt={'User avatar'}
                  src={`data:${userAvatar?.encoding};base64, ${userAvatar?.base64}`}
                />
              </Avatar>
            )}
            <Input
              style={{ width: '280px' }}
              id='avatar'
              name='avatar'
              type='file'
              accept='image/png, image/jpeg'
              {...register('avatar', {})}
              placeholder='Enter Here'
              label='Profile'
              error={errors.name}
              className={`${errors['avatar'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='avatar' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='name'
              name='name'
              type='text'
              {...register('name', {})}
              placeholder='Enter Here'
              label='Your First Name'
              error={errors.name}
              className={`${errors['name'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='name' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='lastName'
              name='lastName'
              type='text'
              {...register('lastName', {})}
              placeholder='Enter Here'
              label='Your Last Name'
              error={errors.name}
              className={`${errors['lastName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='lastName' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='email'
              name='email'
              type='email'
              {...register('email', {})}
              placeholder='Enter Here'
              label='Your Email Address'
              error={errors.name}
              className={`${errors['email'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='email' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='password'
              name='password'
              type='password'
              {...register('password', {})}
              placeholder='Enter Here'
              label='New Password'
              error={errors.name}
              className={`${errors['password'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='password' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='passwordConfirm'
              name='passwordConfirm'
              type='password'
              {...register('passwordConfirm', {
                validate: (value) =>
                  value === watchPassword || 'The passwords do not match',
              })}
              placeholder='Enter Here'
              label='Confirm New Password'
              error={errors.name}
              className={`${errors['passwordConfirm'] ? 'error' : ''}`}
            />
            <ErrorMessage
              errors={errors}
              name='passwordConfirm'
              as={InputError}
            />
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit Changes
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to edit your account information?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isUpdating}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
