import { addDoctor } from '../../../../api/doctors';
import { useMutation, useQueryClient } from 'react-query';

export const useAddDoctor = () => {
  const queryClient = useQueryClient();

  return useMutation(addDoctor, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('doctors');
    },
  });
};
