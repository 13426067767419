import React from 'react';
import PropTypes from 'prop-types';
import Component from './styles';

export const Button = ({ children, ...rest }) => {
  return <Component {...rest}>{children}</Component>;
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  variant: 'primary',
};
