import React from 'react'
import { Typography } from "../../../atoms/Typography";
import { OrderedList } from "../../../atoms/OrderedList";
import { StyledRoot, StyledLogo } from '../styles'

export const MobileInvitation = () => {
  return (
    <StyledRoot>
      <StyledLogo name='mainLogo' />
      <Typography as={'p'} tag={'h2'}>Player invitation (mobile app only)</Typography>
      <OrderedList
        title={'Follow these next steps in order to use the mobile application'}
        list={[
          'Download the mobile app.',
          'Open the link on your email app and select the mobile app as the startup app (when prompted)',
          'Go through the steps prompted on the mobile app.',
        ]}
      />
    </StyledRoot>
  )
}
