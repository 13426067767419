import { addProblem } from '../../../../api/playerProblems';
import { useMutation, useQueryClient } from 'react-query';

export const useAddProblem = () => {
  const queryClient = useQueryClient();

  return useMutation(addProblem, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerProblems');
    },
  });
};
