import React, { useContext } from 'react';
import { useQuery } from "react-query";

import { getUserAvatar } from "../../pages/Account/hooks/getUserAvatar";
import { UserContext } from "../../../context";
import { DefaultTextAvatar, LogoAvatar } from './styles';

export const UserAvatar = () => {
  const [user] = useContext(UserContext);
  const { data: userAvatar = {} } = useQuery(
    'userAvatar',
    getUserAvatar,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      retry: false
    }
  );

  const defaultMassages = [
    user?.firstName[0],
    user?.lastName[0],
  ]

  if (userAvatar?.base64) {
    return (
      <LogoAvatar
        alt={'user avatar'}
        src={`data:${userAvatar?.encoding};base64, ${userAvatar?.base64}`}
      />
    )
  }

  return (
    <DefaultTextAvatar style={{ fontSize: '12px', margin: 0 }}>
      {defaultMassages.join('')}
    </DefaultTextAvatar>
  )
};
