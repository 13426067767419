import React from "react";
import RightScreen from "./RightScreen";
import LeftScreen from "./LeftScreen";
import "./style.css";

const Registration = () => {
  return (
    <main className="wrapper">
      <div className="flex flex-wrap w-100 h-full flex-row">
        <LeftScreen />
        <RightScreen />
      </div>
    </main>
  );
};

export default Registration;
