import axiosWrapper from './axiosInterceptor';

export const sendNotification = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/notifications/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
