import axiosWrapper from './axiosInterceptor';

export const getExternal = async (externalId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external/${externalId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/external',
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/external/${data.id}`,
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

/*export const deleteTeam = async ({ id }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/teams/${id}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};*/
