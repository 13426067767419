import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from '../../utils/cookie';
import { USER_COOKIE } from '../../utils/constants';

const PrivateRoute = ({ layout: Layout, children, path }) => (
  <Route
    path={path}
    render={(props) => {
      const isAuthenticated = getCookie(USER_COOKIE);
      return isAuthenticated ? (
        <Layout props={props}>{children}</Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
