import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  FormItem,
  InputError,
  Fieldset,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import {
  getResultInformationById, 
  getResultInformationPdfUrl,
  getResultInformationPhoto,
} from 'api/playerInformation';

Modal.setAppElement('#root');

export const ResultsModule = ({ params }) => {
  const methods = useForm();

  const { teamId, playerId, informationId } = params;

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = methods;

  const {
    data: resultInformation,
    isLoading: isLoading,
  } = useQuery(['resultInformation', informationId], () =>
    getResultInformationById(teamId, playerId, informationId)
  );

  const [prevPhotos, setPrevPhotos] = useState([]);

  const fetchPhoto = async ({ teamId, playerId, informationId, id }) => {
    const data = await getResultInformationPhoto(
      teamId,
      playerId,
      informationId,
      id
    );

    const link = document.createElement('a');
    link.href = "data:" + data.encoding + ";base64," + data.base64;
    document.body.appendChild(link);
    link.download = data.original_name;
    link.click();
    document.body.removeChild(link);
  };

  const fetchPdfUrl = async ({ teamId, playerId, informationId, id }) => {
    const downloadUrl = await getResultInformationPdfUrl(
      teamId,
      playerId,
      informationId,
      id
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  const formPhotos = [];

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const detailsDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const detailsDateTimeFormat = new Intl.DateTimeFormat("en-GB", detailsDateOptions);

  useEffect(() => {
    if (!isLoading && resultInformation) {
      const d = new Date(resultInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);

      let detailsDate, detailsDateString = '';
      if (resultInformation.information_results?.date) {
        detailsDate = new Date(resultInformation.information_results.date);
        detailsDateString = detailsDateTimeFormat.format(detailsDate);
      }

      [
        {
          name: 'resultsCategory',
          value: {
            value: {
              id: resultInformation.information_results.category_type.id,
              name: resultInformation.information_results.category_type.name,
            },
            label: resultInformation.information_results.category_type.name,
          },
        },
        {
          name: 'date',
          value: detailsDateString,
        },
        {
          name: 'outcome',
          value: resultInformation.information_results.outcome,
        },
        {
          name: 'tests',
          value: resultInformation.information_results.tests,
        },
        {
          name: 'indication',
          value: resultInformation.information_results.indication,
        },
        {
          name: 'dateAdded',
          value: dateAddedString,
        },
      ].forEach(({ name, value }) => setValue(name, value));
      const images = resultInformation.information_results.photos.map(
        (photo) => {
          return {
            ...photo,
            image: null,
          };
        }
      );
      setPrevPhotos(images);
    }
  }, [resultInformation]);

  return (
    <FormProvider {...methods}>
      <form>
        <Fieldset>
          <FormItem>
            <Controller
              name='resultsCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='resultsCategory'>Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId='resultsCategory'
                    ref={ref}
                    isClearable
                    isDisabled
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['resultsCategory'] ? 'error' : ''
                    }`}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='resultsCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id='dateAdded'
              name='dateAdded'
              type='text'
              {...register('dateAdded', {})}
              label='Date Added'
              disabled
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='text'
              {...register('date', {})}
              disabled
              label='Date'
            />
          </FormItem>
          <FormItem>
            <Input
              id='indication'
              name='indication'
              type='text'
              variant='textarea'
              disabled
              {...register('indication', {})}
              error={errors.name}
              placeholder='Enter Here'
              label='Indication'
              className={`${errors['indication'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='indication' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='outcome'
              name='outcome'
              type='text'
              variant='textarea'
              disabled
              {...register('outcome', { required: 'This is required' })}
              placeholder='Enter Here'
              label='Outcome'
              error={errors.name}
              className={`${errors['outcome'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='outcome' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='tests'
              name='tests'
              type='text'
              variant='textarea'
              disabled
              {...register('tests', {})}
              placeholder='Enter Here'
              label='Tests'
              error={errors.name}
              className={`${errors['tests'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='tests' as={InputError} />
          </FormItem>
        </Fieldset>
        <Fieldset>
          <FormItem>
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return <div key={i + index}>{i.name}</div>;
              })}
            </PhotosWrapper>
            {prevPhotos.length > 0 && (
              <>
                <label>Current files</label>
                <PhotosWrapper>
                  {prevPhotos.map((photo, index) => {
                    return (
                      <div key={index + photo}>
                        {photo.encoding.startsWith('application') && (

                          <Button
                            type='button'
                            aria-label='Fetch url'
                            icon
                            onClick={() =>
                              fetchPdfUrl({
                                teamId,
                                playerId,
                                informationId,
                                id: photo.id,
                              })
                            }
                          >
                            Download {photo.original_name}
                          </Button>

                        )}
                        {photo.encoding.startsWith('image') && (
                          photo?.image ? (
                            <img
                              alt={photo.original_name}
                              src={`data:${photo?.encoding};base64, ${photo?.image}`}
                            />
                          ) : (
                            <Button
                              type='button'
                              aria-label='Fetch photo'
                              icon
                              onClick={() =>
                                fetchPhoto({
                                  teamId,
                                  playerId,
                                  informationId,
                                  id: photo.id
                                })
                              }
                            >
                              Download {photo.original_name}
                            </Button>
                          )
                        )}
                      </div>
                    );
                  })}
                </PhotosWrapper>
              </>
            )}
          </FormItem>
        </Fieldset>
      </form>
    </FormProvider>
  );
};
