import React, {useState, useEffect, useContext} from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';


import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { getPlayerReferrals, getExternalReferralPDF } from "../../../api/playerReferrals";

export const PlayerReferrals = ({ params }) => {
  let { url } = useRouteMatch();

  const {
    data: playerReferralsData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['referrals', params.playerId], () =>
      getPlayerReferrals(params.teamId, params.playerId)
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Problem',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Title',
        accessor: 'col2', // accessor is the "key" in the data
      },
      {
        Header: 'External',
        accessor: 'col3',
      },
      {
        Header: 'Date',
        accessor: 'col4',
      },
      {
        Header: '',
        accessor: 'col5',
        disableSortBy: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return playerReferralsData.map((e) => {

      return {
        col1: (
            <InterButton title={`View Problem`} as={Link} to={`./problems/${e.problem.id}`}>
              {e?.problem.name}
            </InterButton>
        ),
        col2: e?.name,
        col3: e?.external.name,
        col4: e?.date,
        col5: (
            <>
                <InterButton title={`View`} as={Link} to={`${url}/${e.id}`}>
                  <Icon name='view'/>
                </InterButton>
            </>
        )
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving teams data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </>
  );
};
