import styled, { css } from 'styled-components';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';
import { devices } from '../../../theme/devices';

export const InputListWrapper = styled.div`
  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &:first-child {
      margin-top: 8px;
    }

    button {
      svg {
        margin: 0;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 16px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;

  > div:first-child {
    flex-grow: 1;
    margin-right: 16px;
  }
`;
