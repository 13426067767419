import { devices } from './devices';
import { fonts } from './fonts';

const colors = {
  red: '#CC2726',
  green: '#03AB66',
  blue: '#0093D3',
  gray: {
    100: '#0F0F0F',
    200: '#4F4F4F',
    300: '#828282',
    400: '#BDBDBD',
    500: '#6B7280',
    600: '#F2F2F2',
  },
  white: '#fff',
  black: '#000',
  brandRed: ' #FF264B',
  brandBlue: '#1E51DC',
  lightBlue: '#E8EDFB',
  boldText: '#02094E',
  darkBlue: '#02094E',
  border: '#02094e'
};

const fontWeight = {
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
};

const typographyPrimary = {
  display: 'block',
  textDecoration: 'none',
  margin: '0 0 10px',
};

const btnBase = {
  appearance: 'none',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontFamily: fonts.monserrat,
  fontWeight: 500,
  fontSize: '14px',
  minHeight: '38px',
  padding: '9px 17px',
  cursor: 'pointer',
  border: 0,
  borderRadius: '6px',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  transitionProperty: 'background, font-size, color',
  width: 'auto',
  '&:disabled, &[disabled]': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0,
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const statusBase = {
  appearance: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: fonts.monserrat,
  fontWeight: 500,
  fontSize: '14px',
  minHeight: '25px',
  padding: '2px 12px',
  cursor: 'pointer',
  border: 0,
  borderRadius: '12px',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  transitionProperty: 'background, font-size, color',
  width: 'auto',
  '&:disabled, &[disabled]': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0,
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const inputBase = {
  fontFamily: fonts.monserrat,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '1',
  padding: '8px 13px 7px 13px',
  background: colors.white,
  border: '1px solid #d1d5db',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  borderRadius: '6px',
  minHeight: '38px',
  boxSizing: 'border-box',
  width: '100%',
  '&:focus, &:active, &:hover': {
    borderColor: colors.blue,
    outline: 'none',
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const themeMain = {
  colors,

  input: {
    ...inputBase,
  },

  buttons: {
    primary: {
      ...btnBase,
      backgroundColor: colors.brandBlue,
      color: colors.white,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
      fontWeight: 'bold',
      boxShadow: '0px 3px 6px #00000029',
    },
    secondary: {
      ...btnBase,
      color: colors.brandBlue,
      fontWeight: 'bold',
      backgroundColor: colors.white,
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #1e51dc'
    },
  },

  status: {
    active: {
      ...statusBase,
      backgroundColor: colors.green,
      color: colors.white,
    },
    inactive: {
      ...statusBase,
      backgroundColor: colors.red,
      color: colors.white,
    },
  },

  interButton: {
    appearance: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    minWidth: '75px',
    color: colors.brandBlue,
    fontFamily: fonts.monserrat,
    fontWeight: 600,
    fontSize: '12px',
    minHeight: '25px',
    padding: '2px 12px',
    cursor: 'pointer',
    border: 0,
    borderRadius: '12px',
    boxSizing: 'border-box',
    width: 'auto',
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    '&:focus, &:active, &:hover': {
      outline: 'none',
      textDecoration: 'none',
      border: 0,
    },
    [`@media ${devices.laptop}`]: {},
    [`@media ${devices.tablet}`]: {},

    path: {
      fill: colors.gray[400],
    },
  },


  interButtonDelete: {
    appearance: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    minWidth: '75px',
    color: colors.brandRed,
    fontFamily: fonts.monserrat,
    fontWeight: 600,
    fontSize: '12px',
    minHeight: '25px',
    padding: '2px 12px',
    cursor: 'pointer',
    border: 0,
    borderRadius: '12px',
    boxSizing: 'border-box',
    width: 'auto',
    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    '&:focus, &:active, &:hover': {
      outline: 'none',
      textDecoration: 'none',
      border: 0,
    },
    [`@media ${devices.laptop}`]: {},
    [`@media ${devices.tablet}`]: {},

    path: {
      fill: colors.gray[400],
    },
  },


  typography: {
    tag: {
      h1: {
        ...typographyPrimary,
        fontSize: `30px`,
        lineHeight: '36px',
        fontWeight: fontWeight[700],
        fontFamily: fonts.monserrat,
        color: colors.boldText,
        [`@media ${devices.tablet}`]: {},
      },
      h2: {
        ...typographyPrimary,
        fontSize: `24px`,
        lineHeight: '28px',
        fontWeight: fontWeight[700],
        fontFamily: fonts.monserrat,
        [`@media ${devices.tablet}`]: {},
      },
      h3: {
        ...typographyPrimary,
        fontSize: `14px`,
        lineHeight: '20px',
        fontWeight: fontWeight[500],
        fontFamily: fonts.monserrat,
        color: colors.gray[300],
        [`@media ${devices.tablet}`]: {},
      },
      h4: {
        ...typographyPrimary,
        fontWeight: 500,
        fontFamily: fonts.monserrat,
        [`@media ${devices.tablet}`]: {},
      },
      subtitle1: {
        ...typographyPrimary,
        fontSize: 15,
        color: colors.gray["300"],
        fontFamily: fonts.monserrat,
      },
      error: {
        ...typographyPrimary,
        fontFamily: fonts.monserrat,
        margin: '8px 0 0',
        fontWeight: 400,
        fontSize: 14,
        color: colors.red,
      }
    },
  },
};

export default themeMain;
