import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from './../../organisms/Layout/index';
import { Typography } from './../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { formatFormDate } from '../../../utils/formatFormDate';
import { useQuery } from 'react-query';
import { useAddTeam } from '../Teams/hooks/useAddTeam';
import { getAllSports } from '../../../api/sports';
import { getAllStatuses } from '../../../api/statuses';
import { loadOptions } from '../../../utils/loadSelectOptions';

import Modal from 'react-modal';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const AddTeam = () => {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "sports" by invoking "getAllSports" hook
  const { data: sportsData = [] } = useQuery('sports', getAllSports);

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as an 3rd argument
  const sportsOptions = [];
  for (let i = 0; i < sportsData.length; ++i) {
    sportsOptions.push({
      value: sportsData[i],
      label: sportsData[i].name,
    });
  }

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  const { mutateAsync, isAddLoading } = useAddTeam();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        //Check whether entity exists and should be updated
        // ...(playerFirstName && { name: newData.playerFirstName }),
        name: newData.teamName,
        statusId: parseInt(newData.status?.id) || parseInt(newData.status),
        sportId: parseInt(newData.sport?.id) || parseInt(newData.sport),
      });
      push('/teams');
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add Team
        </Typography>
        <Button as={Link} variant={'secondary'} to={'/teams'}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              id='teamName'
              name='teamName'
              type='text'
              {...register('teamName', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Team Name'
              error={errors.name}
              className={`${errors['teamName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='teamName' as={InputError} />
          </FormItem>
          <FormItem>
            <Controller
              name='sport'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='sport'>Sport</label>
                  <AsyncPaginate
                    {...field}
                    inputId='sport'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${errors['sport'] ? 'error' : ''}`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, sportsOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage errors={errors} name='sport' as={InputError} />
          </FormItem>
          <FormItem>
            <Controller
              name='status'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='status'>Status</label>
                  <AsyncPaginate
                    {...field}
                    inputId='status'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['status'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, statusesOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage errors={errors} name='status' as={InputError} />
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit Team
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this team?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isAddLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
