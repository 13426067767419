import React, { useState, useEffect, useContext, useRef } from 'react';
import { getSearchResult } from 'api/search';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick';
import { Nav } from './styles';
import { SearchItem } from './searchItem';
import { NoResults } from './noResults';
import { SearchContext } from 'context';

export const Search = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [typedString, setTypedString] = useState('');
  const [searchResult, setSearchResult] = useState({
    teams: [],
    players: [],
    doctors: [],
  });
  const [typedLimit, setTypedLimit] = useState('');
  const [search, setSearchContext] = useContext(SearchContext);

  const onSetSearchResult = async (item) => {
    if (item.length >= 2) {
      const result = await getSearchResult(item);
      setIsActive(true);
      setSearchResult(result);
      setSearchContext(result);
      setTypedLimit('');
    } else {
      setSearchResult({
        teams: [],
        players: [],
        doctors: [],
      });
      setTypedLimit('Please enter at least 2 characters');
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => onSetSearchResult(typedString), 500);
    return () => clearTimeout(handler);
  }, [typedString]);

  return (
    <div style={{ position: 'relative' }}>
      <Input
        search='true'
        type='text'
        tag='search'
        placeholder='search (enter at least 2 characters)'
        onChange={(e) => setTypedString(e.target.value)}
        style={{
          borderStyle: 'hidden',
          background: '#02094E ',
          borderRadius: 10,
          color: 'white',
          fontSize: 17,
          padding: 4
        }}
      >
        <Icon color="white" name='search' />
      </Input>
      <Nav
        ref={dropdownRef}
        className={`menu ${isActive ? 'active' : 'inactive'}`}
      >
        {searchResult.teams.length ? (
          <>
            <h3>Teams:</h3>
            <ul>
              {searchResult.teams.map((team) => {
                return (
                  <li key={team.id + team.name}>
                    <SearchItem info={team} link={`/teams/${team.id}`} />
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}
        {searchResult.players.length ? (
          <>
            <h3>Players:</h3>
            {searchResult.players.length && (
              <ul>
                {searchResult.players.map((player, index) => {
                  return (
                    <li key={index + player.user.first_name + player.id}>
                      <SearchItem
                        info={player}
                        link={
                          player.team
                            ? `/teams/${player.team.id}/players/${player.id}`
                            : ''
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        ) : null}
        {searchResult.doctors.length ? (
          <>
            <h3>Doctors:</h3>
            {searchResult.doctors.length && (
              <ul>
                {searchResult.doctors.map((doctor, index) => {
                  return (
                    <li key={index + doctor.user.first_name + doctor.id}>
                      <SearchItem
                        info={doctor}
                        link={
                          doctor.user.teams[0]
                            ? `/teams/${doctor.user.teams[0].id}/team-medics/${doctor.id}/edit`
                            : ''
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        ) : null}
        <NoResults info={searchResult} isTypedMin={typedLimit} />
      </Nav>
    </div>
  );
};
