import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  PhotosWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { useQuery } from 'react-query';
import { useAddExternalReferral } from './hooks/useAddExternalReferral';
import { loadOptions } from '../../../utils/loadSelectOptions';
import Modal from 'react-modal';
import { trimFormData } from "../../../utils/trimFormData";

// player, team and problem comes from the URL, we just need to select the external...
import { getExternals } from "../../../api/external";
import {formatFormDate} from "../../../utils/formatFormDate";

Modal.setAppElement('#root');

export const AddExternalReferral = () => {
  let { teamId, playerId, problemId } = useParams();

  const maxDate = new Date().toISOString().split('T')[0];

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      givenDate: maxDate,
    }
  });


  const { mutateAsync, isLoading: isLoadingButton } = useAddExternalReferral();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const [externalIdVar, setExternalId] = useState([]);
  const [externalNameVar, setExternalName] = useState([]);
  const [externalAddressVar, setExternalAddress] = useState([]);
  const [externalPhoneVar, setExternalPhone] = useState([]);
  const [externalEmailVar, setExternalEmail] = useState([]);

  const setExternalValues = (e) => {
    // setExternalCompany(e.value);
    setExternalId(e.value);
    setExternalName(e.label);
    setExternalAddress(externalData[e.index].address_1 + ', ' + externalData[e.index].address_2 + ', ' + externalData[e.index].address_3 + '. ' + externalData[e.index].address_postcode);
    setExternalPhone(externalData[e.index].telephone_number);
    setExternalEmail(externalData[e.index].contact_email);
  }

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)

    // console.log(data)

    try {
      await mutateAsync({
        teamId: parseInt(teamId),
        playerId: parseInt(playerId),
        statusId: 1,
        problemId: parseInt(problemId),
        externalId: parseInt(externalIdVar),
        date: data.givenDate,
        name: data.referralName,
        description: data.referralDescription,
        external_name: externalNameVar,
        external_address: externalAddressVar,
        external_telephone: externalPhoneVar,
        external_email: externalEmailVar,
      });
      push(`/teams/${teamId}/players/${playerId}/referrals`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };



  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const { data: externalData = [] } = useQuery(
    ['external'],
    () => getExternals()
  );

  const externalOptions = [];
  for (let i = 0; i < externalData.length; ++i) {
    externalOptions.push({
      value: externalData[i].id,
      label: externalData[i].name,
      index: i,
    });
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    const result = await trigger();

    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);



  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Create External Referral
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/players/${playerId}/notes`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem small>
            <Input
              id='givenDate'
              name='givenDate'
              type='date'
              max={maxDate}
              {...register('givenDate', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='Date'
              error={errors.name}
              disabled={true}
              className={`${errors['givenDate'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='givenDate' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='referralName'
              name='referralName'
              type='text'
              {...register('referralName')}
              placeholder='Enter text...'
              label='Referral Title'
              error={errors.name}
              className={`${errors['referralName'] ? 'error' : ''}`}
            />
            <ErrorMessage
              errors={errors}
              name='referralName'
              as={InputError}
            />
          </FormItem>
          <FormItem>
            <Input
              id='referralDescription'
              name='referralDescription'
              type='text'
              variant='textarea'
              {...register('referralDescription')}
              placeholder='Enter text...'
              label='Referral Description'
              error={errors.name}
              className={`${errors['referralDescription'] ? 'error' : ''}`}
              rows={5}
            />
            <ErrorMessage
              errors={errors}
              name='referralDescription'
              as={InputError}
            />
          </FormItem>
          <FormItem>
            <Controller
                name='external'
                control={control}
                render={({ field }, ref) => (
                    <>
                      <label htmlFor='problem'>External Company</label>
                      <AsyncPaginate
                          {...field}
                          inputId='external'
                          ref={ref}
                          isClearable
                          isSearchable
                          placeholder={'Select External Company'}
                          onChange={setExternalValues}
                          className={`react-select ${
                              errors['external'] ? 'error' : ''
                          }`}
                          loadOptions={(search, prevOptions) =>
                              loadOptions(search, prevOptions, externalOptions)
                          }
                      />
                    </>
                )}
            />
            <ErrorMessage errors={errors} name='external' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='externalName'
              name='externalName'
              value={externalNameVar}
              type='text'
              {...register('externalName')}
              placeholder='Enter text...'
              label='External Company Name'
              error={errors.name}
              className={`${errors['externalName'] ? 'error' : ''}`}
              disabled={true}
            />
            <ErrorMessage
              errors={errors}
              name='externalName'
              as={InputError}
            />
          </FormItem>
          <FormItem>
            <Input
                id='externalAddress'
                name='externalAddress'
                value={externalAddressVar}
                type='text'
                variant='textarea'
                {...register('externalAddress')}
                placeholder='Enter text...'
                label='External Company Address'
                error={errors.name}
                className={`${errors['externalAddress'] ? 'error' : ''}`}
                rows={5}
                disabled={true}
            />
            <ErrorMessage
                errors={errors}
                name='externalAddress'
                as={InputError}
            />
          </FormItem>
          <FormItem>
            <Input
                id='externalPhone'
                name='externalPhone'
                value={externalPhoneVar}
                type='text'
                {...register('externalPhone')}
                placeholder='Enter text...'
                label='External Company Phone Number'
                error={errors.name}
                className={`${errors['externalPhone'] ? 'error' : ''}`}
                disabled={true}
            />
            <ErrorMessage
                errors={errors}
                name='externalPhone'
                as={InputError}
            />
          </FormItem>
          <FormItem>
            <Input
                id='externalEmail'
                name='externalEmail'
                value={externalEmailVar}
                type='text'
                {...register('externalEmail')}
                placeholder='Enter text...'
                label='External Company Email Address'
                error={errors.name}
                className={`${errors['externalEmail'] ? 'error' : ''}`}
                disabled={true}
            />
            <ErrorMessage
                errors={errors}
                name='externalEmail'
                as={InputError}
            />
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Submit External Referral
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this External Referral?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoadingButton}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
