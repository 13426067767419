import { useMutation, useQueryClient } from 'react-query';
import { addExternal } from '../../../../api/external-one';

export const useAddExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addExternal, {
    onSuccess: (response, variables) => {
      // const cachedTodos = queryClient.getQueryData('teams');
      queryClient.invalidateQueries('external');
      // queryClient.setQueryData('teams', [
      //   ...cachedTodos,
      //   {
      //     id: response.id,
      //     name: variables.name,
      //     statusId: variables.status,
      //     sportId: variables.sport,
      //   },
      // ]);
    },
  });
};
