export const EnumInformationCategories = {
  Details: {
    id: 1,
    name: 'details',
    order: 1,
  },
  Consultation: {
    id: 2,
    name: 'consultation',
    order: 2,
  },
  Medications: {
    id: 3,
    name: 'medications',
    order: 3,
  },
  Results: {
    id: 4,
    name: 'results',
    order: 4,
  },
  Letters: {
    id: 5,
    name: 'letters',
    order: 5,
  },
  Treatments: {
    id: 6,
    name: 'treatments',
    order: 6,
  },
}
