import React, {useContext} from 'react';
import {Switch, Route, Link, useRouteMatch, useParams} from 'react-router-dom';

import { ExternalTable } from './external';
import { AddExternal } from "../AddExternal";
import { EditExternal } from "../EditExternal";
import { ExternalUsers } from "../ExternalUsers";
import { EditExternalUser } from "../EditExternalUser";
import { AddExternalUser } from "../AddExternalUser";
import {ContentWrapper, SubheaderWrapper, TableButtonGroup, TableHeader, TableSubheader} from "../../organisms/Layout";
import { UserContext } from "../../../context";
import { ExternalReferrals } from "../ExternalReferrals";

export const External = () => {
  let { url } = useRouteMatch();
  let { externalId } = useParams();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  return (
    <>
      <ContentWrapper>
          <Switch>
            <Route exact path={'/external'}>
              <ExternalTable />
            </Route>
            <Route path={'/external/add-external'}>
              <AddExternal />
            </Route>
            <Route path={'/external/:externalId/edit'}>
              <EditExternal />
            </Route>
            <Route path={'/external/:externalId/add-external-user'}>
              <AddExternalUser />
            </Route>
            <Route path={'/external/:externalId/external-users/:externalUserId/edit'}>
              <EditExternalUser />
            </Route>
            <Route path={'/external/:externalId'}>
              <ExternalUsers />
            </Route>
            <Route path={'/external/:externalId/referrals'}>
              <ExternalReferrals />
            </Route>
          </Switch>
      </ContentWrapper>
    </>
  );
};
