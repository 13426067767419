import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout';
import { Typography } from '../../atoms/Typography';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { getPlayerInformation } from 'api/playerInformation';
import { getPlayerProblems } from 'api/playerProblems';
import { ProblemModule } from './modules/Problem';
import { MedicationsModule } from './modules/Medications';
import { ConsultModule } from './modules/Consult';
import { getDiagnosisCodeNameFromProblem } from "../../../utils/getDiagnosisCodeNameFromProblem";
import { TreatmentsModule } from './modules/Treatments';
import { LettersModule } from './modules/Letters';
import { ResultsModule } from './modules/Results';
import { EnumInformationCategories } from "../../../constants/informationCategories";
import {useApproveInfo} from "../EditInformation/hooks";
import {Icon} from "../../atoms/Icon";

Modal.setAppElement('#root');

export const ViewPlayerInformation = () => {
  let { teamId, playerId, informationId } = useParams();

  const {
    control,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "playerInformation" for a particular player by invoking "playerInformation" hook
  const { data: playerInformationData, isLoading, isError } = useQuery(
    ['playerInformation', playerId],
    () => getPlayerInformation(teamId, playerId)
  );

  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const { data: playerProblemsData = [] } = useQuery(
    ['playerProblems', playerId],
    () => getPlayerProblems(teamId, playerId)
  );

  const problemOptions = [];
  for (let i = 0; i < playerProblemsData.length; ++i) {
    problemOptions.push({
      value: playerProblemsData[i],
      label: playerProblemsData[i].name,
    });
  }

  const [problemId, setProblemId] = useState();

  const watchProblem = watch('problem');

  const watchStatus = watch('status');

  const _renderModule = (id) => {
    switch (id) {
      case EnumInformationCategories.Details.id:
        return <ProblemModule params={moduleParams} />

      case EnumInformationCategories.Consultation.id:
        return <ConsultModule params={moduleParams} />

      case EnumInformationCategories.Medications.id:
        return <MedicationsModule params={moduleParams} />

      case EnumInformationCategories.Treatments.id:
        return <TreatmentsModule params={moduleParams} />

      case EnumInformationCategories.Letters.id:
        return <LettersModule params={moduleParams} />

      case EnumInformationCategories.Results.id:
        return <ResultsModule params={moduleParams} />

      default:
        return null
    }
  }

  useEffect(() => {
    setProblemId(watchProblem?.value?.id);
  }, [watchProblem]);

  const [infoCategory, setInfoCategory] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState();
  const [statusId, setStatusId] = useState();

  const { mutateAsync, isLoading: isEditLoading } = useApproveInfo();

  const { push } = useHistory();

  const watchInfoCategory = watch('informationCategory');

  useEffect(() => {
    setInfoCategory(watchInfoCategory?.value?.id);
  }, [watchInfoCategory]);

  useEffect(() => {
    setStatusId(watchStatus?.value?.id);
  }, [watchStatus]);

  const moduleParams = {
    playerId,
    teamId,
    infoCategory,
    problemId,
    informationId,
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage();
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  const onSubmit = async () => {
    try {
      await mutateAsync({
        teamId: teamId,
        playerId: playerId,
        informationId: informationId,
      });
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    if (!playerProblemsData?.length || !playerInformationData?.length) return null

    const filterPlayerInfoById = playerInformationData?.find((e) => {
      return e.id === +informationId;
    });

    if (!isLoading && filterPlayerInfoById?.category_type?.id) {
      [
        {
          name: 'informationCategory',
          value: {
            value: {
              id: filterPlayerInfoById.category_type.id,
              name: filterPlayerInfoById.category_type.name,
            },
            label: filterPlayerInfoById.category_type.name,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }

    if (!isLoading && filterPlayerInfoById?.problem?.id) {
      [
        {
          name: 'problem',
          value: {
            value: {
              id: filterPlayerInfoById.problem.id,
              name: filterPlayerInfoById.problem.name,
            },
            label: `${filterPlayerInfoById.problem.name} ${
              getDiagnosisCodeNameFromProblem(playerProblemsData.find(item => item.id === (playerInformationData || [])[0]?.problem?.id))
            }`,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }

    if (!isLoading && filterPlayerInfoById?.status?.id) {
      [
        {
          name: 'status',
          value: {
            value: {
              id: filterPlayerInfoById.status.id,
              name: filterPlayerInfoById.status.name,
            },
            label: filterPlayerInfoById.status.name,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }

  }, [playerInformationData, playerProblemsData]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          View App Info
        </Typography>
        { statusId !== 1 && (
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Send to Player App
          </Button>
        )}
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/players/${playerId}/information`}
        >
          Back
        </Button>
      </TableHeader>
      <FormWrapper>
        {isError && (
          <p>An error occurred during receiving player information data</p>
        )}
        {isLoading ? (
          'Receiving player name...'
        ) : (
          <div>
            <FormItem>
              <Controller
                name='informationCategory'
                control={control}
                rules={{  }}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='informationCategory'>
                      Information Category
                    </label>
                    <AsyncPaginate
                      {...field}
                      inputId='informationCategory'
                      ref={ref}
                      isClearable
                      isDisabled
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['informationCategory'] ? 'error' : ''
                      }`}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name='informationCategory'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Controller
                name='problem'
                control={control}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='problem'>Problem</label>
                    <AsyncPaginate
                      {...field}
                      inputId='problem'
                      ref={ref}
                      isClearable
                      isDisabled
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['problem'] ? 'error' : ''
                      }`}
                      //onChange={({ value }) => setProblem({ value })}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, problemOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name='problem' as={InputError} />
            </FormItem>
            {/* Conditional form render depending on "Information Category" select */}
            {_renderModule(infoCategory)}
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel='My dialog'
                className='mymodal'
                overlayClassName='myoverlay'
                closeTimeoutMS={500}
            >
              {submitMessage?.length > 0 ? (
                  <>
                    <div className='modal-body'>
                      <Typography as={'h2'} tag={'h2'}>
                        Something went wrong
                      </Typography>
                      <Typography as={'p'} tag={'h4'}>
                        {submitMessage}
                      </Typography>
                    </div>
                    <div className='modal-buttons'>
                      <Button onClick={toggleModalClear} variant={'secondary'}>
                        Try again
                      </Button>
                    </div>
                  </>
              ) : (
                  <>
                    <div className='modal-body'>
                      <Typography as={'h2'} tag={'h2'}>
                        Are you sure?
                      </Typography>
                      <Typography as={'p'} tag={'h4'}>
                        Do you really want to send this information to the player app?
                      </Typography>
                    </div>
                    <div className='modal-buttons'>
                      <Button onClick={toggleModal} variant={'secondary'}>
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit(onSubmit)} disabled={isEditLoading}>
                        Confirm
                      </Button>
                    </div>
                  </>
              )}
            </Modal>
          </div>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};
