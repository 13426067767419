import { editPlayer } from '../../../../api/players';
import { useMutation, useQueryClient } from 'react-query';

export const useEditPlayer = () => {
  const queryClient = useQueryClient();

  return useMutation(editPlayer, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('players');
      queryClient.invalidateQueries('teamPlayers');
    },
  });
};
