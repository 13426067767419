import { Icon } from 'components/atoms/Icon';
import React, { useRef } from 'react';
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick';
import { Nav, NotificationIconWrapper } from './styles';

export const NotificationsDropdown = ({
  children,
  isNotif,
  setNew,
  onGetNotifications,
}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const onClick = () => {
    if (isNotif) {
      setNew(false);
    }
    onGetNotifications();
    setIsActive(!isActive);
  };

  return (
    <NotificationIconWrapper isNew={isNotif}>
      <Icon
        name='notification'
        onClick={onClick}
        className={`${isActive ? 'active' : 'inactive'}`}
      />
      <Nav
        ref={dropdownRef}
        className={`menu ${isActive ? 'active' : 'inactive'}`}
      >
        {children}
      </Nav>
    </NotificationIconWrapper>
  );
};
