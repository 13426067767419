import React from 'react';
import { Icon } from '../../atoms/Icon/index';
import { Nav, Container, Logo } from '../Layout';
import Breadcrumbs from '../Breadcrumbs';
import { Burger } from './Burger';

export const NavBar = () => {
  return (
    <>
      <Nav>
        <Container>
          <Logo>
            <Icon name='logo' />
          </Logo>
          <Burger />
        </Container>
      </Nav>
      <Breadcrumbs />
    </>
  );
};
