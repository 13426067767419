import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from './../../organisms/Layout/index';
import { Typography } from './../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { formatFormDate } from '../../../utils/formatFormDate';
import { useQuery } from 'react-query';
import { useAddExternal } from '../External/hooks/useAddExternal';
import { getAllStatuses } from '../../../api/statuses';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const AddExternal = () => {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  const { mutateAsync, isAddLoading } = useAddExternal();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        //Check whether entity exists and should be updated
        // ...(playerFirstName && { name: newData.playerFirstName }),
        name: newData.externalName,
        address_1: newData.externalAddress1,
        address_2: newData.externalAddress2,
        address_3: newData.externalAddress3,
        address_postcode: newData.externalPostcode,
        telephone_number: newData.externalPhone,
        contact_name: newData.externalContactName,
        contact_email: newData.externalEmail,
        statusId: parseInt(newData.status?.id) || parseInt(newData.status),
      });
      push('/external');
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add External Company
        </Typography>
        <Button as={Link} variant={'secondary'} to={'/external'}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              id='externalName'
              name='externalName'
              type='text'
              {...register('externalName', {
                required: 'This is required',
              })}
              placeholder='Enter Here'
              label='External Company Name'
              error={errors.name}
              className={`${errors['externalName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalName' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalAddress1'
                name='externalAddress1'
                type='text'
                {...register('externalAddress1', {
                  required: 'This is required',
                })}
                placeholder='Address 1'
                label='Address 1'
                error={errors.name}
                className={`${errors['externalAddress1'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalAddress1' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalAddress2'
                name='externalAddress2'
                type='text'
                {...register('externalAddress2', {
                  required: 'This is required',
                })}
                placeholder='Address 2'
                label='Address 2'
                error={errors.name}
                className={`${errors['externalAddress2'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalAddress2' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalAddress3'
                name='externalAddress3'
                type='text'
                {...register('externalAddress3', {
                  required: 'This is required',
                })}
                placeholder='Address 3'
                label='Address 3'
                error={errors.name}
                className={`${errors['externalAddress3'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalAddress3' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalPostcode'
                name='externalPostcode'
                type='text'
                {...register('externalPostcode', {
                  required: 'This is required',
                })}
                placeholder='Postcode'
                label='Postcode'
                error={errors.name}
                className={`${errors['externalPostcode'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalPostcode' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalPhone'
                name='externalPhone'
                type='text'
                {...register('externalPhone', {
                  required: 'This is required',
                })}
                placeholder='Phone number'
                label='Phone number'
                error={errors.name}
                className={`${errors['externalPhone'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalPhone' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalContactName'
                name='externalContactName'
                type='text'
                {...register('externalContactName', {
                  required: 'This is required',
                })}
                placeholder='Contact Name'
                label='Contact Name'
                error={errors.name}
                className={`${errors['externalContactName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalContactName' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
                id='externalEmail'
                name='externalEmail'
                type='text'
                {...register('externalEmail', {
                  required: 'This is required',
                })}
                placeholder='Email'
                label='Email'
                error={errors.name}
                className={`${errors['externalEmail'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='externalEmail' as={InputError} />
          </FormItem>
          <FormItem>
            <Controller
              name='status'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='status'>Status</label>
                  <AsyncPaginate
                    {...field}
                    inputId='status'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['status'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, statusesOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage errors={errors} name='status' as={InputError} />
          </FormItem>
          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Add External Company
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to add this team?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isAddLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
