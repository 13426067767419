import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AddTeam } from './../AddTeam/index';
import { AddPlayer } from './../AddPlayer/index';
import { EditTeam } from '../EditTeam/index';
import { AddDoctor } from './../AddDoctor/index';
import { AddForm } from './../AddForm/index';
import { EditForm } from '../EditForm/index';
import { AddGroupTool } from './../AddGroupTool/index';
import { EditGrouptool } from '../EditGrouptool/index';
import { Team } from '../Team';
import { TeamsTable } from './teams';
import { EditPlayer } from '../EditPlayer';
import { AddProblem } from '../AddProblem';
import { AddInformation } from '../AddInformation';
import { EditPlayerInformation } from '../EditInformation';
import { ViewPlayerInformation } from '../ViewInformation';
import { EditProblem } from '../EditProblem';
import { ViewPlayerProblemInformation } from '../ViewPlayerProblemInformation';
import { ViewPlayerProblemResults } from '../ViewPlayerProblemResults';
import { ViewPlayerProblemDetails } from '../ViewPlayerProblemDetails';
import { ViewPlayerProblemConsultations } from '../ViewPlayerProblemConsultations';
import { ViewPlayerProblemLetters } from '../ViewPlayerProblemLetters';
import { ViewPlayerProblemMedication } from '../ViewPlayerProblemMedication';
import { ViewPlayerProblemTreatments } from '../ViewPlayerProblemTreatments';
import { ViewPlayerProblemFiles } from '../ViewPlayerProblemFiles';
import { AddNote } from "../AddNote";
import { ViewNote } from '../ViewNote';
import { EditNote } from '../EditNote';
import { AddExternalReferral } from "../AddExternalReferral";
import { ViewExternalReferral } from "../ViewExternalReferral";

export const Teams = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/teams'}>
          <TeamsTable />
        </Route>
        <Route path={'/teams/:teamId/add-player'}>
          <AddPlayer />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/edit'}>
          <EditPlayer />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/add-information'}>
          <AddInformation />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/add-note'}>
          <AddNote />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/add-external-referral'}>
          <AddExternalReferral />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/referrals/:externalReferralId'}>
          <ViewExternalReferral />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/add-problem'}>
          <AddProblem />
        </Route>
        <Route
          path={'/teams/:teamId/players/:playerId/problems/:problemId/edit'}
        >
          <EditProblem />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/results'}>
          <ViewPlayerProblemResults />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/details'}>
          <ViewPlayerProblemDetails />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/consultations'}>
          <ViewPlayerProblemConsultations />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/letters'}>
          <ViewPlayerProblemLetters />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/medication'}>
          <ViewPlayerProblemMedication />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/treatments'}>
          <ViewPlayerProblemTreatments />
        </Route>
        <Route path={'/teams/:teamId/players/:playerId/problems/:problemId/files'}>
          <ViewPlayerProblemFiles />
        </Route>
        <Route
          path={'/teams/:teamId/players/:playerId/problems/:problemId'}
        >
          <ViewPlayerProblemInformation />
        </Route>
        <Route
          path={
            '/teams/:teamId/players/:playerId/information/:informationId/edit'
          }
        >
          <EditPlayerInformation />
        </Route>
        <Route
          path={'/teams/:teamId/players/:playerId/information/:informationId'}
        >
          <ViewPlayerInformation />
        </Route>
        <Route
          path={'/teams/:teamId/players/:playerId/notes/:noteId/edit'}
        >
          <EditNote />
        </Route>
        <Route
          path={'/teams/:teamId/players/:playerId/notes/:noteId'}
        >
          <ViewNote />
        </Route>
        <Route path={'/teams/:teamId/add-medic'}>
          <AddDoctor />
        </Route>
        <Route path={'/teams/:teamId/add-form'}>
            <AddForm />
        </Route>
        <Route path={'/teams/:teamId/add-tool'}>
          <AddGroupTool />
        </Route>
        <Route path={'/teams/:teamId/forms/:formId/edit'}>
          <EditForm />
        </Route>
        <Route path={'/teams/:teamId/tools/:toolId/edit'}>
          <EditGrouptool />
        </Route>
        <Route path={'/teams/:teamId/add-notification'}>
          <div>Add Notification</div>
        </Route>
        <Route path={'/teams/add-team'}>
          <AddTeam />
        </Route>
        <Route path={'/teams/:teamId/edit'}>
          <EditTeam />
        </Route>
        <Route path={'/teams/:teamId'}>
          <Team />
        </Route>
      </Switch>
    </>
  );
};
