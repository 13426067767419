import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { Typography } from '../../../../atoms/Typography';
import { useHistory } from 'react-router-dom';
import {
  FormItem,
  InputError,
  CheckboxGroup,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { Checkbox } from 'components/atoms/Checkbox';
import {
  getConsultInfo,
  getConsultInformationById,
} from 'api/playerInformation';
import { useEditInfoConsult } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import { REQUIRED_FIELD } from "../../../../../constants/massages";

Modal.setAppElement('#root');

export const ConsultModule = ({ params }) => {
  const { push } = useHistory();

  const { teamId, playerId, informationId } = params;

  const maxDate = new Date().toISOString().split('T')[0];

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      investigations: [],
      plans: [],
    },
  });

  const {
    data: consultInformation = [],
    isLoading: consultInfoIsLoading,
    isError: consultInfoIsError,
  } = useQuery(['consultInformation', informationId], () =>
    getConsultInformationById(teamId, playerId, informationId)
  );

  // Receiving entity "playerConsultInfo"
  const { data: playerConsultInfo = [], isLoading } = useQuery(
    'playerConsultInfo',
    getConsultInfo
  );

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isEditLoading } = useEditInfoConsult();

  const [isOpen, setIsOpen] = useState(false);

  const watchAudio = watch('audios');

  const [formAudios, setFormAudios] = useState([]);

  const [prevAudios, setPrevAudios] = useState([]);

  const [deletedAudios, setDeletedAudios] = useState([]);

  useEffect(() => {
    if (getValues('photos')) {
      setFormAudios(getValues('photos'));
    }
  }, [watchAudio]);

  const onDelete = (index) => {
    setFormAudios(
      Array.from(formAudios).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onPrevAudiosPhotosDelete = (deletedId) => {
    setPrevAudios(
      prevAudios.filter((elem) => {
        return elem.id !== deletedId;
      })
    );
    setDeletedAudios([...deletedAudios, deletedId]);
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (params.problemId) {
      formData.append('problemId', params.problemId);
    }

    if (data.history) {
      formData.append('history', data.history);
    }

    if (data.exam) {
      formData.append('exam', data.exam);
    }

    if (data.date) {
      formData.append('date', data.date);
    }

    if (data.diagnosis) {
      formData.append('diagnosisName', data.diagnosis);
    }

    if (data.clinicianName) {
      formData.append('clinicianName', data.clinicianName);
    }

    if (data.location) {
      formData.append('location', data.location);
    }

    if (data.registrationNumber || data.registrationNumber === '') {
      formData.append('registrationNumber', data.registrationNumber);
    }

    if (data.details) {
      formData.append('details', data.details);
    }

    if (data.investigations.length >= 1) {
      formData.append(
        'investigationsIdsList',
        JSON.stringify(data.investigations.map(Number))
      );
    }

    if (data.plans.length >= 1) {
      formData.append('planIdsList', JSON.stringify(data.plans.map(Number)));
    }

    if (data.audios) {
      for (const key of data.audios) {
        formData.append('files', key);
      }
    }

    formData.append('deletedFiles', JSON.stringify(deletedAudios));

    try {
      await mutateAsync({
        teamId: params.teamId,
        playerId: params.playerId,
        informationId: params.informationId,
        formData,
        audio: !!watchAudio?.length,
      });
      push(`/teams/${params.teamId}/players/${params.playerId}/information`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage();
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    if (watchAudio?.length >= 1) {
      clearErrors();
    }
  }, [watchAudio]);

  const [investArray, setInvestArray] = useState([]);
  const [planArray, setPlanArray] = useState([]);

  useEffect(() => {
    if (!isLoading && !consultInfoIsLoading) {
      [
        { name: 'history', value: consultInformation.consult.history },
        { name: 'exam', value: consultInformation.consult.exam },
        { name: 'date', value: consultInformation.consult.date },
        { name: 'diagnosis', value: consultInformation.consult.diagnosis.name },
        { name: 'clinicianName', value: consultInformation.consult.clinicianName },
        { name: 'location', value: consultInformation.consult.location },
        { name: 'registrationNumber', value: consultInformation.consult.registrationNumber },
        { name: 'details', value: consultInformation.consult.details },
      ].forEach(({ name, value }) => setValue(name, value));
      setInvestArray(
        consultInformation.consult.investigations.map((e) => e.name)
      );
      setPlanArray(consultInformation.consult.plan.plans.map((e) => e.name));
      setPrevAudios(consultInformation.consult.audio);
    }
  }, [consultInformation]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {consultInfoIsError && (
        <p>An error occured during receiving consult data</p>
      )}
      {consultInfoIsLoading ? (
        'Receiving investigation data...'
      ) : (
        <>
          <FormItem>
            <Input
              id='clinicianName'
              name='clinicianName'
              type='text'
              variant='input'
              {...register('clinicianName', { required: { value: true, message: REQUIRED_FIELD }})}
              placeholder='Enter Here'
              label='Clinician Name'
              error={errors.name}
              className={`${errors['clinicianName'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='clinicianName' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='location'
              name='location'
              type='text'
              variant='input'
              {...register('location', { required: { value: true, message: REQUIRED_FIELD }})}
              placeholder='Enter Here'
              label='Location'
              className={`${errors['location'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='location' as={InputError} />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='date'
              max={maxDate}
              {...register('date')}
              placeholder='Enter Here'
              label='Date'
              error={errors.name}
              className={`${errors['date'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='date' as={InputError}/>
          </FormItem>
          <FormItem>
            <Input
              id='registrationNumber'
              name='registrationNumber'
              type='text'
              variant='input'
              {...register('registrationNumber')}
              placeholder='Enter Here'
              label='Registration Number'
              className={`${errors['registrationNumber'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='registrationNumber' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='history'
              name='history'
              type='text'
              variant='textarea'
              {...register('history', {
                validate: (value) =>
                  watchAudio?.length >= 1 ||
                  value?.length >= 1 ||
                  'This is required',
              })}
              placeholder='Enter Here'
              label='History'
              error={errors.name}
              className={`${errors['history'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='history' as={InputError} />
          </FormItem>
          {!isLoading && (
            <FormItem>
              <label>Investigations</label>
              <CheckboxGroup>
                {playerConsultInfo.investigations.map((item) => {
                  if (investArray.includes(item.name)) {
                    return (
                      <Checkbox
                        key={item.name}
                        name='investigations'
                        type='checkbox'
                        value={item.id}
                        label={item.name}
                        defaultChecked={true}
                        {...register('investigations')}
                        className={`${errors['investigations'] ? 'error' : ''}`}
                      />
                    );
                  }
                  if (!investArray.includes(item.name)) {
                    return (
                      <Checkbox
                        key={item.name + item.name}
                        name='investigations'
                        type='checkbox'
                        value={item.id}
                        label={item.name}
                        defaultChecked={false}
                        {...register('investigations')}
                        className={`${errors['investigations'] ? 'error' : ''}`}
                      />
                    );
                  }
                })}
              </CheckboxGroup>
            </FormItem>
          )}
          <FormItem>
            <Input
              id='exam'
              name='exam'
              type='text'
              variant='textarea'
              {...register('exam', {
                validate: (value) =>
                  watchAudio?.length >= 1 ||
                  value?.length >= 1 ||
                  'This is required',
              })}
              placeholder='Enter Here'
              label='Exam'
              error={errors.name}
              className={`${errors['exam'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='exam' as={InputError} />
          </FormItem>
          <FormItem>
            <Input
              id='diagnosis'
              name='diagnosis'
              type='text'
              variant='textarea'
              {...register('diagnosis', {
                validate: (value) =>
                  watchAudio?.length >= 1 ||
                  value?.length >= 1 ||
                  'This is required',
              })}
              placeholder='Enter Here'
              label='Diagnosis'
              error={errors.name}
              className={`${errors['diagnosis'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='diagnosis' as={InputError} />
          </FormItem>
          <FormItem>
            <label>Treatment</label>
            <CheckboxGroup>
              {playerConsultInfo?.plans?.map((item, index) => {
                if (planArray.includes(item.name)) {
                  return (
                    <Checkbox
                      key={index * 10 - index}
                      name='plans'
                      type='checkbox'
                      value={item.id}
                      label={item.name}
                      defaultChecked={true}
                      {...register('plans', {
                        validate: (value) =>
                          watchAudio?.length >= 1 ||
                          prevAudios.length >= 1 ||
                          value?.length >= 1 ||
                          'This is required',
                      })}
                      className={`${errors['plans'] ? 'error' : ''}`}
                    />
                  );
                }
                if (!planArray.includes(item.name)) {
                  return (
                    <Checkbox
                      key={index + item + index}
                      name='plans'
                      type='checkbox'
                      value={item.id}
                      label={item.name}
                      defaultChecked={false}
                      {...register('plans', {
                        validate: (value) =>
                          watchAudio?.length >= 1 ||
                          prevAudios.length >= 1 ||
                          value?.length >= 1 ||
                          'This is required',
                      })}
                      className={`${errors['plans'] ? 'error' : ''}`}
                    />
                  );
                }
              })}
              <ErrorMessage errors={errors} name='plans' as={InputError} />
            </CheckboxGroup>
          </FormItem>
          <FormItem>
            <Input
              id='details'
              name='details'
              type='text'
              variant='textarea'
              {...register('details')}
              placeholder='Enter Here'
              label='Treatment details'
              error={errors.name}
              className={`${errors['details'] ? 'error' : ''}`}
            />
          </FormItem>
          <FormItem>
            <Input
              id='audios'
              name='audios'
              type='file'
              multiple
              accept='audio/mpeg, audio/mp4, audio/ogg, audio/3gpp, audio/3gp2, audio/webm'
              {...register('audios', {})}
              placeholder='Enter Here'
              label='Add Audio'
              error={errors.name}
              className={`${errors['audios'] ? 'error' : ''}`}
            />
            <ErrorMessage errors={errors} name='audios' as={InputError} />
            <PhotosWrapper>
              {Array.from(formAudios).map((i, index) => {
                return (
                  <div key={i + index}>
                    {i.name}
                    <Button
                      type='button'
                      aria-label='Delete'
                      icon
                      onClick={() => onDelete(index)}
                    >
                      <Icon name='remove' />
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
            {prevAudios?.length > 0 && (
              <>
                <label>Current audios</label>
                <PhotosWrapper>
                  {prevAudios.map((i, index) => {
                    return (
                      <div key={index + i + index}>
                        {i.fileName}
                        <Button
                          type='button'
                          aria-label='Delete'
                          icon
                          onClick={() => onPrevAudiosPhotosDelete(i.id)}
                        >
                          <Icon name='remove' />
                        </Button>
                      </div>
                    );
                  })}
                </PhotosWrapper>
              </>
            )}
          </FormItem>
        </>
      )}
      <Button type='button' onClick={onPreSubmit}>
        <Icon name='check' />
        Submit Information
      </Button>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='My dialog'
        className='mymodal'
        overlayClassName='myoverlay'
        closeTimeoutMS={500}
      >
        {submitMessage?.length > 0 ? (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                Something went wrong
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                {submitMessage}
              </Typography>
            </div>
            <div className='modal-buttons'>
              <Button onClick={toggleModalClear} variant={'secondary'}>
                Try again
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                Are you sure?
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                Do you really want to edit this information?
              </Typography>
            </div>
            <div className='modal-buttons'>
              <Button onClick={toggleModal} variant={'secondary'}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isEditLoading}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal>
    </form>
  );
};
