import React, { useState, useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import Modal from "react-modal";
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { getPlayerProblemInformationConsultations } from 'api/playerInformation';
import { useDeletePlayerProblemInformation } from "../ViewPlayerProblemInformation/hooks/useDeletePlayerProblemInformation";
import { Typography } from "../../atoms/Typography";
import { Button } from "../../atoms/Button";
import {Status} from "../../atoms/Status";


export const ViewPlayerProblemConsultations = ({ params }) => {
  let { url } = useRouteMatch();
  let { playerId, teamId, problemId } = useParams();
  const problemUrl = url.substring(0, url.lastIndexOf("\/"));
  const problemsUrl = problemUrl.substring(0, problemUrl.lastIndexOf("\/"));
  const playersUrl = problemsUrl.substring(0, problemsUrl.lastIndexOf("\/"));

  const {
    data: playerInformationData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['playerProblemInformation'], () =>
    getPlayerProblemInformationConsultations(teamId, playerId, problemId)
  );

  const {
    mutateAsync,
    isLoading: isDeleteLoading,
  } = useDeletePlayerProblemInformation();

  const [isOpen, setIsOpen] = useState(false);

  const [currentInformation, setCurrentInformation] = useState();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentInformation(e.id);
    toggleModal();
  };

  const onDeleteProblem = () => {
    //console.log(teamId, playerId, currentInformation);
    mutateAsync({
      teamId: teamId,
      playerId: playerId,
      informationId: currentInformation,
    });
    toggleModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Date Added',
        accessor: 'col2', // accessor is the "key" in the data
      },
      {
        Header: 'Category',
        accessor: 'col3',
      },
      {
        Header: 'Added by',
        accessor: 'col4',
      },
      {
        Header: 'Problem',
        accessor: 'col5',
      },
      {
        Header: 'Status',
        accessor: 'col6',
      },
      {
        Header: '',
        accessor: 'col7',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col8',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col9',
        disableSortBy: true,
      },
    ],
    []
  );

  const playerInformationDataDesc = playerInformationData.sort(function(a,b){
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const infoDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const infoDateTimeFormat = new Intl.DateTimeFormat("en-GB", infoDateOptions);

  const data = React.useMemo(() => {
    return playerInformationDataDesc.map((e) => {
      let d = new Date(e.created_at);
      let dateTime = dateTimeFormat.format(d);
      let infoDate, infoDateString = '';
      if (e?.infoDate) {
        infoDate = new Date(e.infoDate);
        infoDateString = infoDateTimeFormat.format(infoDate);
      }



      return {
        col1: infoDateString,
        col2: dateTime,
        col3: e.category_type.name,
        col4: e.added_by,
        col5: e?.problem?.name,
        col6: (
            <Status variant={e.status.name}>
              {e.status.name && e.status.name.toUpperCase()}
            </Status>
        ),
        col7: (
            <InterButton title={'View'} as={Link} to={`${playersUrl}/information/${e.id}`}>
              <Icon name='view'/>
            </InterButton>
        ),
        col8: (
            <InterButton title={'Edit'} as={Link} to={`${playersUrl}/information/${e.id}/edit`}>
              <Icon name='edit'/>
            </InterButton>
        ),
        col9: (
            <InterButton
                color={'red'}
                title={'Delete'}
                type='button'
                onClick={() => onPreSubmit(e)}
                disabled={isDeleteLoading}
            >
              <Icon name='remove'/>
            </InterButton>
        ),
      };




    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  // Get the problem name to put into the title, is probably a better way to do this.
  const problemTitle = playerInformationData.map(elem => ({ name: elem.problem.name }))
  let problemName;
  React.useMemo(() => {
    return problemTitle.map((e) => {
      if (problemName === undefined) {
        problemName = e.name;
      }
    });
  });


  return (
    <>
      <ContentWrapper>
        <Typography as={'h1'} tag={'h1'}>Problem: {problemName} CONSULTATIONS</Typography>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving problem data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Are you sure?
            </Typography>
            <Typography as={'p'} tag={'h4'}>
              Do you really want to delete this information?
            </Typography>
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={onDeleteProblem}>Confirm</Button>
          </div>
        </Modal>
      </ContentWrapper>
    </>
  );
};
