import { useMutation, useQueryClient } from 'react-query';
import { addViewedPage } from 'api/timeline';

export const useAddViewedPage = () => {
  const queryClient = useQueryClient();

  return useMutation(addViewedPage, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('viewedPages');
    },
  });
};
