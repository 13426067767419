import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout';
import { Typography } from '../../atoms/Typography';
import { useQuery } from 'react-query';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { getInformationCategories } from 'api/playerInformation';
import { getPlayerProblems } from 'api/playerProblems';
import { ProblemModule } from './modules/Problem';
import { MedicationsModule } from './modules/Medications';
import { ConsultModule } from './modules/Consult';

import Modal from 'react-modal';
import { TreatmentsModule } from './modules/Treatments';
import { EnumInformationCategories } from "../../../constants/informationCategories";
import { LettersModule } from "./modules/Letters";
import { ResultsModule } from "./modules/Results";

Modal.setAppElement('#root');

export const AddInformation = () => {
  let { playerId, teamId } = useParams();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "informationCategories"
  const { data: informationCategories = [] } = useQuery(
    'informationCategories',
    getInformationCategories
  );

  // Creating the array of options for our select "sport" and passing in to a "loadOptions" property as a 3rd argument
  const categoryOptions = [];
  // To include "details", change back to "let i = 0; .."
  for (let i = 1; i < informationCategories.length; ++i) {
    categoryOptions.push({
      value: informationCategories[i],
      label: informationCategories[i].name,
    });
  }

  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const { data: playerProblemsData = [] } = useQuery(
    ['playerProblems', playerId],
    () => getPlayerProblems(teamId, playerId)
  );

  const problemOptions = [];
  for (let i = 0; i < playerProblemsData.length; ++i) {
    problemOptions.push({
      value: playerProblemsData[i],
      label: `${playerProblemsData[i].name}${playerProblemsData[i].category ? `[${playerProblemsData[i].category?.diagnosis_code.name}]` : '' }`,
    });
  }

  const [problemId, setProblemId] = useState();

  const watchProblem = watch('problem');

  const _renderModule = (id) => {
    switch (id) {
      case EnumInformationCategories.Details.id:
        return <ProblemModule params={moduleParams} />

      case EnumInformationCategories.Consultation.id:
        return <ConsultModule params={moduleParams} />

      case EnumInformationCategories.Medications.id:
        return <MedicationsModule params={moduleParams} />

      case EnumInformationCategories.Treatments.id:
        return <TreatmentsModule params={moduleParams} />

      case EnumInformationCategories.Letters.id:
        return <LettersModule params={moduleParams} />

      case EnumInformationCategories.Results.id:
        return <ResultsModule params={moduleParams} />

      default:
        return null
    }
  }

  useEffect(() => {
    setProblemId(watchProblem?.value?.id);
  }, [watchProblem]);

  const [infoCategory, setInfoCategory] = useState();

  const watchInfoCategory = watch('informationCategory');

  useEffect(() => {
    setInfoCategory(watchInfoCategory?.value?.id);
  }, [watchInfoCategory]);

  const moduleParams = {
    playerId,
    teamId,
    infoCategory,
    problemId,
  };

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add Information
        </Typography>
        <Button as={Link} variant={'secondary'} to={'/teams'}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <div>
          <FormItem>
            <Controller
              name='informationCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='informationCategory'>
                    Information Category
                  </label>
                  <AsyncPaginate
                    {...field}
                    inputId='informationCategory'
                    ref={ref}
                    isClearable
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['informationCategory'] ? 'error' : ''
                    }`}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, categoryOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='informationCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem>
            <Controller
              name='problem'
              control={control}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='problem'>Problem</label>
                  <AsyncPaginate
                    {...field}
                    inputId='problem'
                    ref={ref}
                    isClearable
                    placeholder={'Select Problem'}
                    className={`react-select ${
                      errors['problem'] ? 'error' : ''
                    }`}
                    //onChange={({ value }) => setProblem({ value })}
                    loadOptions={(search, prevOptions) =>
                      loadOptions(search, prevOptions, problemOptions)
                    }
                  />
                </>
              )}
            />
            <ErrorMessage errors={errors} name='problem' as={InputError} />
          </FormItem>
          {/* Conditional form render depending on "Information Category" select */}
          {_renderModule(infoCategory)}
        </div>
      </FormWrapper>
    </ContentWrapper>
  );
};
