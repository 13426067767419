import React, { useEffect } from 'react';
import { Container } from '../../../components/organisms/Layout';
import Header from './Header';
import { useAddViewedPage } from './hooks/useAddViewedPage';

const PrivateLayout = ({ children, props }) => {
  const routesStore = {
    '/teams': 'Teams',
    '/teams/add-team': 'Add team',
    '/teams/edit': 'Edit team',
    '/teams/notifications': 'Team notifications',
    '/teams/team-medics': 'Team medics',
    '/teams/add-medic': 'Add medic',
    '/teams/add-player': 'Add player',
    '/teams/team-medics/edit': 'Edit medic',
    '/players': 'Players',
    '/players/add-player': 'Add player',
    '/teams/players': 'Team players',
    '/teams/players/edit': 'Edit player',
    '/teams/players/information': 'Player information',
    '/teams/players/information/add-information': 'Add player information',
    '/teams/players/information/edit': 'Edit player information',
    '/teams/players/problems': 'Player problems',
    '/teams/players/problems/add-problem': 'Add player problem',
    '/teams/players/problems/edit': 'Edit player problem',
    //'/teams/players/notes': 'Player notes',
    //'/teams/players/notes/add-note': 'Add player note',
    //'/teams/players/notes/edit': 'Edit player note',
    // '/tools': 'Tools',
    // '/tools/add-tool': 'Add tool',
    // '/tools/edit': 'Edit tool',
    '/configuration': 'Configuration',
    '/configuration/sports': 'Sports',
    '/configuration/add-sport': 'Add sport',
    '/configuration/sports/edit': 'Edit sport',
    '/configuration/problem-categories': 'Problem categories',
    '/configuration/add-problem-category': 'Add problem category',
    '/configuration/problem-categories/edit': 'Edit problem category',
    '/search-results': 'Search results',
    '/account': 'Manage account',
    '/referrals': 'Referrals',
  };
  const array = props.location.pathname.split('/');
  const filtered = array.filter((i) => !Number.isInteger(Number.parseInt(i)));
  const joined = filtered.join('/');

  const { mutateAsync } = useAddViewedPage();

  const onSubmit = async (data) => {
    try {
      await mutateAsync({
        viewedPage: `'${data}'`,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (routesStore[joined]) {
      onSubmit(routesStore[joined]);
    }
  }, [props.location.pathname]);

  return (
    <>
      <Header />
      <main style={{ padding: '25px 0 60px' }}>
        <Container>{children}</Container>
      </main>
    </>
  );
};

export default PrivateLayout;
