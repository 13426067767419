import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { PasswordForm, FormItem, InputError } from '../Layout';
import { Input } from '../../atoms/Input';
import { Icon } from '../../atoms/Icon';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { useCreatePassword } from './hooks/useCreatePassword';
import { PlainLink } from 'components/atoms/PlainLink';
import { AuthButton } from 'components/atoms/AuthButton';
import styled from "styled-components";

export const Password = () => {
  const { token } = useParams();

  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useCreatePassword();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'blur' });

  const watchPassword = watch('password');

  const onSubmit = async (data) => {
    try {
      await mutateAsync({
        password: data.password,
        passwordConfirm: data.password_repeat,
        token,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <PasswordForm onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Icon name='mainLogo' />
        <Typography as={'h2'} tag={'h2'}>
          New password
        </Typography>
      </div>
      {isSuccess ? (
        <>
          <StyledDescriptionContainer>
            <Typography as={'h2'} tag={'p'} variant={'subtitle1'}>
              Your password has been created. Now you can log in. Click the button below.
            </Typography>
          </StyledDescriptionContainer>
          <StyledButton to={'/login'}>Login page</StyledButton>
        </>
      ) : (
        <>
          <PlainLink as='p'>
            Please enter your new password and confirm it
          </PlainLink>
          <FormItem style={{ margin: '24px auto 0' }}>
            <Input
              name='password'
              type='password'
              placeholder='Password'
              required
              className='first'
              {...register('password', {
                required: 'Password field is required',
              })}
            />
          </FormItem>
          <FormItem>
            <Input
              name='password_repeat'
              type='password'
              placeholder='Repeat your password'
              required
              className='second'
              {...register('password_repeat', {
                required: 'Password repeat field is required',
                validate: (value) =>
                  value === watchPassword || 'The passwords do not match',
              })}
            />
            <ErrorMessage errors={errors} name='password' as={InputError} />
            <ErrorMessage
              errors={errors}
              name='password_repeat'
              as={InputError}
            />
          </FormItem>
          <Button type='submit' disabled={isLoading}>
            Accept
          </Button>
        </>
      )}
      {isError && (
        <div>
          <InputError>Something went wrong</InputError>
          {Array.isArray(error?.data?.message) ? (
            <>
              {error?.data?.message.map((e, id) => {
                return (
                  <InputError key={id} style={{ textAlign: 'left' }}>
                    {e}
                  </InputError>
                );
              })}
            </>
          ) : (
            <InputError>{error?.data?.message}</InputError>
          )}
        </div>
      )}
    </PasswordForm>
  );
};


const StyledDescriptionContainer = styled.div`
  margin-bottom: 20px;
`

const StyledButton = styled(AuthButton)`
  width: 100%;
  justify-content: center;
`
