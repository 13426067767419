import React, { useEffect, useState } from 'react';
import { Icon } from '../../atoms/Icon';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Typography } from '../../atoms/Typography';
import {
  ContentWrapper,
  TableWrapper,
  TableHeader,
  PaginationWrapper,
} from '../../organisms/Layout';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { getTimeline } from 'api/timeline';

import Modal from 'react-modal';

Modal.setAppElement('#root');

export const Timeline = () => {
  // Receiving entity "players"
  const {
    data: timelineData = { notifications: [] },
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery('viewedPages', getTimeline, { retry: false });

  // Table Header Columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Action ID',
        accessor: 'col2',
      },
      {
        Header: 'User',
        accessor: 'col3',
      },
      {
        Header: 'Date & Time',
        accessor: 'col4',
      },
    ],
    []
  );

  // Table data recompiled from query request to react-table tbody data
  const data = React.useMemo(() => {
    return timelineData.notifications.map((e) => {
      const time = new Date(e.created_at);
      const createdAt = time.toLocaleString();
      return {
        col1: <span title={e.msg}>{e.msg}</span>,
        col2: e.id,
        col3: e.added_by,
        col4: createdAt,
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Timeline
        </Typography>
      </TableHeader>
      <TableWrapper>
        {isLoading ? (
          <p>Receiving timeline data...</p>
        ) : (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {isError && <Typography variant='error'>{error?.data?.message || error?.message}</Typography>}
      </TableWrapper>
      {pageCount > 1 && (
        <PaginationWrapper>
          {/* React Paginate component*/}
          <ReactPaginate
            previousLabel={
              <span>
                <Icon name='arrowLeft' />
                Previous
              </span>
            }
            nextLabel={
              <span>
                Next
                <Icon name='arrowRight' />
              </span>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={pageCount}
            forcePage={selectedPage}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
    </ContentWrapper>
  );
};
