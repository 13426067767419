import axiosWrapper from './axiosInterceptor';

export const getPlayerProblemInformation = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationResults = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/results`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationConsultations = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/consultations`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationDetails = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/details`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationLetters = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/letters`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationMedication = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/medication`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationTreatments = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/treatments`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerProblemInformationFiles = async (teamId, playerId, problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}/problems/${problemId}/files`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerInformation = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerInformationForReferral = async (externalReferralId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/referral/${externalReferralId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPlayerInformationForReferralInfo = async (externalReferralId, informationId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information/referral/${externalReferralId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deletePlayerInformation = async ({
  teamId,
  playerId,
  informationId,
}) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/information/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getInformationCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/category-types',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getTreatmentCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/treatment-category-types',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getProblemCategoryTypes = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/information-problem-category-types',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getConsultInfo = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/static/consult-info',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPublicConsultInfo = async (token) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/static/public-consult-info',
      responseType: 'json',
      withCredentials: true,
      headers: {
        'public-consult': token,
      },
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getMedicationCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/medication-category-types',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getResultsCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/information-results-categories-types',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addConsult = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: data.audio
        ? `/consult/v2/team/${data.teamId}/player/${data.playerId}`
        : `/consult/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addConsultExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/consult/referrals/${data.externalReferralId}/add`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editConsult = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: data.audio
        ? `/consult/v2/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`
        : `/consult/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};



export const approveInfo = async ({  teamId,
                                    playerId,
                                    informationId,}) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/information/team/${teamId}/player/${playerId}/information/${informationId}/approve`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addProblem = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/information-problem/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editProblem = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/information-problem/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getProblemInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-problem/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getResultInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-results/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addResult = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/information-results/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addResultExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/information-results/referrals/${data.externalReferralId}/add`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editResult = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/information-results/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addLetter = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/information-letters/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addLetterExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/information-letters/referrals/${data.externalReferralId}/add`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editLetter = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/information-letters/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addMedication = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/medications/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addMedicationExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/medications/referrals/${data.externalReferralId}/add`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editMedication = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/medications/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addTreatment = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/treatment/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const addTreatmentExternal = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/treatment/referrals/${data.externalReferralId}/add`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editTreatment = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/treatment/team/${data.teamId}/player/${data.playerId}/information/${data.informationId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getConsultInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/consult/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getMedicationsInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/medications/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getTreatmentsInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/treatment/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getLetterInformationById = async (
  teamId,
  playerId,
  informationId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-letters/team/${teamId}/player/${playerId}/information/${informationId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getProblemInformationPhoto = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-problem/team/${teamId}/player/${playerId}/information/${informationId}/photo/${photoId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getProblemInformationPdfUrl = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-problem/team/${teamId}/player/${playerId}/information/${informationId}/pdf/${photoId}`,
      responseType: 'text',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getConsultInformationAudios = async ({
  teamId,
  playerId,
  informationId,
  id,
}) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/consult/team/${teamId}/player/${playerId}/information/${informationId}/file/${id}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getResultInformationPhoto = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-results/team/${teamId}/player/${playerId}/information/${informationId}/photo/${photoId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getResultInformationPdfUrl = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-results/team/${teamId}/player/${playerId}/information/${informationId}/pdf/${photoId}`,
      responseType: 'text',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getLetterInformationPhoto = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-letters/team/${teamId}/player/${playerId}/information/${informationId}/file/${photoId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getLetterInformationPdfUrl = async (
  teamId,
  playerId,
  informationId,
  photoId
) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/information-letters/team/${teamId}/player/${playerId}/information/${informationId}/pdf/${photoId}`,
      responseType: 'text',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getPDFInfo = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/pdf-info/team/${data.teamId}/player/${data.playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getAllVaccinesShots = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/vaccine-shots-types',
      responseType: 'json',
      withCredentials: true,
    })
    return response.data
  } catch (err) {
    return Promise.reject(err.response)
  }
}
