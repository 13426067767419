import React, { useContext } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Link,
  Redirect,
} from 'react-router-dom';

import { Typography } from '../../atoms/Typography';
import {
  ContentWrapper,
  TableHeader,
  TableSubheader,
} from '../../organisms/Layout';
import { Button } from '../../atoms/Button';
import { TabItem } from '../../atoms/TabItem/index';
import { TabList } from '../../atoms/TabList/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Icon } from '../../atoms/Icon';
import { SubheaderWrapper } from '../../organisms/Layout/index';
import { Doctors } from '../TeamDoctors/index';
import { Player } from './../Player/index';
import { getTeam } from '../../../api/team';
import { useQuery } from 'react-query';
import { UserContext } from '../../../context';
import { ADMIN, DOCTOR, EXTERNAL } from '../../../utils/constants';
import { TeamPlayers } from '../TeamPlayers';
import { EditDoctor } from '../EditDoctor';
import { TeamNotifications } from '../TeamNotifications';
import { TeamForms } from '../TeamForms';
import { EditForm } from "../EditForm";
import { TeamTools } from '../TeamTools';
import { EditGrouptool } from '../EditGrouptool';
import { TeamReferrals } from '../TeamReferrals';

export const Team = () => {
  let { teamId } = useParams();

  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "Team" for a particular Team by invoking "getTeam" hook
  const { data: teamData, isLoading, error, isError } = useQuery(
    ['team', teamId],
    () => getTeam(teamId)
  );

  return (
    <Switch>
      <Route path={`${url}/players/:playerId`}>
        <Player teamId={teamId} />
      </Route>
      <Route path={`${url}/team-medics/:doctorId/edit`}>
        <EditDoctor teamId={teamId} />
      </Route>
      <Route path={`${url}/forms/:formId/edit`}>
        <EditForm />
      </Route>
      <Route path={`${url}/tools/:toolId/edit`}>
        <EditGrouptool />
      </Route>
      <Route path={`${url}`}>
        <ContentWrapper>
          <TableHeader>
            <Typography as={'h1'} tag={'h1'}>
              {isError && <div>{error?.message}</div>}
              {isLoading ? (
                <p>Receiving teams data...</p>
              ) : (
                <>{teamData?.name}</>
              )}
            </Typography>
            {user?.role === ADMIN && (
              <Button as={Link} variant={'secondary'} to={`${url}/edit`}>
                Edit Team
              </Button>
            )}
          </TableHeader>
          <SubheaderWrapper>
            <TableSubheader>
              <TabList>
                <li>
                  <TabItem to={`${url}/players`}>Players</TabItem>
                </li>
                {user?.role === ADMIN && (
                    <li>
                      <TabItem to={`${url}/team-medics`}>Team Medics</TabItem>
                    </li>
                )}
                {(user?.role === ADMIN || user?.role === DOCTOR) && (
                    <li>
                      <TabItem to={`${url}/notifications`}>Notifications</TabItem>
                    </li>
                )}
                <li>
                  <TabItem to={`${url}/forms`}>Forms</TabItem>
                </li>
                <li>
                  <TabItem to={`${url}/tools`}>Tools</TabItem>
                </li>
                <li>
                  <TabItem to={`${url}/referrals`}>Referrals</TabItem>
                </li>
              </TabList>
            </TableSubheader>
            <Switch>
              {/* This redirects to a "players" tab by default */}
              <Redirect exact from={`${url}`} to={`${url}/players`} />
              <Route path={`${url}/players`}>
                {user?.role === ADMIN && (
                  <InterButton title={'Add a player'} as={Link} to={`${url}/add-player`}>
                    <Icon name='add' />
                    Add Player
                  </InterButton>
                )}
                <TeamPlayers teamId={teamId} />
              </Route>
              <Route path={`${url}/team-medics`}>
                {user?.role === ADMIN && (
                  <InterButton title={'Add a medic'} as={Link} to={`${url}/add-medic`}>
                    <Icon name='add' />
                    Add Medic
                  </InterButton>
                )}
                <Doctors teamId={teamId} />
              </Route>
              <Route path={`${url}/notifications`}>
                <TeamNotifications teamId={teamId} />
              </Route>
              <Route path={`${url}/forms`}>
                <InterButton title={'Add a form'} as={Link} to={`${url}/add-form`}>
                  <Icon name='add' />
                  Add Form
                </InterButton>
                <TeamForms teamId={teamId} />
              </Route>
              <Route path={`${url}/tools`}>
                <InterButton title={'Add a tool'} as={Link} to={`${url}/add-tool`}>
                  <Icon name='add' />
                  Add Tool
                </InterButton>
                <TeamTools teamId={teamId} />
              </Route>
              <Route path={`${url}/referrals`}>
                <TeamReferrals teamId={teamId} />
              </Route>
            </Switch>
          </SubheaderWrapper>
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
