import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';
import { fonts } from './../../../theme/fonts';

export const Label = styled.label``;

export const Ic = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background: ${themeMain.colors.white};
  border-radius: 3px;
  box-shadow: 0 0 0 1px ${themeMain.colors.blue} inset;
  transition: all 150ms;

  input:focus {
    box-shadow: 0 0 0 3px ${themeMain.colors.blue};

    &:not(:checked) {
      box-shadow: none;
    }
  }

  ${Ic} {
    visibility: hidden;
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-top: 1px;

  &.error {
    ${StyledCheckbox} {
      box-shadow: 0 0 0 1px ${themeMain.colors.red} inset;
    }
  }
`;

export const Component = styled.div`
  display: flex;
  padding-top: 8px;

  span {
    font: 300 16px/18px ${fonts.monserrat};
    margin-left: 8px;
  }

  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    cursor: pointer;

    &[type='radio'] {
      & ~ ${StyledCheckbox} {
        border-radius: 50%;

        ${Ic} {
          display: none;
        }
      }
    }

    &:focus {
      &:not(:checked) {
        & ~ ${StyledCheckbox} {
          box-shadow: 0 0 0 2px ${themeMain.colors.blue} inset;
        }
      }
    }

    &:checked {
      & ~ ${StyledCheckbox} {
        background: ${themeMain.colors.blue};

        ${Ic} {
          visibility: visible;
        }
      }
    }

    &[disabled][checked] {
      & ~ ${StyledCheckbox} {
        background: ${themeMain.colors.blue} !important;
        cursor: not-allowed;
      }
    }
  }
`;
