import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../../atoms/Input';
import { FormItem, InputError } from '../../../../../organisms/Layout';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export const AllergyModule = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormItem>
        <Input
          id='medication'
          name='medication'
          type='text'
          variant='textarea'
          disabled
          {...register('medication', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Medication'
          className={`${errors['medication'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='medication' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='reaction'
          name='reaction'
          type='text'
          variant='textarea'
          disabled
          {...register('reaction', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Reaction'
          className={`${errors['reaction'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='reaction' as={InputError} />
      </FormItem>
    </>
  );
};
