import { editUser } from 'api/account';
import { useMutation, useQueryClient } from 'react-query';

export const useEditUser = () => {
  const queryClient = useQueryClient();

  return useMutation(editUser, {
    onSuccess: (response) => {
      response.cookies.forEach((cookie) => {
        document.cookie = cookie;
      });
      queryClient.invalidateQueries('userAccount');
      queryClient.invalidateQueries('userAvatar');
      return response;
    },
  });
};
