import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { useQuery } from "react-query";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import { Input } from "../../../../atoms/Input";
import { Button } from "../../../../atoms/Button";
import { Icon } from "../../../../atoms/Icon";
import { Typography } from "../../../../atoms/Typography";
import {
  FormItem,
  InputError,
  Fieldset,
  PhotosWrapper,
} from "../../../../organisms/Layout";
import { Checkbox } from "components/atoms/Checkbox";
import {
  getProblemCategoryTypes,
  getProblemInformationById,
} from "api/playerInformation";
import { useEditInfoProblem } from "../../hooks";
import { trimFormData } from "../../../../../utils/trimFormData";

Modal.setAppElement("#root");

export const ProblemModule = ({ params }) => {
  const { push } = useHistory();

  const { teamId, playerId, problemId, informationId } = params;

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    resetField,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      category: "",
      subCategory: "",
    },
  });

  const selectedSubCategory = watch("category");

  const maxDate = new Date().toISOString().split("T")[0];

  const {
    data: informationCategories = [],
    isLoading: informationCategoryIsLoading,
  } = useQuery("problemCategoryTypes", getProblemCategoryTypes);

  const categories = informationCategories.reduce((accumulator, item) => {
    if (item.parent_id !== 0) {
      return accumulator?.map((i) => {
        if (i.id === item.parent_id) {
          return { ...i, sub: [...(i.sub || []), item] };
        }
        return i;
      });
    }
    return [...accumulator, item];
  }, []);

  const { data: problemInformation = [], isLoading: isLoading } = useQuery(
    ["problemInformation", informationId],
    () => getProblemInformationById(teamId, playerId, informationId)
  );

  const { mutateAsync, isLoading: isEditLoading } = useEditInfoProblem();

  const [submitMessage, setSubmitMessage] = useState();

  const watchPhotos = watch("photos");

  const [formPhotos, setFormPhotos] = useState([]);

  const [prevPhotos, setPrevPhotos] = useState([]);

  const [deletedPhotos, setDeletedPhotos] = useState([]);

  useEffect(() => {
    setFormPhotos(getValues("photos"));
  }, [watchPhotos]);

  const onDelete = (index) => {
    setFormPhotos(
      Array.from(formPhotos).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onPrevPhotosDelete = (deletedId) => {
    setPrevPhotos(
      prevPhotos.filter((elem) => {
        return elem.id !== deletedId;
      })
    );
    setDeletedPhotos([...deletedPhotos, deletedId]);
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);
    const formData = new FormData();

    if (problemId) {
      formData.append("problemId", problemId);
    }
    formData.append(
      "categoryTypeId",
      data.subCategory?.value?.id || data.category.value.id
    );
    formData.append("from", data.from);
    if (data.until) {
      formData.append("until", data.until);
    }
    formData.append("problemStatusId", data.major);
    formData.append("notes", data.notes);
    formData.append("deletedFiles", JSON.stringify(deletedPhotos));

    if (data.photos) {
      for (const key of data.photos) {
        formData.append("file", key);
      }
    }

    try {
      await mutateAsync({
        teamId,
        playerId,
        informationId,
        formData,
      });
      push(`/teams/${teamId}/players/${playerId}/information`);
    } catch (error) {
      console.log("error", error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  const categoryOptions = categories.map((category) => ({
    value: category,
    label: category.name,
  }));

  const subCategoryOptions =
    selectedSubCategory?.value?.subCategories?.map((item) => ({
      label: item.name,
      value: item,
    })) || [];

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage("");
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading && !informationCategoryIsLoading && problemInformation) {
      const {
        category_type: categoryType,
        photos,
      } = problemInformation.information_problem;

      const parentCategory =
        informationCategories.find(
          (category) => category.id === categoryType.parent_id
        ) || null;

      reset({
        from: problemInformation.information_problem?.from,
        until: problemInformation.information_problem?.until,
        notes: problemInformation.information_problem?.notes,
        category:
          categoryType.parent_id !== 0
            ? {
                value: {
                  id: parentCategory.id,
                  name: parentCategory.name,
                  subCategories: informationCategories.filter(
                    (i) => i.parent_id === parentCategory.id
                  ),
                },
                label: parentCategory.name,
              }
            : {
                value: {
                  id: categoryType.id,
                  name: categoryType.name,
                },
                label: categoryType.name,
              },
        subCategory:
          categoryType.parent_id !== 0
            ? {
                value: {
                  id: categoryType.id,
                  name: categoryType.name,
                },
                label: categoryType.name,
              }
            : null,
      });
      setPrevPhotos(photos);
    }
  }, [problemInformation]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <Controller
          name="category"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field: { onChange, onBlur, value } }, ref) => (
            <>
              <label htmlFor="category">Category</label>
              <Select
                onChange={(v) => {
                  resetField("subCategory");
                  onChange(v);
                }}
                onBlur={onBlur}
                value={value}
                inputId="category"
                ref={ref}
                isClearable
                isDisabled
                placeholder={"Your information"}
                className={`react-select ${errors["category"] ? "error" : ""}`}
                options={categoryOptions}
              />
            </>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="informationCategory"
          as={InputError}
        />
      </FormItem>
      {subCategoryOptions.length ? (
        <FormItem>
          <Controller
            name="subCategory"
            control={control}
            rules={{ required: "This is required" }}
            render={({ field }, ref) => (
              <>
                <label htmlFor="subCategory">Sub Category</label>
                <Select
                  {...field}
                  inputId="subCategory"
                  ref={ref}
                  isClearable
                  isDisabled
                  placeholder={"Your information"}
                  className={`react-select ${
                    errors["subCategory"] ? "error" : ""
                  }`}
                  options={subCategoryOptions}
                />
              </>
            )}
          />
          <ErrorMessage errors={errors} name="subCategory" as={InputError} />
        </FormItem>
      ) : null}
      <Fieldset>
        <FormItem small>
          <Input
            id="from"
            name="from"
            type="date"
            max={maxDate}
            {...register("from", {
              required: "This is required",
            })}
            placeholder="Enter Here"
            label="From"
            error={errors.name}
            className={`${errors["from"] ? "error" : ""}`}
          />
          <ErrorMessage errors={errors} name="from" as={InputError} />
        </FormItem>
        <FormItem small>
          <Input
            id="until"
            name="until"
            type="date"
            max={maxDate}
            {...register("until")}
            placeholder="Enter Here"
            label="Until"
            error={errors.name}
            className={`${errors["until"] ? "error" : ""}`}
          />
          <ErrorMessage errors={errors} name="until" as={InputError} />
        </FormItem>
      </Fieldset>
      <FormItem>
        <Input
          id="notes"
          name="notes"
          type="text"
          variant="textarea"
          {...register("notes", {
            required: "This is required",
          })}
          placeholder="Enter Here"
          label="Overview"
          error={errors.name}
          className={`${errors["notes"] ? "error" : ""}`}
        />
        <ErrorMessage errors={errors} name="notes" as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id="photos"
          name="photos"
          type="file"
          multiple
          accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          {...register("photos", {})}
          placeholder="Enter Here"
          label="Add Files"
          error={errors.name}
          className={`${errors["photos"] ? "error" : ""}`}
        />
        <ErrorMessage errors={errors} name="photos" as={InputError} />
        <PhotosWrapper>
          {Array.from(formPhotos).map((i, index) => {
            return (
              <div key={i + index}>
                {i.name}
                <Button
                  type="button"
                  aria-label="Delete"
                  icon
                  onClick={() => onDelete(index)}
                >
                  <Icon name="remove" />
                </Button>
              </div>
            );
          })}
        </PhotosWrapper>
        {prevPhotos.length > 0 && (
          <>
            <label>Current Files</label>
            <PhotosWrapper>
              {prevPhotos.map((i, index) => {
                return (
                  <div key={index + i + index}>
                    {i.original_name}
                    <Button
                      type="button"
                      aria-label="Delete"
                      icon
                      onClick={() => onPrevPhotosDelete(i.id)}
                    >
                      <Icon name="remove" />
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
          </>
        )}
      </FormItem>
      {!isLoading && (
        <>
          <FormItem>
            <label>Problem Status</label>
            <Checkbox
              name="major"
              type="radio"
              value={1}
              label="Minor"
              defaultChecked={
                1 === problemInformation.information_problem.problem_status.id
              }
              {...register("major")}
              className={`${errors["major"] ? "error" : ""}`}
            />
            <Checkbox
              name="major"
              type="radio"
              value={2}
              label="Major"
              defaultChecked={
                2 === problemInformation.information_problem.problem_status.id
              }
              {...register("major")}
              className={`${errors["major"] ? "error" : ""}`}
            />
          </FormItem>
        </>
      )}
      <Button type="button" onClick={onPreSubmit}>
        <Icon name="check" />
        Submit Information
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        {submitMessage?.length > 0 ? (
          <>
            <div className="modal-body">
              <Typography as={"h2"} tag={"h2"}>
                Something went wrong
              </Typography>
              <Typography as={"p"} tag={"h4"}>
                {submitMessage}
              </Typography>
            </div>
            <div className="modal-buttons">
              <Button onClick={toggleModalClear} variant={"secondary"}>
                Try again
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-body">
              <Typography as={"h2"} tag={"h2"}>
                Are you sure?
              </Typography>
              <Typography as={"p"} tag={"h4"}>
                Do you really want to edit this information?
              </Typography>
            </div>
            <div className="modal-buttons">
              <Button onClick={toggleModal} variant={"secondary"}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isEditLoading}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal>
    </form>
  );
};
