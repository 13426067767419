import React from 'react';
import {
  NavComponent,
  ContainerComponent,
  BreadcrumbsComponent,
  LogoComponent,
  SearchComponent,
  UserComponent,
  NavListComponent,
  NavListItemComponent,
  NavRightComponent,
  BreadcrumbsWrapperComponent,
  ContentWrapperComponent,
  TableWrapperComponent,
  TableHeaderComponent,
  TableSubheaderComponent,
  TableButtonGroupComponent,
  SubheaderWrapperComponent,
  PaginationWrapperComponent,
  FormWrapperComponent,
  FormItemComponent,
  InputErrorComponent,
  FieldsetComponent,
  LoginFormComponent,
  ForgotFormComponent,
  PasswordFormComponent,
  CheckboxGroupComponent,
  PhotosWrapperComponent,
  ButtonGroupComponent,
} from './styles';

export const Container = ({ children, ...rest }) => {
  return <ContainerComponent {...rest}>{children}</ContainerComponent>;
};

export const Breadcrumbs =  ({ children, ...rest }) => {
  return <BreadcrumbsComponent {...rest}>{children}</BreadcrumbsComponent>;
};

export const Nav = ({ children, ...rest }) => {
  return <NavComponent {...rest}>{children}</NavComponent>;
};

export const Logo = ({ children, ...rest }) => {
  return <LogoComponent {...rest}>{children}</LogoComponent>;
};

export const SearchForm = ({ children, ...rest }) => {
  return <SearchComponent {...rest}>{children}</SearchComponent>;
};

export const User = ({ children, ...rest }) => {
  return <UserComponent {...rest}>{children}</UserComponent>;
};

export const NavList = ({ children, ...rest }) => {
  return <NavListComponent {...rest}>{children}</NavListComponent>;
};

export const NavListItem = ({ children, ...rest }) => {
  return <NavListItemComponent {...rest}>{children}</NavListItemComponent>;
};

export const NavRight = ({ children, ...rest }) => {
  return <NavRightComponent {...rest}>{children}</NavRightComponent>;
};

export const BreadcrumbsWrapper = ({ children, ...rest }) => {
  return (
    <BreadcrumbsWrapperComponent {...rest}>
      {children}
    </BreadcrumbsWrapperComponent>
  );
};

export const TableWrapper = ({ children, ...rest }) => {
  return <TableWrapperComponent {...rest}>{children}</TableWrapperComponent>;
};

export const TableHeader = ({ children, ...rest }) => {
  return <TableHeaderComponent {...rest}>{children}</TableHeaderComponent>;
};

export const TableSubheader = ({ children, ...rest }) => {
  return (
    <TableSubheaderComponent {...rest}>{children}</TableSubheaderComponent>
  );
};

export const SubheaderWrapper = ({ children, ...rest }) => {
  return (
    <SubheaderWrapperComponent {...rest}>{children}</SubheaderWrapperComponent>
  );
};

export const ContentWrapper = ({ children, ...rest }) => {
  return (
    <ContentWrapperComponent {...rest}>{children}</ContentWrapperComponent>
  );
};

export const PaginationWrapper = ({ children, ...rest }) => {
  return (
    <PaginationWrapperComponent {...rest}>
      {children}
    </PaginationWrapperComponent>
  );
};

export const FormWrapper = ({ children, ...rest }) => {
  return <FormWrapperComponent {...rest}>{children}</FormWrapperComponent>;
};

export const FormItem = ({ children, ...rest }) => {
  return <FormItemComponent {...rest}>{children}</FormItemComponent>;
};

export const InputError = ({ children, ...rest }) => {
  return <InputErrorComponent {...rest}>{children}</InputErrorComponent>;
};

export const Fieldset = ({ children, ...rest }) => {
  return <FieldsetComponent {...rest}>{children}</FieldsetComponent>;
};

export const TableButtonGroup = ({ children, ...rest }) => {
  return (
    <TableButtonGroupComponent {...rest}>{children}</TableButtonGroupComponent>
  );
};

export const LoginForm = ({ children, ...rest }) => {
  return <LoginFormComponent {...rest}>{children}</LoginFormComponent>;
};

export const ForgotForm = ({ children, ...rest }) => {
  return <ForgotFormComponent {...rest}>{children}</ForgotFormComponent>;
};

export const PasswordForm = ({ children, ...rest }) => {
  return <PasswordFormComponent {...rest}>{children}</PasswordFormComponent>;
};

export const CheckboxGroup = ({ children, ...rest }) => {
  return <CheckboxGroupComponent {...rest}>{children}</CheckboxGroupComponent>;
};

export const PhotosWrapper = ({ children, ...rest }) => {
  return <PhotosWrapperComponent {...rest}>{children}</PhotosWrapperComponent>;
};

export const ButtonGroup = ({ children, ...rest }) => {
  return <ButtonGroupComponent {...rest}>{children}</ButtonGroupComponent>;
};
