import { useMutation, useQueryClient } from 'react-query';
import {
  addConsult,
  addProblem,
  addTreatment,
  addMedication,
  addResult,
  addLetter,
  addResultExternal, addConsultExternal, addMedicationExternal, addLetterExternal, addTreatmentExternal,
} from 'api/playerInformation';

export const useAddInfoConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(addConsult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoConsultExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addConsultExternal, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoProblem = () => {
  const queryClient = useQueryClient();

  return useMutation(addProblem, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoMedications = () => {
  const queryClient = useQueryClient();

  return useMutation(addMedication, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoMedicationsExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addMedicationExternal, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoResult = () => {
  const queryClient = useQueryClient();

  return useMutation(addResult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoResultExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addResultExternal, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};



export const useAddInfoTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation(addTreatment, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoTreatmentExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addTreatmentExternal, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(addLetter, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoLetterExternal = () => {
  const queryClient = useQueryClient();

  return useMutation(addLetterExternal, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};