import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../../atoms/Input';
import { FormItem, InputError } from '../../../../../organisms/Layout';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export const InjectionModule = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormItem>
        <Input
          id='bodyPart'
          name='bodyPart'
          type='text'
          variant='textarea'
          {...register('bodyPart', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Body Part'
          className={`${errors['bodyPart'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='bodyPart' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='drug'
          name='drug'
          type='text'
          variant='textarea'
          {...register('drug', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Drug'
          className={`${errors['drug'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='drug' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='dose'
          name='dose'
          type='text'
          variant='textarea'
          {...register('dose', {})}
          error={errors.name}
          placeholder='Enter Here'
          label='Dose'
          className={`${errors['dose'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='dose' as={InputError} />
      </FormItem>
    </>
  );
};
