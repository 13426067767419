import React from 'react';
import { useQuery } from "react-query";
import Modal from 'react-modal';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { CheckboxGroup, FormItem, InputError } from '../../../../../organisms/Layout';
import { Checkbox } from 'components/atoms/Checkbox';

import { Input } from "../../../../../atoms/Input";
import { getAllVaccinesShots } from "../../../../../../api/playerInformation";

Modal.setAppElement('#root');

export const VaccinesModule = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { data: vaccinesShots, isLoading } = useQuery('getVaccines', getAllVaccinesShots)

  return (
    <>
      <FormItem>
        <Input
          id='name'
          name='name'
          type='text'
          {...register('name', {
            required: 'This is required',
          })}
          placeholder='Enter Here'
          label='Name'
          className={`${errors['name'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='name' as={InputError}/>
      </FormItem>
      {!isLoading && (
        <FormItem>
          <label>Injection</label>
          <Controller
            name='vaccinesIdsList'
            render={({ field: { value, onChange } }) => {
              return (
                <CheckboxGroup>
                  {vaccinesShots.map((item) => {
                    const stringValue = item.id.toString()
                    return (
                      <Checkbox
                        key={item.name}
                        name='vaccinesIdsList'
                        type='checkbox'
                        onChange={() => onChange(value?.includes(stringValue) ? value?.filter(i => i !== stringValue) : [...value, stringValue])}
                        value={stringValue}
                        label={item.name}
                        defaultChecked={value?.includes(item.id.toString())}
                        className={`${errors['vaccinesIdsList'] ? 'error' : ''}`}
                      />
                    );
                  })}
                </CheckboxGroup>
              )
            }}
          />
        </FormItem>
      )}
    </>
  );
};
