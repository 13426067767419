import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Input } from "../../../atoms/Input";
import { Button } from "../../../atoms/Button";
import { Icon } from "../../../atoms/Icon";
import { Typography } from "../../../atoms/Typography";
import { FormItem, InputError } from "../../Layout";
import Modal from "react-modal";
import { FormImages } from "../../FormImages";
import { REQUIRED_FIELD } from "../../../../constants/massages";

Modal.setAppElement("#root");

export const EnumInformationLetterFormMode = {
  Add: "add",
  Edit: "edit",
  View: "view",
};

export const InformationLetterForm = ({
  params,
  onSubmit,
  mode,
  defaultValues = {},
  isLoading,
}) => {
  const maxDate = new Date().toISOString().split("T")[0];
  const fileInputRef = useRef(null);
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: { ...defaultValues, deletedFiles: [], currentFiles: "" },
  });
  const isViewMode = mode === EnumInformationLetterFormMode.View;

  const [submitMessage, setSubmitMessage] = useState();

  const [files, currentFiles, deletedFiles] = watch([
    "files",
    "currentFiles",
    "deletedFiles",
  ]);

  const onDelete = (index) => {
    setValue(
      "currentFiles",
      Array.from(currentFiles).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onDeleteFromServer = (fileId) => {
    setValue("deletedFiles", [
      ...deletedFiles.filter((id) => id !== fileId),
      fileId,
    ]);
    setValue("files", [...files.filter((f) => f.id !== fileId)]);
  };

  const onImageLoad = (data) => {
    setValue("files", data);
  };

  const [isOpen, setIsOpen] = useState(false);

  const _onSubmit = (props) => {
    onSubmit && onSubmit(props, setSubmitMessage);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage("");
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    const result = await trigger();

    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage("");
    }, 500);
  }, [isOpen]);

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <FormItem>
        <Input
          id="name"
          name="name"
          type="text"
          variant="textarea"
          {...register("name", {
            required: "This is required",
          })}
          placeholder="Enter Here"
          label="Name"
          error={errors.name}
          className={`${errors["name"] ? "error" : ""}`}
          disabled={isViewMode}
        />
        <ErrorMessage errors={errors} name="name" as={InputError} />
      </FormItem>
      <FormItem small>
        <Input
          id="dateAdded"
          name="dateAdded"
          type="text"
          {...register("dateAdded", {})}
          label="Date Added"
          disabled
        />
      </FormItem>
      <FormItem small>
        <Input
          id="date"
          name="date"
          type="date"
          max={maxDate}
          {...register("date", {
            required: "This is required",
          })}
          placeholder="Enter Here"
          label="Date"
          error={errors.name}
          className={`${errors["date"] ? "error" : ""}`}
          disabled={isViewMode}
        />
        <ErrorMessage errors={errors} name="date" as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id="indication"
          name="indication"
          type="text"
          variant="textarea"
          {...register("indication", {})}
          placeholder="Enter Here"
          label="Indication"
          error={errors.name}
          className={`${errors["indication"] ? "error" : ""}`}
          disabled={isViewMode}
        />
        <ErrorMessage errors={errors} name="indication" as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id="outcome"
          name="outcome"
          type="text"
          variant="textarea"
          {...register("outcome", {})}
          placeholder="Enter Here"
          label="Outcome"
          error={errors.name}
          className={`${errors["outcome"] ? "error" : ""}`}
          disabled={isViewMode}
        />
        <ErrorMessage errors={errors} name="outcome" as={InputError} />
      </FormItem>
      <FormItem>
        {mode !== EnumInformationLetterFormMode.View && (
          <Controller
            name={"currentFiles"}
            control={control}
            rules={{
              validate: (value) =>
                value?.length || files?.length ? null : REQUIRED_FIELD,
            }}
            render={({ field: { onChange } }) => (
              <>
                <Input
                  id="currentFiles"
                  name="currentFiles"
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={(e) => onChange(Array.from(e.target.files))}
                  accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  placeholder="Enter Here"
                  label="Files"
                  error={errors.name}
                  className={`${errors["currentFiles"] ? "error" : ""}`}
                />
                <ErrorMessage
                  errors={errors}
                  name="currentFiles"
                  as={InputError}
                />
              </>
            )}
          />
        )}
        <FormImages
          mode={mode}
          onDelete={onDelete}
          onDeleteFromServer={onDeleteFromServer}
          files={files}
          currentFiles={currentFiles}
          params={params}
          onLoad={onImageLoad}
        />
      </FormItem>
      {mode !== EnumInformationLetterFormMode.View && (
        <>
          <Button type="button" onClick={onPreSubmit}>
            <Icon name="check" />
            Submit Information
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className="modal-body">
                  <Typography as={"h2"} tag={"h2"}>
                    Something went wrong
                  </Typography>
                  <Typography as={"p"} tag={"h4"}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className="modal-buttons">
                  <Button onClick={toggleModalClear} variant={"secondary"}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <Typography as={"h2"} tag={"h2"}>
                    Are you sure?
                  </Typography>
                  <Typography as={"p"} tag={"h4"}>
                    {`Do you really want to ${mode} this information?`}
                  </Typography>
                </div>
                <div className="modal-buttons">
                  <Button onClick={toggleModal} variant={"secondary"}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </>
      )}
    </form>
  );
};
