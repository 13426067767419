import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Typography } from '../../atoms/Typography';
import {
  ContentWrapper,
  TableHeader,
  TableSubheader,
} from '../../organisms/Layout';
import { TabItem } from '../../atoms/TabItem/index';
import { TabList } from '../../atoms/TabList/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Icon } from '../../atoms/Icon';
import { SubheaderWrapper } from '../../organisms/Layout/index';
import { UserContext } from '../../../context';
import { ADMIN } from '../../../utils/constants';
import { AddProblemCategory } from '../AddProblemCategory';
import { ProblemCategories } from '../ProblemCategories';
import { EditProblemCategory } from '../EditProblemCategory';
import { Sports } from '../Sports';
import { AddSport } from '../AddSport';
import { EditSport } from '../EditSport';

export const Configuration = () => {
  let { url } = useRouteMatch();

  const [user] = useContext(UserContext);

  return (
    <Switch>
      <Route path={`${url}/problem-categories/:problemId/edit`}>
        <EditProblemCategory />
      </Route>
      <Route path={`${url}/sports/:sportId/edit`}>
        <EditSport />
      </Route>
      <Route path={`${url}/add-sport`}>
        <AddSport />
      </Route>
      <Route path={`${url}/add-problem-category`}>
        <AddProblemCategory />
      </Route>
      <Route path={`${url}`}>
        <ContentWrapper>
          <TableHeader>
            <Typography as={'h1'} tag={'h1'}>
              Configuration
            </Typography>
          </TableHeader>
          <SubheaderWrapper>
            <TableSubheader>
              <TabList>
                <li>
                  <TabItem to={`${url}/sports`}>Sports</TabItem>
                </li>
                <li>
                  <TabItem to={`${url}/problem-categories`}>
                    Problem categories
                  </TabItem>
                </li>
              </TabList>
            </TableSubheader>
            <Switch>
              <Redirect exact from={`${url}`} to={`${url}/sports`} />
              <Route path={`${url}/problem-categories`}>
                {user?.role === ADMIN && (
                  <InterButton title={'Add a problem category'} as={Link} to={`${url}/add-problem-category`}>
                    <Icon name='add' />
                    Add Problem Category
                  </InterButton>
                )}
                <ProblemCategories />
              </Route>
              <Route path={`${url}/sports`}>
                {user?.role === ADMIN && (
                  <InterButton title={'Add a sport'} as={Link} to={`${url}/add-sport`}>
                    <Icon name='add' />
                    Add Sport
                  </InterButton>
                )}
                <Sports />
              </Route>
            </Switch>
          </SubheaderWrapper>
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
