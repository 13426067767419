import axiosWrapper from '../../../../api/axiosInterceptor';

export const getUserAvatar = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/user/avatar',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
