import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import {
  getLetterInformationById,
} from 'api/playerInformation';
import {
  InformationLetterForm,
  EnumInformationLetterFormMode
} from "../../../../organisms/Forms/InformationLetterForm";

Modal.setAppElement('#root');

export const LettersModule = ({ params }) => {
  const { teamId, playerId, informationId } = params;
  const [defaultValues, setDefaultValues] = useState()

  const {
    data: letterInformation,
    isLoading: isLoading,
  } = useQuery(['letterInformation', informationId], () =>
    getLetterInformationById(teamId, playerId, informationId)
  );

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  useEffect(() => {
    if (!isLoading && letterInformation) {
      const d = new Date(letterInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);

      setDefaultValues({
        name: letterInformation.information_letters.name,
        date: letterInformation.information_letters.date,
        outcome: letterInformation.information_letters.outcome,
        indication: letterInformation.information_letters.indication,
        dateAdded: dateAddedString,
        files: letterInformation.information_letters.files.map(
          (photo) => {
            return {
              ...photo,
              image: null,
            };
          }
        )
      })
    }
  }, [letterInformation]);

  if (!defaultValues) return null

  return (
    <InformationLetterForm
      mode={EnumInformationLetterFormMode.View}
      defaultValues={defaultValues}
      params={params}
    />
  );
};
