import React, { useEffect, useState, useContext } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Link, useRouteMatch } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Button } from '../../atoms/Button/index';
import { Icon } from '../../atoms/Icon';
import { Status } from '../../atoms/Status/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Typography } from '../../atoms/Typography';

import {
  ContentWrapper,
  TableWrapper,
  TableHeader,
  PaginationWrapper,
} from '../../organisms/Layout';
import { UserContext } from '../../../context';
import { getReferralsActive } from '../../../api/externalReferrals';
import { ADMIN } from '../../../utils/constants';


Modal.setAppElement('#root');

export const ExternalReferralsActive = () => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "external"
  const {
    data: referralsData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery('referrals', getReferralsActive);

  // Table Header Columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Team',
        accessor: 'col2',
      },
      {
        Header: 'Player',
        accessor: 'col3',
      },
      {
        Header: 'Team Contact',
        accessor: 'col4',
      },
      {
        Header: 'Status',
        accessor: 'col5',
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
    ],
    []
  );

  // Table data recompiled from query request to react-table tbody data
  const data = React.useMemo(() => {
    return referralsData.map((e) => {
        return {
          col1: e?.id,
          col2: e?.team?.name,
          col3: e?.player?.user?.first_name + ' ' + e?.player?.user?.last_name,
          col4: e?.added_by,
          col5: (
            <Status variant={e?.status.name}>
              {e?.status.name && e?.status.name.toUpperCase()}
            </Status>
          ),
          col6: (
              <>
                  <InterButton title={`View ${e?.name} referral`} as={Link} to={`${url}/${e?.id}`}>
                      <Icon name='view'/>
                  </InterButton>
                  <InterButton title={`View ${e?.name} information`} as={Link} to={`${url}/${e?.id}/info`}>
                      <Icon name='list'/>
                  </InterButton>
              </>
          ),
        };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <ContentWrapper>
        <TableHeader>
            <Typography as={'h1'} tag={'h1'}>
                Active Referrals
            </Typography>
        </TableHeader>
      <TableWrapper>
        {isError && <div>{error?.message}</div>}
        {isLoading ? (
          <p>Receiving external company data...</p>
        ) : (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWrapper>
      {pageCount > 1 && (
        <PaginationWrapper>
          {/* React Paginate component*/}
          <ReactPaginate
            previousLabel={
              <span>
                <Icon name='arrowLeft' />
                Previous
              </span>
            }
            nextLabel={
              <span>
                Next
                <Icon name='arrowRight' />
              </span>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={pageCount}
            forcePage={selectedPage}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
    </ContentWrapper>
  );
};
