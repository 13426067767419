import React, { useContext } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Typography } from '../../atoms/Typography';
import {
  ContentWrapper,
  TableHeader,
  TableSubheader,
} from '../../organisms/Layout';
import { TabItem } from '../../atoms/TabItem/index';
import { TabList } from '../../atoms/TabList/index';
import { SubheaderWrapper } from '../../organisms/Layout/index';
import { SearchContext } from '../../../context';
//import { Sports } from '../Sports';
import { TeamsSearch } from './teams';
import { PlayersSearch } from './players';
import { DoctorsSearch } from './doctors';

export const SearchResults = () => {
  let { url } = useRouteMatch();

  const [search] = useContext(SearchContext);

  return (
    <Switch>
      <Route path={`${url}`}>
        <ContentWrapper>
          <TableHeader>
            <Typography as={'h1'} tag={'h1'}>
              Search Results
            </Typography>
          </TableHeader>
          <SubheaderWrapper>
            <TableSubheader>
              <TabList>
                <li>
                  <TabItem to={`${url}/teams`}>
                    Teams <sup>{search.teams.length}</sup>
                  </TabItem>
                </li>
                <li>
                  <TabItem to={`${url}/players`}>
                    Players <sup>{search.players.length}</sup>
                  </TabItem>
                </li>
                <li>
                  <TabItem to={`${url}/doctors`}>
                    Doctors <sup>{search.doctors.length}</sup>
                  </TabItem>
                </li>
              </TabList>
            </TableSubheader>
            <Switch>
              <Redirect exact from={`${url}`} to={`${url}/teams`} />
              <Route path={`${url}/teams`}>
                <TeamsSearch />
              </Route>
              <Route path={`${url}/players`}>
                <PlayersSearch />
              </Route>
              <Route path={`${url}/doctors`}>
                <DoctorsSearch />
              </Route>
            </Switch>
          </SubheaderWrapper>
        </ContentWrapper>
      </Route>
    </Switch>
  );
};
