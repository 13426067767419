import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';
import { devices } from '../../../theme/devices';

export const Component = styled(NavLink)`
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 8px 0 0;
  margin: 0 auto;
  font: 300 16px/20px ${fonts.monserrat};
  color: ${themeMain.colors.blue};
`;
