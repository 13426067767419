import { useState, useRef, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SiteLogo from "./../../../assets/imgs/site-logo.png";
import ProfileImage from "./../../../assets/imgs/profile-img.jpg";
import { useRegistration } from "./hooks/useRegistration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosWrapper from "../../../api/axiosInterceptor";
import {
  Athelete,
  ChevronLeft,
  Info,
  Message,
  Mobile,
  SelectArrow,
  Team,
  Basketball,
  Football,
  Golf,
  Pinkball,
  SlideRight,
  SlideLeft,
} from "./../../../assets/svg/Main";
import { useHistory } from "react-router-dom";

const LeftScreen = () => {
  const history = useHistory();
  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useRegistration();
  const [step, setStep] = useState(1);
  const [profileImagePlaceholder, setProfileImagePlaceholder] = useState(
    ProfileImage
  );
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [twoFactorType, setTwoFactorType] = useState("byemail");
  const [activeAtheleteType, setActiveAtheleteType] = useState(
    "individual_athelete"
  );

  const [formData, setFormData] = useState({
    email: "",
    statusId: 1,
    countryId: "",
    genderId: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    nhsNumber: "",
    birthday: "",
    gpSurgery: "",
    nextOfKin: "",
    address1: "",
    address2: "",
    city: "",
    postcode: "",
    isInvited: true,
    password: "",
    confirmpassword: "",
    emergencyContactEmail: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactPhoneNumber: "",
    gpCity: "",
    gpRegion: "",
    gpCountryId: "",
    day: "",
    month: "",
    year: "",
    avatar: "",
  });
  const carouselRef = useRef();
  const profileImageRef = useRef();
  const [headingText, setHeadingText] = useState([
    {
      step: 1,
      text: "what type of athlete are you?",
    },
    {
      step: 2,
      text: "Register for an account with ab3 medical",
    },
    {
      step: 3,
      text: "your details",
    },
    {
      step: 4,
      text: "your address",
    },
    {
      step: 5,
      text: "your gp details",
    },
    {
      step: 6,
      text: "your emergency contact",
    },
    {
      step: 7,
      text: "add your profile picture",
    },
    {
      step: 8,
      text: "add multi-factor authentication",
    },
    {
      step: 9,
      text: "request your club",
    },
  ]);

  const [buttonText, setbuttonText] = useState([
    {
      step: 1,
      text: "Continue Registration",
    },
    {
      step: 2,
      text: "Continue Registration",
    },
    {
      step: 3,
      text: "Continue Registration",
    },
    {
      step: 4,
      text: "Continue Registration",
    },
    {
      step: 5,
      text: "Continue Registration",
    },
    {
      step: 6,
      text: "Continue Registration",
    },
    {
      step: 7,
      text: "Continue Registration",
    },
    {
      step: 8,
      text: "Complete Registration",
    },
    {
      step: 9,
      text: "Request Your Club",
    },
  ]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // const [nationality, setNationality] = useState([]);
  const [gender, setGender] = useState([]);
  const [country, setCountry] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const registration = async (data) => {
    try {
      const form = new FormData();
      for (const key in formData) {
        form.append(key, formData[key]);
      }
      const response = await mutateAsync(form);
      if (response.status == "success") {
        toast.success(response.message + "\nRedirecting to Login");

        setTimeout(() => {
          return history.push("/login");
        }, 5000);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const updateStep = () => {
    const fields = validateStep(step);
    const requiredFields = fields.filter((field) => field.required);

    const allRequiredFilled = requiredFields.every(
      (field) => formData[field.name] !== ""
    );

    if (allRequiredFilled) {
      if (step === 2 && formData.password !== formData.confirmpassword) {
        toast.error("Password and confirm password do not match.");
        return;
      }

      setStep((prevStep) => (prevStep === 8 ? 8 : prevStep + 1));

      if (step === 8) {
        registration(formData);
      }
    } else {
      toast.error("Please fill all required fields before proceeding.");
    }
  };

  const validateStep = (step) => {
    switch (step) {
      case 1:
        return [];
      case 2:
        return [
          { name: "email", required: true },
          { name: "password", required: true },
          { name: "confirmpassword", required: true },
        ];
      case 3:
        return [
          { name: "firstName", required: true },
          { name: "lastName", required: true },
          { name: "birthday", required: true },
          { name: "genderId", required: true },
          { name: "nhsNumber", required: false },
          { name: "phoneNumber", required: true },
        ];
      case 4:
        return [
          { name: "address1", required: true },
          { name: "address2", required: false },
          { name: "postcode", required: true },
          { name: "city", required: true },
          { name: "countryId", required: true },
        ];
      case 5:
        return [
          { name: "gpSurgery", required: false },
          { name: "gpCity", required: false },
          { name: "gpRegion", required: false },
          { name: "gpCountryId", required: false },
        ];
      case 6:
        return [
          { name: "emergencyContactFirstName", required: true },
          { name: "emergencyContactLastName", required: true },
          { name: "nextOfKin", required: true },
          { name: "emergencyContactPhoneNumber", required: true },
          { name: "emergencyContactEmail", required: false },
        ];
      case 7:
        return [{ name: "avatar", required: false }];
      case 8:
        return [];

      default:
        return [];
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group absolute">
        <button
          className={
            currentSlide === 0
              ? "disable"
              : "border border-red-300 px-3 py-2 rounded-sm bg-blue-300"
          }
          onClick={() => previous()}
        >
          PREV
        </button>
        <button
          className="border border-red-300 px-3 py-2 rounded-sm bg-blue-300 mx-3"
          onClick={() => next()}
        >
          NEXT
        </button>
      </div>
    );
  };
  const rightButtonStyle = {
    position: "absolute",
    outline: 0,
    transition: "all .5s",
    borderRadius: "35px",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
    minWidth: "43px",
    minHeight: "43px",
    opacity: 1,
    cursor: "pointer",
    right: "calc(4% + 1px)",
  };
  const leftButtonStyle = {
    position: "absolute",
    outline: 0,
    transition: "all .5s",
    borderRadius: "35px",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
    minWidth: "43px",
    minHeight: "43px",
    opacity: 1,
    cursor: "pointer",
    left: "calc(4% + 1px)",
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        style={rightButtonStyle}
        onClick={() => carouselRef.current.next()}
      >
        <SlideRight />
      </button>
    );
  };
  const CustomLeftArrow = ({ previous, onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    return (
      <button
        style={leftButtonStyle}
        onClick={() => carouselRef.current.previous()}
      >
        <SlideLeft />
      </button>
    );
  };
  // const getNationalities = async () => {
  //   const response = await axiosWrapper({
  //     method: "GET",
  //     url: "/nationality",
  //     responseType: "json",
  //     withCredentials: true,
  //   });
  //   setNationality(response.data);
  // };
  const getCountries = async () => {
    const response = await axiosWrapper({
      method: "GET",
      url: "/country",
      responseType: "json",
      withCredentials: true,
    });
    setCountry(response.data);
  };
  const getGenders = async () => {
    const response = await axiosWrapper({
      method: "GET",
      url: "/gender",
      responseType: "json",
      withCredentials: true,
    });
    setGender(response.data);
  };

  useEffect(() => {
    // getNationalities();
    getCountries();
    getGenders();
  }, []);

  useEffect(() => {
    const year = parseInt(formData.year, 10);
    const month = parseInt(formData.month, 10) - 1;
    const day = parseInt(formData.day, 10) + 1;

    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      const birthday = new Date(year, month, day);
      const timestamp = birthday.toISOString();
      setFormData({ ...formData, birthday: timestamp });
    }
  }, [formData.day, formData.month, formData.year]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImagePlaceholder(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    setFormData({
      ...formData,
      ["avatar"]: file,
    });
  };
  const black = "#000000";
  const white = "#FFFFFF";
  const gray = "#F3F4F6";
  const blue = "#4476FF";
  return (
    <>
      <ToastContainer />
      <div className="flex flex-col px-3 md:w-1/2 sm:w-full items-center min-h-screen">
        {step !== 1 && (
          <div className="flex box-border h-24 p-8 w-full ">
            <div
              onClick={() => setStep(step - 1)}
              className="flex cursor-pointer space-x-3 pointer"
            >
              <ChevronLeft />
              <span className="font-semibold pt-2">GO BACK</span>
            </div>
          </div>
        )}

        <div className="flex flex-shrink justify-center box-border p-8 w-full">
          <img
            src={SiteLogo}
            style={{ maxWidth: "100px" }}
            className="logo  h-100"
            alt=""
          />
        </div>
        <div className="flex-shrink flex flex-col max-w-full justify-center items-center px-4 w-full lg:w-full mt-5">
          <div className="flex items-center flex-col flex-wrap">
            <span className="font-semibold text-center text-2xl uppercase">
              {headingText[step - 1].text}
            </span>
            <span
              style={{ border: "3px solid #FF264B" }}
              className=" w-16 mt-3"
            ></span>
          </div>
          {step === 1 && (
            <>
              <div
                className={`flex flex-row ${
                  activeAtheleteType == "individual_athelete"
                    ? "bg-blue-500 border-4 border-blue-500 text-white"
                    : "bg-gray-100 border-4 border-gray-100 text-black"
                } items-center rounded-2xl w-3/4 w-sm-full py-3 px-5 mt-8`}
                onClick={() => setActiveAtheleteType("individual_athelete")}
              >
                <div className="">
                  <Athelete
                    color={
                      activeAtheleteType === "individual_athelete"
                        ? white
                        : black
                    }
                    bgcolor={
                      activeAtheleteType === "individual_athelete" ? blue : gray
                    }
                  />
                </div>
                <div>
                  <span className="font-semibold text-lg mx-4">
                    INDIVIDUAL ATHLETE
                  </span>
                </div>
              </div>
              <div
                className={`flex flex-row ${
                  activeAtheleteType == "team_athelete"
                    ? "bg-blue-500 border-4 border-blue-500 text-white"
                    : "bg-gray-100 border-4 border-gray-100 text-black"
                } items-center rounded-2xl w-3/4 w-sm-full py-3 px-5 mt-8`}
                onClick={() => setActiveAtheleteType("team_athelete")}
              >
                <div className="">
                  <Team
                    color={
                      activeAtheleteType === "team_athelete" ? white : black
                    }
                    bgcolor={
                      activeAtheleteType === "team_athelete" ? blue : gray
                    }
                  />
                </div>
                <div>
                  <span className="font-semibold text-lg mx-4 uppercase">
                    Club / team ATHLETE
                  </span>
                </div>
              </div>
              <div className="flex flex-col bg-pink text-white rounded-2xl w-3/4 w-sm-full py-3 px-3 mt-8">
                <div className="flex text-xs font-semibold flex-row items-center">
                  <Info />
                  <span className="ml-2 ">
                    ARE YOU A TEAM / CLUB OR MEDICAL SERVICE?
                  </span>
                </div>
                <div className="flex flex-row py-3 items-center">
                  <span className="text-xxs font-normal">
                    If you would like to onboard your team on AB3 Medical,
                    please request your team to be added.
                  </span>
                </div>
                <div className="flex flex-row justify-end items-center">
                  <a className="text-xxs font-semibold" href="">
                    <u>Request your team →</u>
                  </a>
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="w-3/4 form_wrap w-sm-full">
                <div className="input_wrap">
                  <input
                    className=" input-placeholder flex text-xs flex-row bg-gray-100 font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    type="email"
                    name="email"
                    id="unique-input"
                    required
                    onChange={handleChange}
                    value={formData.email}
                    style={{ height: "75px" }}
                  />
                  <label className="text-xs" for="unique-input">
                    Email address<super>*</super>
                  </label>
                </div>
              </div>
              <div className="w-3/4 w-sm-full">
                <div className="relative">
                  <input
                    placeholder="Password"
                    name="password"
                    required
                    onChange={handleChange}
                    value={formData.password}
                    type={showPassword ? "password" : "text"}
                    className="input-placeholder  flex flex-row bg-gray-100 text-xs font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    style={{ height: "75px" }}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <svg
                      className="h-6 w-6 text-gray-700 mr-3"
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      {...(showPassword ? {} : { className: "hidden" })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>
                    <svg
                      className="h-6 w-6 text-gray-700 mr-3"
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      {...(!showPassword ? {} : { className: "hidden" })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-3/4 w-sm-full">
                <div className="relative">
                  <input
                    required
                    placeholder="Confirm Password"
                    name="confirmpassword"
                    onChange={handleChange}
                    style={{ height: "75px" }}
                    value={formData.confirmpassword}
                    type={showConfirmPassword ? "password" : "text"}
                    className="input-placeholder  flex flex-row bg-gray-100 text-xs font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <svg
                      className="h-6 w-6 text-gray-700 mr-3"
                      fill="none"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      {...(showConfirmPassword ? {} : { className: "hidden" })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>
                    <svg
                      className="h-6 w-6 text-gray-700 mr-3"
                      fill="none"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      {...(!showConfirmPassword ? {} : { className: "hidden" })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <div className="w-3/4 w-sm-full  space-x-5 flex flex-row">
                <div className="w-1/2 form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 font-normal text-xs  items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                      type="text"
                      name="firstName"
                      required
                      id="unique-input"
                      onChange={handleChange}
                      value={formData.firstName}
                      style={{ height: "75px" }}
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      First name<super>*</super>
                    </label>
                  </div>
                </div>
                <div className="w-1/2 form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                      type="text"
                      required
                      name="lastName"
                      id="unique-input"
                      style={{ height: "75px" }}
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      Last name<super>*</super>
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-3/4 w-sm-full mt-8">
                <span>Date of Birth:</span>
              </div>
              <div className="w-3/4 w-sm-full space-x-5 flex flex-row">
                <div className="w-full form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-3"
                      type="number"
                      name="day"
                      required
                      id="unique-input"
                      onChange={handleChange}
                      value={formData.day}
                      min="1"
                      max="31"
                      style={{ height: "75px" }}
                    />
                    <label className="text-xs ml-5" for="unique-input">
                      Day<super>*</super>
                    </label>
                  </div>
                </div>
                <div className="w-full form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-3"
                      type="number"
                      name="month"
                      required
                      id="unique-input"
                      onChange={handleChange}
                      value={formData.month}
                      style={{ height: "75px" }}
                      min="1"
                      max="12"
                    />
                    <label className="text-xs ml-5" for="unique-input">
                      Month<super>*</super>
                    </label>
                  </div>
                </div>
                <div className="w-full form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 font-normal text-xs  items-center justify-center rounded-xl w-full px-5 py-6 mt-3"
                      type="number"
                      name="year"
                      required
                      id="unique-input"
                      style={{ height: "75px" }}
                      onChange={handleChange}
                      value={formData.year}
                    />
                    <label className="text-xs ml-5" for="unique-input">
                      Year<super>*</super>
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-3/4 form_wrap w-sm-full ">
                <div className="relative input_wrap ">
                  <select
                    className=" appearance-none  text-xs flex bg-gray-100  mt-8 py-6 px-4 pr-5 rounded-xl w-full"
                    name="genderId"
                    required
                    id="unique-input"
                    value={formData.genderId}
                    onChange={handleChange}
                    style={{ height: "75px" }}
                  >
                    <option value=""></option>
                    {gender.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <SelectArrow />
                  </div>
                  {!formData.genderId && (
                    <label className="text-xs ml-2" for="unique-input">
                      Gender<super>*</super>
                    </label>
                  )}
                </div>
              </div>
              <div className="w-3/4 w-sm-full">
                <input
                  className="input-placeholder  flex flex-row bg-gray-100 font-normal text-xs  items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                  type="number"
                  name="nhsNumber"
                  style={{ height: "75px" }}
                  onChange={handleChange}
                  value={formData.nhsNumber}
                  placeholder="Your NHS number"
                />
              </div>
              <div className="w-3/4 form_wrap w-sm-full">
                <div className="input_wrap">
                  <input
                    className="input-placeholder flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    type="number"
                    name="phoneNumber"
                    required
                    id="unique-input"
                    style={{ height: "75px" }}
                    onChange={handleChange}
                    value={formData.phoneNumber}
                  />
                  <label className="text-xs " for="unique-input">
                    Mobile telephone number<super>*</super>
                  </label>
                </div>
              </div>
              <div className="w-3/4 w-sm-full flex flex-row space-x-2 items-center mt-8 mb-3">
                <input className=" w-5 h-5" type="checkbox" />{" "}
                <small className="leading-4  font-normal  text-xs ">
                  I agree to update, new features and marketing communication
                  from AB3 Medical.
                </small>
              </div>
            </>
          )}

          {step === 4 && (
            <>
              <div className="w-3/4 w-sm-full form_wrap ">
                <div className="input_wrap">
                  <input
                    className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    type="text"
                    name="address1"
                    required
                    style={{ height: "75px" }}
                    onChange={handleChange}
                    value={formData.address1}
                    id="unique-input"
                  />
                  <label className="text-xs " for="unique-input">
                    Street address<super>*</super>
                  </label>
                </div>
              </div>

              <div className="w-3/4 w-sm-full form_wrap ">
                <input
                  className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                  type="text"
                  name="address2"
                  style={{ height: "75px" }}
                  onChange={handleChange}
                  value={formData.address2}
                  placeholder="Additional line"
                />
              </div>

              <div className="w-3/4 w-sm-full">
                <div className="input_wrap">
                  <input
                    className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    type="text"
                    name="postcode"
                    required
                    style={{ height: "75px" }}
                    onChange={handleChange}
                    value={formData.postcode}
                    id="unique-input"
                  />
                  <label className="text-xs " for="unique-input">
                    Postcode<super>*</super>
                  </label>
                </div>
              </div>
              <div className="w-3/4 w-sm-full flex flex-row space-x-5">
                <div className="w-1/2 form_wrap mt-8">
                  <div className="relative input_wrap">
                    <input
                      className="input-placeholder  flex flex-row font-normal text-xs  bg-gray-100 items-center justify-center rounded-xl w-full px-5 py-6"
                      type="text"
                      name="city"
                      required
                      onChange={handleChange}
                      value={formData.city}
                      style={{ height: "75px" }}
                      id="unique-input"
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      Town / City<super>*</super>
                    </label>
                  </div>
                </div>
                <div className="w-1/2 form_wrap mt-8">
                  <div className="relative input_wrap">
                    <input
                      className="input-placeholder  flex flex-row font-normal text-xs  bg-gray-100 items-center justify-center rounded-xl w-full px-5 py-6"
                      type="text"
                      name="region"
                      required
                      style={{ height: "75px" }}
                      onChange={handleChange}
                      value={formData.region}
                      id="unique-input"
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      Region<super>*</super>
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-3/4  w-sm-full form_wrap">
                <div className="relative input_wrap">
                  <select
                    className="appearance-none  text-xs flex bg-gray-100  mt-8 py-6 px-4 pr-5 rounded-xl w-full"
                    name="countryId"
                    required
                    id="unique-input"
                    value={formData.countryId}
                    style={{ height: "75px" }}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {country.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <SelectArrow />
                  </div>
                  {!formData.countryId && (
                    <label className="text-xs ml-2" for="unique-input">
                      Country<super>*</super>
                    </label>
                  )}
                </div>
              </div>
            </>
          )}

          {step === 5 && (
            <>
              <div className="w-3/4 w-sm-full space-x-5 flex flex-row">
                <input
                  className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                  type="text"
                  name="gpSurgery"
                  style={{ height: "75px" }}
                  onChange={handleChange}
                  value={formData.gpSurgery}
                  placeholder="Name of Surgery"
                />
              </div>

              <div className="w-3/4 w-sm-full flex flex-row space-x-5">
                <div className="w-1/2 mt-8">
                  <div className="relative">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6"
                      type="text"
                      name="gpCity"
                      style={{ height: "75px" }}
                      onChange={handleChange}
                      value={formData.gpCity}
                      placeholder="Town / City"
                    />
                    {/* <select
                    placeholder="Town/City"
                    value={formData.gpCity}
                    className="appearance-none flex bg-gray-100 text-gray-400 py-6 px-4 pr-5 rounded-xl w-full"
                    name="city"
                  >
                    <option value="">Town/City</option>
                    <option value="">Lahore</option>
                    <option value="">Toba Tek Singh</option>
                  </select> */}

                    {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <SelectArrow />
                    </div> */}
                  </div>
                </div>
                <div className="w-1/2 mt-8">
                  <div className="relative">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6"
                      type="text"
                      name="gpRegion"
                      style={{ height: "75px" }}
                      onChange={handleChange}
                      value={formData.gpRegion}
                      placeholder="Region"
                    />
                    {/* <select
                    placeholder="Gender"
                    value={formData.gpRegion}
                    className="appearance-none flex bg-gray-100 text-gray-400 py-6 px-4 pr-5 rounded-xl w-full"
                    name="region"
                  >
                    <option value="">Region</option>
                    <option value="">Punjab</option>
                    <option value="">Sindh</option>
                  </select> */}

                    {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <SelectArrow />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-3/4 w-sm-full">
                <div className="relative">
                  <select
                    placeholder="Country"
                    className="appearance-none  text-xs flex bg-gray-100  mt-8 py-6 px-4 pr-5 rounded-xl w-full"
                    name="gpCountryId"
                    value={formData.gpCountryId}
                    style={{ height: "75px" }}
                    onChange={handleChange}
                  >
                    <option value="">Country</option>
                    {country.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <SelectArrow />
                  </div>
                </div>
              </div>
              {/* <div className="w-3/4 w-sm-full flex flex-row space-x-2 mt-8 mb-3">
                <input className="w-6" type="checkbox" />{" "}
                <small className="leading-4">
                  Do you give AB3 Medical, Service Providers or Doctors
                  permission to contact your GP if needed.
                </small>
              </div> */}
            </>
          )}

          {step === 6 && (
            <>
              <div className="w-3/4 w-sm-full space-x-5 flex flex-row">
                <div className="w-1/2 form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                      type="text"
                      required
                      id="unique-input"
                      name="emergencyContactFirstName"
                      onChange={handleChange}
                      style={{ height: "75px" }}
                      value={formData.emergencyContactFirstName}
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      First name<super>*</super>
                    </label>
                  </div>
                </div>
                <div className="w-1/2 form_wrap">
                  <div className="input_wrap">
                    <input
                      className="input-placeholder  flex flex-row bg-gray-100  text-xs font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                      type="text"
                      required
                      id="unique-input"
                      name="emergencyContactLastName"
                      onChange={handleChange}
                      style={{ height: "75px" }}
                      value={formData.emergencyContactLastName}
                    />
                    <label className="text-xs ml-3" for="unique-input">
                      Last name<super>*</super>
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-3/4 w-sm-full mt-8 form_wrap">
                <div className="relative input_wrap">
                  <select
                    value={formData.nextOfKin}
                    style={{ height: "75px" }}
                    required
                    id="unique-input"
                    className="appearance-none text-xs flex bg-gray-100  py-6 px-4 pr-5 rounded-xl w-full"
                    name="nextOfKin"
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="Partner">Partner</option>
                    <option value="Parent">Parent</option>
                    <option value="Carer">Carer</option>
                    <option value="Other">Other</option>
                  </select>

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <SelectArrow />
                  </div>
                  {!formData.nextOfKin && (
                    <label className="text-xs ml-2" for="unique-input">
                      Relationship to you<super>*</super>
                    </label>
                  )}
                </div>
              </div>
              <div className="w-3/4 form_wrap w-sm-full">
                <div className="input_wrap">
                  <input
                    className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                    type="number"
                    required
                    id="unique-input"
                    name="emergencyContactPhoneNumber"
                    onChange={handleChange}
                    style={{ height: "75px" }}
                    value={formData.emergencyContactPhoneNumber}
                  />
                  <label className="text-xs " for="unique-input">
                    Mobile telephone number<super>*</super>
                  </label>
                </div>
              </div>
              <div className="w-3/4 w-sm-full">
                <input
                  className="input-placeholder  flex flex-row bg-gray-100 text-xs  font-normal items-center justify-center rounded-xl w-full px-5 py-6 mt-8"
                  type="email"
                  name="emergencyContactEmail"
                  onChange={handleChange}
                  style={{ height: "75px" }}
                  value={formData.emergencyContactEmail}
                  placeholder="Email address"
                />
              </div>
              <div className="w-3/4 w-sm-full flex flex-row space-x-2 items-center mt-8 mb-3">
                <input className="w-7 h-5" type="checkbox" />{" "}
                <small className="leading-4 font-normal  text-xs ">
                  Has your emergency contact agreed for AB3 Medical, Service
                  Providers, Clubs and Doctors to contact them if required?
                </small>
              </div>
            </>
          )}

          {step === 7 && (
            <div className="flex flex-row items-center justify-center fit-content w-3/4 w-sm-full p-3 mt-8">
              <div className="relative  w-44 h-44 rounded-full">
                <img
                  onClick={() => profileImageRef.current.click()}
                  src={profileImagePlaceholder}
                  alt=""
                  className="w-full h-full object-cover  rounded-full"
                />
                <div className=" absolute bottom-2 right-2">
                  <div
                    onClick={() => profileImageRef.current.click()}
                    className=" w-10 h-10 text-white bg-blue-700 flex text-center items-center justify-center rounded-full"
                  >
                    <button className="text-3xl">+</button>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref={profileImageRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                accept="image/*"
              />
            </div>
          )}
          {step === 8 && (
            <>
              <div
                className={`flex flex-row ${
                  twoFactorType == "byemail"
                    ? "bg-blue-500 border-4 border-blue-500 text-white"
                    : "bg-gray-100 border-4 border-gray-100 text-black"
                } items-center rounded-2xl w-3/4 w-sm-full py-3 px-5 mt-8`}
                onClick={() => setTwoFactorType("byemail")}
              >
                <div className="">
                  <Message
                    color={twoFactorType == "byemail" ? white : black}
                    bgcolor={twoFactorType == "byemail" ? blue : gray}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="xl:w-10/12 lg:w-full">
                    <span className="font-semibold text-lg uppercase mx-4">
                      Send codes to my email
                    </span>
                    <p className="text-sm mx-4">
                      We will send codes to your email: {formData.email} to
                      confirm your log in.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-row ${
                  twoFactorType == "byphone"
                    ? "bg-blue-500 border-4 border-blue-500 text-white"
                    : "bg-gray-100 border-4 border-gray-100 text-black"
                } items-center rounded-2xl w-3/4 w-sm-full py-3 px-5 mt-8`}
                onClick={() => setTwoFactorType("byphone")}
              >
                <div className="">
                  <Mobile
                    color={twoFactorType == "byphone" ? white : black}
                    bgcolor={twoFactorType == "byphone" ? blue : gray}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="xl:w-10/12 lg:w-full">
                    <span className="font-semibold text-lg uppercase mx-4">
                      Send codes to my phone
                    </span>
                    <p className="text-sm ml-4 mr-10">
                      We will send codes to your phone: {formData.phoneNumber}{" "}
                      to confirm your log in.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {step !== 1 &&
            step !== 2 &&
            step !== 3 &&
            step !== 4 &&
            step !== 5 &&
            step !== 6 &&
            step !== 7 &&
            step !== 8 && (
              <>
                <div className="flex-shrink flex flex-col max-w-full justify-center items-center px-4 w-full lg:w-full mt-5">
                  <div className="w-3/4 w-sm-full">
                    <input
                      className="flex flex-row bg-gray-100 items-center justify-center rounded-xl w-full px-3 py-6 mt-8"
                      type="text"
                      placeholder="Club name*"
                    />
                  </div>
                  <div className="w-3/4 w-sm-full">
                    <input
                      className="flex flex-row bg-gray-100 items-center justify-center rounded-xl w-full px-3 py-6 mt-8"
                      type="text"
                      placeholder="Club contact full name*"
                    />
                  </div>
                  <div className="w-3/4 w-sm-full">
                    <input
                      className="flex flex-row bg-gray-100 items-center justify-center rounded-xl w-full px-3 py-6 mt-8"
                      type="number"
                      placeholder="Club contact number*"
                    />
                  </div>
                  <div className="w-3/4 w-sm-full mt-3">
                    <span>
                      Sports{" "}
                      <small className="text-pinkred font-semibold text-lg">
                        *
                      </small>{" "}
                    </span>
                  </div>
                  <div className="xl:w-full 2xl:w-3/4 w-sm-full mt-3">
                    {/* <Carousel responsive={responsive} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}> */}
                    <Carousel
                      ref={carouselRef}
                      responsive={responsive}
                      customRightArrow={<CustomRightArrow />}
                      customLeftArrow={<CustomLeftArrow />}
                    >
                      <div className="flex slide flex-col items-center space-y-2">
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Football />
                            <span>Football</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Basketball</span>
                          </button>
                        </div>
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Football />
                            <span>Football</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Basketball</span>
                          </button>
                        </div>
                      </div>
                      <div className="flex slide flex-col items-center space-y-2">
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Basketball />
                            <span>Basketball</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Football</span>
                          </button>
                        </div>
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Basketball />
                            <span>Basketball</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Football</span>
                          </button>
                        </div>
                      </div>
                      <div className="flex slide flex-col items-center space-y-2">
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Football />
                            <span>Football</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Basketball</span>
                          </button>
                        </div>
                        <div className="flex items-center space-x-3">
                          <button className="bg-pinkred text-white rounded-xl space-x-3 px-4 py-3 flex items-center justify-center">
                            <Football />
                            <span>Football</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-8 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Golf />
                            <span>Golf</span>
                          </button>
                          <button className="rounded-xl space-x-3 px-4 py-3 bg-white text-pinkred border-pinkred flex items-center justify-center">
                            <Pinkball />
                            <span>Basketball</span>
                          </button>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                  <div className="w-3/4 w-sm-full">
                    <input
                      className="flex flex-row bg-gray-100 items-center justify-center rounded-xl w-full px-3 py-6 mt-8"
                      type="text"
                      name="good"
                      placeholder="League"
                    />
                  </div>
                </div>
              </>
            )}

          <button
            disabled={isLoading}
            className="text-white font-semibold text-base flex flex-row bg-blue-700 items-center justify-center rounded-2xl w-3/4 w-sm-full px-3 py-6 mb-5 mt-8"
            onClick={updateStep}
          >
            {buttonText[step - 1].text}
          </button>
        </div>
      </div>
    </>
  );
};

export default LeftScreen;
