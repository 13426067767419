import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { AsyncPaginate } from 'react-select-async-paginate';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
  ButtonGroup,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { getAllStatuses } from '../../../api/statuses';
import { formatFormDate } from '../../../utils/formatFormDate';
import { useEditDoctor } from './hooks/useEditDoctor';
import { getDoctorById } from 'api/doctors';
import { trimFormData } from "../../../utils/trimFormData";
import { getTeams } from "../../../api/teams";
import { getMedicTypes } from "../../../api/medicTypes";

Modal.setAppElement('#root');

export const EditDoctor = ({ teamId }) => {
  let { doctorId } = useParams();

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "Doctor" for a particular Doctor by invoking "getDoctorById" hook
  const { data: doctorData, isLoading } = useQuery(['doctor', doctorId], () =>
    getDoctorById(doctorId)
  );

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  // Receiving entity "teams" by invoking "getTeams" hook
  const { data: teamsData = [] } = useQuery('team', getTeams);
  const teamsOptions = [];
  if (!teamsData) {
    console.log('TeamsData is empty');
  }
  for (let i = 0; i < teamsData.length; i++) {
    teamsOptions.push({
      value: teamsData[i],
      label: teamsData[i].name,
    });
  }

    // Receiving entity "medicTypesData" by invoking "getMedicTypes" hook
    const { data: medicTypesData = [] } = useQuery('medicType', getMedicTypes);
    const medicTypesOptions = [];
    if (!medicTypesData) {
        console.log('medicTypesData is empty');
    }
    for (let i = 0; i < medicTypesData.length; i++) {
        medicTypesOptions.push({
            value: medicTypesData[i],
            label: medicTypesData[i].name,
        });
    }

  const { mutateAsync } = useEditDoctor();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object)
    // before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string,
    // object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      const editedTeamsValue=[];
      let valueToAdd = undefined;
      if (undefined === newData.additionalTeamIds) {
        setError('additionalTeamIds', { type: 'custom', message: 'Must have at least 2 teams.' });
      }
      if (undefined !== newData.additionalTeamIds) {
        for (let i=0; i<newData.additionalTeamIds.length; i++) {
          valueToAdd = newData.additionalTeamIds[i].value.id || newData.additionalTeamIds[i].value;
          editedTeamsValue.push(valueToAdd);
        }
      }

      await mutateAsync({
        doctorId,
        firstName: newData.doctorFirstName,
        lastName: newData.doctorSecondName,
        email: newData.doctorEmail,
        statusId: parseInt(newData.status?.id) || parseInt(newData.status),
        phoneNumber: phoneValue,
        gmcNumber: newData.GMCdoctor,
        isInvited: isInvited,
        additionalTeamIds: editedTeamsValue,
        medicTypeId: parseInt(newData.medicType?.id) || parseInt(newData.medicType),
      });
      push(`/teams/${teamId}/team-medics`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isInvited, setIsInvited] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();

  const [phoneValue, setPhoneValue] = useState();

  //const [medicType, setMedicType] = useState();

  //const [teamIds, setTeamIds] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async (arg) => {
    setIsInvited(arg);
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    setPhoneValue(doctorData?.user?.phone_number);
  }, [doctorData]);

/*  useEffect(() => {
    setTeamIds(doctorData?.user?.additionalTeamIds);
  }, [doctorData]);*/

  // Setting default values to react-hook-form component, depending on receiving data for this entity
  useEffect(() => {
    if (doctorData) {
      const testArray = doctorData.user.teams;
      const teamsValue = [];
      for(let i=0;i<testArray.length;i++){
        teamsValue.push({
          value: testArray[i].id,
          label: testArray[i].name,
        });
      }

        const originalMedicType = doctorData.medicType.id;
        const medicTypeValue = [];
        for(let i=0;i<originalMedicType.length;i++){
            medicTypeValue.push({
                value: originalMedicType[i].id,
                label: originalMedicType[i].name,
            });
        }

      [
        { name: 'doctorFirstName', value: doctorData.user.first_name },
        { name: 'doctorSecondName', value: doctorData.user.last_name },
        { name: 'GMCdoctor', value: doctorData.gms_number },
        { name: 'doctorPhone', value: doctorData.user.phone_number },
        {
          name: 'additionalTeamIds',
          value: teamsValue,
        },
        {
          name: 'status',
          value: { value: doctorData.status.id, label: doctorData.status.name },
        },
        { name: 'doctorEmail', value: doctorData.user.email },
        { name: 'medicType', value: { value: doctorData.medicType.id, label: doctorData.medicType.name }, },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [doctorData, isLoading]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit Team Medic
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/team-medics`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset small>
            <FormItem>
              <Input
                id='doctorFirstName'
                name='doctorFirstName'
                type='text'
                {...register('doctorFirstName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='Medic First Name'
                error={errors.name}
                className={`${errors['doctorFirstName'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='doctorFirstName'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id='doctorSecondName'
                name='doctorSecondName'
                type='text'
                {...register('doctorSecondName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='Medic Second Name'
                error={errors.name}
                className={`${errors['doctorSecondName'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='doctorSecondName'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id='GMCdoctor'
                name='GMCdoctor'
                type='text'
                {...register('GMCdoctor', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='GMC or Registration Number'
                error={errors.name}
                className={`${errors['GMCdoctor'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='GMCdoctor' as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name='status'
                control={control}
                rules={{ required: 'This is required' }}
                render={({ field }) => (
                  <>
                    <label htmlFor='status'>Status</label>
                    <AsyncPaginate
                      {...field}
                      inputId='status'
                      isClearable
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['status'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, statusesOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name='status' as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name='additionalTeamIds'
                control={control}
                {...register('additionalTeamIds', {
                  required: 'This is required',
                })}
                render={({ field }) => (
                  <>
                    <label htmlFor='additionalTeamIds'>Teams</label>
                    <AsyncPaginate
                      isMulti
                      closeMenuOnSelect={false}
                      {...field}
                      isClearable
                      placeholder={'Add Teams'}
                      className={`react-select ${
                        errors['additionalTeamIds'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, teamsOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name='additionalTeamIds' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                id='doctorEmail'
                name='doctorEmail'
                type='email'
                {...register('doctorEmail', {
                  required: 'This is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                placeholder='yourname@example.com'
                label='Email Address'
                error={errors.name}
                className={`${errors['doctorEmail'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='doctorEmail'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <label htmlFor='doctorPhone'>Contact Telephone Number</label>
              <PhoneInput
                id='doctorPhone'
                placeholder='Enter here'
                name='doctorPhone'
                {...register('doctorPhone', {
                  required: 'This is required',
                })}
                value={phoneValue}
                onChange={setPhoneValue}
                className={`${errors['doctorPhone'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='doctorPhone'
                as={InputError}
              />
            </FormItem>
            <FormItem>
                <Controller
                    name='medicType'
                    control={control}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                        <>
                            <label htmlFor='medicType'>Medic Type</label>
                            <AsyncPaginate
                                {...field}
                                inputId='medicType'
                                isClearable
                                placeholder={'Medic Type'}
                                className={`react-select ${
                                    errors['medicType'] ? 'error' : ''
                                }`}
                                loadOptions={(search, prevOptions) =>
                                    loadOptions(search, prevOptions, medicTypesOptions)
                                }
                            />
                        </>
                    )}
                />
                <ErrorMessage errors={errors} name='medicType' as={InputError} />
            </FormItem>
          </Fieldset>
          <ButtonGroup>
            <Button
              type='button'
              onClick={() => onPreSubmit(false)}
              style={{ marginRight: '16px' }}
            >
              <Icon name='check' />
              Submit Medic
            </Button>
            <Button
              type='button'
              onClick={() => onPreSubmit(true)}
              variant={'secondary'}
            >
              Edit Team Medic and Send Link
            </Button>
          </ButtonGroup>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to edit this medic?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)}>Confirm</Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
