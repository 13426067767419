import styled from "styled-components";
import { Icon } from "../../atoms/Icon";

export const StyledRoot = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledLogo = styled(Icon)`
  margin-bottom: 20px;
`
