const device = {
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
};

// eslint-disable-next-line
export const devices = {
  maxMobile: `(max-width: ${device.tablet - 1}px)`,
  tablet: `(min-width: ${device.tablet}px)`,
  maxTablet: `(max-width: ${device.laptop - 1}px)`,
  laptop: `(min-width: ${device.laptop}px)`,
  desktop: `(min-width: ${device.desktop}px)`,
};
