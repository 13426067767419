import axiosWrapper from './axiosInterceptor';

export const getReferral = async (teamId, toolId) => {
    try {
        const response = await axiosWrapper({
            method: 'GET',
            url: `/external-referrals/team/${teamId}/tool/${toolId}`,
            responseType: 'json',
            withCredentials: true,
        });
        return response.data;
    } catch (err) {
        return Promise.reject(err.response);
    }
};

export const getReferrals = async (teamId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/team/${teamId}?offset=0&limit=0`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};


