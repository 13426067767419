import React from 'react';
import {
  Component,
  Label,
  CheckboxContainer,
  StyledCheckbox,
  Ic,
} from './styles';

export const Checkbox = React.forwardRef(
  ({ className, name, type, value, label, onChange, checked, ...props }, ref) => {
    return (
      <Component {...props}>
        <Label>
          <CheckboxContainer className={className}>
            <input
              ref={ref}
              type={type}
              name={name}
              value={value ?? ''}
              onChange={onChange}
              checked={checked}
              {...props}
            />
            <StyledCheckbox>
              <Ic viewBox='0 0 24 24'>
                <polyline points='20 6 9 17 4 12' />
              </Ic>
            </StyledCheckbox>
          </CheckboxContainer>
          <span>{label}</span>
        </Label>
      </Component>
    );
  }
);
