import { Link } from "react-router-dom";
const RightScreen = () => {
  return (
    <div className="d-sm-none md:block lg:block flex bg-image xxl:fixed xxl:left-1/2 xl:fixed xl:left-1/2 lg:fixed lg:left-1/2 sm:relative sm:left-0  flex-col sm:w-full xsm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 items-center min-h-screen">
      <div class="overlay relative px-3">
        <div className="flex relative box-border h-24 p-8 w-full text-white justify-end items-center">
          <span className="space-x-3 text-lg font-medium">
            Already have an account?
          </span>
          <a className="flex space-x-3 items-center ml-2" href="">
            <span className="font-bold space-x-3">
              <u>
                <Link to="/login">Log in now</Link>
              </u>
            </span>
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.75 1.5L9.25 10L0.75 18.5"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </a>
        </div>
        <div className="flex absolute top-0 flex-shrink justify-center box-border p-8 w-full h-full items-center mt-32">
          <h2 className="font-bold text-4xl text-white text-center mt-48">
            Effortlessly manage your <br /> medical records with AB3 <br />{" "}
            Medical.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default RightScreen;
