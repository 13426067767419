import React, { useState, useEffect, useContext } from 'react';
import { Button } from '../../atoms/Button';
import { NavItem } from '../../atoms/NavItem';
import { Link, useHistory } from 'react-router-dom';
import { useLogout } from '../Login/hooks/useLogout';
import { User, NavList, NavListItem, NavRight } from '../Layout';
import { Dropdown } from '../Dropdown';
import { StyledBurger, Ul } from './styles';
import { Notifications } from '../Notifications';
import { Search } from '../Search';
import { UserContext } from "../../../context";
import { ADMIN, EXTERNAL } from "../../../utils/constants";

export const Burger = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [user] = useContext(UserContext);

  const { mutateAsync } = useLogout();

  const handleLogOut = async () => {
    await mutateAsync(history);
    history.push('/login');
  };

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    document.body.style.height = open ? '100%' : 'auto';
    document.body.style.width = open ? '100%' : 'auto';
    document.body.style.position = open ? 'fixed' : 'static';
  }, [open]);

  const handleResize = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Ul open={open}>
        <NavList>
          {user?.role !== EXTERNAL && (
            <NavListItem>
              <NavItem exact to='/teams' onClick={() => setOpen(false)}>
                Teams
              </NavItem>
            </NavListItem>
          )}
          {user?.role !== EXTERNAL && (
            <NavListItem>
              <NavItem to='/players' onClick={() => setOpen(false)}>
                Players
              </NavItem>
            </NavListItem>
          )}
          {user?.role === ADMIN && (
            <NavListItem>
              <NavItem to='/external' onClick={() => setOpen(false)}>
                External Companies
              </NavItem>
            </NavListItem>
          )}
          {user?.role === ADMIN && (
              <NavListItem>
                <NavItem to='/timeline' onClick={() => setOpen(false)}>
                  Timeline
                </NavItem>
              </NavListItem>
          )}
          {user?.role !== EXTERNAL && (
            <NavListItem>
              <NavItem to='/configuration' onClick={() => setOpen(false)}>
                Configuration
              </NavItem>
            </NavListItem>
          )}
          {user?.role === EXTERNAL && (
            <NavListItem>
              <NavItem to='/referrals' onClick={() => setOpen(false)}>
                Referrals
              </NavItem>
            </NavListItem>
          )}
          {user?.role === EXTERNAL && (
              <NavListItem>
                <NavItem to='/archived' onClick={() => setOpen(false)}>
                  Archive
                </NavItem>
              </NavListItem>
          )}
        </NavList>
        <NavRight>
          {user?.role !== EXTERNAL && (
            <Search />
          )}
          <User>
            <Notifications />
            <Dropdown>
              <Button as={Link} to={'/account'}>
                Manage your AB3 account
              </Button>
              <Button onClick={handleLogOut} variant={'secondary'}>
                Sign Out
              </Button>
            </Dropdown>
          </User>
        </NavRight>
      </Ul>
    </>
  );
};
