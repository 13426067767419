import styled, { css } from 'styled-components';
import { devices } from '../../../theme/devices';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';

export const NavComponent = styled.nav`
  display: flex;
  align-items: center;
  background-color: ${themeMain.colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  min-height: 64px;
`;

export const ContainerComponent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media ${devices.tablet} {
    padding: 0 30px;
    width: 768px;
    align-items: center;
  }

  @media ${devices.laptop} {
    width: 960px;
  }

  @media ${devices.desktop} {
    width: 1276px;
  }
`;

export const BreadcrumbsComponent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media ${devices.tablet} {
    padding: 0 30px;
    width: 768px;
    align-items: center;
  }

  @media ${devices.laptop} {
    width: 960px;
  }

  @media ${devices.desktop} {
    width: 1500px;
  }
`;

export const LogoComponent = styled.div`
  position: relative;
  z-index: 11;
  margin-right: 40px;
`;

export const SearchComponent = styled.div``;

export const UserComponent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  > button {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    height: auto;
    min-height: auto;
    border-radius: 0;
    width: 100%;
    border: 0;
    cursor: pointer;

    svg {
      margin: 0;
    }
  }
`;

export const NavRightComponent = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  margin: 0 auto;

  @media ${devices.laptop} {
    margin: 0 0 0 auto;
  }

  ${SearchComponent} {
    @media ${devices.laptop} {
      margin: 0 24px 0 0;
      width: auto;
    }
  }
`;

export const NavListComponent = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding: 0;

  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

export const NavListItemComponent = styled.li``;

export const BreadcrumbsWrapperComponent = styled.div`
  border-bottom: 1px solid ${themeMain.colors.gray[600]};
  box-sizing: border-box;
  background: ${themeMain.colors.lightBlue};
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 45px;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      &:last-child {
        svg {
          display: none;
        }
      }

      span {
        white-space: nowrap;
      }
    }

    a {
      font: 500 14px/1 ${fonts.monserrat};
      color: ${themeMain.colors.boldText};
      font-weight: bold;
      font-size: 18px;
      text-decoration: none;
    }

    svg {
      margin: 0 16px;
    }
  }
`;

export const ContentWrapperComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableWrapperComponent = styled.div`
  position: relative;
  z-index: 8;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 40px;

  table {
    border: 1px solid ${themeMain.colors.border};
    box-sizing: border-box;
    border-radius: 3px;
    border-spacing: 0;
    text-align: left;
    width: 100%;

    thead {
      background-color: ${themeMain.colors.gray[600]};
      border: none;
      border-collapse: collapse;
      border-spacing: 0;
      height: 39px;

      th {
        padding: 12px 24px;
        font: 300 12px/14px ${fonts.monserrat};
        color: ${themeMain.colors.darkBlue};
        background-color: ${themeMain.colors.lightBlue};
        font-weight: bold;
      }
    }

    tbody {
      tr {
        td {
          padding: 16px 24px;
          font: 300 16px/23px ${fonts.monserrat};
          border-bottom: 1px solid #e0e0e0;
          white-space: nowrap;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: ${themeMain.colors.darkBlue};
          font-size: 12px;

          &:nth-child(n + 6) {
            padding: 12px;
          }
        }

        td.active {
          font-weight: bold;
        }

        &:first-child {
          td {
            border-top: 1px solid #e0e0e0;
          }
        }

        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
  }
`;

export const TableHeaderComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  > h1 {
    margin: 0;
  }

  > button,
  a {
    margin-left: auto;
  }
`;

export const TableSubheaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableButtonGroupComponent = styled.div`
  display: flex;

  > a,
  button {
    & + button,
    & ~ a {
      margin-left: 12px;
    }
  }
`;

export const SubheaderWrapperComponent = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:last-child {
    width: 100%;
  }

  > a {
    padding: 0 0 11px 0;
    margin-left: auto;
  }
`;

export const PaginationWrapperComponent = styled.div`
  border-top: 1px solid #e0e0e0;

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:first-child {
        margin-right: auto;

        svg {
          margin-right: 14px;
        }
      }

      &:last-child {
        margin-left: auto;

        svg {
          margin-left: 14px;
        }
      }

      &.active {
        box-shadow: 0px 2px 0 0 rgb(0, 147, 211) inset;
        transform: translateY(-1px);
        padding-top: 1px;
        a {
          color: ${themeMain.colors.blue};
        }
      }

      &.disabled {
        opacity: 0;
        visibility: hidden;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        min-width: 40px;
        cursor: pointer;
        font: 500 14px/16px ${fonts.monserrat};
        color: ${themeMain.colors.gray[200]};

        &:hover {
          color: ${themeMain.colors.blue};

          path {
            fill: ${themeMain.colors.blue};
          }
        }
      }
    }
  }
`;

export const FormWrapperComponent = styled.div`
  form {
    .react-select {
      &.error {
        [class*='-control'] {
          border-color: ${themeMain.colors.red};
        }
      }
      [class*='-control'] {
        border-radius: 6px;

        &:hover,
        &:focus {
          border-color: ${themeMain.colors.blue};
          box-shadow: none;
        }
      }
      [class*='-menu'] {
        margin: 1px 0 0 0;
        padding: 0;
        border: 1px solid #e0e0e0;
      }

      [class*='-option'] {
        background-color: transparent;
        color: ${themeMain.colors.gray[100]};

        &:hover,
        &:focus {
          background-color: #deebff;
          color: ${themeMain.colors.gray[100]};
        }
      }

      > div {
        box-shadow: none;
      }
    }

    button[type='submit'] {
      margin-top: 40px;
    }
  }
`;

export const FormItemComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 392px;
  margin-bottom: 16px;

  ${(props) =>
    props.small &&
    css`
      @media ${devices.laptop} {
        max-width: 186px;
      }
    `}

  label {
    display: block;
    font: 500 14px/16px ${fonts.monserrat};
    color: ${themeMain.colors.gray[700]};
    margin-bottom: 3px;
  }

  input {
    &.PhoneInputInput {
      outline: none;
      height: 38px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      padding: 9px 13px 9px 13px;
      background: #fff;
      border: 1px solid #d1d5db;
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      border-radius: 6px;
      width: 100%;

      &:focus,
      &::focus-visible {
        border-color: ${themeMain.colors.blue} !important;
      }
    }
  }
`;

export const InputErrorComponent = styled.p`
  margin: 8px 0 0;
  font: 400 14px/16px ${fonts.monserrat};
  color: ${themeMain.colors.red};
`;

export const FieldsetComponent = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;

  > div {
    @media ${devices.laptop} {
      flex: 0 0 33.333%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  & + fieldset {
    padding-top: 30px;
  }

  ${(props) =>
    props.small &&
    css`
      max-width: 804px;

      > div {
        @media ${devices.laptop} {
          flex: 0 0 50%;
        }
      }
    `}
`;

export const LoginFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: 0 auto;

  > div {
    text-align: center;
    width: 100%;
  }

  h2 {
    padding: 24px 0 0;
    margin: 0 0 28px;
  }

  button[type='submit'] {
    justify-content: center;
    width: 100%;
  }

  input {
    &.first {
      border-radius: 6px 6px 0 0;
    }
    &.second {
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const ForgotFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  margin: 0 auto;

  > div {
    text-align: center;
    width: 100%;
  }

  h2 {
    padding: 24px 0 0;
    margin: 0 0 24px;
  }

  button[type='submit'] {
    justify-content: center;
    width: 100%;
  }

  input {
    margin: 24px 0;
  }
`;

export const PasswordFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  margin: 0 auto;

  > div {
    text-align: center;
    width: 100%;
  }

  h2 {
    padding: 24px 0 0;
    margin: 0 0 24px;
  }

  p {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  button[type='submit'] {
    margin-top: 24px;
    justify-content: center;
    width: 100%;
  }

  input {
    &.first {
      border-radius: 6px 6px 0 0;
    }
    &.second {
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const CheckboxGroupComponent = styled.fieldset``;

export const PhotosWrapperComponent = styled.div`
  padding-top: 8px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > div + div {
    padding-top: 10px;
  }

  button {
    margin-left: 10px;

    svg {
      margin-right: 0;
    }
  }

  img {
    margin-left: 8px;
    object-fit: contain;
    width: auto;
    height: 150px;
  }
`;

export const ButtonGroupComponent = styled.div`
  display: flex;
  padding-top: 24px;

  > button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
