import axiosWrapper from './axiosInterceptor';

export const getTeamDoctors = async (teamId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/teams/${teamId}/doctors`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getDoctorById = async (doctorId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/doctors/${doctorId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addDoctor = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/doctors/team/${data.teamId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editDoctor = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/doctors/${data.doctorId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deleteDoctor = async ({ doctorId }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/doctors/${doctorId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
