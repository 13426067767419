import axios from 'axios';
import { getCookie, deleteCookie } from '../utils/cookie';
import {
  AUTHENTICATION_COOKIE,
  REFRESH_COOKIE,
  USER_COOKIE,
} from '../utils/constants';
import { API_ENDPOINT } from './_config';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const api = axios.create({
  baseURL: API_ENDPOINT
});

api.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${getCookie(AUTHENTICATION_COOKIE)}`,
      // Refresh: getCookie(REFRESH_COOKIE),
    },
  };
});

const checkIsUrlTokenRefreshForbidden = (url) => {
  const urlArr = [
    '/auth/refresh',
    '/auth/log-in',
    '/auth/log-in-confirm',
    '/auth/log-out',
    '/auth/invitation',
    '/auth/forgot-password',
    '/auth/password-reset',
    '/auth/confirm',
  ];

  return urlArr.find((str) => {
    return !!url.includes(str);
  });
};

const refreshToken = async () => {
  const fpPromise = FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();

  try {
    const response = await api.post(
      `/auth/refresh`,
      {
        fingerprint: result.visitorId,
        userAgent: 'web-application',
        isRememberMe: localStorage.getItem('rememberMe'),
      },
      {
        headers: {
          Refresh: getCookie(REFRESH_COOKIE),
        },
      }
    );

    response.data.cookies.forEach((cookie) => {
      document.cookie = cookie;
    });
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createAxiosResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        !error ||
        error?.response.status !== 401 ||
        checkIsUrlTokenRefreshForbidden(error.response.config.url)
      ) {
        return Promise.reject(error);
      }
      api.interceptors.response.eject(interceptor);

      try {
        await refreshToken();
        return api(error.response.config);
      } catch (err) {
        deleteCookie(REFRESH_COOKIE);
        deleteCookie(USER_COOKIE);
        deleteCookie(AUTHENTICATION_COOKIE);
        window.location.href = '/';
        return Promise.reject(error);
      } finally {
        createAxiosResponseInterceptor();
      }
    }
  );
};

createAxiosResponseInterceptor();

export default api;
