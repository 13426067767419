import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import Modal from 'react-modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
  ButtonGroup,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { useAddForm } from './hooks/useAddForm';
import { formatFormDate } from '../../../utils/formatFormDate';
import { trimFormData } from "../../../utils/trimFormData";
import { getAllStatuses } from "../../../api/statuses";

Modal.setAppElement('#root');

export const AddForm = () => {
  const { teamId } = useParams();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "statuses" by invoking "getAllStatuses" hook, used for "active"
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const activeOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    activeOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  const { mutateAsync } = useAddForm();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data (empty string, object as values etc). After it's executed use new object as a data to be sent
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        teamId: teamId,
        url: newData.url,
        title: newData.title,
        description: newData.description,
        active: parseInt(newData.active?.id) || parseInt(newData.active),
      });
      history.push(`/teams/${teamId}/forms`);

      // goBack cannot be just like this, or else the redirect happens straight away when the page loads
      // history.goBack();
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [submitMessage, setSubmitMessage] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async (arg) => {
    const result = await trigger();
    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Add Form
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/forms`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <FormItem>
                <Input
                  id='url'
                  name='url'
                  type='text'
                  {...register('url', {
                    required: 'This is required',
                  })}
                  placeholder='https://'
                  label='Form URL'
                  error={errors.name}
                  className={`${errors['url'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='url'
                  as={InputError}
                />
              </FormItem>
              </Fieldset>
              <Fieldset>
              <FormItem>
                <Input
                  id='title'
                  name='title'
                  type='text'
                  {...register('title', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Form Title'
                  error={errors.name}
                  className={`${errors['title'] ? 'error' : ''}`}
                />
                <ErrorMessage
                  errors={errors}
                  name='title'
                  as={InputError}
                />
              </FormItem>
              </Fieldset>
              <Fieldset>
              <FormItem>
                <Input
                  id='description'
                  name='description'
                  type='text'
                  variant='textarea'
                  {...register('description', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Form Description'
                  error={errors.name}
                  className={`${errors['description'] ? 'error' : ''}`}
                  rows={5}
                />
                <ErrorMessage errors={errors} name='description' as={InputError} />
              </FormItem>
              </Fieldset>
              <Fieldset>
              <FormItem>
                <Controller
                  name='active'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                    <>
                      <label htmlFor='active'>Status</label>
                      <AsyncPaginate
                        {...field}
                        inputId='active'
                        ref={ref}
                        isClearable
                        placeholder={'Active?'}
                        className={`react-select ${
                          errors['active'] ? 'error' : ''
                        }`}
                        loadOptions={(search, prevOptions) =>
                          loadOptions(search, prevOptions, activeOptions)
                        }
                      />
                    </>
                  )}
                />
                <ErrorMessage errors={errors} name='active' as={InputError} />
              </FormItem>
            </Fieldset>
            <ButtonGroup>
              <Button type='button' onClick={() => onPreSubmit(false)}>
                <Icon name='check' />
                Add Form
              </Button>
            </ButtonGroup>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel='My dialog'
              className='mymodal'
              overlayClassName='myoverlay'
              closeTimeoutMS={500}
            >
              {submitMessage?.length > 0 ? (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Something went wrong
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      {submitMessage}
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModalClear} variant={'secondary'}>
                      Try again
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Are you sure?
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      Do you really want to add this form?
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModal} variant={'secondary'}>
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)}>Confirm</Button>
                  </div>
                </>
              )}
            </Modal>
          </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
