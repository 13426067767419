import React from 'react';
import PropTypes from 'prop-types';
import Component from './styles';
import theme from '../../../theme/themeMain'

export const Typography = ({ children, variant, tag, ...rest }) => {
  return (
    <Component {...rest} as={tag} variant={variant || tag}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  variant: PropTypes.oneOf(Object.keys(theme.typography.tag)),
  tag: PropTypes.string,
  as: PropTypes.string,
};

Typography.defaultProps = {
  tag: 'h1',
  as: 'p',
};
