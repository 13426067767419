import axiosWrapper from './axiosInterceptor';


export const getPlayerFiles = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/players/team/${teamId}/player/${playerId}/files`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

