import { useMutation, useQueryClient } from 'react-query';
import { addTeam } from '../../../../api/team';

export const useAddTeam = () => {
  const queryClient = useQueryClient();

  return useMutation(addTeam, {
    onSuccess: (response, variables) => {
      // const cachedTodos = queryClient.getQueryData('teams');
      queryClient.invalidateQueries('teams');
      // queryClient.setQueryData('teams', [
      //   ...cachedTodos,
      //   {
      //     id: response.id,
      //     name: variables.name,
      //     statusId: variables.status,
      //     sportId: variables.sport,
      //   },
      // ]);
    },
  });
};
