import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { useQuery } from 'react-query';
import { loadOptions } from '../../../utils/loadSelectOptions';
import Downshift from 'downshift';
import { getAllProblemCategories } from 'api/problems';
import Modal from 'react-modal';
import { getPlayerProblem } from 'api/playerProblems';
import { useEditProblem } from './hooks/useEditProblem';
import { useDebounce } from '../AddProblem/hooks/useDebounce';
import { DownshiftList } from '../AddProblem/DownshiftList';
import { trimFormData } from "../../../utils/trimFormData";
import { getPrivacyTypes } from "../../../api/privacyTypes";
import { formatFormDate } from "../../../utils/formatFormDate";

Modal.setAppElement('#root');

export const EditProblem = () => {
  let { teamId, playerId, problemId } = useParams();

  const {
    register,
    unregister,
    handleSubmit,
    control,
    trigger,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "playerProblem" for a particular Doctor by invoking "getPlayerProblem" hook
  const { data: playerProblem, isLoading, isError } = useQuery(
    ['playerProblem', problemId],
    () => getPlayerProblem(teamId, playerId, problemId)
  );

  // Receiving entity "teams"
  const { data: problemCategories } = useQuery(
    'allProblemCategories',
    getAllProblemCategories
  );

  // Receiving entity "privacyTypesData" by invoking "getPrivacyTypes" hook
  const { data: privacyTypesData = [] } = useQuery('privacyType', getPrivacyTypes);
  const privacyTypesOptions = [];
  for (let i = 0; i < privacyTypesData.length; i++) {
    privacyTypesOptions.push({
      value: privacyTypesData[i],
      label: privacyTypesData[i].name,
    });
  }

  const problemOptions = problemCategories?.problemTypes.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const illnessOptions = problemCategories?.illnessTypes.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const specialityOptions = problemCategories?.illnessSelectBoxList.map(
    (item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }
  );

  const bodyPartOptions = problemCategories?.injuryTypes.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const sideOptions = problemCategories?.injurySelectBoxList.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const sideOptionsForHead = sideOptions?.filter((item) => {
    return item.value === 2;
  });

  const { mutateAsync, isLoading: isLoadingButton } = useEditProblem();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)

      console.log('data: ', data);

    // This is required to make sure privacy value gets sent as an integer
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        teamId,
        playerId,
        problemId,
        name: data.problemName,
        categoryId: data.categoryId,
        problemTypeId: data.problemType.value,
        privacyTypeId: parseInt(newData.privacyType?.id) || parseInt(newData.privacyType),

        ...(data.illnessType && {
          illnessTypeId: data.illnessType.value,
        }),
        ...(data.speciality && {
          illnessSelectBoxId: data.speciality.value,
        }),
        ...(data.bodyPart && { injuryTypeId: data.bodyPart.value }),
        ...(data.bodySide && { injurySelectBoxId: data.bodySide.value }),
      });
      push(`/teams/${teamId}/players/${playerId}/problems`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  const [downShiftValue, setDownShiftValue, items] = useDebounce({
    delay: 1000,
    clearErrors,
    inputName: 'categoryId',
  });

  const watchProblemType = watch('problemType');

  const watchBodyPart = watch('bodyPart');

  const resetDownshiftValue = async () => {
    setValue('categoryId', '');
    await trigger();
  };

  const onDownShiftSelected = (selected) => {
    if (selected) {
      setDownShiftValue(selected.id);
      setValue('categoryId', selected.id);
    }
  };

  // In case we need to unregister some fields
  useEffect(() => {
    if (watchProblemType?.value) {
      (watchProblemType.label !== 'illness' &&
        unregister(['illnessType', 'speciality'])) ||
      (watchProblemType.label !== 'injury' &&
        unregister(['bodyPart', 'side']));
    }
  }, [watchProblemType?.value]);

  useEffect(() => {
    if (playerProblem) {
      const originalPrivacyType = playerProblem.privacyType?.id || null;
      const privacyTypeValue = [];
      if (originalPrivacyType) {
        for(let i=0; i < originalPrivacyType.length; i++){
          privacyTypeValue.push({
            value: originalPrivacyType[i].id,
            label: originalPrivacyType[i].name,
          });
        }
      }
    }


    if (playerProblem && playerProblem?.type.name === 'medical') {
      [
        { name: 'problemName', value: playerProblem.name },
        {
          name: 'problemType',
          value: {
            value: playerProblem.type.id,
            label: playerProblem.type.name,
          },
        },
        {
          name: 'illnessType',
          value: {
            value: playerProblem.illness_type.id,
            label: playerProblem.illness_type.name,
          },
        },
        {
          name: 'speciality',
          value: {
            value: playerProblem.illness_select_box.id,
            label: playerProblem.illness_select_box.name,
          },
        },
        { name: 'categoryId', value: playerProblem.category.id },
        { name: 'privacyType', value: { value: playerProblem.privacyType?.id, label: playerProblem.privacyType?.name }, },
      ].forEach(({ name, value }) => setValue(name, value));
      setDownShiftValue(playerProblem.category.id);
    }
    if (playerProblem && playerProblem?.type.name === 'injury') {
      [
        { name: 'problemName', value: playerProblem.name },
        {
          name: 'problemType',
          value: {
            value: playerProblem.type.id,
            label: playerProblem.type.name,
          },
        },
        {
          name: 'bodyPart',
          value: {
            value: playerProblem.injury_type.id,
            label: playerProblem.injury_type.name,
          },
        },
        {
          name: 'bodySide',
          value: {
            value: playerProblem.injury_select_box.id,
            label: playerProblem.injury_select_box.name,
          },
        },
        { name: 'categoryId', value: playerProblem.category.id },
        { name: 'privacyType', value: { value: playerProblem.privacyType?.id, label: playerProblem.privacyType?.name }, },
      ].forEach(({ name, value }) => setValue(name, value));
      setDownShiftValue(playerProblem.category.id);
    }
  }, [playerProblem, isLoading]);
  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit Problem
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/players/${playerId}/problems`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        {isError && <p>An error occured during receiving problem data</p>}
        {isLoading ? (
          'Receiving problem data...'
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormItem>
              <Controller
                control={control}
                name='categoryId'
                rules={{ required: 'This is required' }}
                render={() => (
                  <Downshift
                    itemToString={(item) => (item ? `${item.name} [${item.diagnosis_code.name}]` : '')}
                    onInputValueChange={(typed) => setDownShiftValue(typed)}
                    onChange={(selection) => onDownShiftSelected(selection)}
                    initialSelectedItem={playerProblem?.category?.name}
                    initialInputValue={playerProblem.category ? `${playerProblem.category.name} [${playerProblem.category.diagnosis_code.name}]` : ''}
                  >
                    {({
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        clearSelection,
                        isOpen,
                        selectedItem,
                      }) => (
                      <div>
                        <label {...getLabelProps()}>Search against name (*required for coding)</label>
                        <Input
                          {...getInputProps()}
                          placeholder='Enter here (minimum 2 characters)'
                          className={`react-select ${
                            errors['categoryId'] ? 'error' : ''
                          }`}
                        >
                          {selectedItem ? (
                            <div
                              className='css-tlfecz-indicatorContainer downshift-close'
                              aria-hidden='true'
                              onClick={() => {
                                clearSelection();
                                resetDownshiftValue();
                              }}
                            >
                              <svg
                                height='20'
                                width='20'
                                viewBox='0 0 20 20'
                                aria-hidden='true'
                                focusable='false'
                                className='css-tj5bde-Svg'
                              >
                                <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                              </svg>
                            </div>
                          ) : null}
                        </Input>
                        <DownshiftList {...getMenuProps()}>
                          {isOpen && items
                            ? items
                              .map((item, index) => (
                                <li
                                  {...getItemProps({
                                    key: item.id,
                                    index,
                                    item,
                                    style: {
                                      fontWeight:
                                        selectedItem === item
                                          ? 'bold'
                                          : 'normal',
                                    },
                                  })}
                                >
                                  {`${item.name} [${item.diagnosis_code.name}]`}
                                </li>
                              ))
                            : null}
                        </DownshiftList>
                      </div>
                    )}
                  </Downshift>
                )}
              />
              <ErrorMessage errors={errors} name='categoryId' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                id='problemName'
                name='problemName'
                type='text'
                {...register('problemName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='Diagnosis – free text (*required for app)'
                error={errors.name}
                className={`${errors['problemName'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='problemName'
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Controller
                  name='privacyType'
                  control={control}
                  rules={{  }}
                  render={({ field }) => (
                      <>
                        <label htmlFor='privacyType'>Privacy Type</label>
                        <AsyncPaginate
                            {...field}
                            inputId='privacyType'
                            isClearable
                            placeholder='All medics'
                            className={`react-select ${
                                errors['privacyType'] ? 'error' : ''
                            }`}
                            loadOptions={(search, prevOptions) =>
                                loadOptions(search, prevOptions, privacyTypesOptions)
                            }
                        />
                      </>
                  )}
              />
              <ErrorMessage errors={errors} name='privacyType' as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name='problemType'
                control={control}
                rules={{ required: 'This is required' }}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='problemType'>Problem Type</label>
                    <AsyncPaginate
                      {...field}
                      inputId='problemType'
                      ref={ref}
                      isClearable
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['problemType'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, problemOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name='problemType'
                as={InputError}
              />
            </FormItem>
            {watchProblemType && (
              <>
                {watchProblemType.label === 'medical' && (
                  <>
                    <FormItem>
                      <Controller
                        name='illnessType'
                        control={control}
                        rules={{ required: 'This is required' }}
                        render={({ field }, ref) => (
                          <>
                            <label htmlFor='illnessType'>Illness type</label>
                            <AsyncPaginate
                              {...field}
                              inputId='illnessType'
                              ref={ref}
                              isClearable
                              placeholder={'Your information'}
                              className={`react-select ${
                                errors['illnessType'] ? 'error' : ''
                              }`}
                              loadOptions={(search, prevOptions) =>
                                loadOptions(search, prevOptions, illnessOptions)
                              }
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='illnessType'
                        as={InputError}
                      />
                    </FormItem>
                    <FormItem>
                      <Controller
                        name='speciality'
                        control={control}
                        rules={{ required: 'This is required' }}
                        render={({ field }, ref) => (
                          <>
                            <label htmlFor='speciality'>Speciality</label>
                            <AsyncPaginate
                              {...field}
                              inputId='speciality'
                              ref={ref}
                              isClearable
                              placeholder={'Your information'}
                              className={`react-select ${
                                errors['speciality'] ? 'error' : ''
                              }`}
                              loadOptions={(search, prevOptions) =>
                                loadOptions(
                                  search,
                                  prevOptions,
                                  specialityOptions
                                )
                              }
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='speciality'
                        as={InputError}
                      />
                    </FormItem>
                  </>
                )}
                {watchProblemType.label === 'injury' && (
                  <>
                    <FormItem>
                      <Controller
                        name='bodyPart'
                        control={control}
                        rules={{ required: 'This is required' }}
                        render={({ field }, ref) => (
                          <>
                            <label htmlFor='bodyPart'>Body Part</label>
                            <AsyncPaginate
                              {...field}
                              inputId='bodyPart'
                              ref={ref}
                              isClearable
                              placeholder={'Your information'}
                              className={`react-select ${
                                errors['bodyPart'] ? 'error' : ''
                              }`}
                              loadOptions={(search, prevOptions) =>
                                loadOptions(
                                  search,
                                  prevOptions,
                                  bodyPartOptions
                                )
                              }
                            />
                          </>
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name='bodyPart'
                        as={InputError}
                      />
                    </FormItem>
                    {watchBodyPart && (
                      <>
                        {watchBodyPart.value === 1 && (
                          <FormItem>
                            <Controller
                              name='bodySide'
                              control={control}
                              rules={{ required: 'This is required' }}
                              render={({ field }, ref) => (
                                <>
                                  <label htmlFor='bodySide'>Side</label>
                                  <AsyncPaginate
                                    {...field}
                                    inputId='bodySide'
                                    ref={ref}
                                    isClearable
                                    placeholder={'Your information'}
                                    className={`react-select ${
                                      errors['bodySide'] ? 'error' : ''
                                    }`}
                                    loadOptions={(search, prevOptions) =>
                                      loadOptions(
                                        search,
                                        prevOptions,
                                        sideOptionsForHead
                                      )
                                    }
                                  />
                                </>
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name='bodySide'
                              as={InputError}
                            />
                          </FormItem>
                        )}
                        {watchBodyPart.value !== 1 && (
                          <FormItem>
                            <Controller
                              name='bodySide'
                              control={control}
                              rules={{ required: 'This is required' }}
                              render={({ field }, ref) => (
                                <>
                                  <label htmlFor='bodySide'>Side</label>
                                  <AsyncPaginate
                                    {...field}
                                    inputId='bodySide'
                                    ref={ref}
                                    isClearable
                                    placeholder={'Your information'}
                                    className={`react-select ${
                                      errors['bodySide'] ? 'error' : ''
                                    }`}
                                    loadOptions={(search, prevOptions) =>
                                      loadOptions(
                                        search,
                                        prevOptions,
                                        sideOptions
                                      )
                                    }
                                  />
                                </>
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name='bodySide'
                              as={InputError}
                            />
                          </FormItem>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Button type='button' onClick={onPreSubmit}>
              <Icon name='check' />
              Submit Problem
            </Button>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel='My dialog'
              className='mymodal'
              overlayClassName='myoverlay'
              closeTimeoutMS={500}
            >
              {submitMessage?.length > 0 ? (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Something went wrong
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      {submitMessage}
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModalClear} variant={'secondary'}>
                      Try again
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Are you sure?
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      Do you really want to add this problem?
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModal} variant={'secondary'}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      disabled={isLoadingButton}
                    >
                      Confirm
                    </Button>
                  </div>
                </>
              )}
            </Modal>
          </form>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};
