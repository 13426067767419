import React from 'react';
import PropTypes from 'prop-types';
import { Component, InputWrapper } from './styles';

export const Input = React.forwardRef(
  (
    {
      label,
      id,
      children,
      type,
      value,
      placeholder,
      required,
      variant,
      ...inputProps
    },
    ref
  ) => {
    return (
      <Component {...inputProps}>
        {label && <label htmlFor={id}>{label}</label>}
        <InputWrapper>
          {variant === 'input' && (
            <input
              ref={ref}
              id={id}
              value={value}
              type={type}
              placeholder={placeholder}
              required={required}
              {...inputProps}
            />
          )}
          {variant === 'textarea' && (
            <textarea
              rows='3'
              ref={ref}
              id={id}
              value={value}
              type={type}
              placeholder={placeholder}
              required={required}
              {...inputProps}
            ></textarea>
          )}
          {children}
        </InputWrapper>
      </Component>
    );
  }
);

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  value: undefined,
  type: 'text',
  label: undefined,
  variant: 'input',
};
