import { useMutation, useQueryClient } from 'react-query';
import { addSport } from 'api/sports';

export const useAddSport = () => {
  const queryClient = useQueryClient();

  return useMutation(addSport, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('sports');
    },
  });
};
