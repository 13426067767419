import axiosWrapper from './axiosInterceptor';

export const getTeam = async (teamId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/teams/${teamId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addTeam = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/teams',
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editTeam = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/teams/${data.id}`,
      responseType: 'json',
      data,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deleteTeam = async ({ id }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/teams/${id}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
