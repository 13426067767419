import { useMutation, useQueryClient } from 'react-query';
import {
  addConsult,
  addProblem,
  addTreatment,
  addMedication,
  addResult,
  addLetter,
} from 'api/playerInformation';

export const useAddInfoConsult = () => {
  const queryClient = useQueryClient();

  return useMutation(addConsult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoProblem = () => {
  const queryClient = useQueryClient();

  return useMutation(addProblem, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoMedications = () => {
  const queryClient = useQueryClient();

  return useMutation(addMedication, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoResult = () => {
  const queryClient = useQueryClient();

  return useMutation(addResult, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoTreatment = () => {
  const queryClient = useQueryClient();

  return useMutation(addTreatment, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};

export const useAddInfoLetter = () => {
  const queryClient = useQueryClient();

  return useMutation(addLetter, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('playerInformation');
    },
  });
};
