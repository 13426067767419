import axiosWrapper from './axiosInterceptor';


export const getPlayerReferrals = async (teamId, playerId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/team/${teamId}/player/${playerId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getExternalReferrals = async (externalId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/external/${externalId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getExternalReferral = async (externalReferralId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/${externalReferralId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addExternalReferral = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/external-referrals`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getExternalReferralPDF = async (teamId, playerId, referralId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-referrals/team/${teamId}/player/${playerId}/referral/${referralId}/print`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};