import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Input } from "../../atoms/Input";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button } from "../../atoms/Button";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  PhotosWrapper,
  Fieldset,
} from "../../organisms/Layout";
import { TableHeader } from "../../organisms/Layout/index";
import { Typography } from "../../atoms/Typography/index";
import { Icon } from "../../atoms/Icon";
import { useQuery } from "react-query";
import { useEditNote } from "./hooks/useEditNote";
import { loadOptions } from "../../../utils/loadSelectOptions";
import Modal from "react-modal";
import { trimFormData } from "../../../utils/trimFormData";
import { getPlayerProblems } from "../../../api/playerProblems";
import { getNotePhoto, getPlayerNote } from "../../../api/playerNotes";
import { getDiagnosisCodeNameFromProblem } from "../../../utils/getDiagnosisCodeNameFromProblem";

Modal.setAppElement("#root");

export const EditNote = () => {
  let { teamId, playerId, noteId } = useParams();

  const maxDate = new Date().toISOString().split("T")[0];

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  // Receiving entity "playerNote" for a particular player by invoking "playerNote" hook
  const { data: playerNoteData, isLoading, isError } = useQuery(
    ["playerNote", playerId],
    () => getPlayerNote(teamId, playerId, noteId)
  );

  const { mutateAsync, isLoading: isLoadingButton } = useEditNote();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const watchPhotos = watch("photos");

  const [formPhotos, setFormPhotos] = useState([]);

  useEffect(() => {
    setFormPhotos(getValues("photos"));
  }, [watchPhotos]);

  const onDelete = (index) => {
    setFormPhotos(
      Array.from(formPhotos).filter((_, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const [deletedPhotos, setDeletedPhotos] = useState([]);

  const onPrevPhotosDelete = (deletedId) => {
    setPrevPhotos(
      prevPhotos.filter((elem) => {
        return elem.id !== deletedId;
      })
    );
    setDeletedPhotos([...deletedPhotos, deletedId]);
  };

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);

    const formData = new FormData();

    formData.append("given_date", data.givenDate);
    if (data.problem?.value) {
      formData.append(
        "problemId",
        data.problem?.value.id ?? data.problem?.value
      );
    }
    formData.append("text_subjective", data.textSubjective);
    formData.append("text_objective", data.textObjective);
    formData.append("text_assessment", data.textAssessment);
    formData.append("text_plan", data.textPlan);
    formData.append("deletedFiles", JSON.stringify(deletedPhotos));

    if (data.photos) {
      for (const key of data.photos) {
        formData.append("file", key);
      }
    }

    // console.log(data)
    // console.log(playerNoteData.problem?.id);
    // console.log(data.problem?.value.id);
    // console.log(Object.fromEntries(formData))

    try {
      await mutateAsync({
        teamId: teamId,
        playerId: playerId,
        noteId: noteId,
        formData,
      });
      push(`/teams/${teamId}/players/${playerId}/notes`);
    } catch (error) {
      console.log("error", error);
      setSubmitMessage(error?.data?.message);
    }
  };

  // Receiving entity "playerProblems" for a particular Team by invoking "getPlayerProblems" hook
  const { data: playerProblemsData = [] } = useQuery(
    ["playerProblems", playerId],
    () => getPlayerProblems(teamId, playerId)
  );

  const problemOptions = [];
  for (let i = 0; i < playerProblemsData.length; ++i) {
    problemOptions.push({
      value: playerProblemsData[i].id,
      label: `${playerProblemsData[i].name}${
        playerProblemsData[i].category
          ? `[${playerProblemsData[i].category?.diagnosis_code.name}]`
          : ""
      }`,
    });
  }

  const [prevPhotos, setPrevPhotos] = useState([]);

  const fetchPhoto = async ({ teamId, playerId, noteId, id }) => {
    const data = await getNotePhoto(teamId, playerId, noteId, id);

    const arr = prevPhotos.map((photo) => {
      if (photo.id === id) {
        return {
          ...photo,
          image: data.base64,
        };
      }

      return photo;
    });

    setPrevPhotos(arr);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage("");
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    const result = await trigger();

    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    if (!isLoading && playerNoteData) {
      [
        {
          name: "problem",
          value: playerNoteData.problem?.id,
        },
        {
          name: "textSubjective",
          value: playerNoteData.text_subjective,
        },
        {
          name: "textObjective",
          value: playerNoteData.text_objective,
        },
        {
          name: "textAssessment",
          value: playerNoteData.text_assessment,
        },
        {
          name: "textPlan",
          value: playerNoteData.text_plan,
        },
        {
          name: "givenDate",
          value: playerNoteData.given_date,
        },
        {
          name: "addedBy",
          value: playerNoteData.added_by,
        },
      ].forEach(({ name, value }) => setValue(name, value));
      const images = playerNoteData.photos.map((photo) => {
        return {
          ...photo,
          image: null,
        };
      });
      setPrevPhotos(images);
    }

    if (playerNoteData?.problem?.id) {
      [
        {
          name: "problem",
          value: {
            value: {
              id: playerNoteData.problem?.id,
              name: playerNoteData.problem?.name,
            },
            label: `${
              playerNoteData.problem.name
            } ${getDiagnosisCodeNameFromProblem(
              playerProblemsData.find(
                (item) => item.id === (playerNoteData || [])[0]?.problem?.id
              )
            )}`,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [playerNoteData]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={"h1"} tag={"h1"}>
          Edit Rehab Note
        </Typography>
        <Button
          as={Link}
          variant={"secondary"}
          to={`/teams/${teamId}/players/${playerId}/notes`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset>
            <FormItem small>
              <Input
                id="givenDate"
                name="givenDate"
                type="date"
                max={maxDate}
                {...register("givenDate", {
                  required: "This is required",
                })}
                placeholder="Enter Here"
                label="Date"
                error={errors.name}
                className={`${errors["givenDate"] ? "error" : ""}`}
              />
              <ErrorMessage errors={errors} name="givenDate" as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name="problem"
                control={control}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor="problem">Problem</label>
                    <AsyncPaginate
                      {...field}
                      inputId="problem"
                      ref={ref}
                      isClearable
                      isSearchable
                      placeholder={"Select Problem"}
                      className={`react-select ${
                        errors["problem"] ? "error" : ""
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, problemOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name="problem" as={InputError} />
            </FormItem>
          </Fieldset>
          <Fieldset>
            <FormItem>
              <Input
                id="textSubjective"
                name="textSubjective"
                type="text"
                variant="textarea"
                {...register("textSubjective")}
                placeholder="Enter text..."
                label="Subjective"
                error={errors.name}
                className={`${errors["textSubjective"] ? "error" : ""}`}
                rows={5}
              />
              <ErrorMessage
                errors={errors}
                name="textSubjective"
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id="textObjective"
                name="textObjective"
                type="text"
                variant="textarea"
                {...register("textObjective")}
                placeholder="Enter text..."
                label="Objective"
                error={errors.name}
                className={`${errors["textObjective"] ? "error" : ""}`}
                rows={5}
              />
              <ErrorMessage
                errors={errors}
                name="textObjective"
                as={InputError}
              />
            </FormItem>
          </Fieldset>
          <Fieldset>
            <FormItem>
              <Input
                id="textAssessment"
                name="textAssessment"
                type="text"
                variant="textarea"
                {...register("textAssessment")}
                placeholder="Enter text..."
                label="Assessment"
                error={errors.name}
                className={`${errors["textAssessment"] ? "error" : ""}`}
                rows={5}
              />
              <ErrorMessage
                errors={errors}
                name="textAssessment"
                as={InputError}
              />
            </FormItem>
            <FormItem>
              <Input
                id="textPlan"
                name="textPlan"
                type="text"
                variant="textarea"
                {...register("textPlan")}
                placeholder="Enter text..."
                label="Plan"
                error={errors.name}
                className={`${errors["textPlan"] ? "error" : ""}`}
                rows={5}
              />
              <ErrorMessage errors={errors} name="textPlan" as={InputError} />
            </FormItem>
          </Fieldset>

          <FormItem>
            <Input
              id="photos"
              name="photos"
              type="file"
              multiple
              accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              {...register("photos")}
              placeholder="Enter Here"
              label="Files"
              error={errors.name}
              className={`${errors["photos"] ? "error" : ""}`}
            />
            <ErrorMessage errors={errors} name="photos" as={InputError} />
            <PhotosWrapper>
              {Array.from(formPhotos).map((i, index) => {
                return (
                  <div key={i + index}>
                    {i.name}
                    <Button
                      aria-label="Delete"
                      icon
                      type="button"
                      onClick={() => onDelete(index)}
                    >
                      <Icon name="remove" />
                    </Button>
                  </div>
                );
              })}
            </PhotosWrapper>
            {prevPhotos.length > 0 && (
              <>
                <label>Current Files</label>
                <PhotosWrapper>
                  {prevPhotos.map((i, index) => {
                    return (
                      <div key={index + i + index}>
                        {i.original_name}
                        <Button
                          type="button"
                          aria-label="Delete"
                          icon
                          onClick={() => onPrevPhotosDelete(i.id)}
                        >
                          <Icon name="remove" />
                        </Button>
                      </div>
                    );
                  })}
                </PhotosWrapper>
              </>
            )}
          </FormItem>

          <Button type="button" onClick={onPreSubmit}>
            <Icon name="check" />
            Edit Rehab Note
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className="modal-body">
                  <Typography as={"h2"} tag={"h2"}>
                    Something went wrong
                  </Typography>
                  <Typography as={"p"} tag={"h4"}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className="modal-buttons">
                  <Button onClick={toggleModalClear} variant={"secondary"}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <Typography as={"h2"} tag={"h2"}>
                    Are you sure?
                  </Typography>
                  <Typography as={"p"} tag={"h4"}>
                    Do you really want to edit this note?
                  </Typography>
                </div>
                <div className="modal-buttons">
                  <Button onClick={toggleModal} variant={"secondary"}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoadingButton}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
