import React from 'react';
import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Input';
import useInputState from './hooks/useInputState';
import { InputWrapper } from './styles';

export const AddListForm = ({ saveTodo }) => {
  const { value, reset, onChange } = useInputState();

  return (
    <InputWrapper>
      <Input
        placeholder='Add todo'
        margin='normal'
        onChange={onChange}
        value={value}
      />
      <Button
        onClick={(event) => {
          event.preventDefault();

          saveTodo(value);
          reset();
        }}
      >
        Add
      </Button>
    </InputWrapper>
  );
};
