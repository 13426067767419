import { useMutation, useQueryClient } from 'react-query';
import { deleteSport } from 'api/sports';

export const useDeleteSport = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSport, {
    onSuccess: () => {
      queryClient.invalidateQueries('sports');
    },
  });
};
