import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { Button } from '../../../../atoms/Button';
import { Icon } from '../../../../atoms/Icon';
import { Typography } from '../../../../atoms/Typography';
import { useHistory } from 'react-router-dom';
import {
  FormItem,
  InputError,
  CheckboxGroup,
  PhotosWrapper,
} from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { Checkbox } from 'components/atoms/Checkbox';
import { getConsultInfo } from 'api/playerInformation';
import { useAddInfoConsultExternal } from '../../hooks';
import { trimFormData } from "../../../../../utils/trimFormData";
import { REQUIRED_FIELD } from "../../../../../constants/massages";

Modal.setAppElement('#root');

export const ConsultModule = ({ params }) => {
  const { push } = useHistory();

  const maxDate = new Date().toISOString().split('T')[0];

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      investigations: [],
      planList: [],
      date: maxDate,
    },
  });

  // Receiving entity "playerConsultInfo"
  const { data: playerConsultInfo = {}, isLoading, isError } = useQuery(
    'playerConsultInfo',
    getConsultInfo
  );

  const [isOpen, setIsOpen] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isAddLoading } = useAddInfoConsultExternal();

  const [formAudios, setFormAudios] = useState([]);

  const watchAudio = watch('audios');

  useEffect(() => {
    setFormAudios(getValues('audios'));
  }, [watchAudio]);

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (params.problemId) {
      formData.append('problemId', params.problemId);
    }

    if (data.history) {
      formData.append('history', data.history);
    }

    if (data.date) {
      formData.append('date', data.date);
    }

    if (data.exam) {
      formData.append('exam', data.exam);
    }

    if (data.diagnosis) {
      formData.append('diagnosisName', data.diagnosis);
    }

    if (data.clinicianName) {
      formData.append('clinicianName', data.clinicianName);
    }

    if (data.location) {
      formData.append('location', data.location);
    }

    if (data.registrationNumber) {
      formData.append('registrationNumber', data.registrationNumber);
    }

    if (data.details) {
      formData.append('details', data.details);
    }

    if (data.investigations.length >= 1) {
      formData.append(
        'investigationsIdsList',
        JSON.stringify(data.investigations.map(Number))
      );
    }

    if (data.plans.length >= 1) {
      formData.append('planIdsList', JSON.stringify(data.plans.map(Number)));
    }

    if (data.audios) {
      for (const key of data.audios) {
        formData.append('files', key);
      }
    }

    try {
      await mutateAsync({
        externalReferralId: params.externalReferralId,
        formData,
        audio: !!watchAudio?.length,
      });
      push(`/referrals/${params.externalReferralId}/info`);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage();
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    if (await trigger()) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    if (watchAudio?.length >= 1) {
      clearErrors();
    }
  }, [watchAudio]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <Input
          id='clinicianName'
          name='clinicianName'
          type='text'
          variant='input'
          {...register('clinicianName', { required: { value: true, message: REQUIRED_FIELD }})}
          placeholder='Enter Here'
          label='Clinician Name'
          error={errors.name}
          className={`${errors['clinicianName'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='clinicianName' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='location'
          name='location'
          type='text'
          variant='input'
          {...register('location', { required: { value: true, message: REQUIRED_FIELD }})}
          placeholder='Enter Here'
          label='Location'
          className={`${errors['location'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='location' as={InputError} />
      </FormItem>
      <FormItem small>
        <Input
          id='date'
          name='date'
          type='date'
          max={maxDate}
          {...register('date')}
          placeholder='Enter Here'
          label='Date'
          error={errors.name}
          className={`${errors['date'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='date' as={InputError}/>
      </FormItem>
      <FormItem>
        <Input
          id='registrationNumber'
          name='registrationNumber'
          type='text'
          variant='input'
          {...register('registrationNumber')}
          placeholder='Enter Here'
          label='Registration Number'
          className={`${errors['registrationNumber'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='registrationNumber' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='history'
          name='history'
          type='text'
          variant='textarea'
          {...register('history', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='History'
          error={errors.name}
          className={`${errors['history'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='history' as={InputError} />
      </FormItem>
      {isError && <p>An error occured during receiving investigations data</p>}
      {isLoading ? (
        'Receiving investigation data...'
      ) : (
        <FormItem>
          <label>Investigations</label>
          <CheckboxGroup>
            {playerConsultInfo?.investigations?.map((item, index) => {
              return (
                <Checkbox
                  key={item + index}
                  name='investigations'
                  type='checkbox'
                  value={item.id}
                  label={item.name}
                  {...register('investigations')}
                  className={`${errors['investigations'] ? 'error' : ''}`}
                />
              );
            })}
          </CheckboxGroup>
        </FormItem>
      )}
      <FormItem>
        <Input
          id='exam'
          name='exam'
          type='text'
          variant='textarea'
          {...register('exam', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='Exam'
          error={errors.name}
          className={`${errors['exam'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='exam' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='diagnosis'
          name='diagnosis'
          type='text'
          variant='textarea'
          {...register('diagnosis', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='Diagnosis'
          error={errors.name}
          className={`${errors['diagnosis'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='diagnosis' as={InputError} />
      </FormItem>
      {isLoading ? (
        'Receiving plans data...'
      ) : (
        <FormItem>
          <label>Treatment</label>
          <CheckboxGroup>
            {playerConsultInfo?.plans?.map((item, index) => {
              return (
                <Checkbox
                  key={item + index}
                  name='plans'
                  type='checkbox'
                  value={item.id}
                  label={item.name}
                  {...register('plans', {
                    validate: (value) =>
                      watchAudio?.length >= 1 ||
                      value?.length >= 1 ||
                      'This is required',
                  })}
                  className={`${errors['plans'] ? 'error' : ''}`}
                />
              );
            })}
            <ErrorMessage errors={errors} name='plans' as={InputError} />
          </CheckboxGroup>
        </FormItem>
      )}
      <FormItem>
        <Input
          id='details'
          name='details'
          type='text'
          variant='textarea'
          {...register('details')}
          placeholder='Enter Here'
          label='Treatment details'
          error={errors.name}
          className={`${errors['details'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='details' as={InputError} />
      </FormItem>
      <Button type='button' onClick={onPreSubmit}>
        <Icon name='check' />
        Submit Information
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='My dialog'
        className='mymodal'
        overlayClassName='myoverlay'
        closeTimeoutMS={500}
      >
        {submitMessage?.length > 0 ? (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                Something went wrong
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                {submitMessage}
              </Typography>
            </div>
            <div className='modal-buttons'>
              <Button onClick={toggleModalClear} variant={'secondary'}>
                Try again
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                Are you sure?
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                Do you really want to add this information?
              </Typography>
            </div>
            <div className='modal-buttons'>
              <Button onClick={toggleModal} variant={'secondary'}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isAddLoading}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal>
    </form>
  );
};
