export const EnumMedicationCategoryTypes = {
  Allergy: {
    id: 1,
    name: 'allergy',
  },
  Meds: {
    id: 2,
    name: 'meds',
  },
  Vaccines: {
    id: 3,
    name: 'vaccines',
  },
}
