import axiosWrapper from './axiosInterceptor';

export const getDiagnosisCodes = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/diagnosis-code',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addProblemCategory = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: '/problem-category',
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const editProblemCategory = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/problem-category/${data.problemId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deleteProblemCategory = async (problemId) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/problem-category/${problemId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const getProblemCategoryById = async (problemCategoryId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/problem-category/${problemCategoryId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getAllProblemCategories = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: '/static/problem-info',
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
