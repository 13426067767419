import React, {useState, useEffect, useContext} from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';

import { getPlayerFiles } from "../../../api/playerFiles";

Modal.setAppElement('#root');

export const PlayerFiles = ({ params }) => {
  let { url } = useRouteMatch();

  const {
    data: playerNotesData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['files', params.playerId], () =>
    getPlayerFiles(params.teamId, params.playerId)
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'File',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Problem',
        accessor: 'col2',
      },
      {
        Header: 'App Info',
        accessor: 'col3',
      },
    ],
    []
  );

  let parentUrl, problemUrl;

  const data = React.useMemo(() => {
    return playerNotesData.map((e) => {
      if (e?.parentType === 'Notes') {
        parentUrl = `${url}/../${e?.parentType.toLowerCase()}/${e.parentId}`;
      } else {
        parentUrl = `${url}/../information/${e?.parentId}`;
      }
      problemUrl = '';
      if (e?.problemId) {
        problemUrl = `${url}/../problems/${e?.problemId}`;
      }

      return {
        col1: e?.filePath && (
          <a href={`${e?.filePath}`} target='_blank'>
            {e?.originalName}
          </a>
        ),
        col2: e?.problemId && problemUrl && (
          <a title={'View'} href={problemUrl}>
            {e?.problemName}
          </a>
        ),
        col3:  e?.parentType && e?.parentId && (
          <a title={'View'} href={parentUrl}>
            View {e?.parentType} {e?.parentId}
          </a>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving teams data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </>
  );
};
