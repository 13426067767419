// This function receives "TeamsId" array and returns the object with correct id+names for breadcrumb component
export const reformatBreadCrumbsTeamsId = (array = {}) => {
  const newInfo = {};

  for (const [, value] of Object.entries(array)) {
    newInfo[value.id] = value.name;
  }
  return newInfo;
};

// This function receives "TeamsId" array and returns the object with correct id+names for breadcrumb component
export const reformatBreadCrumbsExternalId = (array = {}) => {
  const newInfo = {};

  for (const [, value] of Object.entries(array)) {
    newInfo[value.id] = value.name;
  }
  return newInfo;
};

// This function receives "PlayersId" array and returns the object with correct id+name(first_name+last+name) for breadcrumb component
export const reformatBreadCrumbsPlayersId = (array = {}) => {
  const newInfo = {};

  for (const [_key, value] of Object.entries(array)) {
    newInfo[value.id] = `${value.user.first_name} ${value.user.last_name}`;
  }
  return newInfo;
};

// This function receives "DoctorsId" array and returns the object with correct id+name(first_name+last+name) for breadcrumb component
export const reformatBreadCrumbsDoctorsId = (array = {}) => {
  const newInfo = {};

  for (const [_key, value] of Object.entries(array)) {
    if (value.doctor !== null) {
      newInfo[value.doctor.id] = `${value.first_name} ${value.last_name}`;
    }
  }
  return newInfo;
};

// This function receives "ExternalId" array and returns the object with correct id+name(first_name+last+name) for breadcrumb component
export const reformatBreadCrumbsExternalUsersId = (array = {}) => {
  const newInfo = {};

  for (const [_key, value] of Object.entries(array)) {
    if (value.user !== null) {
      newInfo[value.id] = `${value.user.first_name} ${value.user.last_name}`;
    }
  }
  return newInfo;
};