import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { getCookie } from '../utils/cookie';
import { USER_COOKIE } from '../utils/constants';

export const useUser = () => {
  const [context, setContext] = useState(null);

  useEffect(() => {
    const isAuthenticated = getCookie(USER_COOKIE);

    if (isAuthenticated) {
      setContext(jwtDecode(isAuthenticated));
    }
  }, []);

  return [context, setContext];
};
