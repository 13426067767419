import styled from 'styled-components';

export const Component = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
  margin: 0;

  li {
    margin-right: 32px;

    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }
`;
