import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper, PhotosWrapper, Fieldset,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import {getPlayerNote, getNotePhoto, getNotePdfUrl} from "../../../api/playerNotes";



Modal.setAppElement('#root');

export const ViewNote = () => {
  let { teamId, playerId, noteId } = useParams();

  const {
    register,
    setValue,
  } = useForm({
    mode: 'all',
  });

  // Receiving entity "playerNote" for a particular player by invoking "playerNote" hook
  const { data: playerNoteData, isLoading, isError } = useQuery(
    ['playerNote', playerId],
    () => getPlayerNote(teamId, playerId, noteId)
  );

  const [prevPhotos, setPrevPhotos] = useState([]);

  const fetchPhoto = async ({ teamId, playerId, noteId, id }) => {
    const data = await getNotePhoto(
      teamId,
      playerId,
      noteId,
      id
    );

    const link = document.createElement('a');
    link.href = "data:" + data.encoding + ";base64," + data.base64;
    document.body.appendChild(link);
    link.download = data.original_name;
    link.click();
    document.body.removeChild(link);
  };

  const fetchPdfUrl = async ({ teamId, playerId, noteId, id }) => {
    const downloadUrl = await getNotePdfUrl(
      teamId,
      playerId,
      noteId,
      id
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  const formPhotos = [];


  useEffect(() => {
    if (!isLoading && playerNoteData) {
      [
        {
          name: 'problem',
          value: playerNoteData.problem?.name,
        },
        {
          name: 'problemDescription',
          value: playerNoteData.problem?.description,
        },
        {
          name: 'textSubjective',
          value: playerNoteData.text_subjective,
        },
        {
          name: 'textObjective',
          value: playerNoteData.text_objective,
        },
        {
          name: 'textAssessment',
          value: playerNoteData.text_assessment,
        },
        {
          name: 'textPlan',
          value: playerNoteData.text_plan,
        },
        {
          name: 'date',
          value: playerNoteData.given_date,
        },
        {
          name: 'addedBy',
          value: playerNoteData.added_by,
        },
      ].forEach(({ name, value }) => setValue(name, value));
      const images = playerNoteData.photos.map(
        (photo) => {
          return {
            ...photo,
            image: null,
          };
        }
      );
      setPrevPhotos(images);
    }
  }, [playerNoteData]);


  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          View Rehab Note
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/players/${playerId}/notes`}
        >
          Back
        </Button>
      </TableHeader>
      <FormWrapper>
        <form>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='text'
              {...register('date')}
              disabled={true}
              label='Date'
            />
          </FormItem>
          <FormItem>
            <Input
              id='addedBy'
              disabled={true}
              name='addedBy'
              type='text'
              {...register('addedBy')}
              label='Added By'
            />
          </FormItem>
          <FormItem>
            <Input
              name='problem'
              type='text'
              disabled={true}
              label='Problem'
              {...register('problem')}
            />
          </FormItem>
          <FormItem>
            <Input
              id='textSubjective'
              disabled={true}
              name='textSubjective'
              type='text'
              variant='textarea'
              {...register('textSubjective')}
              label='Subjective'
              rows={5}
            />
          </FormItem>
          <FormItem>
            <Input
              id='textObjective'
              disabled={true}
              name='textObjective'
              type='text'
              variant='textarea'
              {...register('textObjective')}
              label='Objective'
              rows={5}
            />
          </FormItem>
          <FormItem>
            <Input
              id='textAssessment'
              disabled={true}
              name='textAssessment'
              type='text'
              variant='textarea'
              {...register('textAssessment')}
              label='Assessment'
              rows={5}
            />
          </FormItem>
          <FormItem>
            <Input
              id='textPlan'
              disabled={true}
              name='textPlan'
              type='text'
              variant='textarea'
              {...register('textPlan')}
              label='Plan'
              rows={5}
            />
          </FormItem>
          <Fieldset>
            <FormItem>
              <PhotosWrapper>
                {Array.from(formPhotos).map((i, index) => {
                  return <div key={i + index}>{i.name}</div>;
                })}
              </PhotosWrapper>
              {prevPhotos.length > 0 && (
                <>
                  <label>Current files</label>
                  <PhotosWrapper>
                    {prevPhotos.map((photo, index) => {
                      return (
                        <div key={index + photo}>
                          {photo.encoding.startsWith('application') && (

                            <Button
                              type='button'
                              aria-label='Fetch url'
                              icon
                              onClick={() =>
                                fetchPdfUrl({
                                  teamId,
                                  playerId,
                                  noteId,
                                  id: photo.id,
                                })
                              }
                            >
                              Download {photo.original_name}
                            </Button>

                          )}
                          {photo.encoding.startsWith('image') && (
                            photo?.image ? (
                              <img
                                alt={photo.original_name}
                                src={`data:${photo?.encoding};base64, ${photo?.image}`}
                              />
                            ) : (
                              <Button
                                type='button'
                                aria-label='Fetch photo'
                                icon
                                onClick={() =>
                                  fetchPhoto({
                                    teamId,
                                    playerId,
                                    noteId,
                                    id: photo.id,
                                  })
                                }
                              >
                                Download {photo.original_name}
                              </Button>
                            )
                          )}
                        </div>
                      );
                    })}
                  </PhotosWrapper>
                </>
              )}
            </FormItem>
          </Fieldset>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};

