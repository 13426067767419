import axiosWrapper from './axiosInterceptor';

export const getAllGenders = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/gender`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};
