import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/atoms/Button';

export const NoResults = ({ info, isTypedMin }) => {
  return (
    <>
      {!info.teams.length && !info.doctors.length && !info.players.length && (
        <>
          <p>No information is available for this request</p>
          <p>{isTypedMin}</p>
        </>
      )}

      {!!(info.teams.length || info.doctors.length || info.players.length) && (
        <Button
          as={Link}
          to={'/search-results'}
          style={{ justifyContent: 'center', marginTop: '10px' }}
        >
          View all results
        </Button>
      )}
    </>
  );
};
