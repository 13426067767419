import React, {useState, useEffect, useContext} from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import { getPlayerNotes } from 'api/playerNotes';
import { UserContext } from '../../../context';

import Modal from 'react-modal';
import { useDeletePlayerNote } from "./hooks/useDeletePlayerNote";
import {Typography} from "../../atoms/Typography";
import {Button} from "../../atoms/Button";

Modal.setAppElement('#root');

export const PlayerNotes = ({ params }) => {
  let { url } = useRouteMatch();

  // Receiving entity "playerNotes" for a particular Player by invoking "playerNotes" hook
  const {
    data: playerNotesData = [],
    isLoading,
    isFetching,
    setValue,
    error,
    isError,
  } = useQuery(['playerNotes', params.playerId], () =>
    getPlayerNotes(params.teamId, params.playerId)
  );

  const [user, setContext] = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);

  const [currentNotes, setCurrentNotes] = useState();

  const { mutateAsync, isLoading: isDeleteLoading } = useDeletePlayerNote();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentNotes(e.id);
    toggleModal();
  };

  const onDeleteNote = () => {
    mutateAsync({
      teamId: params.teamId,
      playerId: params.playerId,
      noteId: currentNotes,
    });
    toggleModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Problem',
        accessor: 'col2',
      },
      {
        Header: 'Rehab Note',
        accessor: 'col3',
      },
      {
        Header: 'Added by',
        accessor: 'col4',
      },
      {
        Header: '',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col7',
        disableSortBy: true,
      },
    ],
    []
  );

  const playerNotesDataDesc = playerNotesData.sort(function(a,b){
    return new Date(b.given_date) - new Date(a.given_date);
  });

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const data = React.useMemo(() => {
    return playerNotesDataDesc.map((e) => {
      let d = new Date(e.given_date);
      let dateTime = dateTimeFormat.format(d);
      return {
        col1: dateTime,
        col2: e?.problem?.name,
        col3: e.text_subjective + e.text_objective + e.text_assessment + e.text_plan,
        col4: e.added_by,
        col5: (
          <InterButton title={'View'} as={Link} to={`${url}/${e.id}`}>
            <Icon name='view' />
          </InterButton>
        ),
        col6: user.id === e.added_by_user?.id && (
          <InterButton title={'Edit'} as={Link} to={`${url}/${e.id}/edit`}>
            <Icon name='edit' />
          </InterButton>
        ),
        col7: user.id === e.added_by_user?.id && (
          <InterButton
            color={'red'}
            title={`Delete`}
            type='button'
            onClick={() => onPreSubmit(e)}
            disabled={isDeleteLoading}
          >
            <Icon name='remove'/>
          </InterButton>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error?.message}</div>}
            {isLoading ? (
              <p>Receiving teams data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel='My dialog'
          className='mymodal'
          overlayClassName='myoverlay'
          closeTimeoutMS={500}
        >
          <div className='modal-body'>
            <Typography as={'h2'} tag={'h2'}>
              Are you sure?
            </Typography>
            <Typography as={'p'} tag={'h4'}>
              Do you really want to delete this note?
            </Typography>
          </div>
          <div className='modal-buttons'>
            <Button onClick={toggleModal} variant={'secondary'}>
              Cancel
            </Button>
            <Button onClick={onDeleteNote}>Confirm</Button>
          </div>
        </Modal>
      </ContentWrapper>
    </>
  );
};
