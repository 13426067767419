import React, { useEffect, useState } from 'react';
import { Icon } from '../../atoms/Icon';
import { useSortBy, useTable, usePagination } from 'react-table';
import { InterButton } from '../../atoms/InterButton/index';
import { Link } from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';

import Modal from 'react-modal';
import { getTeamNotifications } from 'api/teamNotifications';

Modal.setAppElement('#root');

export const TeamNotifications = ({ teamId }) => {
  const {
    data: teamNotificationsData = { notifications: [] },
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['teamNotifications', teamId], () =>
    getTeamNotifications(teamId)
  );

  const teamNotifRefactor = (notifInfo) => {
    if (notifInfo) {
      const option = notifInfo;

      switch (option.action_object_to) {
        case 'team':
          if (option.action !== 'deleted') {
            return (
              <InterButton title={'View team'} as={Link} to={`/teams/${option.team.id}`}>
                <Icon name='view' />
              </InterButton>
            );
          } else return null;
        case 'player':
          if (option.action !== 'deleted') {
            return (
              <InterButton
                title={'View player'}
                as={Link}
                to={`/teams/${option.player.team.id}/players/${option.player.id}`}
              >
                <Icon name='view' />
              </InterButton>
            );
          } else return null;
        case 'doctor':
          if (option.action !== 'deleted') {
            return (
              <InterButton
                title={'View doctor'}
                as={Link}
                to={`/teams/${option.doctor.team.id}/team-medics/${option.doctor.id}/edit`}
              >
                <Icon name='view' />
              </InterButton>
            );
          } else return null;
        case 'configuration':
          return <span>Configuration</span>;
        default:
          return null;
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Object',
        accessor: 'col2',
      },
      {
        Header: 'Action',
        accessor: 'col3',
      },
      {
        Header: 'Done by',
        accessor: 'col4',
      },
      {
        Header: 'Details',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: 'Link',
        accessor: 'col6',
        disableSortBy: true,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return teamNotificationsData.notifications.map((e) => {
      return {
        col1: e.id,
        col2: e.action_object_to,
        col3: e.action,
        col4: e.added_by,
        col5: <span title={e.msg}>{e.msg}</span>,
        col6: teamNotifRefactor(e),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <>
      <ContentWrapper>
        <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
          <TableWrapper>
            {isError && <div>{error.message}</div>}
            {isLoading ? (
              <p>Receiving team medics data...</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </TableWrapper>
        </div>
        {pageCount > 1 && (
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={
                <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
              }
              nextLabel={
                <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
              }
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageCount={pageCount}
              forcePage={selectedPage}
              onPageChange={handlePageClick}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </>
  );
};
