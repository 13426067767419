import React, { useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from '../../../../atoms/Input';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormItem, InputError, Fieldset } from '../../../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { getMedicationsInformationById } from 'api/playerInformation';
import { AllergyModule } from './Modules/allergy';
import { MedsModule } from './Modules/meds';
import { VaccinesModule } from './Modules/vaccines';
import { EnumMedicationCategoryTypes } from "../../../../../constants/medicationCategoryTypes";

Modal.setAppElement('#root');

export const MedicationsModule = ({ params }) => {
  const methods = useForm();

  const { teamId, playerId, informationId } = params;

  const {
    register,
    unregister,
    control,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const medicationsCategoryId = watch('medicationsCategory')?.value?.id;

  const {
    data: medicationsInformation = [],
    isLoading: isLoading,
  } = useQuery(['medicationsInformation', informationId], () =>
    getMedicationsInformationById(teamId, playerId, informationId)
  );

  const _getModule = (id) => {
    switch (id) {
      case EnumMedicationCategoryTypes.Allergy.id:
        return <AllergyModule/>

      case EnumMedicationCategoryTypes.Vaccines.id:
        return <VaccinesModule/>

      case EnumMedicationCategoryTypes.Meds.id:
        return <MedsModule/>

      default:
        return null
    }
  }

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const detailsDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const detailsDateTimeFormat = new Intl.DateTimeFormat("en-GB", detailsDateOptions);

  // In case we need to unregister some fields
  useEffect(() => {
    (medicationsCategoryId !== EnumMedicationCategoryTypes.Allergy.id && unregister(['medication', 'reaction'])) ||
    (medicationsCategoryId !== EnumMedicationCategoryTypes.Vaccines.id &&
      unregister(['form', 'dose', 'times', 'reason'])) ||
    (medicationsCategoryId !== EnumMedicationCategoryTypes.Meds.id && unregister(['type', 'booster']));
  }, [medicationsCategoryId]);

  useEffect(() => {
    if (!isLoading && medicationsInformation) {
      const d = new Date(medicationsInformation.created_at);
      const dateAddedString = dateTimeFormat.format(d);

      let detailsDate, detailsDateString = '';
      if (medicationsInformation.medications.date) {
        detailsDate = new Date(medicationsInformation.medications.date);
        detailsDateString = detailsDateTimeFormat.format(detailsDate);
      }
      reset({
        medicationsCategory: {
          value: {
            id: medicationsInformation.medications.category_type.id,
            name: medicationsInformation.medications.category_type.name,
          },
          label: medicationsInformation.medications.category_type.name,
        },
        name: medicationsInformation.medications.name,
        date: detailsDateString,
        medication: medicationsInformation.medications.medication,
        reaction: medicationsInformation.medications.reaction,
        form: medicationsInformation.medications.form,
        dose: medicationsInformation.medications.dose,
        times: medicationsInformation.medications.times,
        reason: medicationsInformation.medications.reason,
        vaccinesIdsList: medicationsInformation.medications.vaccinesIdsList,
        dateAdded: dateAddedString,
      })
    }
  }, [medicationsInformation]);

  return (
    <FormProvider {...methods}>
      <form>
        <Fieldset>
          <FormItem>
            <Controller
              name='medicationsCategory'
              control={control}
              rules={{ required: 'This is required' }}
              render={({ field }, ref) => (
                <>
                  <label htmlFor='medicationsCategory'>Category</label>
                  <AsyncPaginate
                    {...field}
                    inputId='medicationsCategory'
                    ref={ref}
                    isClearable
                    isDisabled
                    placeholder={'Your information'}
                    className={`react-select ${
                      errors['medicationsCategory'] ? 'error' : ''
                    }`}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name='medicationsCategory'
              as={InputError}
            />
          </FormItem>
          <FormItem small>
            <Input
              id='dateAdded'
              name='dateAdded'
              type='text'
              {...register('dateAdded', {})}
              label='Date Added'
              disabled
            />
          </FormItem>
          <FormItem small>
            <Input
              id='date'
              name='date'
              type='text'
              disabled
              {...register('date', {})}
              label='Date'
            />
          </FormItem>
        </Fieldset>
        {_getModule(medicationsCategoryId)}
      </form>
    </FormProvider>
  );
};
