// This function removes empty fields from form data

const removeEmptyFields = (data) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null || data[key] == undefined) {
      delete data[key];
    }
  });

  return data;
};

// This function reformates data object into a correct one
// First argument is data object from form, second one - new empy object which you wants new data to be collected in

export const formatFormDate = (formData, newData = {}) => {
  removeEmptyFields(formData);

  for (const [key, value] of Object.entries(formData)) {
    if (value != '[object Object]') {
      newData[key] = value;
    } else {
      for (const [key1, value1] of Object.entries(value)) {
        if (key1 === 'value') {
          newData[key] = value1;
        }
      }
    }
  }

  return newData;
};
