import { useMutation, useQueryClient } from 'react-query';

import { editGrouptool } from '../../../../api/grouptools';

export const useEditGrouptool = () => {
  const queryClient = useQueryClient();

  return useMutation(editGrouptool, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('tool');
    },
  });
};
