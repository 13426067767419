import React from 'react'
import { Typography } from "../../../atoms/Typography";
import { OrderedList } from "../../../atoms/OrderedList";
import { StyledRoot, StyledLogo } from '../styles'

export const MobileResetPassword = () => {
  return (
    <StyledRoot>
      <StyledLogo name='mainLogo' />
      <Typography as={'p'} tag={'h2'}>Player Reset Password</Typography>
      <OrderedList
        title={'Follow these next steps in order to set new password'}
        list={[
          'Open the link on your email app and select the mobile app as the startup app (when prompted)',
          'Go through the steps prompted on the mobile app.',
        ]}
      />
    </StyledRoot>
  )
}
