import { useMutation, useQueryClient } from 'react-query';

import { addExternalToProblem } from '../../../../api/playerProblems';

export const useAddProblemToExternal = () => {
  const queryClient = useQueryClient();
  return useMutation(addExternalToProblem, {
    onSuccess: () => {
      queryClient.invalidateQueries('playerProblems');
    },
  });
};
