import React, {useContext} from 'react';
import {Switch, Route, Link, useRouteMatch, useParams} from 'react-router-dom';

import {ContentWrapper} from "../../organisms/Layout";

import { ExternalReferralsActive } from './active';
import { ExternalReferralsArchived } from "./archived";
import { OneReferral } from "./one";
import { ProblemInformation } from "./problemInformation";
import { ViewOneInformation } from "./viewOneInformation";
import {ExternalUsersAddInformation} from "../ExternalUsersAddInformation";

export const ExternalUsersLandingPage = () => {

  return (
    <>
      <ContentWrapper>
          <Switch>
              <Route path={'/referrals/:externalReferralId/team/:teamId/player/:playerId/information/:informationId'}>
                  <ViewOneInformation />
              </Route>
              <Route path={'/referrals/:externalReferralId/add-information'}>
                  <ExternalUsersAddInformation />
              </Route>
              <Route path={'/referrals/:externalReferralId/info'}>
                  <ProblemInformation />
              </Route>
            <Route path={'/referrals/:externalReferralId'}>
                <OneReferral />
            </Route>
            <Route path={'/referrals'}>
              <ExternalReferralsActive />
            </Route>
            <Route path={'/archived'}>
              <ExternalReferralsArchived />
            </Route>
          </Switch>
      </ContentWrapper>
    </>
  );
};

