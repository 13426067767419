import React from "react";
import styled, { css } from "styled-components";
import themeMain from "../../../theme/themeMain";

export const PublicWrapper = styled.div`
  ${({ styles }) =>
    styles &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      background-color: ${themeMain.colors.gray[600]};
    `}
`;

const PublicLayout = ({ children, styles }) => (
  <PublicWrapper styles={styles}>{children}</PublicWrapper>
);

export default PublicLayout;
