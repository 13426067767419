import axiosWrapper from './axiosInterceptor';

export const getExternalUsers = async (externalId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external/${externalId}/external-users`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getAllExternalUsers = async () => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-users`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getExternalUserById = async (externalUserId) => {
  try {
    const response = await axiosWrapper({
      method: 'GET',
      url: `/external-users/${externalUserId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const addExternalUser = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'POST',
      url: `/external-users/external/${data.externalId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const editExternalUser = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PUT',
      url: `/external-users/${data.externalUserId}`,
      responseType: 'json',
      withCredentials: true,
      data,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};

export const deleteExternalUser = async ({ externalUserId }) => {
  try {
    const response = await axiosWrapper({
      method: 'DELETE',
      url: `/external-users/${externalUserId}`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
