import { useMutation, useQueryClient } from 'react-query';
import { deletePlayerNote } from '../../../../api/playerNotes';

export const useDeletePlayerNote = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlayerNote, {
    onSuccess: () => {
      queryClient.invalidateQueries('playerNotes');
    },
  });
};
