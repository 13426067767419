import { useMutation, useQueryClient } from 'react-query';
import { deletePlayer } from '../../../../api/players';

export const useDeletePlayer = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlayer, {
    onSuccess: () => {
      queryClient.invalidateQueries('players');
      queryClient.invalidateQueries('teamPlayers');
    },
  });
};
