import { useMutation } from 'react-query';
import { passwordReset } from '../../../../api/auth';

export const usePasswordReset = () => {
  return useMutation(passwordReset, {
    onSuccess: (response, variables) => {
      return response;
    },
  });
};
