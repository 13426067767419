import React, { useRef } from 'react';
import { useDetectOutsideClick } from '../../../hooks/useDetectOutsideClick';
import { UserAvatar } from "../../atoms/UserAvatar";
import { Nav } from './styles';

export const Dropdown = ({ children }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <div className='menu-container'>
      <button
        onClick={onClick}
        className={`menu-trigger ${isActive ? 'active' : 'inactive'}`}
        style={{ height: '32px', width: '32px', justifyContent: 'center' }}
      >
        <UserAvatar />
      </button>
      <Nav
        ref={dropdownRef}
        onClick={onClick}
        className={`menu ${isActive ? 'active' : 'inactive'}`}
      >
        {children}
      </Nav>
    </div>
  );
};
