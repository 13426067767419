import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import { ForgotForm, InputError } from '../Layout';
import { Icon } from '../../atoms/Icon';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { useInvite } from './hooks/useInvite';
import { AuthButton } from 'components/atoms/AuthButton';

export const Invitation = () => {
  const { token } = useParams();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useInvite();

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    try {
      await mutateAsync({
        token,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <ForgotForm onSubmit={handleSubmit(onSubmit)}>
      <Icon name='mainLogo' />
      <Typography as={'h2'} tag={'h2'} style={{ textAlign: 'center'}}>
        Welcome to AB3 Portal
      </Typography>
      {isSuccess ? (
        <>
          <StyledInvitationContainer>
            <Typography variant={'subtitle1'} tag={'p'} style={{ textAlign: 'center'}}>
              Your password needs to be set first. Click here to do so
            </Typography>
          </StyledInvitationContainer>
          <StyledLinkButton to={'/auth/forgot-password'}>Click here</StyledLinkButton>
        </>
      ) : (
        <>
          <StyledInvitationContainer>
            <Typography tag={'p'} variant={'subtitle1'} style={{ textAlign: 'center'}}>
              Click the button below to setup your account
            </Typography>
          </StyledInvitationContainer>
          <StyledButton type='submit' disabled={isLoading}>
            Setup account
          </StyledButton>
        </>
      )}
      {isError && (
        <div>
          <InputError>Something went wrong</InputError>
          <InputError>{error?.data?.message}</InputError>
        </div>
      )}
    </ForgotForm>
  );
};


const StyledInvitationContainer = styled.div`
  margin-bottom: 20px;
`

const StyledLinkButton = styled(AuthButton)`
  width: 100%;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
`
