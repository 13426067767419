import { useMutation, useQueryClient } from 'react-query';
import { addProblemCategory } from 'api/problems';

export const useAddProblemCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(addProblemCategory, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('problemCategories');
    },
  });
};
