import { NavBar } from '../../../components/organisms/NavBar';
import React from 'react';

const Header = () => {
  return (
    <header>
      <NavBar />
    </header>
  );
};

export default Header;
