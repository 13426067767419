import styled from 'styled-components';

export const DefaultTextAvatar = styled.span`
  font-size: 12px;
  margin: 0;
`

export const LogoAvatar = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`
