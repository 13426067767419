import styled from 'styled-components';

const Component = styled.button`
  ${(props) => props.color === 'red' ? props.theme.interButtonDelete : props.theme.interButton};

  svg {
    transform: translateY(-1px);
    margin-right: 10px;
  }
`;

export default Component;
