import { editExternalUser } from '../../../../api/externalUsers';
import { useMutation, useQueryClient } from 'react-query';

export const useEditExternalUser = () => {
  const queryClient = useQueryClient();

  return useMutation(editExternalUser, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('externalUsers');
    },
  });
};
