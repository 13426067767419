import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
  Fieldset,
} from '../../organisms/Layout';
import { TableHeader } from '../../organisms/Layout/index';
import { Typography } from '../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { trimFormData } from "../../../utils/trimFormData";
import { formatFormDate } from "../../../utils/formatFormDate";
import { getTool } from "../../../api/grouptools";
import { getAllStatuses } from "../../../api/statuses";
import { useEditGrouptool } from './hooks/useEditGrouptool';

Modal.setAppElement('#root');

export const EditGrouptool = () => {
  // If the route is not included in Teams/index.json...
  // pass teamId in via the function
  // export const EditGrouptool = ({ teamId }) => {
  // and on Teams/index.js add this...
  // <EditGrouptool teamId={teamId} />
  const { teamId, toolId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { data: groupToolData, isLoading, isError } = useQuery(
      ['grouptool', toolId],
      () => getTool(teamId, toolId)
  );

  // Receiving entity "statuses" by invoking "getAllStatuses" hook, used for "active"
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);
  const activeOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    activeOptions.push({
      value: statusesData[i].id,
      label: statusesData[i].name,
    });
  }

// console.log(activeOptions);
  console.log('groupToolData: ', groupToolData);


  const { mutateAsync, isLoading: isLoadingButton } = useEditGrouptool();

  const history = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const editedData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim);

    formatFormDate(data, editedData);

    try {
      await mutateAsync({
        teamId: teamId,
        toolId: toolId,
        url: editedData.url,
        title: editedData.title,
        description: editedData.description,
        active: parseInt(editedData.active?.id) || parseInt(editedData.active),
      });
      history.push(`/teams/${teamId}/tools`);

      // goBack cannot be just like this, or else the redirect happens straight away when the page loads
      // history.goBack();
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    const result = await trigger();

    if (result) {
      toggleModal();
    }
  };

  useEffect(() => {
    if (!isLoading && groupToolData) {
      [
        {
          name: 'tool',
          value: groupToolData.id,
        },
        {
          name: 'url',
          value: groupToolData.url,
        },
        {
          name: 'title',
          value: groupToolData.title,
        },
        {
          name: 'description',
          value: groupToolData.description,
        },
        {
          name: 'active',
          value: {
            value: groupToolData.active.id,
            label: groupToolData.active.name,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [groupToolData, isLoading]);



  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit Tool: {teamId}
        </Typography>
        <Button
          as={Link}
          variant={'secondary'}
          to={`/teams/${teamId}/tools`}
        >
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset>
            <FormItem>
              <Input
                id='url'
                name='url'
                type='text'
                {...register('url', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='Tool URL'
                error={errors.name}
                className={`${errors['url'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='url' as={InputError} />
            </FormItem>
            </Fieldset>
          <Fieldset>
            <FormItem>
              <Input
                id='title'
                name='title'
                type='text'
                {...register('title')}
                placeholder='Enter text...'
                label='Tool Title'
                error={errors.name}
                className={`${errors['title'] ? 'error' : ''}`}
              />
              <ErrorMessage
                errors={errors}
                name='title'
                as={InputError}
              />
            </FormItem>
          </Fieldset>
          <Fieldset>
            <FormItem>
              <Input
                id='description'
                name='description'
                type='text'
                variant='textarea'
                {...register('description')}
                placeholder='Enter text...'
                label='Tool Description'
                error={errors.name}
                className={`${errors['description'] ? 'error' : ''}`}
                rows={5}
              />
              <ErrorMessage
                errors={errors}
                name='description'
                as={InputError}
              />
            </FormItem>
          </Fieldset>
          <Fieldset>
            <FormItem>
              <Controller
                  name='active'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field }, ref) => (
                      <>
                        <label htmlFor='active'>Status</label>
                        <AsyncPaginate
                            {...field}
                            inputId='active'
                            ref={ref}
                            isClearable
                            placeholder={'Active?'}
                            className={`react-select ${
                                errors['active'] ? 'error' : ''
                            }`}
                            loadOptions={(search, prevOptions) =>
                                loadOptions(search, prevOptions, activeOptions)
                            }
                        />
                      </>
                  )}
              />
              <ErrorMessage errors={errors} name='active' as={InputError} />
            </FormItem>
          </Fieldset>



          <Button type='button' onClick={onPreSubmit}>
            <Icon name='check' />
            Edit Tool
          </Button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel='My dialog'
            className='mymodal'
            overlayClassName='myoverlay'
            closeTimeoutMS={500}
          >
            {submitMessage?.length > 0 ? (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Something went wrong
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    {submitMessage}
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModalClear} variant={'secondary'}>
                    Try again
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <Typography as={'h2'} tag={'h2'}>
                    Are you sure?
                  </Typography>
                  <Typography as={'p'} tag={'h4'}>
                    Do you really want to edit this tool?
                  </Typography>
                </div>
                <div className='modal-buttons'>
                  <Button onClick={toggleModal} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoadingButton}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </form>
      </FormWrapper>
    </ContentWrapper>
  );
};
