import React, { useEffect, useState, useContext } from 'react';
import { Icon } from '../../atoms/Icon';
import { useSortBy, useTable, usePagination } from 'react-table';
import { Button } from '../../atoms/Button/index';
import { Status } from '../../atoms/Status/index';
import { InterButton } from '../../atoms/InterButton/index';
import { Typography } from '../../atoms/Typography';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  ContentWrapper,
  TableWrapper,
  TableHeader,
  PaginationWrapper,
} from '../../organisms/Layout';
import ReactPaginate from 'react-paginate';
import { UserContext } from '../../../context';
import { useQuery } from 'react-query';
import { getPlayers } from '../../../api/players';
import { ADMIN } from '../../../utils/constants';
import { useDeletePlayer } from './hooks/useDeleteTeamPlayer';
import themeMain from '../../../theme/themeMain';

import Modal from 'react-modal';

Modal.setAppElement('#root');

export const PlayersTable = () => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  // Receiving entity "players"
  const {
    data: playersData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery('players', getPlayers);

  const { mutateAsync, isLoading: isDeleteLoading } = useDeletePlayer();

  const [isOpen, setIsOpen] = useState(false);

  const [currentPlayer, setCurrentPlayer] = useState();
  const [playerTeam, setPlayerTeam] = useState();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentPlayer(e.id);
    setPlayerTeam(e.team.id);
    toggleModal();
  };

  const onDeletePlayer = () => {
    mutateAsync({ teamId: playerTeam, playerId: currentPlayer });
    toggleModal();
  };

  // Table Header Columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Player Name',
        accessor: 'col2',
      },
      {
        Header: 'Last Logged In',
        accessor: 'col3',
      },
      {
        Header: 'Status',
        accessor: 'col4',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col5',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col6',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'col7',
        disableSortBy: true,
      },
    ],
    []
  );

  // Table data recompiled from query request to react-table tbody data
  const data = React.useMemo(() => {
    return playersData.map((e) => {
      return {
        col1: e.id,
        col2: `${e.user.first_name} ${e.user.last_name}`,
        col3: e.user.last_logged_in,
        col4: (
          <Status variant={e.status.name}>
            {e.status.name && e.status.name.toUpperCase()}
          </Status>
        ),
        col5: (
          <InterButton title={`View ${e?.user?.first_name} ${e?.user?.last_name}`} as={Link} to={`/teams/${e.team?.id}/players/${e.id}/problems`}>
            <Icon name='view' />
          </InterButton>
        ),
        col6: (
          <InterButton
            title={`Edit ${e?.user?.first_name} ${e?.user?.last_name}`}
            as={Link}
            to={`/teams/${e.team?.id}/players/${e.id}/edit`}
          >
            <Icon name='edit' />
          </InterButton>
        ),
        col7: (
          <InterButton
            color={'red'}
            type='button'
            onClick={() => onPreSubmit(e)}
            disabled={isDeleteLoading}
          >
            <Icon name='remove' />
          </InterButton>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Pass our default pageSize
    },
    useSortBy,
    usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Players
        </Typography>
        {user?.role === ADMIN && (
          <Button as={Link} to={`${url}/add-player`}>
            Add a Player
          </Button>
        )}
      </TableHeader>
      <TableWrapper>
        {isError && <div>{error?.message}</div>}
        {isLoading ? (
          <p>Receiving players data...</p>
        ) : (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </TableWrapper>
      {pageCount > 1 && (
        <PaginationWrapper>
          {/* React Paginate component*/}
          <ReactPaginate
            previousLabel={
              <span>
                <Icon name='arrowLeft' />
                Previous
              </span>
            }
            nextLabel={
              <span>
                Next
                <Icon name='arrowRight' />
              </span>
            }
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={pageCount}
            forcePage={selectedPage}
            onPageChange={handlePageClick}
          />
        </PaginationWrapper>
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='My dialog'
        className='mymodal'
        overlayClassName='myoverlay'
        closeTimeoutMS={500}
      >
        <div className='modal-body'>
          <Typography as={'h2'} tag={'h2'}>
            Are you sure?
          </Typography>
          <Typography as={'p'} tag={'h4'}>
            Do you really want to delete this player?
          </Typography>
        </div>
        <div className='modal-buttons'>
          <Button onClick={toggleModal} variant={'secondary'}>
            Cancel
          </Button>
          <Button onClick={onDeletePlayer}>Confirm</Button>
        </div>
      </Modal>
    </ContentWrapper>
  );
};
