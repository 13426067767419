import React from 'react';
import PropTypes from 'prop-types';
import Component from './styles';

export const Status = ({ children, ...rest }) => {
  return <Component {...rest}>{children}</Component>;
};

Status.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  variant: PropTypes.string,
};

Status.defaultProps = {
  variant: 'active',
};
