import React, {useContext} from 'react';
import {Switch, Route, Link, useRouteMatch} from 'react-router-dom';

import { ExternalUsersTable } from "./users";
import {ContentWrapper, SubheaderWrapper, TableButtonGroup, TableHeader, TableSubheader} from "../../organisms/Layout";
import { UserContext } from "../../../context";
import { ExternalReferrals } from "../ExternalReferrals";

export const ExternalUsers = () => {
  let { url } = useRouteMatch();

  // Determining whether user is admin by checking property "role", is used for rendering UI depending on role
  const [user] = useContext(UserContext);

  return (
      <>
        <ContentWrapper>
          <Switch>
            <Route exact path={'/external/:externalId'}>
              <ExternalUsersTable />
            </Route>
            <Route path={'/external/:externalId/referrals'}>
              <ExternalReferrals />
            </Route>
          </Switch>
        </ContentWrapper>
      </>
  );
};