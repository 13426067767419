import styled from 'styled-components';

export const Component = styled.span`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
