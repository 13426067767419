import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import themeMain from '../../../theme/themeMain';
import { fonts } from '../../../theme/fonts';
import { devices } from '../../../theme/devices';

export const ComponentWrapper = styled(NavLink)`
  text-decoration: none;
  display: block;
  padding: 8px 0;

  @media ${devices.laptop} {
    display: inline;
    padding: 0;
    margin-right: 16px;
  }

  span {
    color: ${themeMain.colors.boldText};
    font-weight: bold;
    font-size: 18px;
  }
  
  &.active {
    span {
      color: ${themeMain.colors.brandRed};

      @media ${devices.laptop} {
        color: ${themeMain.colors.brandRed};
      }
    }

    @media ${devices.laptop} {
      padding-bottom: 23px;
      border-bottom: 2px solid ${themeMain.colors.brandRed};
    }
  }
`;

export const Component = styled.span`
  font: 500 14px/16px ${fonts.monserrat};
  color: ${themeMain.colors.gray[300]};
  padding: 0 4px;
`;
