import React, {useContext, useState} from 'react';
import { Redirect } from 'react-router';
import jwtDecode from "jwt-decode";

import { getCookie } from '../../utils/cookie';
import { EXTERNAL, USER_COOKIE } from '../../utils/constants';
import { UserContext } from "../../context";


// From "/" this page either redirects them to login, or gives them the teams page if already logged in
const Navigator = () => {
  const auth_token = getCookie(USER_COOKIE);
  // const extusr = jwtDecode(auth_token);
  // const extusrRole = extusr.role;


  /*if (auth_token && jwtDecode(auth_token).role === EXTERNAL) {
    return <Redirect to={`/referrals`} />;
  }*/
  if (auth_token) {
    console.log('auth token decoded: ', jwtDecode(auth_token));
    console.log('auth token decoded, role: ', jwtDecode(auth_token).role);
    if (jwtDecode(auth_token)?.role == 'external') {
      console.log('value of (referrals): ', jwtDecode(auth_token).role);
      return <Redirect to={`/referrals`} />;
    } else if (jwtDecode(auth_token)?.role != 'external') {
      console.log('value of (teams): ', jwtDecode(auth_token).role);
      return <Redirect to={`/teams`} />;
    }

  }
  return <Redirect to={`/login`} />;
};

export default Navigator;
