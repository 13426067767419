import axiosWrapper from './axiosInterceptor';

export const editUser = async (data) => {
  try {
    const response = await axiosWrapper({
      method: 'PATCH',
      url: '/user',
      responseType: 'json',
      withCredentials: true,
      data: data.formData,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err.response);
  }
};
