import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AddPlayer } from './../AddPlayer';
import { PlayersTable } from './players';

export const Players = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/players'}>
          <PlayersTable />
        </Route>
        <Route path={'/players/add-player'}>
          <AddPlayer />
        </Route>
      </Switch>
    </>
  );
};
