import React from 'react'
import {Button} from "../../atoms/Button";
import {Icon} from "../../atoms/Icon";
import {PhotosWrapper} from "../Layout";
import {getLetterInformationPdfUrl, getLetterInformationPhoto} from "../../../api/playerInformation";

export const EnumFormImagesMode = {
  Add: 'add',
  Edit: 'edit',
  View: 'view',
}

export const FormImages = ({
  params: {teamId, playerId, informationId},
  mode,
  files = [],
  currentFiles = [],
  onDelete,
  onDeleteFromServer,
  onLoad,
}) => {

  const fetchPhoto = async ({ teamId, playerId, informationId, id }) => {
    const data = await getLetterInformationPhoto(
      teamId,
      playerId,
      informationId,
      id
    );

    const link = document.createElement('a');
    link.href = "data:" + data.encoding + ";base64," + data.base64;
    document.body.appendChild(link);
    link.download = data.original_name;
    link.click();
    document.body.removeChild(link);
  };

  const fetchPdfUrl = async ({ teamId, playerId, informationId, id }) => {
    const downloadUrl = await getLetterInformationPdfUrl(
      teamId,
      playerId,
      informationId,
      id
    );

    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  };

  switch (mode) {
    case EnumFormImagesMode.Add:
      return (
        <PhotosWrapper>
          {Array.from(currentFiles).map((i, index) => {
            return (
              <div key={i + index}>
                {i.name}
                <Button
                  aria-label='Delete'
                  icon
                  type='button'
                  onClick={() => onDelete(index)}
                >
                  <Icon name='remove'/>
                </Button>
              </div>
            );
          })}
        </PhotosWrapper>
      )

    case EnumFormImagesMode.Edit:
      return (
        <>
          <PhotosWrapper>
            {Array.from(currentFiles).map((i, index) => {
              return (
                <div key={i + index}>
                  {i.name}
                  <Button
                    aria-label='Delete'
                    icon
                    type='button'
                    onClick={() => onDelete(index)}
                  >
                    <Icon name='remove' />
                  </Button>
                </div>
              );
            })}
          </PhotosWrapper>
          {files.length > 0 && (
            <>
              <label>Current photos</label>
              <PhotosWrapper>
                {files.map((i, index) => {
                  return (
                    <div key={index + i + index}>
                      {i.original_name}
                      <Button
                        type='button'
                        aria-label='Delete'
                        icon
                        onClick={() => onDeleteFromServer(i.id)}
                      >
                        <Icon name='remove' />
                      </Button>
                    </div>
                  );
                })}
              </PhotosWrapper>
            </>
          )}
        </>
      )

    case EnumFormImagesMode.View:
      return (
        <>
          {files.length > 0 && (
            <>
              <label>Current files</label>
              <PhotosWrapper>
                {files.map((photo, index) => {
                  return (
                    <div key={index + photo}>
                      {photo.encoding.startsWith('application') && (

                        <Button
                        type='button'
                        aria-label='Fetch url'
                        icon
                        onClick={() =>
                          fetchPdfUrl({
                            teamId,
                            playerId,
                            informationId,
                            id: photo.id,
                          })
                        }
                      >
                          Download {photo.original_name}
                      </Button>

                      )}
                      {photo.encoding.startsWith('image') && (
                      photo?.image ? (
                        <img
                          alt={photo.original_name}
                          src={`data:${photo?.encoding};base64, ${photo?.image}`}
                        />
                      ) : (
                        <Button
                          type='button'
                          aria-label='Fetch photo'
                          icon
                          onClick={() =>
                            fetchPhoto({
                              teamId,
                              playerId,
                              informationId,
                              id: photo.id,
                            })
                          }
                        >
                          Download {photo.original_name}
                        </Button>
                      )
                        )}
                    </div>
                  );
                })}
              </PhotosWrapper>
            </>
          )}
        </>
      )

    default:
      return null
  }
}
