import { useMutation, useQueryClient } from 'react-query';
import { deleteProblemCategory } from 'api/problems';

export const useDeleteProblemCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteProblemCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries('problemCategories');
    },
  });
};
