import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';

export const Nav = styled.nav`
  min-width: 260px;
  padding: 16px;
  background: ${themeMain.colors.white};
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  > button {
    width: 100%;
    justify-content: center;
  }

  > a {
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
    }
  }
`;
