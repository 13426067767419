import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Modal from 'react-modal';

import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import {
  FormWrapper,
  FormItem,
  InputError,
  ContentWrapper,
} from '../../organisms/Layout';
import { TableHeader } from './../../organisms/Layout/index';
import { Typography } from './../../atoms/Typography/index';
import { Icon } from '../../atoms/Icon';
import { formatFormDate } from '../../../utils/formatFormDate';
import { getExternal } from '../../../api/external-one';
import { useEditExternal } from '../External/hooks/useEditExternal';
import { getAllStatuses } from '../../../api/statuses';
import { loadOptions } from '../../../utils/loadSelectOptions';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const EditExternal = () => {
  let { externalId } = useParams();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // const watchStatus = watch('status');

  // const [phase, setPhase] = useState();

  // useEffect(() => {
  //   setPhase(watchStatus?.value?.id);
  // }, [watchStatus]);

  // Receiving entity "Team" for a particular Team by invoking "getTeam" hook
  const { data: externalData, isLoading, isError } = useQuery(
    ['external', externalId],
    () => getExternal(externalId)
  );

  // Receiving entity "statuses" by invoking "getAllStatuses" hook
  const { data: statusesData = [] } = useQuery('statuses', getAllStatuses);

  const statusesOptions = [];
  for (let i = 0; i < statusesData.length; ++i) {
    statusesOptions.push({
      value: statusesData[i],
      label: statusesData[i].name,
    });
  }

  const { mutateAsync, isLoading: isUpdating } = useEditExternal();

  const { push } = useHistory();

  const [submitMessage, setSubmitMessage] = useState();

  const newData = {};

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    // !Important: do not forget to invoke "formatFormDate" function with 2 arguments (1: form data, 2: new empty object) before sumbitting the form, it is required, otherwise you might submit form with incorrect data.
    formatFormDate(data, newData);

    try {
      await mutateAsync({
        //Check whether entity exists and should be updated
        // ...(playerFirstName && { name: newData.playerFirstName }),
        id: externalData.id,
        name: newData.externalName,
        address_1: newData.externalAddress1,
        address_2: newData.externalAddress2,
        address_3: newData.externalAddress3,
        address_postcode: newData.externalPostcode,
        telephone_number: newData.externalPhone,
        contact_name: newData.externalContactName,
        contact_email: newData.externalEmail,
        statusId: parseInt(newData.status?.id),
      });
      push('/external');
    } catch (error) {
      setSubmitMessage(error?.data?.message);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage('');
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  // Setting default values to react-hook-form component, depending on receiving data for this entity
  useEffect(() => {
    if (!isLoading) {
      [
        { name: 'externalName', value: externalData.name },
        { name: 'externalAddress1', value: externalData.address_1 },
        { name: 'externalAddress2', value: externalData.address_2 },
        { name: 'externalAddress3', value: externalData.address_3 },
        { name: 'externalPostcode', value: externalData.address_postcode },
        { name: 'externalPhone', value: externalData.telephone_number },
        { name: 'externalContactName', value: externalData.contact_name },
        { name: 'externalEmail', value: externalData.contact_email },
        {
          name: 'status',
          value: {
            value: { id: externalData.status?.id, name: externalData.status?.name },
            label: externalData.status?.name,
          },
        },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [externalData, isLoading]);

  return (
    <ContentWrapper>
      <TableHeader>
        <Typography as={'h1'} tag={'h1'}>
          Edit External Company
        </Typography>
        <Button as={Link} variant={'secondary'} to={`/external/${externalId}`}>
          Cancel
        </Button>
      </TableHeader>
      <FormWrapper>
        {isError && <p>An error occurred during receiving team data</p>}
        {isLoading ? (
          'Receiving external company data...'
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormItem>
              <Input
                id='externalName'
                name='externalName'
                type='text'
                {...register('externalName', {
                  required: 'This is required',
                })}
                placeholder='Enter Here'
                label='External Company Name'
                error={errors.name}
                className={`${errors['externalName'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalName' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalAddress1'
                  name='externalAddress1'
                  type='text'
                  {...register('externalAddress1', {
                    required: 'This is required',
                  })}
                  placeholder={'Enter Here'}
                  label='Address 1'
                  error={errors.name}
                  className={`${errors['externalAddress1'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalAddress1' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalAddress2'
                  name='externalAddress2'
                  type='text'
                  {...register('externalAddress2', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Address 2'
                  error={errors.name}
                  className={`${errors['externalAddress2'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalAddress2' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalAddress3'
                  name='externalAddress3'
                  type='text'
                  {...register('externalAddress3', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Address 3'
                  error={errors.name}
                  className={`${errors['externalAddress3'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalAddress3' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalPostcode'
                  name='externalPostcode'
                  type='text'
                  {...register('externalPostcode', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Postcode'
                  error={errors.name}
                  className={`${errors['externalPostcode'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalPostcode' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalPhone'
                  name='externalPhone'
                  type='text'
                  {...register('externalPhone', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Phone number'
                  error={errors.name}
                  className={`${errors['externalPhone'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalPhone' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalContactName'
                  name='externalContactName'
                  type='text'
                  {...register('externalContactName', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Contact Name'
                  error={errors.name}
                  className={`${errors['externalContactName'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalContactName' as={InputError} />
            </FormItem>
            <FormItem>
              <Input
                  id='externalEmail'
                  name='externalEmail'
                  type='text'
                  {...register('externalEmail', {
                    required: 'This is required',
                  })}
                  placeholder='Enter Here'
                  label='Email'
                  error={errors.name}
                  className={`${errors['externalEmail'] ? 'error' : ''}`}
              />
              <ErrorMessage errors={errors} name='externalEmail' as={InputError} />
            </FormItem>
            <FormItem>
              <Controller
                name='status'
                control={control}
                rules={{ required: 'This is required' }}
                render={({ field }, ref) => (
                  <>
                    <label htmlFor='status'>Status</label>
                    <AsyncPaginate
                      {...field}
                      ref={ref}
                      isClearable
                      placeholder={'Your information'}
                      className={`react-select ${
                        errors['status'] ? 'error' : ''
                      }`}
                      loadOptions={(search, prevOptions) =>
                        loadOptions(search, prevOptions, statusesOptions)
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage errors={errors} name='status' as={InputError} />
            </FormItem>
            <Button type='button' onClick={onPreSubmit}>
              <Icon name='check' />
              Submit Team
            </Button>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel='My dialog'
              className='mymodal'
              overlayClassName='myoverlay'
              closeTimeoutMS={500}
            >
              {submitMessage?.length > 0 ? (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Something went wrong
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      {submitMessage}
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModalClear} variant={'secondary'}>
                      Try again
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className='modal-body'>
                    <Typography as={'h2'} tag={'h2'}>
                      Are you sure?
                    </Typography>
                    <Typography as={'p'} tag={'h4'}>
                      Do you really want to edit this team?
                    </Typography>
                  </div>
                  <div className='modal-buttons'>
                    <Button onClick={toggleModal} variant={'secondary'}>
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)}>Confirm</Button>
                  </div>
                </>
              )}
            </Modal>
          </form>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};
