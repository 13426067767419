import React from 'react';
import { Container, Logo, Nav } from '../../../components/organisms/Layout';
import { Icon } from 'components/atoms/Icon';

const ConsultLayout = ({ children }) => {
  return (
    <>
      <Nav>
        <Container>
          <Logo>
            <Icon name='logo' />
          </Logo>
        </Container>
      </Nav>
      <main style={{ padding: '25px 0 60px' }}>
        <Container>{children}</Container>
      </main>
    </>
  );
};

export default ConsultLayout;
