import { useMutation, useQueryClient } from 'react-query';
import { editSport } from 'api/sports';

export const useEditSport = () => {
  const queryClient = useQueryClient();

  return useMutation(editSport, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries('sports');
    },
  });
};
