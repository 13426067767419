import React, { useState, useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import Modal from "react-modal";
import { useQuery } from 'react-query';
import ReactPaginate from 'react-paginate';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Icon } from '../../atoms/Icon';
import { InterButton } from '../../atoms/InterButton/index';
import {
  ContentWrapper,
  TableWrapper,
  PaginationWrapper,
} from '../../organisms/Layout';
import {getPlayerInformationForReferral, getPlayerProblemInformation} from 'api/playerInformation';
import { Typography } from "../../atoms/Typography";
import { Button } from "../../atoms/Button";
import {Status} from "../../atoms/Status";


export const ProblemInformation = () => {
  const { url } = useRouteMatch();
  const { externalReferralId } = useParams();
  const problemsUrl = url.substring(0, url.lastIndexOf("\/"));
  const playersUrl = problemsUrl.substring(0, problemsUrl.lastIndexOf("\/"));

  console.log('externalReferralId: ', externalReferralId);

  const {
    data: playerInformationData = [],
    isLoading,
    isFetching,
    error,
    isError,
  } = useQuery(['playerProblemInformation'], () =>
      getPlayerInformationForReferral(externalReferralId)
  );

  console.log('playerInformationData: ', playerInformationData);


  const [isOpen, setIsOpen] = useState(false);

  const [currentInformation, setCurrentInformation] = useState();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onPreSubmit = (e) => {
    setCurrentInformation(e.id);
    toggleModal();
  };

/*  const onDeleteProblem = () => {
    //console.log(teamId, playerId, currentInformation);
    mutateAsync({
      teamId: teamId,
      playerId: playerId,
      informationId: currentInformation,
    });
    toggleModal();
  };*/

  const columns = React.useMemo(
      () => [
        {
          Header: 'Date',
          accessor: 'col1', // accessor is the "key" in the data
        },
        {
          Header: 'Date Added',
          accessor: 'col2', // accessor is the "key" in the data
        },
        {
          Header: 'Category',
          accessor: 'col3',
        },
        {
          Header: 'Added by',
          accessor: 'col4',
        },
        {
          Header: 'Problem',
          accessor: 'col5',
        },

        {
          Header: 'Status',
          accessor: 'col6',
        },
        {
          Header: '',
          accessor: 'col7',
          disableSortBy: true,
        },
      ],
      []
  );

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat("en-GB", dateOptions);

  const infoDateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const infoDateTimeFormat = new Intl.DateTimeFormat("en-GB", infoDateOptions);

  const data = React.useMemo(() => {
    return playerInformationData.map((e) => {
      let infoDate, infoDateString, dateTime = '';
      // Make sure the date exists to avoid RangeError
      if (e?.created_at) {
        let d = new Date(e?.created_at);
        dateTime = dateTimeFormat.format(d);
        if (e?.infoDate) {
          infoDate = new Date(e?.infoDate);
          infoDateString = infoDateTimeFormat.format(infoDate);
        }
      }

      const playerId = e.player?.id;
      const teamId = e.player?.team?.id;
      const problemId = e.problem?.id;

      // console.log('teamId: ', teamId);
      // console.log('playerId: ', playerId);
      // console.log('problemId: ', problemId);

      return {
        col1: infoDateString,
        col2: dateTime,
        col3: e?.category_type?.name,
        col4: e?.added_by,
        col5: e?.problem?.name,
        col6: (
            <Status variant={e?.status.name}>
              {e?.status.name && e?.status.name.toUpperCase()}
            </Status>
        ),
        col7: (
            <InterButton title={'View'} as={Link} to={`${playersUrl}/${externalReferralId}/team/${teamId}/player/${playerId}/information/${e.id}`}>
              <Icon name='view' />
            </InterButton>
        ),
      };
    });
  }, [isLoading, isFetching]);

  // React-table parameters
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { sortBy },
  } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 10 }, // Pass our default pageSize
      },
      useSortBy,
      usePagination
  );

  const [selectedPage, setSelectedPage] = useState(0);

  // Tracker shows which page is selected via pagination clicking
  const handlePageClick = (e) => {
    const currentPage = e.selected;
    setSelectedPage(currentPage);
    gotoPage(currentPage);
  };

  // Setting the correct active page in Pagination component after sorting the table by any column
  useEffect(() => {
    gotoPage(0);
    setSelectedPage(0);
  }, [sortBy]);


  let problemName, resultsExist, lettersExist, detailsExist, medicationsExist, consultsExist, treatmentsExist, showFilesLink;
  React.useMemo(() => {
    return playerInformationData.map((e) => {
      // Get the problem name to put into the title, is probably a better way to do this.
      if (problemName === undefined) {
        problemName = e?.problem?.name;
      }

      // Which types of information exist for this problem?
      if (resultsExist === undefined && e?.category_type?.name === 'results') {
        resultsExist = true;
      }
      if (lettersExist === undefined && e?.category_type?.name === 'letters') {
        lettersExist = true;
      }
      if (detailsExist === undefined && e?.category_type?.name === 'details') {
        detailsExist = true;
      }
      if (medicationsExist === undefined && e?.category_type?.name === 'medications') {
        medicationsExist = true;
      }
      if (consultsExist === undefined && e?.category_type?.name === 'consultations') {
        consultsExist = true;
      }
      if (treatmentsExist === undefined && e?.category_type?.name === 'treatments') {
        treatmentsExist = true;
      }
      // If any info is present show the files link
      if (resultsExist || lettersExist || detailsExist || medicationsExist || consultsExist || treatmentsExist) {
        showFilesLink = true;
      }

    });
  });

  return (
      <>
        <ContentWrapper>
          <Typography as={'h1'} tag={'h1'}>
            Problem: {problemName}
          </Typography>
          <div style={{ paddingTop: '24px', boxShadow: '0 -1px 0 0 #e0e0e0' }}>
            <div>
{/*              {resultsExist && <InterButton title={'RESULTS'} as={Link} to={`${url}/results`}>RESULTS</InterButton>}
              {lettersExist && <InterButton title={'LETTERS'} as={Link} to={`${url}/letters`}>LETTERS</InterButton>}
              {detailsExist && <InterButton title={'DETAILS'} as={Link} to={`${url}/details`}>DETAILS</InterButton>}
              {medicationsExist && <InterButton title={'MEDICATIONS'} as={Link} to={`${url}/medication`}>MEDICATION</InterButton>}
              {consultsExist && <InterButton title={'CONSULTATIONS'} as={Link} to={`${url}/consultations`}>CONSULTATIONS</InterButton>}
              {treatmentsExist && <InterButton title={'TREATMENTS'} as={Link} to={`${url}/treatments`}>TREATMENTS</InterButton>}
              {showFilesLink && <InterButton title={'FILES'} as={Link} to={`${url}/files`}>FILES</InterButton>}*/}
            </div>
            <div style={{
              textAlign: "right",
              margin: "0",
            }}>
              <InterButton title={'Add information'} as={Link} to={`${playersUrl}/${externalReferralId}/add-information`}>
                <Icon name='add' />
                Add Info
              </InterButton>
            </div>
            <TableWrapper>
              {isError && <div>{error?.message}</div>}
              {isLoading ? (
                  <p>Receiving problem data...</p>
              ) : (
                  <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                              <th
                                  {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                  )}
                              >
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>
                            {column.isSorted
                                ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                : null}
                          </span>
                              </th>
                          ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                              );
                            })}
                          </tr>
                      );
                    })}
                    </tbody>
                  </table>
              )}
            </TableWrapper>
          </div>
          {pageCount > 1 && (
              <PaginationWrapper>
                <ReactPaginate
                    previousLabel={
                      <span>
                  <Icon name='arrowLeft' />
                  Previous
                </span>
                    }
                    nextLabel={
                      <span>
                  Next
                  <Icon name='arrowRight' />
                </span>
                    }
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageCount={pageCount}
                    forcePage={selectedPage}
                    onPageChange={handlePageClick}
                />
              </PaginationWrapper>
          )}
        </ContentWrapper>
      </>
  );
};
