import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  FormItem,
  InputError,
  CheckboxGroup,
  PhotosWrapper,
} from '../../organisms/Layout';
import { useQuery } from 'react-query';
import Modal from 'react-modal';
import { AddList } from 'components/organisms/AddList';
import { Checkbox } from 'components/atoms/Checkbox';
import { getPublicConsultInfo } from 'api/playerInformation';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Input } from 'components/atoms/Input';
import { Typography } from 'components/atoms/Typography';
import { useAddPublicConsult } from './hooks/useAddPublicConsult';
import { getPublicPlayerProblems } from 'api/publicConsult';
import { loadOptions } from 'utils/loadSelectOptions';
import { trimFormData } from "../../../utils/trimFormData";

Modal.setAppElement('#root');

export const AddPublicConsult = () => {
  const params = useParams();
  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      investigations: [],
      planList: [],
    },
  });

  // Receiving entity "playerConsultInfo"
  const { data: playerConsultInfo = {}, isLoading, isError } = useQuery(
    ['playerPublicConsultInfo', params.token],
    () => getPublicConsultInfo(params.token)
  );

  const { data: playerProblemsData = [] } = useQuery(
    ['playerProblems', params.token],
    () => getPublicPlayerProblems(params)
  );

  const problemOptions = [];
  for (let i = 0; i < playerProblemsData.length; ++i) {
    problemOptions.push({
      value: playerProblemsData[i],
      label: playerProblemsData[i].name,
    });
  }

  const [isOpen, setIsOpen] = useState(false);

  const [diagnosisClick, setDiagnosisClick] = useState(false);

  //TodoList values array
  const [diagnosisValue, setDiagnosisValue] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();

  const { mutateAsync, isLoading: isAddLoading } = useAddPublicConsult();

  const [formAudios, setFormAudios] = useState([]);

  const watchAudio = watch('audios');

  useEffect(() => {
    setFormAudios(getValues('audios'));
  }, [watchAudio]);

  const onSubmit = async (dataToTrim) => {
    const data = trimFormData(dataToTrim)
    const formData = new FormData();

    if (params.problemId) {
      formData.append('problemId', params.problemId);
    }

    if (data.history) {
      formData.append('history', data.history);
    }

    if (data.exam) {
      formData.append('exam', data.exam);
    }

    if (data.diagnosis) {
      formData.append('diagnosisName', data.diagnosis);
    }

    if (diagnosisValue.length >= 1) {
      formData.append('diagnosisList', JSON.stringify(diagnosisValue));
    }

    if (data.planName) {
      formData.append('planName', data.planName);
    }

    if (data.details) {
      formData.append('details', data.details);
    }

    if (data.investigations.length >= 1) {
      formData.append(
        'investigationsIdsList',
        JSON.stringify(data.investigations.map(Number))
      );
    }

    if (data.plans.length >= 1) {
      formData.append('planIdsList', JSON.stringify(data.plans.map(Number)));
    }

    if (data.audios) {
      for (const key of data.audios) {
        formData.append('files', key);
      }
    }

    try {
      await mutateAsync({
        teamId: params.teamId,
        playerId: params.playerId,
        token: params.token,
        formData,
        audio: !!watchAudio?.length,
      });
      setSubmitMessage('Public consultation has been added');
      setTimeout(() => {
        push(`/login`);
      }, 5000);
    } catch (error) {
      console.log('error', error);
      setSubmitMessage(error?.data?.message);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModalClear = () => {
    setSubmitMessage();
    setIsOpen(!isOpen);
  };

  const onPreSubmit = async () => {
    await trigger();

    if (!(watchAudio?.length >= 1) && diagnosisValue.length < 1) {
      setError('diagnosisList', {
        message: 'This is required. Please select at least one',
      });
    }

    if (!Object.keys(errors).length) {
      toggleModal();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitMessage('');
    }, 500);
  }, [isOpen]);

  useEffect(() => {
    if (watchAudio?.length >= 1) {
      clearErrors();
    }

    if (
      !(watchAudio?.length >= 1) &&
      diagnosisClick &&
      diagnosisValue.length < 1
    ) {
      setError('diagnosisList', {
        message: 'This is required. Please select at least one',
      });
    } else clearErrors('diagnosisList');
  }, [diagnosisValue, watchAudio]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <Controller
          name='problem'
          control={control}
          render={({ field }, ref) => (
            <>
              <label htmlFor='problem'>Problem</label>
              <AsyncPaginate
                {...field}
                inputId='problem'
                ref={ref}
                isClearable
                placeholder={'Your information'}
                className={`react-select ${errors['problem'] ? 'error' : ''}`}
                //onChange={({ value }) => setProblem({ value })}
                loadOptions={(search, prevOptions) =>
                  loadOptions(search, prevOptions, problemOptions)
                }
              />
            </>
          )}
        />
        <ErrorMessage errors={errors} name='problem' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='history'
          name='history'
          type='text'
          variant='textarea'
          {...register('history', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='History'
          error={errors.name}
          className={`${errors['history'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='history' as={InputError} />
      </FormItem>
      {isError && <p>An error occured during receiving investigations data</p>}
      {isLoading ? (
        'Receiving investigation data...'
      ) : (
        <FormItem>
          <label>Investigations</label>
          <CheckboxGroup>
            {playerConsultInfo?.investigations?.map((item, index) => {
              return (
                <Checkbox
                  key={item + index}
                  name='investigations'
                  type='checkbox'
                  value={item.id}
                  label={item.name}
                  {...register('investigations')}
                  className={`${errors['investigations'] ? 'error' : ''}`}
                />
              );
            })}
          </CheckboxGroup>
        </FormItem>
      )}
      <FormItem>
        <Input
          id='exam'
          name='exam'
          type='text'
          variant='textarea'
          {...register('exam', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='Exam'
          error={errors.name}
          className={`${errors['exam'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='exam' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='diagnosis'
          name='diagnosis'
          type='text'
          variant='textarea'
          {...register('diagnosis', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='Diagnosis'
          error={errors.name}
          className={`${errors['diagnosis'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='diagnosis' as={InputError} />
      </FormItem>
      <FormItem onBlur={() => setDiagnosisClick(true)}>
        <AddList
          label='Diagnosis list'
          name='diagnosisList'
          setValues={setDiagnosisValue}
          {...register('diagnosisList', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          className={`${errors['diagnosisList'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='diagnosisList' as={InputError} />
      </FormItem>
      <FormItem>
        <Input
          id='planName'
          name='planName'
          type='text'
          variant='textarea'
          {...register('planName', {
            validate: (value) =>
              watchAudio?.length >= 1 ||
              value?.length >= 1 ||
              'This is required',
          })}
          placeholder='Enter Here'
          label='Plan'
          error={errors.name}
          className={`${errors['planName'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='planName' as={InputError} />
      </FormItem>
      {isLoading ? (
        'Receiving plans data...'
      ) : (
        <FormItem>
          <CheckboxGroup>
            {playerConsultInfo?.plans?.map((item, index) => {
              return (
                <Checkbox
                  key={item + index}
                  name='plans'
                  type='checkbox'
                  value={item.id}
                  label={item.name}
                  {...register('plans', {
                    validate: (value) =>
                      watchAudio?.length >= 1 ||
                      value?.length >= 1 ||
                      'This is required',
                  })}
                  className={`${errors['plans'] ? 'error' : ''}`}
                />
              );
            })}
            <ErrorMessage errors={errors} name='plans' as={InputError} />
          </CheckboxGroup>
        </FormItem>
      )}
      <FormItem>
        <Input
          id='audios'
          name='audios'
          type='file'
          multiple
          accept='audio/mpeg, audio/mp4, audio/ogg, audio/3gpp, audio/3gp2, audio/webm'
          {...register('audios', {})}
          placeholder='Enter Here'
          label='Audio'
          error={errors.name}
          className={`${errors['audios'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='audios' as={InputError} />
        <PhotosWrapper>
          {Array.from(formAudios).map((i, index) => {
            return <div key={i + index}>{i.name}</div>;
          })}
        </PhotosWrapper>
      </FormItem>
      <FormItem>
        <Input
          id='details'
          name='details'
          type='text'
          variant='textarea'
          {...register('details')}
          placeholder='Enter Here'
          label='Details'
          error={errors.name}
          className={`${errors['details'] ? 'error' : ''}`}
        />
        <ErrorMessage errors={errors} name='details' as={InputError} />
      </FormItem>
      <Button type='button' onClick={onPreSubmit}>
        <Icon name='check' />
        Submit Information
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='My dialog'
        className='mymodal'
        overlayClassName='myoverlay'
        closeTimeoutMS={500}
      >
        {submitMessage?.length > 0 ? (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                {submitMessage === 'Public consultation has been added'
                  ? null
                  : 'Something went wrong'}
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                {submitMessage}
              </Typography>
            </div>
            {!(submitMessage === 'Public consultation has been added') && (
              <div className='modal-buttons'>
                <Button onClick={toggleModalClear} variant={'secondary'}>
                  Try again
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className='modal-body'>
              <Typography as={'h2'} tag={'h2'}>
                Are you sure?
              </Typography>
              <Typography as={'p'} tag={'h4'}>
                Do you really want to add this information?
              </Typography>
            </div>
            <div className='modal-buttons'>
              <Button onClick={toggleModal} variant={'secondary'}>
                Cancel
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isAddLoading}>
                Confirm
              </Button>
            </div>
          </>
        )}
      </Modal>
    </form>
  );
};
