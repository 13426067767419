import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useHistory } from 'react-router-dom';
import { useLogin } from './hooks/useLogin';
import { Input } from '../../atoms/Input';
import { InputError, LoginForm } from './../Layout/index';
import { Icon } from '../../atoms/Icon';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button/index';
import { Checkbox } from '../../atoms/Checkbox/index';
import { createCookie, getCookie, parseCookieString } from '../../../utils/cookie';
import { USER_COOKIE } from '../../../utils/constants';
import { PlainLink } from 'components/atoms/PlainLink';
import jwtDecode from "jwt-decode";
import { UserContext } from "../../../context";
import { Link } from 'react-router-dom';

const fpPromise = FingerprintJS.load();

const Login = () => {
  const history = useHistory();
  const [visitorId, setVisitorId] = useState('');
  const { mutateAsync, isLoading, isSuccess, isError, error } = useLogin();

  useEffect(() => {
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();

      // This is the visitor identifier:
      setVisitorId(result.visitorId);
    })();
  }, []);

  const { register, handleSubmit } = useForm();

  const [isNotLoggedIn, setNotIsLoggedIn] = useState(false);

  const [_, setContext] = useContext(UserContext);

  const onSend = async (data) => {
    try {
      if (data) {
        const response = await mutateAsync({
          fingerprint: visitorId,
          userAgent: 'web-application',
          email: data.email,
          password: data.password,
          authMethod: 'basic',
          isRememberMe: !!data.remember,
        });

        localStorage.setItem('rememberMe', data.remember);
        let authToken = getCookie(USER_COOKIE);

        if (!authToken && response.cookies?.length) {
          const cookies = parseCookieString(response.cookies.join('; '));
          [
            'Authentication',
            'Refresh',
            'User'
          ].forEach(key => {
            createCookie(key, cookies[key], cookies['Max-Age'])
          })
          authToken = cookies['User']
        }

        // TODO Redirect!?
        if (authToken) {
          console.log('auth token decoded (login): ', jwtDecode(authToken));
          console.log('auth token decoded (login), role: ', jwtDecode(authToken).role);
          setContext(jwtDecode(authToken));
          if (jwtDecode(authToken)?.role == 'external') {
            return history.push('/referrals');
          } else if (jwtDecode(authToken)?.role != 'external') {
            return history.push('/teams');
          }
          // return history.push('/teams');
        }

        setNotIsLoggedIn(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = (data) => {
    onSend(data);
  };

  return (
    <LoginForm onSubmit={handleSubmit(onSubmit)}>
      {isSuccess && isNotLoggedIn ? (
        <>
          <Icon name='mainLogo' />
          <Typography as={'h2'} tag={'h2'} style={{ textAlign: 'center' }}>
            Two-Factor Authentication
          </Typography>
          <Typography as={'h2'} tag={'p'} variant={'subtitle1'} style={{ textAlign: 'center' }}>
            You have been sent an email to pass Two-Factor Authentication. Please
            check your email and follow the instructions in order to finalise your login.
          </Typography>
        </>
      ) : (
        <>
          <div>
            <Icon name='mainLogo' />
            <Typography as={'h2'} tag={'h2'}>
              Sign in to your account
            </Typography>
          </div>
          <Input
            name='email'
            type='email'
            placeholder='Email address'
            required
            className='first'
            {...register('email', {
              required: 'This is required',
            })}
          />
          <Input
            name='password'
            type='password'
            placeholder='Password'
            required
            className='second'
            {...register('password', {
              required: 'This is required',
            })}
          />
          <PlainLink to={'/auth/forgot-password'} title={'Reset your password'}>
            Forgot your password?
          </PlainLink>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '24px',
            }}
          >
            <Checkbox
              type='checkbox'
              name='remember'
              value={true}
              {...register('remember', {})}
              label='Remember me'
            />
          </div>
          <Button type='submit' disabled={isLoading}>
            Sign in
          </Button>
        </>
      )}

      {isError && (
        <div>
          <InputError>Something went wrong</InputError>
          <InputError>{error?.data?.message}</InputError>
        </div>
      )}
      <Link className="p-4" to={'/registration'}>Navigate to Registration</Link>
    </LoginForm>
  );
};

export default Login;
